import React, { useState } from "react";
import { Card, Badge, } from "react-bootstrap";
import { QUERY_FUNERALHOMES } from "../../utils/queries";
import { useQuery } from "@apollo/client";
import generatePages from "../../utils/generatePages";
import PagesHandler from "../PagesHandler";
import Filter from "../Filter";
import FirstCallCard from "../FirstCallCard";
import $ from "jquery";
import "jquery-mask-plugin/dist/jquery.mask.min";
const FirstcallList = ({ firstCalls, loading, currentUser }) => {
  const { data } = useQuery(QUERY_FUNERALHOMES);
  const funeralhomes = data?.FuneralHomes || [];
  const [filteredPages, setFilteredPages] = useState([]);
  const [page, setPage] = useState(0);
  var filteredFirstCalls = [...firstCalls];
  filteredFirstCalls = filteredFirstCalls.sort(
    ({ takenAt: aTime }, { takenAt: bTime }) => {
      const timeB = new Date(bTime.split("at"));
      const timeA = new Date(aTime.split(["at"]));
      return timeB.getTime() - timeA.getTime();
    }
  );
  var pages = generatePages(filteredFirstCalls, 6);
  if (!loading) {
    $("#time").mask("00:00 ll", {
      placeholder: "10:20pm",
      translation: {
        l: { pattern: /[pma]/ },
      },
    });
  }
  const [filter, setFilter] = useState("none");
  const handleFilterChange = (filter) => {
    setFilter(filter);
    setPage(0);
    if (filter === "none") {
      setFilteredPages([]);
    } else {
      setFilteredPages(
        generatePages(
          filteredFirstCalls.filter(({ funeralhome }) => {
            return funeralhome?.name === filter;
          }),
          6
        )
      );
    }
  };
  return (
    <Card>
      <Card.Header style={{ textAlign: "center" }}>
        <PagesHandler
          pageTitle={"First Call List"}
          page={page}
          pages={filteredPages.length > 0 ? filteredPages : pages}
          pageMessage={
            filteredPages.length > 0
              ? filteredPages.length > 1 && (
                  <>
                    Filtered Page #{page + 1} Start Taken Date and Time:{" "}
                    {filteredPages[page] && filteredPages[page][0].takenAt} End
                    Taken Date and Time:{" "}
                    {filteredPages[page] &&
                      filteredPages[page][filteredPages[page].length - 1]
                        .takenAt}
                  </>
                )
              : pages.length > 1 && (
                  <>
                    Page #{page + 1} Start Taken Date and Time:{" "}
                    {pages[page] && pages[page][0].takenAt} End Taken Date and
                    Time:{" "}
                    {pages[page] && pages[page][pages[page].length - 1].takenAt}
                  </>
                )
          }
          previousBadge={
            filteredPages.length > 0
              ? filteredPages.length > 1 && (
                  <Badge style={{ fontSize: "1.5vh" }}>
                    Start Taken Date and Time:{" "}
                    {filteredPages[page - 1] &&
                      filteredPages[page - 1][0].takenAt}
                    <br />
                    End Taken Date and Time:{" "}
                    {filteredPages[page - 1] &&
                      filteredPages[page - 1][filteredPages[page].length - 1]
                        .takenAt}
                  </Badge>
                )
              : pages.length > 1 && (
                  <Badge style={{ fontSize: "1.5vh" }}>
                    Start Taken Date and Time:{" "}
                    {pages[page - 1] && pages[page - 1][0].takenAt}
                    <br />
                    End Taken Date and Time:{" "}
                    {pages[page - 1] &&
                      pages[page - 1][pages[page].length - 1].takenAt}
                  </Badge>
                )
          }
          setPage={(page) => setPage(page)}
          nextBadge={
            filteredPages.length > 0
              ? filteredPages.length > 1 && (
                  <Badge style={{ fontSize: "1.5vh" }}>
                    Start Taken Date and Time:{" "}
                    {filteredPages[page + 1] &&
                      filteredPages[page + 1][0].takenAt}
                    <br />
                    End Taken Date and Time:{" "}
                    {filteredPages[page + 1] &&
                      filteredPages[page + 1][
                        filteredPages[page + 1].length - 1
                      ].takenAt}
                  </Badge>
                )
              : pages.length > 1 && (
                  <Badge style={{ fontSize: "1.5vh" }}>
                    Start Taken Date and Time:{" "}
                    {pages[page + 1] && pages[page + 1][0].takenAt}
                    <br />
                    End Taken Date and Time:{" "}
                    {pages[page + 1] &&
                      pages[page + 1][pages[page + 1].length - 1].takenAt}
                  </Badge>
                )
          }
          jumpBadges={
            filteredPages.length > 0
              ? filteredPages.length > 1 &&
                filteredPages.map((pageInfo) => (
                  <Badge style={{ fontSize: "1.5vh" }}>
                    Start Taken Date and Time: {pageInfo[0].takenAt}
                    <br />
                    End Taken Date and Time:{" "}
                    {pageInfo[pageInfo.length - 1].takenAt}
                  </Badge>
                ))
              : pages.length > 1 &&
                pages.map((pageInfo) => (
                  <Badge style={{ fontSize: "1.5vh" }}>
                    Start Taken Date and Time: {pageInfo[0].takenAt}
                    <br />
                    End Taken Date and Time:{" "}
                    {pageInfo[pageInfo.length - 1].takenAt}
                  </Badge>
                ))
          }
        ></PagesHandler>
        <br />

        {!loading && (
          <>
             <Filter filter={filter} setFilter={handleFilterChange} filterType='Funeral Home Name' filterData={funeralhomes.map(({name}) => {return name})}></Filter>
          </>
        )}
      </Card.Header>
      <Card.Body
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
        }}
      >
        {filteredPages.length > 0
          ? filteredPages &&
            filteredPages[page].map((firstcall) => (
              <FirstCallCard
                key={firstcall._id}
                currentUser={currentUser}
                firstcall={firstcall}
                funeralhomes={funeralhomes}
              ></FirstCallCard>
            ))
          : pages[page] &&
            pages[page].map((firstcall) => (
              <FirstCallCard
                key={firstcall._id}
                currentUser={currentUser}
                firstcall={firstcall}
                funeralhomes={funeralhomes}
              ></FirstCallCard>
            ))}
      </Card.Body>
    </Card>
  );
};
export default FirstcallList;
