import React from "react";
import { useQuery } from "@apollo/client";
import { QUERY_USER_FEEDBACK } from "../../utils/queries";
import { Card } from "react-bootstrap";
const FeedbackUserList = ({ currentUser }) => {
  const { data } = useQuery(QUERY_USER_FEEDBACK, {
    variables: { userId: currentUser._id },
  });
  const Feedback = data?.UserFeedback || [];
  return (
    <Card>
      <Card.Header>Your Feedback</Card.Header>
      <Card.Body
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      >
        {Feedback.length > 0 ?
          (Feedback.map((Feedback) => (
            <Card
              style={{ border: "1px solid black", margin: "2px" }}
              key={Feedback._id}
            >
              <Card.Header>
                <h4>Submitted At: {Feedback.submittedAt}</h4>
              </Card.Header>
              <Card.Body style={{ textAlign: "center" }}>
                Message: {Feedback.message}
                <p>{Feedback.seen ? "Seen" : "Unseen"}</p>
              </Card.Body>
            </Card>
          ))):
          <>
          No Feedback</>
        }
      </Card.Body>
    </Card>
  );
};
export default FeedbackUserList;
