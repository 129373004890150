import React, { useState } from "react";
import { Card, Button } from "react-bootstrap";
import GeneralInfoTableOfContents from "../components/GeneralInfoTableOfContents";
const InfoPage = ({ toggleNav }) => {
  const handlePageChange = (data) => {
    localStorage.setItem("navActiveKey", data);
  };
  const [open, setOpen] = useState(false);
  const handleTableOfContentsToggle = () => {
    toggleNav();
    if (open === true) {
      setOpen(false);
    } else if (open === false) {
      setOpen(true);
    }
  };
  return (
    <div style={{ fontSize: "3vh" }}>
      <GeneralInfoTableOfContents
        showTableOfContents={open}
        setshowTableOfContents={handleTableOfContentsToggle}
      ></GeneralInfoTableOfContents>
      <Button
        hidden={open}
        style={{
          right: 0,
          top: 0,
          marginTop: "9vh",
          position: "fixed",
          zIndex: 20,
        }}
        onClick={handleTableOfContentsToggle}
      >
        Toggle Table Of Contents
      </Button>
      <Card>
        <Card.Header>
          <h1>General Information Page</h1>
        </Card.Header>
        <Card.Body
          className="GeneralInfoPage"
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}
        >
          <div className="infoBox" id="GeneralInfo"></div>
          <Card>
            <Card.Header>
              <h1>General Website Information:</h1>
            </Card.Header>
            <Card.Body>
              <ul>
                <li>
                  I have been working for over a year and a half on this project
                  and I'm finally ready to start getting it integrated into our
                  work flow.
                </li>
                <li>
                  My goal with this website is to help limit the amount of paper
                  waste we have in this office, to make the work environment
                  more straight forward, and to make bringing new people in
                  easier.
                </li>
                <li>
                  I have a lot of different ideas on how to do this but this
                  will all be a team effort and I'll need your guy's help to
                  make this transition as easy as possible.
                </li>
                <li>
                  I am working on this website alone for the time being, so I
                  have a lot on my plate not only making sure all existing
                  features work and function properly but also trying to add and
                  test new: technologies, ideas, and functionality.
                </li>
                <li>
                  There are some features that require a page reload for you to
                  see changes made on other computers so if something is
                  different on your computer then on your coworkers you should
                  both refresh the page to get the most up to date info.
                </li>
                <li>
                  If you find a website bug that causes an entire portion of the
                  site to not work send me a text. I will do my best to fix it
                  as soon as I can. I will respond to let you know I'm trying to
                  work on it and will let you know once I got the change
                  deployed. When I attempt to fix the bug it may take some time
                  depending on the severity of the issue or if it's hard to find
                  the cause. We will have paper backups for everything just
                  incase I can't fix it right away. Please be patient during the
                  transition.
                </li>
                <li>
                  You will be logged in for 10hr, please make sure you are
                  logged out before you leave.
                </li>
                <li>
                  The Info will be mostly based on the book until I can get Todd
                  to send out a new sheet so we can get an updated information
                  and hopefully information that we've needed for years. If you
                  see any typos or anything along those lines let me know in the
                  feedback section I will be trying to look at that as often as
                  I can. As you all know this place can get very hectic so I
                  shall be doing my best during my shifts to get as much work
                  done as I can
                </li>
                <li>
                  I have implemented Notifications to the website. When you have
                  pending messages, first calls that need responding to or
                  unread updates. The individual numbers will be shown on the
                  Navigation bar and the numbers will be added together in the
                  in the top of your browser so you are reminded to open the
                  website.
                </li>
                <li>
                    I'm still in the process of updating this page with everything
                    I've added recently so some of this page will be still be outdated.
                </li>
              </ul>
            </Card.Body>
          </Card>
          <div className="infoBox" id="Helpful"></div>
          <Card>
            <Card.Header>Helpful Information</Card.Header>
            <Card.Body>
              <ul>
                <li>
                  If Any Calls come in for Carey while no one is in the Carey
                  office, you can text me{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://voice.google.com/u/1/messages?itemId=t.%2B16122804986"
                  >
                    (Cameron)
                  </a>
                  , I usually have my phone on me (Annie usually leaves after
                  6pm Mon-Fri but on Sat it's usually after 2 and they don't
                  have someone in there on Sun unless I'm working that day)
                </li>
                <li>
                  If you have any questions about if we can do a removal you
                  should contact{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://voice.google.com/u/1/messages?itemId=t.%2B16124086040"
                  >
                    Jay
                  </a>{" "}
                  or you can contact the removal team directly to see where they
                  are. (applies to 4-10 Shift after 6pm)
                </li>
                <li>
                  ME Call is when the RCME office calls us to help them with a
                  removal from a scene, ME's also call the funeral home's to
                  report a death. The major difference is which line they call.
                  If they call Johnson Williams Directly it's a ME call. and if
                  they contact a funeral home it's a First call. Grandstrand
                  helps Chisago county with transfers so those can also add
                  confusion to the mix those always go to Tom
                </li>
                <li>
                  If someone calls asking about a hearse/escort while everyone's
                  gone you can check the middle desk on the widow side of the
                  office (Jay's Desk usually) you'll find all removals and
                  escorts ordered by day for the coming week. If it's for
                  further then this coming week they should be in the box at the
                  back of the desk.
                </li>
                <li>
                  If someone calls the FC Sale's Line please take down their
                  name, their funeral home and phone number and Todd will call
                  them back when he's available.
                </li>
                <li>
                  If you get any weird calls don't be afraid to ask them to hold
                  and consult your coworker.
                </li>
                <li>
                  When someone calls the FH Messages Line it could vary, it's
                  usally a director calling us to inquire about the answering
                  service. But some of the funeral homes call that number for
                  Johnson Williams or they call their own number to speak with
                  us as well, it's very confusing at times but you'll come to
                  learn which funeral homes does what.
                </li>
              </ul>
            </Card.Body>
          </Card>
          <div className="infoBox" id="FAQ"></div>
          <Card>
            <Card.Header>Frequently Asked Questions:</Card.Header>
            <Card.Body>
              <ul>
                <li>
                  As we start using the website if I get asked a question a lot
                  it'll be added here, if you have any questions about the
                  website or concerns feel free to reach out via the{" "}
                  <a href="/Feedback">feedback page</a>
                </li>
              </ul>
            </Card.Body>
          </Card>
          <div className="infoBox" id="Feedback"></div>
          <Card>
            <Card.Header>
              <a href="/Feedback">
                <h1>Feedback Page</h1>
              </a>
            </Card.Header>
            <Card.Body>
              <ul>
                <li>
                  The Feedback page is still currently very bare bones and I'll
                  need to update it eventually but for the time being if you
                  could let me know of any bugs you run into, any features you
                  think need to be added, anything that you think might need to
                  be removed / reworked.
                </li>
                <li>
                  I'll be periodically checking the feedback page. If theres a
                  bug I'll do my best to get to it when I'm working next.
                </li>
                <li>
                  If you submit an idea for a new functionality or a change to
                  existing functionality it doesn't mean I will implement it
                  right away or at all, I will implement a way for me to respond
                  to your feedback eventually. You'll be able to see if I have 
                  seen your feedback via the your feedback portion of the feedback page.
                </li>
                <li>
                  I will try to consider any ideas that are given and
                  potentially speak with you directly about the idea and bounce
                  the idea off of other coworkers as well to see if they think
                  the feature would be beneficial to us and worth my time to add
                  it.
                </li>
              </ul>
            </Card.Body>
          </Card>
          <div className="infoBox" id="Updates"></div>
          <Card>
            <Card.Header>
              <a href="/Updates">
                <h1>Updates Page:</h1>
              </a>
            </Card.Header>
            <Card.Body>
              <ul>
                <li>
                  The updates page is a place where I house information about
                  updates I implement into the website or if we change something
                  for the answering service.
                </li>
                <li>
                  Whenever I add a new feature or something changes in the
                  answering service I will add an update and include the gist of
                  what I have added and it will be added to everyones my updates
                  page.
                </li>
                <li>
                  All unread updates will be displayed in the "My Updates" tab
                  within the updates page, you will be able to mark them as read
                  and they will still be accessible through they "All Updates"
                  Tab.
                </li>
                <li>
                  The # of unread updates you have will be displayed on the
                  navigation bar in the updates tab and on the home page. So you
                  can check there to see if there are any new pending updates.
                </li>
              </ul>
            </Card.Body>
          </Card>
          <div className="infoBox" id="Tutorials"></div>
          <Card>
            <Card.Header>
              <a href="/Tutorial">
                <h1>Tutorials Page:</h1>
              </a>
            </Card.Header>
            <Card.Body>
              <ul>
                <li>
                  The tutorial page will be a helpful too for training new
                  people and giving you a refresher after you haven't worked in
                  a while
                </li>
                <li>
                  You will be able to select a tutorial and it will prompt you
                  with the questions you will need to be asking the caller for
                  you to fill out the form properly.
                </li>
                <li>
                  Once you fill out the entire form for the tutorial it will
                  bring up the confirmation page where it will list out all the
                  questions and answers, you will be reminded to give the
                  confirmation number for the hearses or escorts.
                </li>
                <li>
                  I will be adding a functionality to allow for the tutorials to
                  lead to the actual forms, First Calls first, Removals and
                  Escorts once they're added to the webstie
                </li>
                <li>
                  There are general Website Tutorials that I have recorded that
                  should help you with general website processes for the main
                  pages. If you need a rundown on the smaller parts of the
                  website let Cameron know and he can schedule a 1 on 1 during
                  your shift to give some guidance.
                </li>
              </ul>
            </Card.Body>
          </Card>
          <div className="infoBox" id="Message"></div>
          <Card>
            <Card.Header>
              <a href="/Messages" onClick={() => handlePageChange("/Messages")}>
                <h1>Messages Page:</h1>
              </a>
            </Card.Header>
            <Card.Body>
              <ul>
                <li>
                  The message page is where you should be most of the time while
                  waiting for calls to come in.
                </li>
                <li>
                  The page has a list of how to answer all of the lines, the
                  basic message form, a box for general funeral home info, has a
                  list of all your pending messages, has a list of all of todays
                  messages. and a message history box that has all messages
                </li>
                <li>
                  The Answering List Tab includes how all the lines come in, how
                  we answer said line, and who the call is for. If it's a
                  funeral home you will be able to populate the message form
                  with that funeral home.
                </li>
                <li>
                  The Message Form Tab of the Message page is where the Message
                  form is located, whenever you select a funeral home a little
                  box opens up to the side with helpful information for that
                  funeral home, it's a condensed version of the{" "}
                  <a href="#Individual Funeral Home">
                    Individual Funeral Home Page
                  </a>
                </li>
                <li>
                  The Message Form has 4 main pieces of info you need to get
                  from all callers:
                </li>
                <ol>
                  <li>
                    Funeral home they are calling for you will get this from
                    what's displayed on the phone
                  </li>
                  <li>Callers name</li>
                  <li>
                    Callers number (you only need to type in numbers will
                    automatically format into (123)-456-7890)
                  </li>
                  <li>
                    What the Call is regarding / a brief message for the
                    director
                  </li>
                </ol>
                <li>
                  There is a few extra pieces you can get, you can get which
                  specific location it's for, you can also get who the message
                  is for. Some of the funeral homes could require this
                  information or some of them allow you to message any director
                  during the day. You will be notified if the information is
                  required for that funeral home. If a caller asks to not be
                  called back you can toggle the "Do they need a call" box and
                  unselect that and it'll change the auto copied message.
                </li>
                <li>
                  Once you take a message it'll be added to your local pending
                  messages box, only you can access this box so you need to make
                  sure you send any message you have in there because no one
                  else can do it for you. and if you logout someone won't see
                  that message because it won't be in the system yet.
                </li>
                <li>Once you take a message you are able to:</li>
                <ol>
                  <li>
                    copy your created message to send to the on call director
                  </li>
                  <li>
                    mark it as recieved once the director acknowledges the
                    message, you can either mark it down as now or enter another
                    time if you didn't hit recieved right when they responded
                  </li>
                  <li>
                    you will be notified if you don't mark it as read within 10
                    minutes of the message being taken
                  </li>
                  <li>
                    edit the message if someone calls right back to correct
                    something or if you make a mistake in taking the message
                  </li>
                  <li>
                    discard any messages if someone calls back and says they
                    don't need the message sent anymore
                  </li>
                </ol>
                <li>
                  The Default Copy Message starts with "Please call (Name of
                  caller) (Caller #) RE: (Message) Thanks, (Your Name)", If you
                  selected they don't need a call it won't include "Please
                  Call", If you selected a location it will be included in the
                  front of the message.
                </li>
                <li>
                  If you need to look back at a message from today you can open
                  Today's History tab.
                </li>
                <li>
                  If you need to look back at messages from longer then a day
                  ago go to the Message History tab, it'll be separated into
                  different pages and it lists the start and end dates on each
                  page
                </li>
              </ul>
            </Card.Body>
          </Card>
          <div className="infoBox" id="Directors-OnCalls"></div>
          <Card id="Directors-OnCalls">
            <Card.Header>
              <h1>
                <a
                  href="/Directors"
                  onClick={() => handlePageChange("/Directors")}
                >
                  Directors / On Calls
                </a>
              </h1>
            </Card.Header>
            <Card.Body>
              <ul>
                <li>
                  The "Directors / On Calls Page" is where all of the on call
                  information is housed for each funeral home and it allows us
                  to:
                </li>
                <ol>
                  <li>put directors on call</li>
                  <li>track who's on call for each funeral home</li>
                  <li>
                    add notes to on calls if additional information is needed
                  </li>
                  <li>See a list of directors who can be put on call.</li>
                  <li>See a seperate list of all directors</li>
                  <li>make sure every funeral home has an on call</li>
                  <li>put multiple directors on call</li>
                  <li>jump between the listed funeral homes</li>
                  <li>
                    refresh individual on calls and everyone on call for one
                    funeral home
                  </li>
                  <li>
                    jump to the message page and take a message for that
                    specified funeral home
                  </li>
                  <li>set a default director for the funeral home</li>
                </ol>
                <li>
                  When you put a director on it'll take that date and time and
                  add them to the funeral homes on call list
                </li>
                <li>
                  Once they are added to the on call list for that funeral home
                  they can be taken off call, you can refresh the time they were
                  put on call, and you can add notes
                </li>
                <li>
                  Using the on calls options button on the right you can select
                  one of the 3 options:
                </li>
                <ol>
                  <li>
                    * Open Funeral Home List: Open side panel that has a list of
                    the funeral homes so you can easily manuever between all of
                    the funeral homes
                  </li>
                  <li>
                    Check If We Have An On Call For Everyone: Upon clicking this button
                    it'll filter the on call page to only show funeral homes without a current on call.
                    this will allow you to go through the on calls easier. 
                  </li>
                </ol>
                <li>
                  * The side panel can be closed by hitting the x at the top
                  right
                </li>
                <li>
                  Using the director list you can look at all of the directors
                  who can be on for that funeral home, it will also inform you
                  if that director isn't available at the moment
                </li>
                <li>
                  Directors can be added to the Director list from the Full
                  Staff List and will be able to be put on only once they are in
                  the Director List
                </li>
                <li>
                  When you want to put a director on call you have 2 options:
                </li>
                <ol>
                  <li>
                    You can use the director list and scroll down to the desired
                    director and put them on, if the funeral home has an
                    affiliate you can choose which funeral home to put them on
                    for or you can choose to put them on for multiple
                  </li>
                  <li>
                    If you have multiple directors to put on at once you can
                    open up the Bulk On Calls page and select all the directors
                    who need to be put on and if needed you can add notes to
                    them as well. and you can select if you want to replace the
                    current on calls, the current on calls will be listed at the
                    bottom so you can see who's getting replaced.
                  </li>
                </ol>
                <li>
                  When adding notes to an on call director there are 4 options:
                </li>
                <ol>
                  <li>TFN</li>
                  <li>On All Weekend</li>
                  <li>Backup</li>
                  <li>Custom that you can type the note into</li>
                </ol>
                <li>
                  Some Directors will have a default note that will be applied
                  when they are put on call automatically
                </li>
                <li>
                  Some funeral homes automatically have the same on calls as
                  their affiliate, IE Peterson Grimsmo for Dares and Sandberg
                  for CSM, whenever a funeral home takes the on calls for an
                  affiliate the website puts them on for both automatically so
                  you don't have to worry about putting them on for both.
                </li>
                <li>
                  With the on call options button you can:
                  <ol>
                    <li>
                      "Take Message": open the message form with that specific
                      funeral home already chosen
                    </li>
                    <li>
                      "Refresh on Calls": Refresh all the on calls for that
                      specific funeral home
                    </li>
                    <li>*Show On Call List</li>
                    <li>
                      *Show Director List (Directors who can be put on call)
                    </li>
                    <li>*Show Full Staff List (All Directors)</li>
                  </ol>
                  *Depending on which page is currently open it shows the other
                  2
                </li>
                <li>
                  You can check the scheduled directors in the individual
                  funeral home page to see which directors are scheduled
                </li>
                <li>
                  When a director is the default director they will always be
                  put on call, you can remove them as a default director if
                  needed.
                </li>
                <li>
                  If A Director Needs to be added please either send me a text
                  or leave me a note because not everyone will have access to
                  adding directors.
                </li>
              </ul>
            </Card.Body>
          </Card>
          <div className="infoBox" id="Individual Funeral Home"></div>
          <Card>
            <Card.Header>
              <h1>
                <a href="/Funeralhomes">Funeral Homes Pages</a>
              </h1>
            </Card.Header>
            <Card.Body>
              <ul>
                <li>
                  You can select from the list of funeral homes to select the
                  specific funeral home and view all of that funeral homes info
                  and you can click on the funeral homes name to open it's
                  specific page and theres a button to go back to the funeral
                  home page
                </li>
                <li>
                  You can click on the name of the funeral home at the top to
                  open a page that is just that funeral homes info and then get
                  back to the funeral home list by pressing the "Back to Funeral
                  Home List" button at the top of said page.
                </li>
                <li>
                  Each of the Funeral Home Pages houses all of the information
                  for each of the funeral homes and it allows you to:
                </li>
                <ol>
                  <li>view general funeral home information</li>
                  <li>view a list of on calls</li>
                  <li>schedule a director and view scheduled director</li>
                  <li>view a list of locations and their information</li>
                  <li>view a list of the directors who can be put on call</li>
                  <li>view a list of all staff</li>
                  <li>view a list of the flower shops</li>
                  <li>
                    view a list of the affiliated funeral homes and any *parent
                    companies connected to that funeral home.
                  </li>
                  <li>track all the service information</li>
                </ol>
                <li>
                  The general information table houses general information that
                  helps you:
                </li>
                <ol>
                  <li>
                    answer the phones, know which location you are answering for
                    (only applies to funeral home that has multiple lines)
                  </li>
                  <li>pronounce the funeral home name</li>
                  <li>know the hours of the funeral home</li>
                  <li>get special instructions given by the funeral home</li>
                  <li>
                    know funeral homes preffered way of contacting directors
                  </li>
                  <li>know flower room information</li>
                  <li>know the funeral homes website</li>
                  <li>see if we send first call sheets</li>
                  <li>
                    see which email we first call sheets to, and any extra
                    instructions for emailing
                  </li>
                  <li>see if we track services within the website</li>
                  <li>see if we take first calls</li>
                  <li>see if the first calls emailing is complicated</li>
                </ol>
                <li>
                  You are able to see the list of on calls on the individual FH
                  page so you won't have to jump back and forth between pages
                </li>
                <li>
                  You can look at a list of the locations and open each location
                  to see additional information
                </li>
                <li>
                  You can open a director list that functions the same as the on
                  on the Directors / On Calls Page
                </li>
                <li>
                  You can view a full list of staff for the funeral home, this
                  list is currently just directors but in the future it may
                  include different staff types IE: Night Attendants /
                  Prearrangment specialist, For now it's just to distinguish if
                  a director is able to be on call or not
                </li>
                <li>
                  You can open the flower shop list for the funeral home to find
                  the name and number for the recommended flower shops
                </li>
                <li>
                  You can see a list of any affiliated funeral homes and the
                  *parent company of the funeral home (if they have any)
                </li>
                <li>
                  If we track their services you can see a list of their
                  services and add services, once a service is added you can add
                  additional information to that service. you can add
                  visitation, service and internment. you can also add custom
                  service types if needed.
                </li>
              </ul>
              <Card>
                <Card.Header>*Parent Companies</Card.Header>
                <Card.Body>
                  <ul>
                    <li>
                      Parent Companies are a way to make the Affiliates make
                      more sense and to connect the connected funerals to an
                      actual entity instead of just each other.
                    </li>
                  </ul>
                </Card.Body>
              </Card>
            </Card.Body>
          </Card>
          <div className="infoBox" id="First-Calls"></div>
          <Card>
            <Card.Header>
              <h1>
                <a href="/Firstcalls">First Calls</a>
              </h1>
            </Card.Header>
            <Card.Body>
              <li>
                The First Calls Page houses all the functionality for taking a
                first call and houses a list of all taken first calls.
              </li>
              <li>On the first call page you will be able to:</li>
              <ol>
                <li>Take first calls for funeral homes we answer for</li>
                <li>
                  Save a first call if another call comes in (DO NOT PUT FAMILY
                  MEMBERS ON HOLD DURING FIRST CALLS!!!!)
                </li>
                <li>
                  View any of your saved first calls, either delete them or
                  continue taking the information for the saved first call
                </li>
                <li>Clear the first call form</li>
                <li>View and edit first calls that have already been taken</li>
                <li>Filter the first call list by Funeral Home</li>
              </ol>
              <li>
                Required information for the first calls is:
                <ol>
                  <li>Funeral Home and Location</li>
                  <li>
                    Callers Basic Info (Name, Number, Caller type: Family
                    Member, ME, Officer and Sub Caller Type: Daughter, HCME)
                  </li>
                  <li>Name of the deceased</li>
                  <li>Where Deceased is located</li>
                  <li>
                    Next of Kin (Closest relative) Name, Relationship, Number
                    and if they have been notified
                  </li>
                </ol>
              </li>
              <li>The call time is set the time you submit the form</li>
              <li>
                If you don't get the required info you won't be able to submit
                the first call
              </li>
              <li>
                The First call form changes if the caller is a family member, if
                the caller turns out to be the next of kin you can click a
                button to copy their info
              </li>
              <li>
                Once you take the first call it'll be sent to the email that the
                funeral home requests, if the method of emailing is more
                complicated the email will be created as a draft and you can go
                in and send it with the listed emails
              </li>
              <li>
                It'll also be added to the first call list, where you can click
                the been recieved button, edit button, or delete button and once
                it's been recieved you can print the sheet.
              </li>
              <li>
                When you click the recieved button you can fill out the
                notification form where you put who recieved it, the time they
                recieved it and the process of notifying them
              </li>

              <li>
                Some of the inputs only take in specific information like the
                TOD, DOB, all phone #'s, and notified time only and will be
                displayed:
              </li>
              <ol>
                <li>
                  TOD Example: '10:00 (a/p)m 10/10/2023', will take the numbers,
                  a, m, p and just in this specific layout to allow for military
                  time and to distinguish between am and pm
                </li>
                <li>DOB: Example: '1/23/1945', will only take numbers</li>
                <li>
                  Phone Number Example: '(123)-456-7890', will only take in
                  numbers
                </li>
              </ol>
            </Card.Body>
          </Card>
          {/* <Card>
            <Card.Header>
                <h1>
                Looking Ahead
                </h1>
            </Card.Header>
            <Card.Body>
                <ul>
                    <li>as we move forward with fully implementing the website we can </li>
                </ul>
            </Card.Body>
          </Card> */}
        </Card.Body>
      </Card>
    </div>
  );
};
export default InfoPage;
