import React, {useState, useEffect} from 'react'
import ParentCompanyForm from '../components/ParentCompanyForm'
import { Tab, Tabs } from 'react-bootstrap'
import ParentCompanyList from '../components/ParentCompanyList'
import { useQuery } from '@apollo/client'
import { QUERY_PARENT_COMPANIES } from '../utils/queries'
const ParentCompany =({currentUser}) => {
    const {data} = useQuery(QUERY_PARENT_COMPANIES)
    const parentCompanies = data?.ParentCompanies
    const [currentParentCompanies, setCurrentParentCompanies] = useState([])
    useEffect(() => {
        const parentCompanyList = parentCompanies || []
        setCurrentParentCompanies(parentCompanyList)
    }, [setCurrentParentCompanies, parentCompanies])
    const [key, setKey] = useState('PCList')
    const admin = currentUser.adminStatus
    const handleAddPC = (data) => {
        setCurrentParentCompanies(data)
        setKey('PCList')
    }
    const handleRemovePC = (data) => {
        setCurrentParentCompanies(data)
    }
    return (
        <>
        {admin ?
            <Tabs onSelect={(key) => setKey(key)} activeKey={key} justify>
            <Tab title='Parent Company Form' eventKey={'PCForm'}>
                <ParentCompanyForm handleAddPC={handleAddPC}></ParentCompanyForm>
            </Tab>
            <Tab title="Parent Company List" eventKey={'PCList'}>
                <ParentCompanyList handleRemovePC={handleRemovePC} parentCompanies={currentParentCompanies}></ParentCompanyList>
            </Tab>
        </Tabs>:
        <ParentCompanyList parentCompanies={currentParentCompanies}>
        </ParentCompanyList>
        }
        </>
    )
}

export default ParentCompany