import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { LOGIN, RESET_PASSWORD, CHECK_APPROVAL } from "../../../utils/mutations";
import Auth from "../../../utils/auth";
import { Form, Button, Alert, Card } from "react-bootstrap";
const LoginForm = () => {
  const [currentPage, setCurrentPage] = useState("Login");
  const [checkApproval] = useMutation(CHECK_APPROVAL)
  const [notApprovedMessage, setNotApprovedMessage] = useState({status: false, text: 'You have not been approved yet, Please reach out to an admin to approve your account.'})
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
  });
  const [login] = useMutation(LOGIN);
  const [resetPassword] = useMutation(RESET_PASSWORD);
  const [showAlert, setShowAlert] = useState({ status: false });
  const [passwordShown, setPasswordShown] = useState(false);
  const [goodAlert, setGoodAlert] = useState(false);
  const [badAlert, setBadAlert] = useState({ status: false, message: "" });
  const handleToggle = (e) => {
    e.preventDefault();
    if (e.target.id === "PasswordReset") {
      setCurrentPage("PasswordReset");
    } else {
        setCurrentPage("Login")
    }
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password, username} = formData;
    if (currentPage === "Login") {
      try {
        await checkApproval({variables: {email, username: null}}).then( async ({data}) => {
            if(data.checkApproval) {
              try {
                const response = await login({ variables: { email, password } });
                if (!response.data) {
                  throw new Error("something went wrong!");
                }
                console.log(response);
                const { token, user } = await response.data.login;
                console.log(user);
                Auth.login(token, user);
              } catch (e) {
                setShowAlert({ status: true, text: e.message });
              }
            } else {
                setNotApprovedMessage({...notApprovedMessage, status: true})
            }
        })
    } catch (error) {
        console.log(error)
    }
      
    } else if (currentPage === "PasswordReset") {
      try {
        await resetPassword({ variables: { username, password } });
        setGoodAlert(true);
        setFormData({ username: "", email: "", password: "" });
      } catch (error) {
        setBadAlert({ status: true, message: error.message });
      }
    }
  };
  if (currentPage === "Login")
    return (
      <>
        <Card style={{ display: "flex" }}>
          <Card.Header>
            <h2>
            Need to make an Account? Go to the Signup Tab
            </h2>
          </Card.Header>
          <Card.Body>
            <Form onSubmit={handleSubmit}>
              <Alert
                dismissible
                onClose={() => {
                  setShowAlert({ ...showAlert, status: false });
                }}
                show={showAlert.status}
                variant="Danger"
              >
                <Alert.Heading>
                  Something went wrong with your Login! <br />
                  {showAlert.text}
                </Alert.Heading>
                Click to dismiss!
              </Alert>
              <Form.Group>
                <Form.Label htmlFor="email">Email:</Form.Label>
                <Form.Control
                  className="formInput"
                  type="email"
                  placeholder="Your email address"
                  name="email"
                  onChange={handleChange}
                  value={formData.email}
                  required
                  id="email"
                  autoComplete="off"
                />
                <Alert show={formData.email === ""}>Email is required!</Alert>
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="password">Password:</Form.Label>
                <Form.Control
                  onKeyDownCapture={(event) =>
                    event.key === "Enter" && handleSubmit(event)
                  }
                  className="formInput"
                  type={passwordShown ? "text" : "password"}
                  placeholder="Your password"
                  name="password"
                  onChange={handleChange}
                  value={formData.password}
                  required
                  id="password"
                />
                <Button
                  onClick={() => {
                    setPasswordShown(!passwordShown);
                  }}
                >
                  {passwordShown ? "Hide" : "Show"}
                </Button>
                <Alert show={formData.password === ""} type="invalid">
                  Password is required!
                </Alert>
              </Form.Group>
            </Form>
            <Alert variant='danger' show={notApprovedMessage.status} dismissible onClose={()=> setNotApprovedMessage({...notApprovedMessage, status: false})}>
                {notApprovedMessage.text}
                </Alert>
          </Card.Body>
          <Card.Footer>
            <Button id="PasswordReset" onClick={handleToggle}>
              Forgot Password?
            </Button>
            <Button
              onClick={handleSubmit}
              disabled={!(formData.email && formData.password)}
              variant="success"
            >
              Login
            </Button>
          </Card.Footer>
        </Card>
      </>
    );
  else if (currentPage === "PasswordReset") {
    return (
      <Card>
        <Card.Header>
        <h1>Update your Password</h1>
        <Button onClick={handleToggle}>Login</Button>
        </Card.Header>
        <Card.Body>
        <Form>
          <Form.Group>
            <Form.Label>Username:</Form.Label>
            <Form.Control
              className="formInput"
              type="text"
              placeholder="Username"
              name="username"
              value={formData.username}
              id="username"
              onChange={handleChange}
              required
              autoComplete="off"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>New Password:</Form.Label>
            <Form.Control
              className="formInput"
              type={passwordShown ? "text" : "password"}
              placeholder="New password"
              name="password"
              onChange={handleChange}
              value={formData.password}
              required
              id="password"
            />
            <Button
              onClick={() => {
                setPasswordShown(!passwordShown);
              }}
            >
              {passwordShown ? "Hide" : "Show"}
            </Button>
          </Form.Group>
          <Alert
            dismissible
            onClose={() => setCurrentPage("Login")}
            show={goodAlert}
          >
            Click to go back to login page! Your Password was changed
            succesfully
          </Alert>
          <Alert
            dismissible
            onClose={() => setBadAlert({ status: false })}
            show={badAlert.status}
          >
            Click to dismiss! There was an error {badAlert.message}
          </Alert>
        </Form>
        </Card.Body>
        <Card.Footer>
        <Button onClick={handleSubmit}>Submit</Button>
        </Card.Footer>
      </Card>
    );
  }
};
export default LoginForm;
