import React, { useState } from "react";
import { Card, Form, Button} from "react-bootstrap";
const TutorialHandler = ({Tutorial, setTakeTutorial}) => {
    const [answers, setAnswers] = useState(Tutorial.questions.map((question) => {return {question, answer: ''}}))
    const [finished, setFinished] = useState(false)
    const handleAnswerChange = (e, index) => {
        setAnswers(answers.map(({question, answer}, i) =>{
            if(i === index) {
                return {question, answer: e.target.value}
            } else {
                return {question, answer}
            }
            
        }))
    }
    return (
        <Card>
            <Card.Header>
                {Tutorial.name}
            </Card.Header>
            <Card.Body>
                {finished && <h3>Confirm the information with the person, if it is a hearse/ Escort give them the confrimation on the sheet you will fill out after the call</h3>}
                {answers.map(({question, answer}, index) => (
                    <Card>
                    {finished ? 
                    <>
                    <Card.Header>
                        <h4>
                        {question}: 
                        </h4>
                    </Card.Header>
                    <Card.Body>
                        {answer}
                    </Card.Body>
                    </>
                    :
                    <Form.Group key={index}>
                        <Card.Header>
                    <Form.Label>
                        {question}:
                    </Form.Label>
                    </Card.Header>
                    <Card.Body>
                    <Form.Control onChange={(e) => handleAnswerChange(e, index)} value={answer}>
                    </Form.Control>
                    </Card.Body>
                    </Form.Group>
                    }
                    </Card>
                ))}
            </Card.Body>
            <Card.Footer>
                {finished ?
                <>
                <Button onClick={() => setTakeTutorial()}>Finish Tutorial</Button>
                <Button onClick={() => setFinished(false)}>Edit Info</Button>
                </>
                :
                <>
                    <Button onClick={() => setFinished(true)}>Confirm Info</Button>
                    <Button onClick={() => setTakeTutorial()}>Cancel Tutorial</Button>
                </>
                }
            </Card.Footer>
        </Card>
    )
}
export default TutorialHandler