import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { QUERY_FUNERALHOMES } from "../../utils/queries";
import { Form } from "react-bootstrap";
const FuneralHomeOption = ({
  value,
  fhList,
  onChange,
  directorAffiliates,
  page,
}) => {
  const { data } = useQuery(QUERY_FUNERALHOMES);
  var funeralhomes =
    fhList !== null && fhList !== undefined ? fhList : data?.FuneralHomes || [];
  const [currentFH, setCurrentFH] = useState(value);
  const checkDirectorAffiliates = () => {
    const current = [];
    if (directorAffiliates.funeralhome.parentCompany?.funeralhomes.length > 0) {
      funeralhomes.forEach((funeralhome) => {
        if (
          funeralhome.on_calls?.currentOnCalls?.some((oncall) => {
            return oncall?.director === null || false;
          })
        ) {
          if (directorAffiliates.funeralhome.name === funeralhome.name) {
            current.push(funeralhome);
          }
          return false;
        }
        if (
          funeralhome.on_calls?.currentOnCalls?.some(
            (oncall) => oncall?.director.name === directorAffiliates.name
          )
        ) {
          return funeralhome;
        }
        if (
          directorAffiliates.funeralhome.affiliates.some(
            (affiliate) => affiliate.name === funeralhome.name
          )
        ) {
          current.push(funeralhome);
        }
        if (funeralhome.name === directorAffiliates.funeralhome.name) {
          var isInCurrent = false;
          if (current.some((current) => current === funeralhome)) {
            isInCurrent = true;
          }
          if (!isInCurrent) {
            current.push(funeralhome);
          }
        }
        // return funeralhome
      });
    }
    funeralhomes = current;
  };
  // if(currentFHPage !== null && currentFHPage !== undefined) {
  //     checkcurrentFHPage()
  // }
  if (directorAffiliates !== null && directorAffiliates !== undefined) {
    checkDirectorAffiliates();
  }
  if (page === "Message Card") {
    return (
      <Form.Control
        as="select"
        value={currentFH.name}
        id={"funeralhome"}
        onChange={(e) => {
          if (e.target.value === "none") {
            setCurrentFH("none");
            onChange("none");
          } else {
            const funeralhome = funeralhomes.filter(({ name }) => {
              return name === e.target.value;
            })[0];
            onChange(funeralhome);
            setCurrentFH(funeralhome);
          }
        }}
      >
        <option value="none"> Please Select a Funeral Home</option>
        {funeralhomes &&
          funeralhomes.map((funeralhome) => (
            <option key={funeralhome._id} value={funeralhome.name}>
              {funeralhome.name}
            </option>
          ))}
      </Form.Control>
    );
  }
  return (
    <Form.Control
      as="select"
      value={currentFH}
      id={"funeralhome"}
      onChange={(e) => {
        onChange(e);
        setCurrentFH(e.target.value);
      }}
    >
      <option value="none"> Please Select a Funeral Home</option>
      {funeralhomes &&
        funeralhomes.map((funeralhome) => (
          <option key={funeralhome._id}>{funeralhome.name}</option>
        ))}
    </Form.Control>
  );
};
export default FuneralHomeOption;
