import React from "react";
import { useQuery } from "@apollo/client";
import { QUERY_NOTIFICATION_NUMBERS } from "../utils/queries";
import { Alert, Card, ListGroup, ListGroupItem, Badge } from "react-bootstrap";
const HomePage = ({ currentUser }) => {
  const { data } = useQuery(QUERY_NOTIFICATION_NUMBERS, {
    variables: { userId: currentUser._id },
  });
  const notificationNumber = data?.NotificationNumbers || {
    firstcalls: 0,
    updates: 0,
  };
  const hasUpdates = notificationNumber.updates > 0;
  return (
    <Card style={{ fontSize: "3vh", display: "flex" }}>
      <Card.Header>
        <h1 style={{ textAlign: "center" }}>
          Welcome to the Funeral Answering Network
        </h1>
      </Card.Header>
      {hasUpdates && (
        <Alert>
          You have{" "}
          {notificationNumber.updates === 0 ? (
            ""
          ) : (
            <Badge>{notificationNumber.updates}</Badge>
          )}{" "}
          unread updates view them on the <a href="./Updates">updates page</a>
        </Alert>
      )}
      <Card.Body>
        <Card>
          <Card.Header style={{ textAlign: "center" }}>
            <h2 style={{ textAlign: "center" }}>Website Directory:</h2>
          </Card.Header>
          <Card.Body>
            If you haven't used the site before or it's been a while. Please
            checkout the General Info Page for general website functionality
            tips. It'll house information on all the different pages and what
            you can do on them
            <ListGroup>
              <ListGroupItem>
                <a href="/Info">General Info Page</a>
              </ListGroupItem>
              <ListGroupItem>
                <a href="/Feedback">Feedback Page</a>
              </ListGroupItem>
              <ListGroupItem>
                <a href="/Updates">Updates Page</a>
              </ListGroupItem>
              <ListGroupItem>
                <a href="Tutorial">Tutorials Page</a>
              </ListGroupItem>
              <ListGroupItem>
                <a href="/Messages">Messages Page</a>
              </ListGroupItem>
              <ListGroupItem>
                <a href="/Directors">Directors/OnCalls Page</a>
              </ListGroupItem>
              <ListGroupItem>
                <a href="/FirstCalls">First Calls Page</a>
              </ListGroupItem>
            </ListGroup>
          </Card.Body>
        </Card>
      </Card.Body>
    </Card>
  );
};
export default HomePage;
