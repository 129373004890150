import React, { useState, useRef } from "react";
import { Form, Alert, Card, Button } from "react-bootstrap";
import FuneralHomeOption from "../FuneralHomeOptions";
import { useMutation } from "@apollo/client";
import {
  EDIT_DIRECTOR,
  REMOVE_DIRECTOR,
  ADD_TO_DIRECTOR_POOL,
} from "../../utils/mutations";
import dateFormat from "../../utils/dateFormat";
const DirectorCard = ({ director, funeralhome, currentUser }) => {
  const [editMode, setEditMode] = useState(false);
  const [editData, setEditData] = useState({
    oldname: director.name,
    oldfuneralhome: funeralhome.name,
    ...director,
    funeralhome: funeralhome.name,
  });
  const [affiliateFH, setAffiliateFH] = useState("none");
  const oldInfo = useRef({});
  const [editDirector] = useMutation(EDIT_DIRECTOR);
  const [removeDirector] = useMutation(REMOVE_DIRECTOR);
  const [addToDirectorPool] = useMutation(ADD_TO_DIRECTOR_POOL);
  const handleDelete = (e) => {
    try {
      removeDirector({
        variables: { id: director._id, funeralhome: funeralhome._id },
      });
    } catch (e) {
      console.log(e);
    }
  };
  const handleEditToggle = () => {
    oldInfo.current = director;
    setEditMode(!editMode);
  };
  const handleEditChange = (e) => {
    if (e.target.type === "checkbox") {
      setEditData({ ...editData, [e.target.id]: e.target.checked });
    } else {
      setEditData({ ...editData, [e.target.id]: e.target.value });
    }
  };
  const handleEditSubmit = () => {
    const data = {
      funeralhome: editData.funeralhome,
      oldfuneralhome: editData.oldfuneralhome,
      name: editData.name,
      oldname: editData.oldname,
      primaryPhone: editData.primary_phone,
      contactMethod: editData.contact_method,
      unavailable: editData.unavailable,
      defaultNote: editData.defaultNote,
    };
    try {
      editDirector({
        variables: {
          ...data,
          userId: currentUser._id,
          editTime: dateFormat(new Date()),
        },
      });
      setEditMode(false);
    } catch (error) {
      console.log(error);
    }
  };
  const checkIfAccess = (funeralhome) => {
    return currentUser.access.includes(funeralhome);
  };
  const generateLink = (primary_phone) => {
    if (primary_phone.split("-").length > 1) {
      return (
        "https://voice.google.com/u/1/messages?itemId=t.%2B1" +
        primary_phone.split("-")[0] +
        primary_phone.split("-")[1] +
        primary_phone.split("-")[2]
      );
    }
    return "https://voice.google.com/u/1/messages";
  };
  const addDirectorToDirectorPool = async (directorId, id) => {
    try {
      await addToDirectorPool({ variables: { directorId, id } });
    } catch (error) {
      console.log(error);
    }
  };
  const affiliateFHS = funeralhome.parentCompany?.funeralhomes
    .filter(({ _id }) => {
      return _id !== funeralhome._id;
    })
    .filter((fh) => {
      return (
        fh.on_calls.directorPool?.length === 0 ||
        !fh.on_calls.directorPool?.some(({ _id }) => {
          return director._id === _id;
        })
      );
    }) || [];
  return (
    <Card
      style={{
        margin: "5px",
        borderRadius: "1vw",
        width: "20vw",
        justifyContent: "center",
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      {editMode ? (
        <>
          <Card.Header>Edit Mode</Card.Header>
          <Card.Body style={{ overflowY: "scroll", height: "40vh" }}>
            <Form.Group>
              <Form.Label>Funeral Home:</Form.Label>
              <br />
              Previous: {oldInfo.current.funeralhome?.name}
              {funeralhome !== null ? (
                <FuneralHomeOption
                  value={funeralhome.name}
                  onChange={handleEditChange}
                ></FuneralHomeOption>
              ) : (
                <FuneralHomeOption
                  value={"none"}
                  onChange={handleEditChange}
                ></FuneralHomeOption>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Name:</Form.Label>
              <br />
              Previous: {oldInfo.current.name}
              <Form.Control
                value={editData.name}
                onChange={handleEditChange}
                id="name"
                placeholder="Director Name"
              ></Form.Control>
              <Alert show={editData.name === ""}>
                Please Provide a Director Name
              </Alert>
            </Form.Group>
            <Form.Group>
              <Form.Label>Primary Contact Method:</Form.Label>
              <br />
              Previous: {oldInfo.current.primary_phone}
              <Form.Control
                value={editData.primary_phone}
                onChange={handleEditChange}
                id="primary_phone"
                placeholder="Primary Phone"
              ></Form.Control>
              <Alert show={editData.primaryPhone === ""}>
                Please Provide a Phone Number
              </Alert>
            </Form.Group>
            <Form.Group>
              <Form.Label>Contact Type:</Form.Label>
              <br />
              Previous: {oldInfo.current.contact_method}
              <Form.Control
                value={editData.contact_method}
                onChange={handleEditChange}
                id="contact_method"
                placeholder="Contact Method"
              ></Form.Control>
              <Alert show={editData.contactMethod === ""}>
                Please Provide a Contact Method
              </Alert>
            </Form.Group>
            <Form.Group>
              <Form.Label>Default Note:</Form.Label>
              <br />
              Previous: {oldInfo.current.defaultNote}
              <Form.Control
                id="defaultNote"
                onChange={handleEditChange}
                value={editData.defaultNote}
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Unavailable:</Form.Label>
              <Form.Check
                id="unavailable"
                checked={editData.unavailable}
                onChange={handleEditChange}
              ></Form.Check>
            </Form.Group>
            {director.edited.by !== null && (
              <div>
                Last Edited By:
                <br />
                {director.edited.by.username} on {director.edited.time}
              </div>
            )}
            <Button onClick={handleDelete}>Remove Director</Button>
          </Card.Body>
          <Card.Footer>
            <Button onClick={handleEditSubmit}>Submit Changes</Button>
            <Button
              onClick={(e) => {
                handleEditToggle(e);
              }}
            >
              Cancel Edit
            </Button>
          </Card.Footer>
        </>
      ) : (
        <>
          {funeralhome.parentCompany?.funeralhomes?.length === 0 ? (
            <Card.Header>
              {funeralhome.on_calls.defaultDirector?.some(({ _id }) => {
                return _id === director._id;
              }) && "Default "}
              Director
            </Card.Header>
          ) : (
            <>
              {funeralhome?.name !== director.funeralhome?.name ? (
                <Card.Header>
                  Affiliate Director for {director.funeralhome?.name}
                </Card.Header>
              ) : (
                <Card.Header>Director for {funeralhome.name}</Card.Header>
              )}
            </>
          )}
          <Card.Body>
            {!director.unavailable ? (
              <>
                Name: {director.name}
                <br />
              </>
            ) : (
              <Alert variant="danger" show={director.unavailable}>
                {director.name} is not availabile
              </Alert>
            )}
            <>Primary Phone: {director.primary_phone}</> <br />
            {director.primary_phone && (
              <>
                <a
                  target={"_blank"}
                  rel="noreferrer"
                  href={generateLink(director.primary_phone)}
                >
                  Open Google Voice
                </a>
                <br />
              </>
            )}
            <>
              Preffered Contact Method: {director.contact_method}
              <br />
            </>
          </Card.Body>
          <Card.Footer>
            {currentUser.adminStatus || checkIfAccess(funeralhome.name) ? (
              <>
                <Button
                  onClick={(e) => {
                    handleEditToggle();
                  }}
                >
                  Edit Director's Info
                </Button>
              </>
            ) : (
              <></>
            )}
            {(funeralhome.on_calls.directorPool.length === 0 ||
              !funeralhome.on_calls.directorPool.some(
                (directorPoolDirector) => {
                  return directorPoolDirector._id === director._id;
                }
              )) && (
              <>
                <Button
                  onClick={() =>
                    addDirectorToDirectorPool(
                      director._id,
                      director.funeralhome._id
                    )
                  }
                >
                  Add To On Call Director Pool
                </Button>
                {affiliateFHS.length > 0 &&
                    <>
                    <Form.Control
                  as="select"
                  value={affiliateFH}
                  onChange={(e) => setAffiliateFH(e.target.value)}
                >
                  <option value={"none"}>Please Select an Affiliate</option>
                  {affiliateFHS.map((fh) => (
                    <option key={fh._id} value={fh._id}>
                      {fh.name}
                    </option>
                  ))}
                </Form.Control>
                <Button
                  onClick={() => {
                    addDirectorToDirectorPool(director._id, affiliateFH);
                  }}
                  disabled={affiliateFH === "none"}
                >
                  Add to Affiliate On Call Director Pool
                </Button>
                    </>
                }
                
              </>
            )}
          </Card.Footer>
        </>
      )}
    </Card>
  );
};
export default DirectorCard;
