import React, {useState} from "react";
import { Form , Button, Alert, Card, ListGroup, ListGroupItem} from "react-bootstrap";
import { EDIT_SERVICE_OPTIONS } from "../../utils/mutations";
import { useMutation } from "@apollo/client";
const ServiceOptions = ({Funeralhome}) =>{
    const [editServiceOptions] = useMutation(EDIT_SERVICE_OPTIONS)
    const [editServiceOptionMode, setEditServiceOptionsMode] = useState(-1)
    const [optionsFormData, setOptionsFormData] = useState()
    const [serviceOptions, setServiceOptions] = useState(Funeralhome.serviceOptions)
    const [editMode, setEditMode] = useState(false)
    const [removeOption, setRemoveOption] = useState(20000)
    const handleEditServiceOptions = async (e) => {
        const options = serviceOptions
        try {
            await editServiceOptions({variables: {options, funeralhome: Funeralhome.name}})
            window.location.reload()
        } catch (error) {
            console.log(error)
        }
    } 
    const handleRemoveServiceOptions = async  (e, index) => {
        const options = serviceOptions.filter((option) => {
            console.log(option, e.target.id)
            return option !== e.target.id
        })
        try {
            await editServiceOptions({variables: {options, funeralhome: Funeralhome.name}})
            window.location.reload()
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <Card>
            <Card.Header style={{textAlign: 'center'}}>
            Service Options: <br/>
            </Card.Header>
            {editMode ? <>
                {Funeralhome.serviceOptions && serviceOptions.map((serviceOption, index) => (
                <Card.Body key={serviceOption}>
                    {editServiceOptionMode === index ?
                    <>
                    <Form>
                        <Form.Control className='formInput' id={serviceOption} onChange={(e) => {
                            setOptionsFormData(e.target.value)
                        }} value={optionsFormData}>
                        </Form.Control>
                        <Button type='Button' onClick={() => {setEditServiceOptionsMode(-1);
                        const array = serviceOptions.map((option) => {
                            if(option === serviceOption) {
                                return optionsFormData
                            } else{
                                return option
                            }
                        })
                        setServiceOptions(array)
                        }}>Save Edit</Button>
                        <Button type='Button' onClick={() => {setOptionsFormData(); setEditServiceOptionsMode(-1)}}>Cancel</Button>
                    </Form> 
                    </>:
                        <>
                        {serviceOption} <Button onClick={() => {setEditServiceOptionsMode(index); setOptionsFormData(serviceOption)}}>Submit</Button> 
                        {removeOption === index ?
                        <Alert dismissible variant='danger' show={removeOption === index} onClose={() => setRemoveOption(2000000)}>
                        Are you sure you'd like to remove this option?
                        <Button id={serviceOptions[index]} onClick={(e) => handleRemoveServiceOptions(e, index)}>Confirm Removal</Button>
                        </Alert>
                            :
                        <Button onClick={() => setRemoveOption(index)}>Remove</Button>
                        }
                    <br/>
                    </>
                    }
                </Card.Body>    
            ))}
            <Card.Footer>
            <Button onClick={() => {setServiceOptions([...serviceOptions, ""]); setOptionsFormData(""); setEditServiceOptionsMode(serviceOptions.length)}}>Add Sercice Option</Button>
            <Button id="serviceOptions" onClick={(e) => setEditMode(false)}>Cancel Service Options Changes</Button><br/>
            <Button onClick={handleEditServiceOptions}> Submit Changes </Button>
            </Card.Footer>
            </>: <Card.Body>
            <ListGroup>
            {Funeralhome.serviceOptions && serviceOptions.map((serviceOption, index) => (
                <ListGroupItem key={serviceOption}>
                    {serviceOption}
                </ListGroupItem>                    
            ))}
                </ListGroup>
            <Button id="serviceOptions" onClick={(e) => {setEditMode(true); setServiceOptions(Funeralhome.serviceOptions)}}>Open Service Options Changes</Button>
            </Card.Body>
            }
        </Card>
    )
}
export default ServiceOptions