import React from "react";
import { useQuery} from "@apollo/client";
import { QUERY_FUNERALHOMES } from "../../utils/queries";
import { Form } from "react-bootstrap";
const UserListAccess = ({User, access, setAccess}) => {
    const {data} = useQuery(QUERY_FUNERALHOMES)
    const funeralHomes = data?.FuneralHomes || []
    const handleChange = (e) => {
        if(e.target.checked === true) {
        setAccess([...access, e.target.id])
        }
        else if(e.target.checked === false) {
            const w = access.filter((access) => {
               return access !== e.target.id
            })
            setAccess(w)
        }
    }
    return (
        <div>
            <h3>Give Access to which Funeral Homes:</h3>
            <div style={{overflow: 'auto'}}>
            {funeralHomes && funeralHomes.map((funeralhome)=> (
                <div key={funeralhome._id}>
                {funeralhome.name}
                <Form.Check checked={access.includes(funeralhome.name)} id={funeralhome.name} onChange={handleChange} type="checkbox"></Form.Check>
                </div>
            ))
            }
            </div>
        </div>
    )
}
export default UserListAccess