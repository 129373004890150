import React, {useState} from 'react'
import UpdateList from '../components/UpdateList'
import UpdateForm from '../components/UpdateForm'
import {Button} from 'react-bootstrap'
const Updates = ({currentUser}) => {
    const [updateForm, setUpdateForm] = useState(false)
    return (
        <div>
            {currentUser.username === 'Cameron N.'
            &&
            <Button onClick={() => setUpdateForm(true)}>Add Update</Button>
            }
            <UpdateList currentUser={currentUser}></UpdateList>
            {updateForm &&
            <UpdateForm handleClose={() => setUpdateForm(false)}></UpdateForm>
            }
        </div>
    )
}

export default Updates