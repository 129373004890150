import React from "react"
import LoginForm from '../components/Login-Signup-Forms/LoginForm'
import SignupForm from '../components/Login-Signup-Forms/SignupForm'
import {Tabs, Tab} from 'react-bootstrap'
import TutorialVideoPlayer from "../components/TutorialVideoPlayer";
const Login = () => {
  return (
    <div>
      <Tabs justify>
        <Tab title='Login' eventKey="login">
        <Tab.Container >
        <LoginForm></LoginForm>
        </Tab.Container>
        </Tab>
        <Tab title="Signup" eventKey="singup">
        <SignupForm></SignupForm>
        </Tab>
        <Tab title="Signup Tutorial" eventKey='video'>
          <h1 style={{textAlign: 'center'}}>
          Video Tutorial # 1 Signing Up:
          </h1>
          <TutorialVideoPlayer url={'TutorialVideos/Tutorial1SU.mp4'}></TutorialVideoPlayer>
          <p style={{textAlign: 'center'}}>
            All of the other video tutorials will be accessible once you login
          </p>
        </Tab>
      </Tabs>
    </div>
  );
};
export default Login;
