import React, {useState} from "react";
import { Card, Button, } from 'react-bootstrap'
import DirectorsForm from "../DirectorsForm";
import DirectorPoolCard from "../DirectorPoolCard";
const FHDirectorPoolList = ({funeralhome, directors, currentUser, closeDirectorList, currentPage}) => {
    const [addDirector, setAddDirector] = useState({status: false, funeralhome: ''})
    if(directors !== null && directors !== undefined) {
        var arrayForSort = [...directors]
        directors = arrayForSort.sort(function({name: nameA}, {name: nameB}){
            if(nameA === null || nameB === null) {
                return -200
            }
            nameA = nameA.toLowerCase()
            nameB = nameB.toLowerCase()
            if (nameA < nameB)
             {return -1}                    
            if (nameA > nameB)
             {return 1}
            return 0
        })
    }
    if(directors === undefined || directors.length === 0)
    {
    return (
    <Card style={{display: 'flex', flexWrap: 'wrap'}}>
    <Card.Header>Directors: </Card.Header>
    <Card.Body>
    There are no directors in the on call director pool<br/>
    </Card.Body>
    </Card>
    )
    }
    const directorsArray =[...directors]
    return (
    <div style={{display: 'flex', flexWrap: 'wrap', textAlign: 'center'}}>
    {addDirector.status && <DirectorsForm page={'SingleFH'} value={addDirector.funeralhome} handleClose={() => setAddDirector(!addDirector)}></DirectorsForm>}
        <Card key={funeralhome} style={{width: '31.5vw', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', }}>
            <Card.Header>
                Director Who Can Be On Call List
            </Card.Header>
        {(funeralhome === "Directors with No Funeral Home" && !currentUser.adminStatus) ? 
        <div></div> :
        <div>
            {!funeralhome.status &&
            <>
            <h3 className='Alert'>We aren't answering for them currently</h3>
                        <br/>
            </>
            }
            <Card.Body className="outerCard" style={currentPage !== 'singleFHPage' ?{height: '33vh', overflowY: 'scroll', justifyContent: "center", display: 'grid'} : {height: '75vh', overflowY: 'scroll', justifyContent: "center", display: 'grid'}}>
            {directorsArray.length === 0 ?
                <p>No Directors</p>
                :
                <>
            {directorsArray && directorsArray.map((director, index) => (
                <DirectorPoolCard key={director._id} currentUser={currentUser} closeDirectorList={() => closeDirectorList()} funeralhome={funeralhome} director={director}>
                </DirectorPoolCard>
            ))}
            </>}
            </Card.Body>
            {currentUser.adminStatus && currentPage !== 'singleFHPage' && 
            <Card.Footer>
            <Button onClick={() => setAddDirector({...addDirector, status: !addDirector.status, funeralhome: funeralhome.name})}>Add Director</Button>
            </Card.Footer> 
            }
        </div>
        }
        </Card>
</div>
 )
}
export default FHDirectorPoolList