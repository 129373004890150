import React, { useState, useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { DELETE_UPDATE, EDIT_UPDATE, MARK_AS_READ } from '../../utils/mutations'
import {QUERY_UPDATES} from '../../utils/queries'
import { Card, Button, Alert, Form, Tabs, Tab } from 'react-bootstrap'
import dateFormat from '../../utils/dateFormat'
const UpdateList = ({currentUser}) => {
    const {data} = useQuery(QUERY_UPDATES)
    const updates = data?.Updates
    const [currentUpdates, setCurrentUpdates] = useState([])
    const [myUpdates, setMyUpdates] = useState([])
    const [editMode, setEditMode] = useState({index: 200000})
    const [editData, setEditData] = useState()
    const [deleteConfirmation, setDeleteConfirmation] = useState(false)
    const [textareaheight, setTextareaheight] = useState(1);
    const [key, setKey] = useState("My Updates")
    const [markAsRead] = useMutation(MARK_AS_READ)
    const [editUpdate] = useMutation(EDIT_UPDATE)
    const [deleteUpdate] = useMutation(DELETE_UPDATE)
    useEffect(() => {
        const updateArray = updates || []
        const arrayforFilter = [...updateArray]
        setMyUpdates(updateArray)
        setCurrentUpdates(arrayforFilter.filter((update) => {
            return !update.readList.some(({_id})=> {
                return _id === currentUser._id
            })
        }))
    }, [setCurrentUpdates, updates, currentUser, setMyUpdates])
    const handleMarkAsRead = (e) => {
        try {
            markAsRead({variables: {id: e.target.id, userId: currentUser._id}})
        } catch (error) {
            console.log(error)
        }
    }
    const handleEditToggle =(index, update) => {
        setEditData(update)
        setEditMode({index})
        setTextareaheight(1)
    }
    const handleEditChange = (e) => {
        if(e.target.id === 'text') {
            if(e.target.value === "") {
                setTextareaheight(1)
            } else  {
        const height = e.target.scrollHeight;
        const rowHeight = 50; 
        const trows = Math.ceil(height / rowHeight) -1 ;
        if (trows && textareaheight) { 
            setTextareaheight(trows); 
          } 
        }
        }
        setEditData({...editData, [e.target.id]: e.target.value})
    }
    const handleEditSubmit = (e) => {
        const now = dateFormat(new Date()).split(' ')
        const date = now[0] + "/" + now[1] + "/" + now[2]
        const time = dateFormat(new Date()).split('at')[1]
        try {
            editUpdate({variables: {...editData, time: date + " at" + time, id: editData._id}})
        }catch (error) {
            console.log(error)
        }
        setEditData({})
        setEditMode({index: 200000})
        window.location.reload()
    }
    const handleDelete = () => {
        try {
            deleteUpdate({variables: {id: editData._id}})
        } catch (error){
            console.log(error)
        }
        window.location.reload()
    }
    // console.log(editData)
    return (
        <Tabs fill activeKey={key} onSelect={(key) => {
            setKey(key)
            localStorage.setItem('updatesKey', key)
        }}>
            <Tab eventKey='My Updates' title='My Updates'>
            {currentUpdates.length > 0 ?
            <div style={{display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap'}}>{currentUpdates.map((update, index) => (
                    <Card key={update._id} style={{maxWidth: '20vw'}}>
                        <Card.Header>
                            Update:<br/>
                            Title: {update.title} <br/> 
                            Version # {update.number} <br/>
                            on: {update.time}
                        </Card.Header>
                        <Card.Body>
                            {update.text}
                        </Card.Body>
                        <Card.Footer>
                        <Button id={update._id} onClick={handleMarkAsRead}>Mark As Read</Button>
                        </Card.Footer>
                    </Card>
                    ))}
            </div>
        : <Alert>
            No new updates
            </Alert>
        }
            </Tab>
            <Tab title='All Updates' eventKey={'All Updates'}>
            <div style={{display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap'}}>
            {editMode.index !== 200000 ?
                <Card>
                <Card.Header>
                    Edit Mode for {editData.number} Update
                </Card.Header>
                <Card.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>
                            Update Title:
                        </Form.Label>
                        <Form.Control id='title' onChange={handleEditChange} value={editData.title}>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Update Text:
                        </Form.Label>
                        <Form.Control as='textarea' rows={textareaheight} id='text' onChange={handleEditChange} value={editData.text}>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Update Number:
                        </Form.Label>
                        <Form.Control id='number' onChange={handleEditChange} value={editData.number}>
                        </Form.Control>
                    </Form.Group>
                </Form>
                <Alert show={deleteConfirmation}>
                    Are you sure you would like to delete this update?
                </Alert>
                </Card.Body>
                <Card.Footer>
                {deleteConfirmation ?
                    <>
                    <Button onClick={() => setDeleteConfirmation(false)}>Cancel Delete</Button>
                    <Button onClick={handleDelete}>Delete</Button>
                    </>
                    :<>
                    <Button onClick={() => handleEditToggle(200000, {})}>Cancel Edit</Button>
                    <Button onClick={handleEditSubmit}>Submit Edit</Button>
                    <Button onClick={() => setDeleteConfirmation(true)}>Delete Update</Button>
                    </>
                    }
                </Card.Footer>
                </Card>:
                <>{myUpdates.map((update, index) => (
                    <Card key={update._id} style={{maxWidth: '20vw'}}>
                        <Card.Header>
                            Update:<br/>
                            Title: {update.title} <br/> 
                            Version # {update.number} <br/>
                            on: {update.time}
                        </Card.Header>
                        <Card.Body>
                            {update.text}
                        </Card.Body>
                        {currentUser.adminStatus &&
                            <Card.Footer>
                            <Button onClick={() => handleEditToggle(index, update)}>
                            Edit Update               
                            </Button>
                            </Card.Footer>
                        }
                    </Card>
                    ))}
                </>
                }
            </div>
            </Tab>
        </Tabs>
    )
}
export default UpdateList