import React from "react";
import { Card} from 'react-bootstrap'
import DirectorCard from "../DirectorCard";
const FHDirectorList = ({funeralhome, directors, currentUser, closeDirectorList, currentPage}) => {
    var arrayForSort = [...directors]
    directors = arrayForSort.sort(function({name: nameA}, {name: nameB}){
        if(nameA === null || nameB === null) {
            return -200
        }
        nameA = nameA.toLowerCase()
        nameB = nameB.toLowerCase()
        if (nameA < nameB)
         {return -1}                    
        if (nameA > nameB)
         {return 1}
        return 0
    })
    if(directors.length === 0)
    {
    return (
    <Card style={{display: 'flex', flexWrap: 'wrap'}}>
    <Card.Header>Directors: </Card.Header>
    <Card.Body>
    There are no directors in the on call director pool<br/>
    </Card.Body>
    </Card>
    )
    }
    const directorsArray =[...directors]
    return (
    <div style={{display: 'flex', flexWrap: 'wrap', textAlign: 'center'}}>
        <Card key={funeralhome} style={{margin: '.25vw', width: '31.5vw', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', }}>
            <Card.Header>
                Director List
            </Card.Header>
        {(funeralhome === "Directors with No Funeral Home" && !currentUser.adminStatus) ? 
        <div></div> :
        <div>
            {!funeralhome.status &&
            <>
            <h3 className='Alert'>We aren't answering for them currently</h3>
                        <br/>
            </>
            }
            <Card.Body className="outerCard" style={currentPage !== 'singleFHPage' ?{height: '39vh', overflowY: 'scroll', justifyContent: "center", display: 'grid'} : {height: '75vh', overflowY: 'scroll', justifyContent: "center", display: 'grid'}}>
            {directorsArray.length === 0 ?
                <p>No Directors</p>
                :
                <>
            {directorsArray && directorsArray.map((director, index) => (
                <DirectorCard key={director._id} currentUser={currentUser} closeDirectorList={() => closeDirectorList()} funeralhome={funeralhome} director={director}>
                </DirectorCard>
            ))}
            </>}
            </Card.Body>
        </div>
        }
        </Card>
</div>
 )
}
export default FHDirectorList