import React, {useState, useEffect} from "react";
import { useQuery } from "@apollo/client";
import { QUERY_DIRECTORS} from "../utils/queries";
import BulkOnCalls from '../components/BulkOnCalls'
import FHOCPage from "./FHOCPage";
import Loading from "../components/Loading";
import { Tabs, Tab } from "react-bootstrap";
const Director = ({currentUser}) => {
    const {data, loading} = useQuery(QUERY_DIRECTORS)
    const Directors = data?.Directors
    const [currentDirectors, setCurrentDirectors] = useState([])
    useEffect(() => {
        const directorsList = Directors || []
        setCurrentDirectors(directorsList)
    }, [Directors, setCurrentDirectors])
    const directorsWithNoFH = currentDirectors.filter(({funeralhome}) => {
        return funeralhome === null
    })
    return(
        <>
        {loading && <Loading page={'Directors Page'}></Loading>}
        <Tabs justify>
            <Tab title={'On Calls'} eventKey={'OnCalls'}>
                <FHOCPage currentUser={currentUser} directorsWithNoFH={directorsWithNoFH}></FHOCPage>
            </Tab>
            <Tab title={'Bulk On Calls'} eventKey={'BulkOnCalls'}>
            <Tab.Content>
                <BulkOnCalls directors={currentDirectors} setCurrentDirectors={(data) => setCurrentDirectors(data)}></BulkOnCalls>
            </Tab.Content>
            </Tab>
        </Tabs>
        </>
    )
}
export default Director