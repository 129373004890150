import React, {useState} from 'react'
import {Card, Form, Alert, Button} from 'react-bootstrap'
import { useMutation } from '@apollo/client';
import {ADD_SERVICE} from '../../../utils/mutations'
import $ from 'jquery';
import 'jquery-mask-plugin/dist/jquery.mask.min'; 
const ServiceInfoForm = ({Funeralhome, loading}) => {
    const [addService] = useMutation(ADD_SERVICE);
    const [alert, setAlert] = useState({ status: false, text: "" });
    const [serviceData, setServiceData] = useState({funeralhome: Funeralhome.name,location: "", serviceType: 'none', name: 'none', time: '', date: ''})
    const handleChange = (e) => {
    setServiceData({ ...serviceData, [e.target.id]: e.target.value });
    };
    console.log()
    const handleInfoSubmit = async (e) => {
        e.preventDefault();
        try {
          await addService({ variables: { ...serviceData}});
          setAlert({
            status: true,
            text: serviceData.serviceType + " has been added for " + serviceData.name,
          });
          setServiceData({funeralhome: Funeralhome.name,location: "", date: '', time: '', serviceType: 'none', name: 'none'})
        } catch (errors) {
          console.log(errors);
          setAlert({ status: true, text: errors.message });
        }
      };
      if(!loading) {
        $('#date').mask('10/00/0000', {
            placeholder: '10/10/1930',
            translation: {
                "1": {pattern: /[0,1]/},
            }
        })
        $('#time').mask('00:00 ll', {
          placeholder: '10:20pm',
          translation: {
              "l": {pattern: /[pma]/}
          }
      })
    }
    return (
          <Card >
            <Card.Header>
              Add Info to Service
            </Card.Header>
            <Card.Body style={{overflowY: 'scroll', height: '60vh'}}>
              <Form>
                <Form.Group>
                  <Form.Label>
                    Service For:
                  </Form.Label>
                  <Form.Control id="name" as="select" onChange={handleChange} value={serviceData.name}>
                    <option value="none">Please select whose service it's for</option>
                    {Funeralhome.services.map(({name, _id}) => (
                      <option key={_id}>{name}</option>
                    ))
                    }
                  </Form.Control>
                  <Alert variant='danger' show={serviceData.name === 'none'}>
                    Service Name Required!!
                  </Alert>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Type:</Form.Label>
                  <Form.Control
                    value={serviceData.serviceType}
                    onChange={handleChange}
                    id="serviceType"
                    as="select"
                  >
                    <option value="none">Please Select a Service Type</option>
                    {Funeralhome.serviceOptions &&
                      Funeralhome.serviceOptions.map((option) => (
                        <option key={option}>{option}</option>
                      ))}
                  </Form.Control>
                  <Alert show={serviceData.serviceType === 'none'} variant='danger'>
                    Service Type Required!!
                  </Alert>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Date:</Form.Label>
                  <Form.Control id="date" value={serviceData.date} onChange={handleChange}></Form.Control>
                  <Alert variant='danger' show={serviceData.date === null || serviceData.date === ''}>
                    Service Date Required!!
                  </Alert>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Time:</Form.Label>
                  <Form.Control id="time" value={serviceData.time} onChange={handleChange}></Form.Control>
                  <Alert show={serviceData.time === null || serviceData.time === ''} variant='danger'>
                    Service Time Required!!
                  </Alert>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Location:</Form.Label>
                  <Form.Control
                    value={serviceData.location}
                    id="location"
                    onChange={handleChange}
                  ></Form.Control>
                </Form.Group>
              </Form>
            </Card.Body>
            <Card.Footer>
              <Button onClick={handleInfoSubmit}>Submit Service</Button>
              <Alert
                dismissible
                onClose={() => setAlert({ status: false })}
                show={alert.status}
              >
                {alert.text}
              </Alert>
            </Card.Footer>
          </Card>
      );
}
export default ServiceInfoForm