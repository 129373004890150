import React, {useState} from "react";
import { useMutation } from "@apollo/client";
import { ADD_FLOWER_SHOP} from '../../utils/mutations';
import { Card, Form, Button } from "react-bootstrap";
const FlowershopForm = ({funeralhome}) => {
    const [addFlowerShop] = useMutation(ADD_FLOWER_SHOP)
    const [flowerShopData, setFlowerShopData] = useState({location: '', name: '', number: ''})
    const handleFSChange = (e) => {
        setFlowerShopData({...flowerShopData, [e.target.id]: e.target.value, funeralhome})
    }
    
    const handleFSSubmit = (event) => {
        event.preventDefault()
        try {
            addFlowerShop({variables: flowerShopData})
            setFlowerShopData({location: '', name: '', number: ''})
        } catch (e) {
            console.log(e)
        }
    }
    return (
        <Card>
                <Card.Header>
                    Flower Shop Form
                </Card.Header>
                <Card.Body>
                <Form>
                    <Form.Label>
                        Location:
                    </Form.Label>
                    <Form.Control onChange={handleFSChange} value={flowerShopData.location} placeholder='location' id="location"></Form.Control><br/>
                    <Form.Label>
                        Flowershop Name:
                    </Form.Label>
                    <Form.Control onChange={handleFSChange} value={flowerShopData.name} placeholder='Flower Shop Name' id='name'></Form.Control><br/>
                    <Form.Label>
                        Flowershop Number:
                    </Form.Label>
                    <Form.Control onChange={handleFSChange} value={flowerShopData.number} placeholder='Flower Shop Number' id='number'></Form.Control><br/>
                </Form>
                </Card.Body>
                <Card.Footer>
                <Button onClick={handleFSSubmit}>Add Flower Shop</Button>
                </Card.Footer>
                </Card>
    )
}
export default FlowershopForm