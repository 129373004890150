import React, {useState} from 'react'
import {DELETE_SERVICE, DELETE_SERVICE_INFO, EDIT_SERVICE, EDIT_SERVICE_INFO} from '../../utils/mutations'
import { useMutation } from '@apollo/client'
import {Form, Button, Card} from 'react-bootstrap'
import dateFormat from '../../utils/dateFormat'
import $ from 'jquery';
import 'jquery-mask-plugin/dist/jquery.mask.min'; 
const ServicesList = ({Funeralhome, currentUser, loading}) => {
    const [deleteService] = useMutation(DELETE_SERVICE)
    const [deleteServiceInfo] = useMutation(DELETE_SERVICE_INFO)
    const [editService] = useMutation(EDIT_SERVICE)
    const [editServiceInfo] = useMutation(EDIT_SERVICE_INFO)
    const [editData, setEditData] = useState({name: '', fhLocation: 'none', userId: currentUser._id, editTime: dateFormat(new Date())})
    const [editMode, setEditMode] =useState({name: ''})
    const [editInfoData, setEditInfoData] = useState({name: ''})
    const handleEdit = async (e)  => {
        console.log(e.target.id)
        e.preventDefault()
        try {
            await editService({variables: editData})
            setEditMode({name: ''})
        } catch (error) {
            console.log(error)
        }
    }
    const handleInfoEdit = async (e) => {
        e.preventDefault()
        try {
            await editServiceInfo({variables: {...editInfoData, userId: currentUser._id, editTime: dateFormat(new Date())}})
            setEditMode({name: ''})
        } catch (error) {
            console.log(error)
        }
    }
    const handleInfoEditChange = (e) => {
        console.log(e)
        setEditInfoData({...editInfoData, [e.target.id]: e.target.value})
    }
    const handleEditChange = (e) => {
        setEditData({...editData, [e.target.id]: e.target.value})
    }
    const handleRemoveServiceInfo = async (name, date, time, location, serviceType) => {
        try {
            await deleteServiceInfo({variables: {name, date, time, location, serviceType}})
            // window.location.reload()
        } catch (error) {
            console.log(error)
        }
    }
    const handleServiceRemoval = (funeralhome, fhLocation, name) => {
        console.log()
        try {
            deleteService({variables: {funeralhome, fhLocation, name}})
            // window.location.reload()
        } catch (error) {
            console.log(error)
        }        
    }
    if(Funeralhome.services && Funeralhome.services.length === 0) {
        return (
        <div>
            No Services Scheduled <br/>
        </div>)
    }
    if(!loading) {
        $('#date').mask('10/00/0000', {
            placeholder: '10/10/1930',
            translation: {
                "1": {pattern: /[0,1]/},
            }
        })
        $('#time').mask('00:00 ll', {
          placeholder: '10:20pm',
          translation: {
              "l": {pattern: /[pma]/}
          }
      })
    }
    return (
        <Card>
            <Card.Header style={{textAlign: 'center'}}>
                Service List
            </Card.Header>
            <Card.Body style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', height: "70vh", overflowY: 'scroll'}}>
        {Funeralhome.services && Funeralhome.services.map((service) => (
            <Card key={service._id}>   
                {editMode.name === service.name ? 
                    <>
                    <Card.Header>
                    Editing {editMode.name}'s Info                        
                    </Card.Header>
                    <Card.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Name:</Form.Label>
                            <Form.Control onChange={handleEditChange} id="name" value={editData.name}></Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Location:</Form.Label>
                            <Form.Control onChange={handleEditChange} as='select' id="fhLocation" value={editData.fhLocation}>
                                <option value='none'>Please Select a location</option>
                                {Funeralhome.locations && Funeralhome.locations.map(({Name}) => (
                                    <option>{Name}</option>
                                ))
                                }
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                        <Form.Label>
                            Extra Info:
                        </Form.Label>
                    <Form.Control onChange={handleEditChange} id="extraInfo" value={editData.extraInfo}></Form.Control>
                    </Form.Group>
                    </Form>
                    {service.edited.by !== null &&
                    <>
                    <br/>
                    Last Edited by: <br/>
                    {service.edited.by.username} on {service.edited.time}
                    </>
                    }
                    </Card.Body>
                    <Card.Footer>
                    <Button onClick={() => handleServiceRemoval(Funeralhome.name, service.fhLocation, service.name)}>Remove Service</Button>
                    <Button onClick={() => setEditMode({name: ''})}>Cancel Edit</Button>
                    <Button onClick={handleEdit}>Submit</Button>
                    </Card.Footer>
                    </>:<>
                    <Card.Header>
                    Service Created on: {service.timeTaken} <br/>
                    </Card.Header>
                    <Card.Body>
                    {service.name} thru the {service.fhLocation} Location <br/>
                    Extra Info: {service.extraInfo}
                    </Card.Body>
                    <Card.Footer>                    
                <Button onClick={() => {setEditMode({name: service.name}); setEditData({...editData, funeralhome: Funeralhome.name, fhLocation: service.fhLocation, oldName: service.name, name: service.name, extraInfo: service.extraInfo})}}>Edit Service</Button>
                </Card.Footer>
                </>
                }
            <div>
            {service.serviceInfo && service.serviceInfo.map((info) => (
                <Card key={info.time + info.location}>
                    {editMode.name === info.locations + info.date + info.time ?
                    <>
                    <Card.Header>
                        Edit Mode
                    </Card.Header>
                    <Card.Body>
                    <Form>
                        <Form.Group>
                        <Form.Label>
                            Type:
                        </Form.Label>
                        <Form.Control as='select' id="serviceType" onChange={handleInfoEditChange} value={editInfoData.serviceType}>
                            <option value={'none'}>Please Select a Type</option>
                            {Funeralhome.serviceOptions && Funeralhome.serviceOptions.map((option) => (
                                <option>{option}</option>
                            ))}
                        </Form.Control>
                        </Form.Group>
                        <Form.Group>
                        <Form.Label>
                            Date:
                        </Form.Label>
                        <Form.Control id="date" onChange={handleInfoEditChange} value={editInfoData.date}>
                        </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                Time:
                            </Form.Label>
                            <Form.Control id="time" onChange={handleInfoEditChange} value={editInfoData.time}>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                Location:
                            </Form.Label>
                            <Form.Control id='location' onChange={handleInfoEditChange} value={editInfoData.location}>
                            </Form.Control>
                        </Form.Group>
                    </Form>
                    {info.edited &&
                    <>
                    <br/>
                    Last Edited by: <br/>
                    {info.edited.by.username} on {info.edited.time}
                    </>
                    }
                    </Card.Body>
                    <Card.Footer>
                    <Button onClick={handleInfoEdit}>Submit</Button>
                    <Button onClick={() => {setEditMode({name: ''}); setEditInfoData({...editInfoData, name: service.name, date: info.date, time: info.time, serviceType: info.serviceType, location: info.location})}}>Cancel Edit</Button>
                    <Button onClick={() => handleRemoveServiceInfo(service.name, info.date, info.time, info.location, info.serviceType)}>Remove {info.serviceType}</Button>
                    </Card.Footer>
                    </>
                    :
                        <>
                        <Card.Header>
                        {info.serviceType}
                        </Card.Header>
                        <Card.Body>
                        {info.date} {info.time} {info.location}
                        <br/> 
                        </Card.Body>
                        <Card.Footer>
                        <Button onClick={() => {setEditMode({name: info.locations + info.date + info.time}); setEditInfoData({name: service.name, time: info.time, date: info.date, oldLocation: info.location, location: info.location, serviceType: info.serviceType, oldServiceType: info.serviceType})}}>Edit {info.serviceType}</Button>
                        </Card.Footer>
                        </>
                    }
                </Card>
            ))}
            </div>
            </Card>
        ))}
        </Card.Body>
        </Card>
    )
}
export default ServicesList