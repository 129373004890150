import React from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
const GeneralInfoTableOfContents= ({showTableOfContents, setshowTableOfContents}) => {
    return (
        <>
            <Navbar sticky="top" hidden={!showTableOfContents} style={{fontSize: '2.5vh'}}>
                <Navbar.Text style={{color: "white"}}>
                    Table Of Contents:
                </Navbar.Text>
                <Nav variant='tabs' style={{width: "87.5%"}} fill>
                    <Nav.Item>
                        <Nav.Link href='#GeneralInfo'>
                        Info
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href='#Helpful'>
                            Help
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#FAQ">
                            FAQ
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#Feedback">
                        Feedback
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#Updates">
                        Updates
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#Tutorials">
                        Tutorials
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#Message">
                        Messages
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#Directors-OnCalls">
                        Directors
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#Individual Funeral Home">
                        Funeral Home
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="#First-Calls">
                        First Calls
                        </Nav.Link>
                    </Nav.Item>
                    <NavDropdown title="Settings">
                        <NavDropdown.Item onClick={setshowTableOfContents}>
                            Toggle Table Of Contents
                        </NavDropdown.Item>
                    </NavDropdown>
                </Nav>
                    
            </Navbar>
        </>
    )
}
export default GeneralInfoTableOfContents