import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import Loading from "../Loading";
import { useQuery, useReactiveVar, makeVar } from "@apollo/client";
import { QUERY_FUNERALHOMES } from "../../utils/queries";
export const FuneralhomeVar = makeVar([]);
const MessageAnswerList = ({ selectFuneralHome }) => {
  const { data, loading } = useQuery(QUERY_FUNERALHOMES);
  useEffect(() => {
    if (!loading && data !== undefined) {
      FuneralhomeVar(data.FuneralHomes);
    }
  });
  const funeralhomes = useReactiveVar(FuneralhomeVar);
  var answerList = [
    {
      screenReads: "JW Carey Limo",
      howWeAnswer: "Carey Minneapolis, this is (Your Name)",
      whoItsFor: "Carey Minneapolis (Other Office)",
      isFH: false,
    },
    {
      screenReads: "FC Sales",
      howWeAnswer: "Johnson Williams Funeral Car Sales",
      whoItsFor: "Todd",
      isFH: false,
    },
    {
      screenReads: "Johnson-Williams",
      howWeAnswer: "Johnson Williams Funeral Car Service",
      whoItsFor: "Removals/Hearses",
      isFH: false,
    },
    {
      screenReads: "FH Messages",
      howWeAnswer: "This is (Your Name)",
      whoItsFor: "Answering Service",
      isFH: false,
    },
    {
      screenReads: "Escort Services",
      howWeAnswer: "Funeral Escort Services",
      whoItsFor: "Escort Funeral Bikes/Thad",
      isFH: false,
    },
  ];
  funeralhomes.forEach((funeralhome) => {
    funeralhome.answeringThePhone.forEach(
      ({ howWeAnswer, phoneDisplayName, whoItsFor }) => {
        if (
          howWeAnswer !== null &&
          phoneDisplayName !== null &&
          funeralhome.status
        ) {
          answerList.push({
            screenReads: phoneDisplayName,
            howWeAnswer: howWeAnswer,
            whoItsFor: whoItsFor,
            isFH: true,
            funeralhome,
          });
        }
      }
    );
  });
  answerList.sort((a, b) => {
    if (a.screenReads.toLowerCase() > b.screenReads.toLowerCase()) {
      return 1;
    } else if (b.screenReads.toLowerCase() > a.screenReads.toLowerCase()) {
      return -1;
    }
    return 0;
  });
  return (
    <div>
      {loading ? (
        <Loading page="Message Answering List"></Loading>
      ) : (
        <>
          <h4>
            Click on the blue options in "Who They're Calling For" to populate
            it to the message form
          </h4>
          <Table bordered>
            <thead>
              <tr>
                <th>How Phone Reads</th>
                <th>How We Answer</th>
                <th>Who They're Calling for</th>
              </tr>
            </thead>
            <tbody>
              {answerList &&
                answerList.map(
                  ({
                    screenReads,
                    howWeAnswer,
                    whoItsFor,
                    isFH,
                    funeralhome,
                  }) => (
                    <tr key={screenReads + howWeAnswer}>
                      <td>{screenReads}</td>
                      <td>{howWeAnswer}</td>
                      {!isFH ? (
                        <td style={{ color: "grey" }} key={whoItsFor}>
                          {whoItsFor} (Check the Help Section of General Info
                          for Guidance)
                        </td>
                      ) : (
                        <td
                          style={{ color: "blue" }}
                          id="funeralhome"
                          onClick={(e) => {
                            selectFuneralHome(e, JSON.stringify(funeralhome));
                            window.scrollTo(0, 0);
                          }}
                          key={funeralhome._id}
                        >
                          {whoItsFor} ({funeralhome.name})
                        </td>
                      )}
                    </tr>
                  )
                )}
            </tbody>
          </Table>
        </>
      )}
    </div>
  );
};
export default MessageAnswerList;
