import React, {useState} from 'react'
import { Card, Form, Button, Alert } from 'react-bootstrap'
import { EDIT_ON_CALL_SCHEDULE, REMOVE_ON_CALL_SCHEDULE, } from "../../utils/mutations";
// import dateFormat from "../../utils/dateFormat";
import { useMutation } from '@apollo/client';
import TimePicker from 'react-datetime-picker'
import dayjs from 'dayjs';
import dateFormat from '../../utils/dateFormat';
const ScheduledDirectorsCard = ({schedule, Funeralhome, setRemoveAlert}) => {
    const [editStatus, setEditStatus] = useState(false)
    const [removeOnCallSchedule] = useMutation(REMOVE_ON_CALL_SCHEDULE)
    const [editOnCallSchedule] = useMutation(EDIT_ON_CALL_SCHEDULE)
    const [alert, setAlert] = useState(false)
    const [scheduleFormData, setScheduleFormData] = useState({...schedule, director: schedule.director._id})
    const today = dayjs().toDate()
    const handleConfirmScheduleRemoval = () => {
        setAlert(true)
    }
    const handleScheduleChange = (e) => {
        if(e.target.value === 'none') {
            setScheduleFormData({...scheduleFormData, director: 'none'})
        } else {
            setScheduleFormData({...scheduleFormData, director: e.target.value})            
        }
    }
    const handleSubmitChange = (e) => {
        e.preventDefault()
        const endDate = scheduleFormData.endDate
        const startDate = scheduleFormData.startDate
        const directorId = scheduleFormData.director
        const scheduleId = scheduleFormData._id
        editOnCallSchedule({variables: {endDate, startDate, scheduleId, directorId}}).then(({data}) => {
            setEditStatus(false)
        })
    }
    const handleScheduleRemoval = (e) => {
        const funeralhomename = Funeralhome.name
        const scheduleId = JSON.parse(e.target.id)._id
        try {
            removeOnCallSchedule({variables: {scheduleId, funeralhomename}}).then(() => {
                setAlert(false)
            })
        } catch (error) {
            setRemoveAlert({status: true, text: "There was an issue with removing this scheduled director"})
        }
    }
    return (
        <Card style={{border: '1px solid black', maxWidthidth: '20vw', margin: '1vw'}}>
                        {editStatus ?
                        <>
                        <Card.Header>
                        Edit Mode
                        </Card.Header>
                        <Card.Body>
                        <Form>
                            <Form.Group>
                                <Form.Label>Director:</Form.Label>
                                <Form.Control value={scheduleFormData.director} onChange={handleScheduleChange} as='select'>
                                    <option value="none">Please Select a Director</option>
                                    {Funeralhome?.directors.map((director) => (
                                        <option key={director._id} value={director._id}>{director.name}</option>
                                    ))
                                    }
                                </Form.Control>
                            </Form.Group>
                            <Form.Group>
                        <Form.Label>Start Date & Time:</Form.Label>
                        <TimePicker id="startDate" clearIcon={null} minDate={today} minDetail={'year'} value={scheduleFormData.startDate} disableCalendar={true} disableClock={true} onChange={
                            (dateTime) => setScheduleFormData({...scheduleFormData, startDate: String(dateTime)})
                        }></TimePicker>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>End Date & Time:</Form.Label>
                        <TimePicker id="endDate" onChange={
                            (dateTime) => setScheduleFormData({...scheduleFormData, endDate: String(dateTime)})
                        } clearIcon={null} minDate={today} value={scheduleFormData.endDate} disableCalendar={true} disableClock={true}></TimePicker>
                    </Form.Group>
                        </Form>
                        </Card.Body>
                        <Card.Footer>
                        <Button onClick={handleSubmitChange}>Submit Edit</Button>
                        <Button onClick={() => {setEditStatus(false)}}>Cancel Edit</Button>
                        </Card.Footer>
                        </>
                        :
                        <>
                        <Card.Header>
                        <p>{schedule.director.name}</p>
                        </Card.Header>
                        <Card.Body>
                        <p>Will Be Put On Call on: <br/> {schedule.startDate.split(' ')[0]} {dateFormat(schedule.startDate)}</p>
                        <p>Will Be Taken Off Call on: <br/> {schedule.endDate.split(' ')[0]} {dateFormat(schedule.endDate)}</p>
                        {Funeralhome.on_calls.currentOnCalls.some((director) => {return director._id === schedule.director._id}) ?
                        <p style={{color: 'green'}}>On Call</p> : <p style={{color: 'red'}}>Not On Call</p>
                        }
                        </Card.Body>
                        <Card.Footer>
                        {alert ?
                        <>
                        <Alert className='Alert'>Are you Sure you would like to delete?</Alert>
                        <Button id={JSON.stringify(schedule)} onClick={handleScheduleRemoval}>Confirm</Button>
                        <Button onClick={() => setAlert(false)}>Cancel</Button>
                        </>:
                        <>
                        <Button onClick={handleConfirmScheduleRemoval} id={schedule}>Remove From Schedule</Button>
                        <Button onClick={() => setEditStatus(true)}>Edit Schedule</Button>
                        </>
                        }
                        </Card.Footer>
                        </>}
                </Card>
    )
} 
export default ScheduledDirectorsCard