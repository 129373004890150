import React, {useState, useEffect} from 'react'
import { Card, ListGroup, ListGroupItem, Button, Form, Alert } from 'react-bootstrap'
import { EDIT_PARENT_COMPANY, REMOVE_PARENT_COMPANY } from '../../utils/mutations'
import { useMutation, useQuery } from '@apollo/client'
import { QUERY_FUNERALHOMES } from '../../utils/queries'
const ParentCompanyListCard = ({parentCompany: {_id, name, funeralhomes}, handleRemovePC}) => {
    const {data} = useQuery(QUERY_FUNERALHOMES)
    const [availableFuneralHomes, setAvailableFuneralHomes] = useState([])
    const fhomes = data?.FuneralHomes
    useEffect(() => {
        const fh = fhomes || []
        setAvailableFuneralHomes(fh.filter(({_id}) => {
            if(funeralhomes.length === 0) {
                return true
            } else {
                console.log(funeralhomes)
                return !funeralhomes.some(({_id: id}) => {
                    return _id === id
                })
            }
        }))
    }, [fhomes, setAvailableFuneralHomes, funeralhomes])
    const [currentFH, setCurrentFH] = useState('none')
    const [deleteStatus, setDeleteStatus] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [editData, setEditData] = useState({name, funeralhomes, removedFuneralHomes: [], addedFuneralHomes: []})
    const [removeParentCompany] = useMutation(REMOVE_PARENT_COMPANY)
    const [editParentCompany] = useMutation(EDIT_PARENT_COMPANY)
    const toggleDelete = () => {
        setDeleteStatus(!deleteStatus)
    }
    const handleDeleteParentCompany = async () => {
        try {
            await removeParentCompany({variables: {id: _id}}).then(({data}) => {
                handleRemovePC(data.removeParentCompany)
            })
            setEditMode(false)
            setEditData({name, funeralhomes, removedFuneralHomes: [], addedFuneralHomes: []})
        } catch (error) {
            console.log(error)
        }
    }
    const handleEditParentCompany = async () => {
        try {
            editParentCompany({variables: {name: editData.name, id: _id, funeralhomes: [...editData.funeralhomes.map(({_id}) => {return _id}), ...editData.addedFuneralHomes.map(({_id}) => {return _id})], removedfuneralhomes: editData.removedFuneralHomes.map(({_id}) => {return _id})}})
            setEditMode(false)
            setEditData({name: editData.name, funeralhomes: [...editData.addedFuneralHomes, ...editData.funeralhomes], removedFuneralHomes: [], addedFuneralHomes: []})
            setCurrentFH("none")
        } catch (error) {
            console.log(error)
        }
    }
    const handleEditChange = (e) => {
        setEditData({...editData, [e.target.id]: e.target.value})
    }
    const handleAddFuneralHome = () => {
        setEditData({...editData, addedFuneralHomes: [...editData.addedFuneralHomes, currentFH]})
        setAvailableFuneralHomes(availableFuneralHomes.filter(({_id}) => {
            return _id !== currentFH._id
        }))
    }
    const handleRemoveFuneralHome = (e) => {
        const id = JSON.parse(e.target.id)._id
        setEditData({...editData, funeralhomes: editData.funeralhomes.filter(({_id}) => {return id !== _id}), removedFuneralHomes: [...editData.removedFuneralHomes, JSON.parse(e.target.id)]})
    }
    const handleReaddFuneralHome = (e) => {
        const id = JSON.parse(e.target.id)._id
        setEditData({...editData, funeralhomes: [...editData.funeralhomes, JSON.parse(e.target.id)], removedFuneralHomes: editData.removedFuneralHomes.filter(({_id}) => {return id !== _id})})
    }
    const handleUnaddFuneralHome = (e) => {
        const id = JSON.parse(e.target.id)._id
        setEditData({...editData, addedFuneralHomes: editData.addedFuneralHomes.filter((_id) => {return id === _id})})
        setAvailableFuneralHomes([...availableFuneralHomes, JSON.parse(e.target.id)])
    }
    return (
        <Card key={_id} style={{textAlign: 'center', }}>
            {editMode ?
                <>
                <Card.Header>
                    Edit Mode:
                </Card.Header>
                <Card.Body>
                    <Form.Group>
                        <Form.Label>
                        Name: 
                        </Form.Label>
                        <Form.Control id='name' value={editData.name} onChange={handleEditChange}></Form.Control>
                    </Form.Group>
                    <Form.Group>
                        {availableFuneralHomes.length > 0 ?
                        <>
                        <Form.Label>
                            Add Funeral Homes:
                        </Form.Label>
                        <Form.Control as='select' value={JSON.stringify(currentFH)} onChange={(e) => {if(e.target.value !== 'none'){setCurrentFH(JSON.parse(e.target.value))} else{setCurrentFH('none')}}}>
                            <option value='none'>
                                Please Select a Funeral Home
                            </option>
                            {availableFuneralHomes.map(({name, _id}) => (
                                <option key={_id} value={JSON.stringify({name, _id})}>{name}</option>
                            ))}
                        </Form.Control>
                        <Button disabled={currentFH==='none'} onClick={() => handleAddFuneralHome()}>Add Funeral Home</Button>
                        </>
                        :
                        <Alert variant='danger'>
                            All Funeral Homes have been added to another list / There is an error
                        </Alert>
                        }
                        
                    </Form.Group>
                    {editData.funeralhomes.length > 0 ? <>
                    <Form.Label>
                        Current Funeral Homes:
                    </Form.Label>
                    <ListGroup>
                    {editData.funeralhomes.map(({name, _id}) => (
                        <ListGroupItem key={_id}>
                            {name} <Button onClick={handleRemoveFuneralHome} id={JSON.stringify({name, _id})}>Remove</Button>
                        </ListGroupItem>
                    ))}
                    </ListGroup>
                    </> 
                    :
                    <Alert variant='danger'>
                    No Current Funeral Homes
                    </Alert>}
                    <Alert show={editData.removedFuneralHomes.length > 0} variant='danger'>
                        <h3>
                        List of Funeral Homes to be removed from Parent Company: <br/>
                        </h3>
                        {editData.removedFuneralHomes.map(({name, _id}) => (
                            <h4 key={_id}>
                                {name} <Button id={JSON.stringify({name, _id})} onClick={handleReaddFuneralHome}>Cancel</Button>
                            </h4>
                        ))}
                    </Alert>
                    <Alert show={editData.addedFuneralHomes.length > 0}>
                    <h3>
                        List of Funeral Homes to be added to Parent Company: <br/>
                        </h3>
                        {editData.addedFuneralHomes.map(({name, _id}) => (
                            <h4 key={_id}>
                                {name} <Button id={JSON.stringify({name, _id})} onClick={handleUnaddFuneralHome}>Cancel</Button>
                            </h4>
                        ))}
                    </Alert>
                </Card.Body>
                </>:
                <>
                <Card.Header>
                    {name}
                </Card.Header>
                <Card.Body>
                    <ListGroup>
                    {funeralhomes.length > 0 ? 
                    <>
                    {funeralhomes.map(({name, _id}) => (
                        <ListGroupItem key={_id}>
                            {name}
                        </ListGroupItem>
                    ))}
                    </>:
                    <>
                    No Funeral Homes</>
                    }
                </ListGroup>
                </Card.Body>
                </>
            }
            <Card.Footer>
                {!deleteStatus ?
                    <>
                    {editMode && 
                    <>
                    <Button onClick={handleEditParentCompany}>Submit Edit</Button>
                    <Button onClick={toggleDelete}>Delete</Button>
                    </>
                    }
                    <Button onClick={() => setEditMode(!editMode)}>Toggle Edit</Button>
                    </>:
                    <>
                    <Button onClick={handleDeleteParentCompany}>Confirm Delete</Button>
                    <Button onClick={toggleDelete}>Cancel Delete</Button>
                    </>
                }
            </Card.Footer>
        </Card>
    )
}
export default ParentCompanyListCard