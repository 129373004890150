import React from 'react'
import { Card } from 'react-bootstrap'
import TutorialVideoPlayer from '../TutorialVideoPlayer'
const WebsiteTutorials = () => {
    const videoTutorialsArray = [{title: '#2 Messages Page:', url: 'TutorialVideos/Tutorial2M.mp4'}, {title: '#3 On Calls / Directors Page', url: 'TutorialVideos/Tutorial3OCD.mp4'}, {title: "#4 Individual Funeral Home", url: 'TutorialVideos/Tutorial4FH.mp4'}, {title: '#5 First Calls', url:'TutorialVideos/Tutorial5FC.mp4'}]
    return (
        <Card>
        <Card.Header>
            <h1 style={{textAlign: 'center'}}>
            Website Tutorials
            </h1>
        </Card.Header>
        <Card.Body style={{textAlign: 'center'}}>
            All Tutorials Shown were recorded on a test version of the site that doesn't have all the data. It may look different then the version you are currently using. <br/>
            These tutorials are for the general website if you need help with general answering service info click over to those tutorials or ask a coworker. <br/>
            (The first tutorial is on the signup page which is why it starts at #2)
{videoTutorialsArray.map(({title, url, extraInfo}) => (
<Card>
<Card.Header>
    <h2 style={{textAlign: 'center'}}>
    Video Tutorial {title}
    </h2>
</Card.Header>
<Card.Body>
    {extraInfo}
<TutorialVideoPlayer url={url}>
</TutorialVideoPlayer>
</Card.Body>    
</Card>    
))}
</Card.Body>
</Card>
    )
} 
export default WebsiteTutorials