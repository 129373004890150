import React, { useState } from "react";
import { Card, Form, Button, Alert } from "react-bootstrap";
import { ADD_MESSAGE, ARCHIVE_MESSAGE } from "../../utils/mutations";
import { useMutation } from "@apollo/client";
import dateFormat from "../../utils/dateFormat";
import dayjs from "dayjs";
import DateTimePicker from "react-datetime-picker";
const MessagePendingCard = ({
  message,
  pendingMessages,
  currentFHList,
  handlePending,
  handleMessageDataClear,
  handleAddedMessage,
}) => {
  const [messageReceivedData, setMessageReceivedData] = useState({
    ...message,
  });
  const [currentFH, setCurrentFH] = useState();
  const [addMessage] = useMutation(ADD_MESSAGE);
  const [archiveMessage] = useMutation(ARCHIVE_MESSAGE);
  const [deleteTgggle, setDeleteToggle] = useState(false);
  const [pendingEdit, setPendingEdit] = useState(false);
  const [receivedOptions, setReceivedOptions] = useState(false);
  const [copiedMessage, setCopiedMessage] = useState(false);
  const [pendingEditData, setPendingEditData] = useState(message);
  const [textareaheight, setTextareaheight] = useState(1);
  const checkPendingTime = ({ timeSubmitted }) => {
    const tenminutesago = dayjs().subtract(10, "minutes").format();
    return (
      new Date(tenminutesago).getTime() >
      new Date(dayjs(timeSubmitted).format()).getTime()
    );
  };
  const changeToggle = () => {
    setCopiedMessage(false);
  };
  const handleCurrentFHChange = (fhname) => {
    setCurrentFH(
      currentFHList.filter((fh) => {
        return fh.name === fhname;
      })[0]
    );
  };
  const handleCopy = () => {
    const hasParentCompany = currentFHList.some((funeralhome) => {
      if (funeralhome.name === message.funeralhome) {
        return funeralhome.parentCompany !== null;
      }
      return false;
    });
    const fixes = [
      "Please Call ",
      messageReceivedData.fhLocation + ": ",
      " They Asked for " + messageReceivedData.whoItsFor + ",",
      messageReceivedData.funeralhome + ": ",
    ];
    var generatedMessage =
      message.callersName +
      " " +
      message.callersNumber +
      " RE: " +
      message.messageText;
    if (messageReceivedData.doTheyNeedACall) {
      generatedMessage = fixes[0] + generatedMessage;
    }
    if (
      messageReceivedData.requiredInfo.includes("Location") ||
      message.fhLocation !== ""
    ) {
      generatedMessage = fixes[1] + generatedMessage;
    }
    if (hasParentCompany) {
      generatedMessage = fixes[3] + generatedMessage;
    }
    if (messageReceivedData.requiredInfo.includes("Who It's For")) {
      generatedMessage = generatedMessage + fixes[2];
    }
    generatedMessage =
      generatedMessage + " Thanks, " + messageReceivedData.author;
    navigator.clipboard.writeText(generatedMessage);
    setCopiedMessage(true);
  };
  const handlePendingMessageEdit = (e) => {
    if (e.target.id === "messageText") {
      if (e.target.value === "") {
        setTextareaheight(1);
      } else {
        const height = e.target.scrollHeight;
        const rowHeight = 50;
        const trows = Math.ceil(height / rowHeight) - 1;
        if (trows && textareaheight) {
          setTextareaheight(trows);
        }
      }
    }
    if (e.target.id === "funeralhome") {
      handleCurrentFHChange(e.target.value);
    }
    setPendingEditData({ ...pendingEditData, [e.target.id]: e.target.value });
  };
  const handlePendingMessageSubmit = (index) => {
    const array = pendingMessages.map((data, dataIndex) => {
      if (index === dataIndex) {
        return pendingEditData;
      } else {
        return data;
      }
    });
    handlePending(array);
    localStorage.setItem("pendingMessages", JSON.stringify(array));
    setPendingEdit(false);
  };
  const handleReceivedToggle = () => {
    setReceivedOptions(!receivedOptions);
  };
  const handleReceived = async () => {
    const {
      whoItsFor,
      fhLocation,
      messageStatus,
      funeralhome,
      callersNumber,
      callersName,
      messageText,
      messageTakenTime,
      messageTakenDate,
      author,
      doTheyNeedACall,
    } = messageReceivedData;
    try {
      addMessage({
        variables: {
          whoItsFor,
          fhLocation,
          messageStatus,
          funeralhome,
          callersNumber,
          callersName,
          messageText,
          messageTakenTime,
          messageTakenDate,
          author,
          doTheyNeedACall,
        },
      }).then(({ data }) => {
        const recievedDate = dateFormat(messageReceivedData.recievedTime).split(
          "at"
        )[0];
        const splitDate = recievedDate.split(" ");
        var respondDate =
          splitDate[0] + "/" + splitDate[1] + "/" + splitDate[2];
        const respondTime =
          respondDate +
          " at" +
          dateFormat(messageReceivedData.recievedTime).split("at")[1];
        archiveMessage({
          variables: { id: data.AddMessage._id, respondTime },
        }).then(({ data }) => {
          const filteredList = pendingMessages.filter((pendingMessage) => {
            return message.callersNumber !== pendingMessage.callersNumber && message.callersName !== pendingMessage.callersName;
          });
          handlePending(filteredList);
          localStorage.setItem("pendingMessages", JSON.stringify(filteredList));
          handleMessageDataClear();
          handleAddedMessage(data.ArchiveMessage);
        });
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleDelete = () => {
    setDeleteToggle(false);
    const filteredList = pendingMessages.filter((filterMessage) => {
      return filterMessage.messageText !== message.messageText;
    });
    handlePending(filteredList);
    localStorage.setItem("pendingMessages", JSON.stringify(filteredList));
    handleMessageDataClear();
  };
  return (
    <Card
      key={message.callersNumber}
      style={{
        maxHeight: "55vh",
        minHeight: "35vh",
        width: "31vw",
      }}
    >
      {pendingEdit ? (
        <>
          <Card.Header style={{ textAlign: "center" }}>Edit Mode</Card.Header>
          <Card.Body style={{ overflowY: "scroll" }}>
            <Form>
              <Form.Group>
                <Form.Label>Funeral Home:</Form.Label>
                <Form.Control
                  as="select"
                  autoFocus
                  value={pendingEditData.funeralhome}
                  id="funeralhome"
                  onChange={handlePendingMessageEdit}
                >
                  {currentFHList &&
                    currentFHList.map((funeralhome) => (
                      <option value={funeralhome.name} key={funeralhome._id}>
                        {funeralhome.name}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
              {(currentFH?.locations.length > 1 ||
                currentFH?.requiredMessageInfo.includes("Location")) && (
                <Form.Group>
                  <Form.Label>Location:</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={handlePendingMessageEdit}
                    id="fhLocation"
                    value={pendingEditData.fhLocation}
                  >
                    <option value="">No Location Specified</option>
                    {currentFH?.locations.map((location) => (
                      <option key={location._id}>{location.Name}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              )}
              <Form.Group>
                <Form.Label>Callers Name:</Form.Label>
                <Form.Control
                  onChange={handlePendingMessageEdit}
                  id="callersName"
                  value={pendingEditData.callersName}
                ></Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Callers Number:</Form.Label>
                <Form.Control
                  onChange={handlePendingMessageEdit}
                  id="callersNumber"
                  value={pendingEditData.callersNumber}
                ></Form.Control>
              </Form.Group>
              {(currentFH?.directors.lentgh > 1 ||
                currentFH?.requiredMessageInfo.includes("Who It's For")) && (
                <Form.Group>
                  <Form.Label>Who The Message Is For:</Form.Label>
                  <Form.Control
                    onChange={handlePendingMessageEdit}
                    id="whoItsFor"
                    value={pendingEditData.whoItsFor}
                  ></Form.Control>
                </Form.Group>
              )}
              <Form.Group>
                <Form.Label>Message:</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={textareaheight}
                  onChange={handlePendingMessageEdit}
                  id="messageText"
                  value={pendingEditData.messageText}
                ></Form.Control>
              </Form.Group>
              <Form.Check
                onChange={(e) => {
                  setPendingEditData({
                    ...pendingEditData,
                    doTheyNeedACall: e.target.checked,
                  });
                }}
                label="Do They Need a Call"
                checked={pendingEditData.doTheyNeedACall}
              ></Form.Check>
            </Form>
          </Card.Body>
          <Card.Footer style={{ textAlign: "center" }}>
            <Button onClick={() => handlePendingMessageSubmit()}>
              Submit Edit
            </Button>
            <Button
              onClick={() => {
                setPendingEdit(false);
                setPendingEditData(message);
                setCurrentFH();
              }}
              as="button"
            >
              Cancel Edit
            </Button>
          </Card.Footer>
        </>
      ) : (
        <>
          <Card.Header>
            <Alert variant="danger" show={checkPendingTime(message)}>
              Please Reach Out to Director.
            </Alert>
            {message.funeralhome}
          </Card.Header>
          <Card.Body>
            <p>
              Caller: {message.callersName} #: {message.callersNumber}
            </p>
            <p>
              Taken By: {message.author} on: {message.messageTakenDate} at:{" "}
              {message.messageTakenTime}
            </p>
            {!message.doTheyNeedACall && <p>They don't need a call back</p>}
            <p>Message: {message.messageText}</p>
            {message.fhLocation !== "" && <p>Location: {message.fhLocation}</p>}
            {message.whoItsFor !== "" && (
              <p>Who It's For: {message.whoItsFor}</p>
            )}
          </Card.Body>
          <Card.Footer style={{ textAlign: "center" }}>
            <Alert show={copiedMessage}>
              Copied to clipboard <br />
              <Button onClick={changeToggle}>Dismiss</Button>
            </Alert>
            {receivedOptions ? (
              <>
                Select Time
                <DateTimePicker
                  value={messageReceivedData.recievedTime}
                  minDate={new Date(messageReceivedData.timeSubmitted)}
                  clearIcon={null}
                  minDetail={"year"}
                  disableCalendar={true}
                  disableClock={true}
                  onChange={(value) => {
                    setMessageReceivedData({
                      ...messageReceivedData,
                      recievedTime: value,
                    });
                  }}
                ></DateTimePicker>
                <Button
                  id={message._id}
                  onClick={(e) => {
                    handleReceived();
                    setReceivedOptions(false);
                  }}
                >
                  Confirm
                </Button>
                <Button onClick={handleReceivedToggle}>Cancel</Button>
              </>
            ) : (
              <>
                {deleteTgggle ? (
                  <>
                    <Button id={message.messageText} onClick={handleDelete}>
                      Confirm Discard
                    </Button>
                    <Button onClick={() => setDeleteToggle(false)}>
                      Cancel Discard
                    </Button>
                  </>
                ) : (
                  <>
                    <Button onClick={handleCopy}>Copy Message</Button>
                    <Button
                      id={message._id}
                      onClick={() => handleReceivedToggle(message)}
                    >
                      {!receivedOptions ? <>Received</> : <>Cancel</>}
                    </Button>
                    <Button
                      onClick={() => {
                        setPendingEdit(true);
                        handleCurrentFHChange(message.funeralhome);
                      }}
                    >
                      Edit Message
                    </Button>
                    <Button onClick={() => setDeleteToggle(!deleteTgggle)}>
                      Discard
                    </Button>
                  </>
                )}
              </>
            )}
          </Card.Footer>
        </>
      )}
    </Card>
  );
};
export default MessagePendingCard;
