import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { useState } from "react";
import FuneralHome from "./pages/FuneralHome";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
// import FuneralHome from './components/FuneralHome';
// import Removals from './pages/Removals'
import FirstCalls from "./pages/FirstCalls";
import Director from "./pages/Directors";
import HomePage from "./pages/Homepage";
import Messages from "./pages/Messages";
import Tutorial from "./pages/Tutorials";
import Navigation from "./components/Nav";
import Login from "./pages/Login";
import auth from "./utils/auth";
import Footer from "./components/Footer";
// import StaffPage from './pages/StaffPage';
import Users from "./pages/User";
// import Sandbox from './pages/Sandbox'
import Feedback from "./pages/Feedback";
import SingleFuneralHome from "./pages/SingleFuneralhome";
// import Profile from './pages/Profile'
import { Nav, Navbar } from "react-bootstrap";
import Updates from "./pages/Updates";
import Info from "./pages/Info";
// import Directions from './components/Directions-ETAs'
import ParentCompany from "./pages/ParentCompany";
const httpLink = createHttpLink({
  uri: '/graphql',
  // uri: "http://localhost:3001/graphql/",
});
const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});
function App() {
  const loggedIn = auth.loggedIn();
  var currentUser = null;
  if (loggedIn) {
    currentUser = auth.getProfile().data;
  }
  if (
    !loggedIn &&
    window.location.href.split("/")[3] !== "Login" &&
    currentUser === null
  ) {
    window.location.assign("/Login");
  }
  const [showNav, setShowNav] = useState(true);
  const toggleNav = () => {
    setShowNav(!showNav);
  };
  return (
    <ApolloProvider client={client}>
      <Router>
        {currentUser === null || currentUser.type === "Answering Service" ? (
          <>
            {window.location.href.split("/")[3] !== "Login" ? (
              <Navigation hidden={showNav} currentUser={currentUser} />
            ) : (
              <Navbar hidden={!showNav} sticky="top">
                <Nav fill className="bg-black">
                  <Nav.Link style={{ color: "white" }}>
                    <h1>Please Login or Signup</h1>
                  </Nav.Link>
                </Nav>
              </Navbar>
            )}
          </>
        ) : (
          <Nav className="navbar navbar-sticky">
            <div
              style={{
                overflowY: "hidden",
                display: "flex",
                justifyContent: "space-evenly",
                height: "9vh",
              }}
            >
              {currentUser.access &&
                currentUser.access.map((funeralhome) => (
                  <div
                    style={{ color: "white", margin: "1.3vh", display: "flex" }}
                  >
                    <a
                      style={{ whiteSpace: "nowrap" }}
                      href={"../Funeralhomes/" + funeralhome}
                    >
                      {funeralhome}
                    </a>
                  </div>
                ))}
            </div>
            <div className="navbarcontent" id="Profile">
              <a href="/Profile">Profile</a>
            </div>
            <div className="navbarcontent" onClick={auth.logout}>
              Logout
            </div>
          </Nav>
        )}
        <div id="body">
          <Routes>
            <Route
              path="/"
              element={<HomePage currentUser={currentUser} />}
            ></Route>
            <Route
              path="/Directors"
              element={<Director currentUser={currentUser} />}
            ></Route>
            <Route
              path="/FuneralHomes"
              element={<FuneralHome currentUser={currentUser} />}
            ></Route>
            <Route
              path="/Funeralhomes/:Funeralhomename"
              element={<SingleFuneralHome currentUser={currentUser} />}
            ></Route>
            <Route
              path="/Feedback"
              element={<Feedback currentUser={currentUser} />}
            ></Route>
            <Route
              path="/Messages"
              element={<Messages currentUser={currentUser} />}
            ></Route>
            <Route
              path="/Tutorial"
              element={<Tutorial currentUser={currentUser} />}
            ></Route>
            {/* <Route path='/Profile' element={<Profile currentUser={currentUser}></Profile>}></Route>  */}
            {/* <Route path='/Directions' element={<Directions></Directions>}></Route> */}
            {/* <Route path='/Removals' element={<Removals></Removals>}></Route> */}
            <Route
              path="/FirstCalls"
              element={<FirstCalls currentUser={currentUser} />}
            ></Route>
            <Route path="/Login" element={<Login />}></Route>
            {/* <Route path="/StaffList" element={<StaffPage canEdit={currentUser}></StaffPage>}></Route> */}
            <Route
              path="/Users"
              element={<Users currentUser={currentUser} />}
            ></Route>
            <Route
              path="/Updates"
              element={<Updates currentUser={currentUser} />}
            ></Route>
            <Route
              path="/Info"
              element={<Info toggleNav={toggleNav} />}
            ></Route>
            <Route
              path="/ParentCompanies"
              element={<ParentCompany currentUser={currentUser} />}
            ></Route>
          </Routes>
        </div>
        <Footer currentUser={currentUser} />
      </Router>
    </ApolloProvider>
  );
}

export default App;
