import React from "react";
import { useMutation} from "@apollo/client";
import { ADD_DIRECTOR } from "../../utils/mutations";
import { useState } from "react";
import { Form, Button, Alert, Card} from 'react-bootstrap'
const DirectorsForm = ({value, handleClose}) => {
    const [addDirector] = useMutation(ADD_DIRECTOR) 
    const [directorFormData, setDirectorFormData] = useState({name: "", primaryPhone: '', contactMethod: '', funeralhome: value || 'none'})
    const [goodAlert, setGoodAlert] = useState({status: false, message: ''})
    const [badAlert, setBadAlert] = useState({status: false, message: ''})
    const handleChange = (e) => {
        const {value, id} = e.target
        setDirectorFormData({...directorFormData, [id]: value})
    }
    const handleDirectorSubmit = async (e) => {
        e.preventDefault()
        try{ 
            await addDirector({variables: directorFormData}).then(({data}) => {
                return data
            })
            setGoodAlert({status: true, message: "Director has been added."})
            setDirectorFormData({name: "", primaryPhone: '', contactMethod: '', funeralhome: value || 'none'})

        }
        catch(error) {
            console.log(error)
            setBadAlert({message: error.message, status: true})
        }
    }
    return (
        <Card>
            <Card.Header>
                <h4>
                Add Director
                </h4>
            </Card.Header>
            <Card.Body style={{overflowY: 'scroll', height: "50vh"}}>
            <Form>
                <Form.Group>
            Funeral Home: {value}
                </Form.Group>
            <Form.Group>
                <Form.Label>Name:</Form.Label>
                <Form.Control className="formInput" autoComplete="off" value={directorFormData.name} onChange={handleChange} id="name" placeholder="Director Name"></Form.Control>
                <Alert className="Alert" show={directorFormData.name === ""}>Please Provide a Director Name</Alert>
            </Form.Group>
            <Form.Group>
                <Form.Label>Primary Number:</Form.Label>
                <Form.Control className="formInput" autoComplete="off" value={directorFormData.primaryPhone} onChange={handleChange}id="primaryPhone"  placeholder="Primary Phone"></Form.Control>
                <Alert className="Alert" show={directorFormData.primaryPhone === ''}>Please Provide a Phone Number</Alert>
            </Form.Group>
            <Form.Group>
                    <Form.Label>Contact Method:</Form.Label>
                    <Form.Control className="formInput" autoComplete="off" value={directorFormData.contactMethod} onChange={handleChange} id="contactMethod" placeholder="Contact Method"></Form.Control>
                    <Alert className="Alert" show={directorFormData.contactMethod === ''}>Please Provide a Contact Method</Alert>
            </Form.Group>
            </Form>
            </Card.Body>
            <Card.Footer >
            <Alert dismissible onClose={() => {setGoodAlert({status: false})}}show={goodAlert.status}>
                Click The X To Dismiss Message <br/>
                    {goodAlert.message}
            </Alert>
            <Alert dismissible onClose={() => {setBadAlert({status: false})}} show={badAlert.status}>
            Click The X To Dismiss Message <br/>
                {badAlert.message}
            </Alert>
            <Button onClick={handleDirectorSubmit}>Submit</Button>
            </Card.Footer>
        </Card>
    )
}
export default DirectorsForm