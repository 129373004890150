import React from 'react'
import { Form } from 'react-bootstrap'
const Filter = ({filter, filterData, setFilter, filterType}) => {
    return (
        <Form.Group>
            <h2>
            Filter By {filterType}:
            </h2>
        <Form.Control id='filter' as='select' value={filter} onChange={(e) => setFilter(e.target.value)}>
            <option value="none">
                No Filter
            </option>
            {filterData && filterData.map((value, index) => (
                <option key={index}>
                    {value}
                </option>
            ))}
        </Form.Control>
        </Form.Group>
    )
}
export default Filter