import React, { useState } from "react";
import {
  Table,
  Form,
  Card,
  Button,
  ListGroup,
  ListGroupItem,
  Alert,
} from "react-bootstrap";
import { useMutation } from "@apollo/client";
import { EDIT_FUNERALHOME } from "../../utils/mutations";
import dateFormat from "../../utils/dateFormat";
const GeneralFHInfoTable = ({ Funeralhome, canEdit, currentUser }) => {
  const [EditFuneralHome] = useMutation(EDIT_FUNERALHOME);
  const [editMode, setEditMode] = useState(false);
  const [editData, setEditData] = useState(Funeralhome);
  const [affiliateEmailList, setAffiliateEmailList] = useState({
    status: false,
    list: [],
    affiliate: Funeralhome.name,
  });
  const handleChange = (e, index) => {
    if (
      e.target.id === "doWeSend" ||
      e.target.id === "complicatedInstructions"
    ) {
      setEditData({
        ...editData,
        firstcallsEmail: {
          ...editData.firstcallsEmail,
          [e.target.id]: e.target.checked,
        },
      });
    } else if (
      e.target.id === "howWeAnswer" ||
      e.target.id === "phoneDisplayName" ||
      e.target.id === "whoItsFor"
    ) {
      setEditData({
        ...editData,
        answeringThePhone: editData.answeringThePhone.map((data, i) => {
          if (index === i) {
            return { ...data, [e.target.id]: e.target.value };
          }
          return data;
        }),
      });
    } else if (e.target.id === "emailList") {
      setEditData({
        ...editData,
        firstcallsEmail: {
          ...editData.firstcallsEmail,
          emailList: editData.firstcallsEmail.emailList.map(
            ({ email, status }, i) => {
              if (i === index) {
                return { email: e.target.value, status };
              }
              return { email, status };
            }
          ),
        },
      });
    } else if (e.target.id === "emailInstructions") {
      setEditData({
        ...editData,
        firstcallsEmail: {
          ...editData.firstcallsEmail,
          [e.target.id]: e.target.value,
        },
      });
    } else if (e.target.id === "emailListActive") {
      setEditData({
        ...editData,
        firstcallsEmail: {
          ...editData.firstcallsEmail,
          emailList: editData.firstcallsEmail.emailList.map(
            ({ email, status }, i) => {
              if (i === index) {
                return { status: e.target.checked, email };
              }
              return { email, status };
            }
          ),
        },
      });
    } else {
      setEditData({ ...editData, [e.target.id]: e.target.value });
    }
  };
  const handleAddAnotherWayToAnswer = () => {
    if (editData.answeringThePhone.length === 0) {
      setEditData({
        ...editData,
        answeringThePhone: [
          ...editData.answeringThePhone,
          { howWeAnswer: "", phoneDisplayName: "", whoItsFor: editData.name },
        ],
      });
    } else {
      setEditData({
        ...editData,
        answeringThePhone: [
          ...editData.answeringThePhone,
          { howWeAnswer: "", phoneDisplayName: "", whoItsFor: "" },
        ],
      });
    }
  };
  const handleRemoveAnotherWaytoAnswer = (index) => {
    setEditData({
      ...editData,
      answeringThePhone: editData.answeringThePhone.filter((data, i) => {
        return i !== index;
      }),
    });
  };
  const handleEditToggle = () => {
    if (!editMode && editData.firstcallsEmail.emailList.length === 0) {
      setEditData({
        ...editData,
        firstcallsEmail: { ...editData.firstcallsEmail },
      });
    }
    setEditMode(!editMode);
  };
  const handleEmailListRemove = (index) => {
    setEditData({
      ...editData,
      firstcallsEmail: {
        ...editData.firstcallsEmail,
        emailList: editData.firstcallsEmail.emailList.filter((email, i) => {
          return i !== index;
        }),
      },
    });
  };
  const handleFHEdit = (e) => {
    e.preventDefault();
    try {
      EditFuneralHome({
        variables: {
          ...editData,
          complicatedEmailInstructions:
            editData.firstcallsEmail.complicatedInstructions,
          doWeSend: editData.firstcallsEmail.doWeSend,
          emailList: editData.firstcallsEmail.emailList.map(({ email }) => {
            return email;
          }),
          emailListChecked: editData.firstcallsEmail.emailList.map((email) => {
            console.log(email);
            return email.status;
          }),
          emailInstructions: editData.firstcallsEmail.emailInstructions,
          oldName: Funeralhome.name,
          howWeAnswer: editData.answeringThePhone.map(({ howWeAnswer }) => {
            return howWeAnswer;
          }),
          phoneDisplayName: editData.answeringThePhone.map(
            ({ phoneDisplayName }) => {
              return phoneDisplayName;
            }
          ),
          answeringWhoItsFor:
            editData.answeringThePhone.length > 1
              ? editData.answeringThePhone.map(({ whoItsFor }) => {
                  return whoItsFor;
                })
              : [editData.name],
          userId: currentUser._id,
          editTime: dateFormat(new Date()),
        },
      }).then(({ data }) => {
        setEditMode(false);
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleAffiliateEmailListChange = (e) => {
    if (e.target.value === "none") {
      setAffiliateEmailList({
        ...affiliateEmailList,
        list: [],
        affiliate: e.target.value,
      });
    } else {
      const affiliate = Funeralhome.parentCompany.funeralhomes.filter((fh) => {
        return fh.name === e.target.value;
      })[0];
      setAffiliateEmailList({
        ...affiliateEmailList,
        affiliate: e.target.value,
        list: affiliate.firstcallsEmail.emailList,
      });
    }
  };
  const handleAffiliateEmailListSelect = () => {
    setEditData({
      ...editData,
      firstcallsEmail: {
        ...editData.firstcallsEmail,
        emailList: affiliateEmailList.list,
      },
    });
    setAffiliateEmailList({ ...affiliateEmailList, list: [], status: false });
  };
  return (
    <Card>
      <Card.Header>{Funeralhome.name} General Info</Card.Header>
      <Card.Body>
        <Table bordered>
          <tbody>
            <tr>
              <td>How it's Displayed on the phone</td>
              <td>How We Answer</td>
              <td>Who It's For</td>
            </tr>
            {!editMode ? (
              <>
                {Funeralhome.answeringThePhone &&
                  Funeralhome.answeringThePhone.map(
                    ({ phoneDisplayName, howWeAnswer, whoItsFor }, index) => (
                      <tr key={index}>
                        {editMode ? (
                          <></>
                        ) : (
                          <>
                            <td>{phoneDisplayName}</td>
                            <td>{howWeAnswer}</td>
                            <td>{whoItsFor}</td>
                          </>
                        )}
                      </tr>
                    )
                  )}
              </>
            ) : (
              <>
                {editData.answeringThePhone.map(
                  ({ phoneDisplayName, howWeAnswer, whoItsFor }, index) => (
                    <tr key={index}>
                      <td>
                        <Form.Control
                          id="phoneDisplayName"
                          onChange={(e) => handleChange(e, index)}
                          value={phoneDisplayName}
                        ></Form.Control>
                      </td>
                      <td>
                        <Form.Control
                          id="howWeAnswer"
                          onChange={(e) => handleChange(e, index)}
                          value={howWeAnswer}
                        ></Form.Control>
                      </td>
                      <td>
                        {editData.answeringThePhone.length > 1 ? (
                          <Form.Control
                            id="whoItsFor"
                            onChange={(e) => handleChange(e, index)}
                            value={whoItsFor}
                          ></Form.Control>
                        ) : (
                          <>{whoItsFor}</>
                        )}
                      </td>
                      {index !== 0 && (
                        <td>
                          <Button
                            onClick={() =>
                              handleRemoveAnotherWaytoAnswer(index)
                            }
                          >
                            Remove Row
                          </Button>
                        </td>
                      )}
                    </tr>
                  )
                )}
              </>
            )}
            {editMode && (
              <tr>
                <td>
                  <Button onClick={handleAddAnotherWayToAnswer}>
                    Add Another Way to Answer
                  </Button>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <Table bordered>
          <tbody>
            <tr>
              <td>How to pronounce:</td>
              <td>
                {editMode ? (
                  <>
                    <Form.Control
                      onChange={handleChange}
                      id="howToPronounce"
                      value={editData.howToPronounce}
                    ></Form.Control>
                  </>
                ) : (
                  <>
                    {Funeralhome.howToPronounce ? (
                      <>{Funeralhome.howToPronounce}</>
                    ) : (
                      <>No Info</>
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td>Hours:</td>
              <td>
                {editMode ? (
                  <>
                    {editData.hours !== Funeralhome.hours}
                    <Form.Control
                      onChange={handleChange}
                      id="hours"
                      value={editData.hours}
                    ></Form.Control>
                  </>
                ) : (
                  <>
                    {Funeralhome.hours ? (
                      <>{Funeralhome.hours}</>
                    ) : (
                      <>No Info</>
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td>Special Instructions:</td>
              <td>
                {editMode ? (
                  <>
                    {editData.specialInstructions !==
                      Funeralhome.specialInstructions}
                    <Form.Control
                      onChange={handleChange}
                      id="specialInstructions"
                      value={editData.specialInstructions}
                    ></Form.Control>
                  </>
                ) : (
                  <>
                    {Funeralhome.specialInstructions ? (
                      <>{Funeralhome.specialInstructions}</>
                    ) : (
                      <>No Info</>
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td>Contact Preference:</td>
              <td>
                {editMode ? (
                  <>
                    {editData.contactPreference !==
                      Funeralhome.contactPreference}
                    <Form.Control
                      onChange={handleChange}
                      id="contactPreference"
                      value={editData.contactPreference}
                    ></Form.Control>
                  </>
                ) : (
                  <>
                    {Funeralhome.contactPreference ? (
                      <>{Funeralhome.contactPreference}</>
                    ) : (
                      <>No Info</>
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td>Flower Room Info:</td>
              <td>
                {editMode ? (
                  <>
                    {editData.flowerRoomInfo !== Funeralhome.flowerRoomInfo}
                    <Form.Control
                      onChange={handleChange}
                      id="flowerRoomInfo"
                      value={editData.flowerRoomInfo}
                    ></Form.Control>
                  </>
                ) : (
                  <>
                    {Funeralhome.flowerRoomInfo ? (
                      <>{Funeralhome.flowerRoomInfo}</>
                    ) : (
                      <>No Info</>
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td>Website:</td>
              <td>
                {editMode ? (
                  <>
                    {editData.website !== Funeralhome.website}
                    <Form.Control
                      onChange={handleChange}
                      id="website"
                      value={editData.website}
                    ></Form.Control>
                  </>
                ) : (
                  <>
                    {Funeralhome.website ? (
                      <>
                        <a
                          href={"https://" + Funeralhome.website}
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          {Funeralhome.website}
                        </a>
                      </>
                    ) : (
                      <>No Info</>
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td>Do We Send First Calls:</td>
              <td>
                {editMode ? (
                  <>
                    {editData.firstcallsEmail !== Funeralhome.firstcallsEmail}
                    <Form.Check
                      onChange={handleChange}
                      id="doWeSend"
                      checked={editData.firstcallsEmail.doWeSend}
                    ></Form.Check>
                  </>
                ) : (
                  <>
                    {Funeralhome.firstcallsEmail ? (
                      <>
                        {Funeralhome.firstcallsEmail &&
                        Funeralhome.firstcallsEmail.doWeSend ? (
                          <>Yes</>
                        ) : (
                          <>No</>
                        )}
                      </>
                    ) : (
                      <>No Info</>
                    )}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td>Email Instructions:</td>
              <td>
                {editMode ? (
                  <Form.Control
                    onChange={handleChange}
                    id="emailInstructions"
                    value={editData.firstcallsEmail.emailInstructions}
                  ></Form.Control>
                ) : (
                  <>
                    {Funeralhome.firstcallsEmail &&
                      Funeralhome.firstcallsEmail.emailInstructions}
                  </>
                )}
              </td>
            </tr>
            <tr>
              <td>Complicated Email Instructions:</td>
              <td>
                {editMode ? (
                  <Form.Check
                    onChange={handleChange}
                    checked={editData.firstcallsEmail.complicatedInstructions}
                    id="complicatedInstructions"
                  ></Form.Check>
                ) : (
                  <>
                    {Funeralhome.firstcallsEmail && (
                      <>
                        {Funeralhome.firstcallsEmail.complicatedInstructions
                          ? "Yes "
                          : "No"}
                      </>
                    )}
                  </>
                )}
              </td>
            </tr>
          </tbody>
        </Table>
        <Table bordered>
          <thead>
            <tr>
              <td>
                Do We Track Services on the website: <br />
                {editMode && (
                  <Alert>Can be edited in the important Info Window</Alert>
                )}
              </td>
              <td>
                Do we Take First calls:
                <br />
                {editMode && (
                  <Alert>Can be edited in the important Info Window</Alert>
                )}
              </td>
            </tr>
          </thead>
          <tbody>
            {Funeralhome.doWeTrack && (
              <tr>
                <td>{Funeralhome.doWeTrack?.services ? "Yes" : "No"}</td>
                <td>{Funeralhome.doWeTrack?.fc ? "Yes" : "No"}</td>
              </tr>
            )}
          </tbody>
        </Table>
        <Table bordered>
          <thead>
            <tr>
              <td>
                Required Message Info: <br />
                {editMode && (
                  <Alert>Can be edited in the important Info Window</Alert>
                )}
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {Funeralhome.requiredMessageInfo && (
                  <ListGroup>
                    {Funeralhome.requiredMessageInfo.map((info, index) => (
                      <ListGroupItem key={index}>
                        {" "}
                        {info}
                        {Funeralhome.requiredMessageInfo.length - 1 !==
                          index && <>,</>}
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                )}
              </td>
            </tr>
          </tbody>
        </Table>
        <Table bordered>
          <thead>
            <tr>
              <td>First Call Email List:</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {editMode ? (
                  <>
                    {Funeralhome.parentCompany?.funeralhomes.length > 0 && (
                      <>
                        {affiliateEmailList.status ? (
                          <>
                            <Form.Control
                              onChange={handleAffiliateEmailListChange}
                              value={affiliateEmailList.affiliate}
                              as="select"
                            >
                              <option value={Funeralhome.name}>
                                Please select an affiliate
                              </option>
                              {Funeralhome.parentCompany.funeralhomes
                                .filter(({ name }) => {
                                  return name !== Funeralhome.name;
                                })
                                .map((fh) => (
                                  <option
                                    id={fh._id}
                                    value={fh.name}
                                    key={fh._id}
                                  >
                                    {fh.name === Funeralhome.name ? (
                                      "This FH"
                                    ) : (
                                      <>{fh.name}</>
                                    )}
                                  </option>
                                ))}
                            </Form.Control>
                            <Button
                              disabled={
                                affiliateEmailList.affiliate ===
                                Funeralhome.name
                              }
                              onClick={handleAffiliateEmailListSelect}
                            >
                              Select
                            </Button>
                            <Button
                              onClick={() =>
                                setAffiliateEmailList({
                                  ...affiliateEmailList,
                                  status: false,
                                  affiliate: Funeralhome.name,
                                })
                              }
                            >
                              Cancel
                            </Button>
                          </>
                        ) : (
                          <>
                            {Funeralhome.name !==
                              affiliateEmailList.affiliate && (
                              <>
                                Currently Using Email For:{" "}
                                {affiliateEmailList.affiliate}
                                <br />
                                <Button
                                  onClick={() => {
                                    setEditData({
                                      ...editData,
                                      firstcallsEmail: {
                                        ...editData.firstcallsEmail,
                                        emailList:
                                          Funeralhome.firstcallsEmail.emailList,
                                      },
                                    });
                                    setAffiliateEmailList({
                                      status: false,
                                      list: [],
                                      affiliate: Funeralhome.name,
                                    });
                                  }}
                                >
                                  Cancel Copy
                                </Button>
                              </>
                            )}
                            <Button
                              onClick={() =>
                                setAffiliateEmailList({
                                  ...affiliateEmailList,
                                  status: true,
                                })
                              }
                            >
                              Copy Affiliates Email List
                            </Button>
                          </>
                        )}
                      </>
                    )}
                    <ListGroup>
                      {editData.firstcallsEmail.emailList.map(
                        ({ email, status }, index) => (
                          <ListGroupItem
                            key={index}
                            style={{ display: "flex" }}
                          >
                            Active:
                            <Form.Check
                              id="emailListActive"
                              onChange={(e) => handleChange(e, index)}
                              checked={status}
                            ></Form.Check>
                            <Form.Control
                              onChange={(e) => handleChange(e, index)}
                              id="emailList"
                              value={email}
                            ></Form.Control>
                            {index !==
                              editData.firstcallsEmail.emailList.length && (
                              <Button
                                onClick={() => handleEmailListRemove(index)}
                              >
                                Remove
                              </Button>
                            )}
                          </ListGroupItem>
                        )
                      )}
                    </ListGroup>
                    {editData.firstcallsEmail.emailList.length === 0 && (
                      <>
                        No Emails
                        <br />
                      </>
                    )}
                    <Button
                      onClick={() =>
                        setEditData({
                          ...editData,
                          firstcallsEmail: {
                            ...editData.firstcallsEmail,
                            emailList: [
                              ...editData.firstcallsEmail.emailList,
                              { email: "", status: true },
                            ],
                          },
                        })
                      }
                    >
                      Add Another Email
                    </Button>
                  </>
                ) : (
                  <>
                    {Funeralhome.firstcallsEmail?.emailList.length > 0 ? (
                      <ListGroup>
                        {Funeralhome.firstcallsEmail.emailList.map(
                          ({ email, status }) => (
                            <ListGroupItem
                              style={{ color: status ? "Black" : "Red" }}
                              key={email}
                            >
                              {email},
                            </ListGroupItem>
                          )
                        )}
                      </ListGroup>
                    ) : (
                      <>No Emails</>
                    )}
                  </>
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      </Card.Body>
      {canEdit && (
        <Card.Footer>
          <Button onClick={handleEditToggle}>Toggle Edit General Info</Button>
          {editMode && editData !== Funeralhome && (
            <Button onClick={handleFHEdit}>Submit Edit</Button>
          )}
        </Card.Footer>
      )}
    </Card>
  );
};
export default GeneralFHInfoTable;
