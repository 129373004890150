import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, makeVar, useReactiveVar} from '@apollo/client';
import { QUERY_FUNERALHOMES_BY_NAME } from '../utils/queries';
import {Button} from 'react-bootstrap'
import '../index.css'
import './cs/style.css'
import IndividualFH from '../components/IndividualFH';
export const FuneralhomeVar = makeVar({})
const SingleFuneralHome = ({currentUser}) => {
    const {Funeralhomename: name} = useParams()
    const {data, loading} = useQuery(QUERY_FUNERALHOMES_BY_NAME, {
        variables: {name}
    })
    if(!loading && data !== undefined) {
        FuneralhomeVar(data.FuneralhomebyName)
    }
    const Funeralhome = useReactiveVar(FuneralhomeVar)
    return (
        <>
        <h2 style={{textAlign: 'center', background: 'lightgrey'}}>
                {Funeralhome.name} <br/>
                <a href='/Funeralhomes'>
                <Button onClick={() => localStorage.setItem('fhPageKey', Funeralhome.name)}>Back to Funeral Home List</Button>
                </a>
        </h2>
        {!loading && <IndividualFH Funeralhome={Funeralhome} currentUser={currentUser} singleFHPage={true} loading={loading}></IndividualFH>}
        </>
    )
}
export default SingleFuneralHome