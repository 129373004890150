import React, {useState} from "react";
import { Form, Button, Card} from "react-bootstrap";
import { CREATE_TUTORIAL } from "../../utils/mutations";
import { useMutation } from "@apollo/client";
const TutorialForm = ({setCurrentTutorials, currentTutorials, setKey}) => {
    const [data, setData] = useState({name: ''})
    const [createTutorial] = useMutation(CREATE_TUTORIAL)
    const handleCreateTutorial = async (e) => {
        try {
            await createTutorial({variables: data}).then(({data}) => {
                setCurrentTutorials([...currentTutorials, data.createTutorial])
                setData({name: ""})
                setKey()
            })
        } catch (error) {
            console.log(error)
        }
    }
    return (
            <Card>
                <Card.Header>
                <h2>Add A Tutorial</h2>
                </Card.Header>
                    <Form.Group>
                        <Form.Label>
                            Name:
                        </Form.Label>
                        <Form.Control id="name" autoComplete="off" value={data.name} onChange={(e) => setData({...data, [e.target.id]: e.target.value})}>
                        </Form.Control>
                    </Form.Group>
                    <Card.Footer>
                    <Button onClick={handleCreateTutorial}>Add Tutorial</Button>
                    </Card.Footer>
            </Card>
    )
}
export default TutorialForm