import React, {useState} from 'react'
import { useMutation } from '@apollo/client'
import {EDIT_LOCATION, REMOVE_LOCATION} from '../../utils/mutations'
import { Form, Button , Card} from 'react-bootstrap'
import dateFormat from '../../utils/dateFormat'
const FuneralHomesLocationCard = ({Funeralhome, location, currentUser, changeFuneralhomes}) => {
    const [editMode, setEditMode] = useState(false)
    const [editData, setEditData] = useState({...location})
    const [EditLocation] = useMutation(EDIT_LOCATION)
    const [RemoveLocation] = useMutation(REMOVE_LOCATION)
    const handleEdit = async (e, location) => {
        setEditMode(!editMode)
    }
    const hanldeEditChange = (e) => {
        setEditData({...editData, [e.target.id]: e.target.value})
    }
    const handleEditSubmit = (e) => {
        e.preventDefault()
        const data = {funeralhomename: Funeralhome.name, name: location.Name, changedName: editData.Name, address: editData.Address, main: editData.Main, back: editData.Back, fax: editData.Fax, email: editData.Email, directions: editData.Directions, userId: currentUser._id, editTime: dateFormat(new Date())}
        EditLocation({variables: data}).then(({data}) => {
            changeFuneralhomes(data.EditLocation)
            setEditMode(false)
        })
        // window.location.reload()
    }
    const handleLocationDelete = async (id, funeralHomeName) => {
        try {
            await RemoveLocation({
            variables: {funeralHomeName, id}}).then(({data}) => {
                changeFuneralhomes(data.RemoveLocation)
            })
        // window.location.reload()
    } catch (e) {
        console.error(e);
    } 
    }
    
    return (
        <Card>
            {editMode ? 
                    <>
                    <Card.Header>
                        Editing {location.Name} Location Information
                    </Card.Header>
                    <Card.Body style={{overflowY: 'auto', height: '50vh'}}>
                    <Form>
                    <Form.Group>
                    <Form.Label>Name:</Form.Label>
                    <Form.Control autoFocus onChange={hanldeEditChange} id={'Name'} value={editData.Name} placeholder="Location Name">
                    </Form.Control>
                    </Form.Group>
                    <Form.Group>
                    <Form.Label>Address:</Form.Label>
                    <Form.Control onChange={hanldeEditChange} id={'Address'} value={editData.Address} placeholder="Address"></Form.Control>
                    </Form.Group>
                    <Form.Group>
                    <Form.Label>Main:</Form.Label>
                    <Form.Control onChange={hanldeEditChange} id={'Main'} value={editData.Main} placeholder="Main"></Form.Control>
                    </Form.Group>
                    <Form.Group>
                    <Form.Label>Back:</Form.Label>
                    <Form.Control onChange={hanldeEditChange} id={'Back'} value={editData.Back} placeholder="Back"></Form.Control>
                    </Form.Group>
                    <Form.Group>
                    <Form.Label>Fax:</Form.Label>
                    <Form.Control onChange={hanldeEditChange} id={'Fax'} value={editData.Fax} placeholder="Fax"></Form.Control>
                    </Form.Group>
                    <Form.Group>
                    <Form.Label>Email:</Form.Label>
                    <Form.Control onChange={hanldeEditChange} id={'Email'} value={editData.Email} placeholder="Email"></Form.Control>
                    </Form.Group>
                    <Form.Group>
                    <Form.Label>Directions:</Form.Label>
                    <Form.Control onChange={hanldeEditChange} id={'Directions'} value={editData.Directions} placeholder="Directions"></Form.Control>
                    </Form.Group>
                    </Form>
                    </Card.Body>
                    {location.edited && location.edited.by !== null && 
                    <>
                    Last Edited by:
                    <br/>
                    {location.edited.by.username} on {location.edited.time}
                    </>
                    }
                    <Card.Footer>
                    <Button onClick={handleEditSubmit}>Submit Changes</Button>
                    <Button onClick={(e) => {handleEdit(e)}}>Cancel Edit</Button>
                    <Button onClick={() => handleLocationDelete(location._id, Funeralhome.name)}>
                    Remove {location.Name} Location
                    </Button> 
                    </Card.Footer>
                    </>
                    :
                    <>
                    <Card.Header>
                        {location.Name} Information
                    </Card.Header>
                    <Card.Body style={{overflowY: 'auto', height: '50vh'}}>
                    <p>Address: {location.Address}</p>
                    <p>Main: {location.Main}</p>
                    <p>Back: {location.Back}</p>
                    <p>Fax: {location.Fax}</p>  
                    <p>Email: {location.Email}</p>
                    <p>Directions: {location.Directions}</p>
                    </Card.Body>
                    {(currentUser.adminStatus || currentUser.access.includes(Funeralhome.name)) &&
                    <Card.Footer>
                    <Button onClick={(e) => handleEdit(e, location)}>Edit {location.Name} Location</Button> 
                    </Card.Footer>
                    }
                    </>
                        }
        </Card>
    )
}
export default FuneralHomesLocationCard