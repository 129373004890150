import React, { useState, useEffect } from "react";
import FuneralHomesForm from "../components/FuneralHomesForm";
import { useQuery } from "@apollo/client";
import { QUERY_FUNERALHOMES } from "../utils/queries";
import { Row, Tab, Col, Nav } from "react-bootstrap";
import IndividualFH from "../components/IndividualFH";
import Loading from "../components/Loading";
const FuneralHome = ({ currentUser }) => {
  const { data, loading } = useQuery(QUERY_FUNERALHOMES);
  const funeralhomes = data?.FuneralHomes;
  const [key, setKey] = useState(localStorage.getItem("fhPageKey") || "none");
  const handleFHChange = (newKey) => {
    setKey(newKey);
    localStorage.setItem("fhPageKey", newKey);
  };
  const [currentFHList, setCurrentFHList] = useState([]);
  useEffect(() => {
    const fhList = funeralhomes || [];
    setCurrentFHList(fhList);
  }, [setCurrentFHList, funeralhomes]);
  return (
    <Tab.Container activeKey={key} onSelect={handleFHChange}>
      <Row>
        <Col sm={2} style={{ overflowY: "scroll", maxHeight: "85vh" }}>
          <Nav variant="pills" className="flex-column">
            <Nav.Item style={{ borderBottom: "1px solid black" }}>
              <Nav.Link style={{ color: "black" }}>
                {loading ? (
                  <Loading page="Funeral Home List"></Loading>
                ) : (
                  <h3>Funeral Home List</h3>
                )}
              </Nav.Link>
            </Nav.Item>
            {currentUser.adminStatus && (
              <Nav.Item>
                <Nav.Link
                  style={{ color: "black" }}
                  eventKey={"Add Funeral Home"}
                >
                  Add Funeral Home
                </Nav.Link>
              </Nav.Item>
            )}
            {currentFHList.map((funeralhome) => (
              <Nav.Item key={funeralhome._id}>
                <Nav.Link
                  style={{ color: !funeralhome.status ? "Red" : "Black" }}
                  eventKey={funeralhome.name}
                >
                  {funeralhome.name}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Col>
        <Col sm={10} style={{ overflowY: "scroll", maxHeight: "85vh" }}>
          <Tab.Content>
            <Tab.Pane eventKey={"none"}>
              <h1>Select a Funeral Home to see Their Information</h1>
            </Tab.Pane>
            {currentFHList.map((funeralhome) => (
              <Tab.Pane key={funeralhome._id} eventKey={funeralhome.name}>
                <IndividualFH
                  currentUser={currentUser}
                  Funeralhome={funeralhome}
                ></IndividualFH>
              </Tab.Pane>
            ))}
            {currentUser.adminStatus && (
              <Tab.Pane eventKey={"Add Funeral Home"}>
                <FuneralHomesForm
                  handleClose={() => setKey("none")}
                ></FuneralHomesForm>
              </Tab.Pane>
            )}
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};
export default FuneralHome;
