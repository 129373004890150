import React from "react";
import {Card} from 'react-bootstrap'
import Loading from "../Loading";
import MessageCard from "../MessageCard";
// import dayjs from "dayjs";
const MessagesList = ({handlePending, pendingMessages, currentUser, Messages, loading}) => {
    var arrayForSort = [...Messages]
    arrayForSort.sort((a, b) => {
        var aTime = a.messageTaken.time
        var bTime = b.messageTaken.time
        if(aTime.split('').includes('p')) {
            aTime= (+aTime.split(':')[0] + 12) + ':' +  aTime.split(':')[1]
            aTime =aTime.split('pm')[0]
        } else {
            aTime= aTime.split('am')[0]
        }
        if(bTime.split('').includes('p')) {
            bTime= ((+bTime.split(':')[0]) + 12) + ':' +  bTime.split(':')[1]
            bTime = bTime.split('pm')[0]
        } else {
            bTime =bTime.split('am')[0]
        }
        return new Date(b.messageTaken.date + " " + bTime).getTime() - new Date(a.messageTaken.date + ' ' + aTime).getTime()
    })
    Messages = arrayForSort
    return (    
        <Card>
        <Card.Header>Todays Message History:</Card.Header>
        <Card.Body style={{display: 'flex', flexWrap: 'wrap', height: '90vh', justifyContent: 'space-evenly'}}>
        {loading && <Loading page='Message Histoy'></Loading>}
            {Messages.length === 0 &&
                <>No Messages for Today</>
            }
            {Messages && Messages.map((Message) => (
                <div key={Message._id}>
                <MessageCard currentUser={currentUser} handlePending={handlePending} page="Message List" pendingMessages={pendingMessages} Message={Message}></MessageCard>
                </div>
            ))}
        </Card.Body>
        </Card>
        
    )
}
export default MessagesList