import React, {useState} from "react";
import { useMutation, useQuery } from "@apollo/client";
import { QUERY_USERS} from "../../utils/queries";
import {REMOVE_USER,EDIT_USER} from "../../utils/mutations";
import UserListAccess from "../UserList Access";
import { Form, Card, Button, } from "react-bootstrap";
const UserList = () => {
    const {data} = useQuery(QUERY_USERS)
    const [viewAccessList, setViewAccessList] = useState({status: false, index: 200000})
    const [edit, setEdit] = useState({status: false, index: 200000, data: {}})
    const [remove, setRemove] = useState({status: false, index: 20000})
    const [editUser] = useMutation(EDIT_USER)
    const [removeUser] = useMutation(REMOVE_USER)
    const Users = data?.getAllUsers || []
    const handleRemoveUser = (user) => {
        removeUser({variables: {username: user}})
        window.location.reload()
    }
    const handleAccessListToggle = (e) => {
        if(Number(e.target.id) === viewAccessList.index) {
            setViewAccessList({status: false, index: 200000})
        } else {
            setViewAccessList({status: true, index: Number(e.target.id)})
        }
    }
    const handleEditToggle = (e, index) => {
        const user = JSON.parse(e.target.id)
        if(user.username === edit.data?.username) {
            setEdit({status: false, index: 20000})
        } else if (edit.status) {
            setEdit({...edit, index, data: user})
        } 
        else {
            setEdit({status: true, index, data: {...user, newUsername: user.username}})
        }
    }
    const handleEditChange = (e) => {
        if(e.target.id !== 'adminStatus' && e.target.id !== 'inTraining' && e.target.id !== 'approved') {
            setEdit({...edit, data: {...edit.data, [e.target.id]: e.target.value}})
        } else {
            setEdit({...edit, data: {...edit.data, [e.target.id]: e.target.checked}})
        }
    }
    const handleEditSubmit = () => {
        editUser({variables: {...edit.data}}).then(({data}) => {
            const currentUser = data.changeUser
            Users.map((user) => {
                if(user._id === currentUser._id) {
                    return currentUser
                } else {
                    return user
                }
            })
        })
        setEdit({status: false, index: 200000, data: {}})
    }
    return (
        <Card>
            <Card.Header>
                <h1>
                Users:
                </h1>
            </Card.Header>
            <Card.Body style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', textAlign: 'center'}}>
            {Users && Users.map((User, index)=> (
                <Card style={{width: '32vw'}} key={User._id}>
                    {edit.status && edit.index === index ? 
                    <>
                    <Card.Header>Edit Mode for User: {User.username}</Card.Header>
                    <Card.Body>
                        <Form.Group>
                        New Username: 
                        <Form.Control id="newUsername" value={edit.data.newUsername} onChange={handleEditChange}>
                        </Form.Control>
                    </Form.Group>
                        <Form.Group>
                        Email:
                        <Form.Control id='email' value={edit.data.email} onChange={handleEditChange}>
                        </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            Admin Status:
                            <Form.Check id='adminStatus' onChange={handleEditChange} checked={edit.data.adminStatus}>
                            </Form.Check>
                        </Form.Group>
                        <Form.Group>
                            In Training: 
                            <Form.Check id='inTraining' onChange={handleEditChange} checked={edit.data.inTraining}>
                            </Form.Check>
                        </Form.Group>
                        <Form.Group>
                            Approved:
                            <Form.Check id='approved' onChange={handleEditChange} checked={edit.data.approved}>

                            </Form.Check>
                        </Form.Group>
                        <Form.Group>
                            Type:
                            <Form.Control as='select' onChange={handleEditChange} id='type' value={edit.data.type}>
                                <option>Answering Service</option>
                                <option>Funeral Home</option>
                            </Form.Control>
                        </Form.Group>
                        {edit.data.type === "Funeral Home" &&
                        <>
                        <Button id={index} onClick={handleAccessListToggle}>Toggle Access List</Button>
                        {viewAccessList.status && viewAccessList.index === index &&
                        <UserListAccess User={User} access={edit.data.access} setAccess={(access) => {setEdit({...edit, data: {...edit.data, access}})}}></UserListAccess>                    
                        }
                        </> 
                        }
                        </Card.Body>
                    </>
                    :
                    <>
                    <Card.Header>Username: {User.username}</Card.Header>
                    <Card.Body>
                    <p>Email: {User.email}</p>
                    {User.adminStatus ? 
                    <p>Admin: <span  style={{color: 'green'}}>True</span></p>
                    :
                    <p>Admin: <span  style={{color: 'red'}}>False</span></p>
                    }
                    {(User.inTraining === true) &&
                    <p>In Training</p>
                    }
                    <p>Type: {User.type}</p>
                    <p>
                        Approved {User.approved ? "Yes": 'No'}
                    </p>
                    </Card.Body>
                    </>
                    }
                    
                    <Card.Footer>
                        {edit.status && edit.index === index ?
                        <>
                        {remove.status && remove.index === index ?
                        <>
                        <Button onClick={handleRemoveUser(User.username)}>Confirm Delete User</Button>
                        <Button onClick={() => setRemove({status: false, index: 20000})}>Cancel Delete User</Button>
                        </>
                        :
                        <>
                        <Button onClick={handleEditSubmit}>Submit Edit</Button>
                        <Button id={JSON.stringify(User)} onClick={(e) => handleEditToggle(e, index)}>Toggle Edit User</Button>
                        <Button id={User.username} onClick={() => setRemove({status: true, index})}>Delete User</Button>
                        </>
                        }
                        </>:
                        <>
                        <Button id={JSON.stringify(User)} onClick={(e) => handleEditToggle(e, index)}>Toggle Edit User</Button>
                        </>
                        }
                    </Card.Footer>
                </Card>
            ))
            }
            </Card.Body>
        </Card>
    )
}
export default UserList