import React, {useState} from "react";
import {Card, Button} from 'react-bootstrap'
import { useMutation } from "@apollo/client";
import { REMOVE_FLOWER_SHOP } from "../../utils/mutations";
const Flowershops = ({flowershops, canEdit, funeralhome}) => {
    const [removeFlowerShop] = useMutation(REMOVE_FLOWER_SHOP)
    const [deleteFS, setDeleteFS] = useState({status: false, index: 200000})
    const handleFSDelete = (name) => {
        try {
            removeFlowerShop({variables: {funeralhome, name}})
            setDeleteFS({status: false, index: 2000000})
        } catch (e) {
            console.log(e)
        }
    }
    return (
        <Card>
            <Card.Header>
                Flower Shop List
            </Card.Header>
            <Card.Body>
                {flowershops?.length === 0 &&
                    "No Flower Shops"
                }
                {flowershops && flowershops.map((flowershop, index) => (
                <Card style={{border: '1px solid black', margin: '2px', width: '100%'}} key={flowershop.location + flowershop.name + flowershop.number}>
                    <Card.Header>
                    <h4>{flowershop.location}</h4>
                    </Card.Header>
                    <Card.Body>
                    <p>{flowershop.name}</p>
                    <p>{flowershop.number}</p>
                    </Card.Body>
                    {canEdit &&
                    <Card.Footer>
                    {(deleteFS.status && deleteFS.index === index) ?
                    <>
                    <Button onClick={() => setDeleteFS({status: false, index:200000})}>Cancel Delete</Button>
                    <Button onClick={() => handleFSDelete(flowershop.name)}>Confirm Delete</Button>
                    </>:
                    <Button onClick={() => setDeleteFS({status: true, index})}>Remove {flowershop.name}</Button>
                    }
                    </Card.Footer>
                    }
                    </Card>
                ))}
            </Card.Body>
        </Card>
    )
}
export default Flowershops