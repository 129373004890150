import React, { useState} from "react";
import {Tabs, Tab} from 'react-bootstrap'
import FirstCallsForm from '../components/FirstcallForm'
import FirstCallsList from "../components/FirstcallList";
import { QUERY_FIRSTCALLS } from "../utils/queries";
import { useQuery } from "@apollo/client";
const FirstCalls = ({currentUser}) => {
    const {data, loading} = useQuery(QUERY_FIRSTCALLS)
    const firstCalls = data?.FirstCalls || []
    const lsKey = localStorage.getItem('fckey')
    const [key, setKey] = useState(lsKey || 'takeFirstCall')
    return (
        <Tabs justify onSelect={(key) => {setKey(key); localStorage.setItem('fckey', key)}} activeKey={key}>
            <Tab eventKey={'takeFirstCall'} title={'Take First Call'}>
            <FirstCallsForm setKey={(key) => setKey(key)}></FirstCallsForm>
            </Tab>
            <Tab eventKey={'firstCallList'} title={'First Call List'}>
            <FirstCallsList currentUser={currentUser} firstCalls={firstCalls} loading={loading}></FirstCallsList>
            </Tab>
        </Tabs>
    )
}
export default FirstCalls