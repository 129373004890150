import React, { useState } from "react";
import { Card, Button, Form } from "react-bootstrap";
import {
  PUT_ON_CALL_FOR_FH,
  REFRESH_ON_CALL,
  REMOVE_ON_CALL_FOR_FH,
  HANDLE_NOTES,
  REMOVE_ON_CALL_FOR_MULTI_FH,
  SET_DEFAULT_DIRECTOR,
} from "../../utils/mutations";
import { useMutation } from "@apollo/client";
import dayjs from "dayjs";
const OnCallCard = ({ funeralhome, director }) => {
  const [editMode, setEditMode] = useState(false);
  const [noteFormData, setNoteFormData] = useState(" ");
  const [putOnCallForFH] = useMutation(PUT_ON_CALL_FOR_FH);
  const [removeOnCallForFH] = useMutation(REMOVE_ON_CALL_FOR_FH);
  const [removeOnCallForMultiFH] = useMutation(REMOVE_ON_CALL_FOR_MULTI_FH);
  const [handleNotes] = useMutation(HANDLE_NOTES);
  const [refreshOnCall] = useMutation(REFRESH_ON_CALL);
  const [setDefaultDirector] = useMutation(SET_DEFAULT_DIRECTOR);
  const onCallAffiliates =
    funeralhome.parentCompany !== null &&
    funeralhome.parentCompany.funeralhomes.length !== 0
      ? funeralhome.parentCompany.funeralhomes.filter((affiliate) => {
          if (
            affiliate.useOnCallForAffiliate !== null &&
            affiliate.useOnCallForAffiliate._id === funeralhome._id
          ) {
            return true;
          } else {
            return false;
          }
        })
      : [];
  const parentCompanyFuneralHomes =
    funeralhome.parentCompany !== null &&
    funeralhome.parentCompany.funeralhomes.length !== 0
      ? funeralhome.parentCompany.funeralhomes
      : [];
  const handleNoteSubmit = (e) => {
    e.preventDefault();
    handleNotes({ variables: { director: director.name, note: noteFormData } });
    setEditMode(false);
  };
  const handleNoteRemoval = (e) => {
    e.preventDefault();
    handleNotes({ variables: { director: director.name, note: "" } });
    setEditMode(false);
  };
  const setOnCall = async (e, funeralhome, director) => {
    const on_call = () => {
      return !funeralhome.on_calls.currentOnCalls.some((oncall) => {
        return oncall.name === director.name;
      });
    };
    const isOnCall = on_call();
    const directorId = director._id;
    if (isOnCall) {
      await putOnCallForFH({
        variables: { directorId, fhname: funeralhome.name },
      });
    } else if (!isOnCall) {
      await removeOnCallForFH({
        variables: { directorId, fhname: funeralhome.name },
      });
    }
  };
  const removeForAffiliateDirector = async (fhnames, { _id: directorId }) => {
    try {
      await removeOnCallForMultiFH({ variables: { fhnames, directorId } });
    } catch (error) {
      console.log(error);
    }
  };
  const handleEditToggle = (e, director) => {
    setEditMode(!editMode);
    setNoteFormData(director.on_call.note);
  };
  const handleNoteChange = (e) => {
    setNoteFormData(e.target.value);
  };
  const handleOnCallRefresh = async () => {
    const time = dayjs().format("ddd MM DD YYYY [at] hh:mm a");
    try {
      await refreshOnCall({ variables: { time, directorId: director._id } });
    } catch (error) {
      console.log(error);
    }
  };
  const generateLink = (primary_phone) => {
    if (primary_phone.split("-").length > 1) {
      return (
        "https://voice.google.com/u/1/messages?itemId=t.%2B1" +
        primary_phone.split("-")[0] +
        primary_phone.split("-")[1] +
        primary_phone.split("-")[2]
      );
    }
    return "https://voice.google.com/u/1/messages";
  };
  const handleToggleDefaultDirector = async (
    funeralhome,
    { _id: directorId, defaultDirector: defaultStatus }
  ) => {
    const time = dayjs().format("ddd MM DD YYYY [at] hh:mm a");
    try {
      await setDefaultDirector({
        variables: { directorId, time, defaultStatus: !defaultStatus },
      });
      if (onCallAffiliates.length > 0) {
        await removeOnCallForMultiFH({
          variables: {
            directorId,
            fhnames: [
              ...onCallAffiliates.map(({ name }) => {
                return name;
              }),
              funeralhome.name,
            ],
            time,
          },
        });
      } else {
        await removeOnCallForMultiFH({
          variables: { directorId, fhnames: [funeralhome.name], time },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  if (
    (director.defaultStatus === true || director.on_call.note === "TFN") &&
    dayjs().format("ddd MM DD YYYY") !== director.on_call.time.split(" at")[0]
  ) {
    handleOnCallRefresh();
  } else if (
    director.on_call.note === "On All Weekend" &&
    dayjs().format("ddd") !== "Mon" &&
      dayjs().format("ddd") !== "Tue" &&
      dayjs().format("ddd") !== "Wed" &&
      dayjs().format("ddd") !== "Thu"&&
      dayjs().format("ddd MM DD YYYY") !== director.on_call.time.split(" at")[0]
  ) {
    handleOnCallRefresh();
  }
  return (
    <Card style={{ maxWidth: "30vw" }}>
      <Card.Header>
        {director.defaultDirector && (
          <>
            <h4>Default On Call:</h4>
          </>
        )}
        {director.name} at: <br />
        {director.on_call.time}
        <Button onClick={handleOnCallRefresh} id={director.name}>
          Refresh
        </Button>
        <br />
      </Card.Header>
      {editMode ? (
        <Card.Body>
          <Form
            id={director.name}
            onSubmit={(e) => {
              handleNoteSubmit(e, funeralhome);
            }}
          >
            Note Options:
            <Form.Control
              value={noteFormData}
              onChange={handleNoteChange}
              as="select"
            >
              <option value="">Please Select a Note</option>
              <option>TFN</option>
              <option>On All Weekend</option>
              <option>Back Up</option>
            </Form.Control>
            <br />
            Custom:
            <Form.Control
              autoFocus
              value={noteFormData}
              onChange={handleNoteChange}
            ></Form.Control>
            <Button type="submit">Submit Note</Button>
          </Form>
        </Card.Body>
      ) : (
        <Card.Body>
          Primary Contact Method: {director.primary_phone} <br />
          <a
            target={"_blank"}
            rel="noreferrer"
            href={generateLink(director.primary_phone)}
          >
            Open Google Voice
          </a>
          <br />
          Contact Type: {director.contact_method} <br />
          Notes: {director.on_call.note} <br />
        </Card.Body>
      )}
      <Card.Footer>
        {director.on_call.note === "" ? (
          <Button
            onClick={(e) => handleEditToggle(e, director)}
            id={director.name}
          >
            Toggle Add Note
          </Button>
        ) : (
          <>
            <Button
              onClick={(e) => handleEditToggle(e, director)}
              id={director.name}
            >
              Toggle Edit Note
            </Button>
            {!editMode && (
              <Button
                onClick={(e) => handleNoteRemoval(e, funeralhome)}
                id={director.name}
              >
                Remove Note
              </Button>
            )}
          </>
        )}
        {!editMode && (
          <>
            {funeralhome.useOnCallForAffiliate ? (
              <>
                {director.defaultDirector ? (
                  <Button
                    onClick={() =>
                      handleToggleDefaultDirector(funeralhome, director)
                    }
                  >
                    Remove as a Default Director for Affiliate
                  </Button>
                ) : (
                  <Button
                    id="useAffiliateOnCall"
                    onClick={() =>
                      removeForAffiliateDirector(
                        [director.funeralhome.name, funeralhome.name],
                        director
                      )
                    }
                  >
                    Take Off Call for Affiliate
                  </Button>
                )}
              </>
            ) : (
              <>
                {director.defaultDirector ? (
                  <Button
                    onClick={() =>
                      handleToggleDefaultDirector(funeralhome, director)
                    }
                  >
                    Remove as a Default Director
                  </Button>
                ) : (
                  <>
                    {onCallAffiliates.length === 0 ? (
                      <Button
                        onClick={(e) => setOnCall(e, funeralhome, director)}
                      >
                        Take Off Call
                      </Button>
                    ) : (
                      <Button
                        onClick={() =>
                          removeForAffiliateDirector(
                            parentCompanyFuneralHomes.map(({ name }) => {
                              return name;
                            }),
                            director
                          )
                        }
                      >
                        Take Off Call For Affiliates
                      </Button>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </Card.Footer>
    </Card>
  );
};
export default OnCallCard;
