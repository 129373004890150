import React, {useState} from 'react'
import { ADD_ON_CALL_SCHEDULE, ADD_MULTIPLE_ON_CALL_SCHEDULE } from '../../utils/mutations'
import { useMutation } from '@apollo/client'
import { Form, Alert, Button, Card, Accordion } from 'react-bootstrap'
import dateFormat from '../../utils/dateFormat'
import TimePicker from 'react-datetime-picker'
import dayjs from 'dayjs'
const ScheduleOnCalls = ({currentFH}) => {
    const [addOnCallSchedule] = useMutation(ADD_ON_CALL_SCHEDULE)
    const [addMultipleOnCallSchedule] = useMutation(ADD_MULTIPLE_ON_CALL_SCHEDULE)
    const [schedule, setSchedule] = useState([])
    const [alert, setAlert] = useState({status: false, info: ''})
    const today = dayjs().toDate().toString()
    const tomorrow = dayjs().add(1, 'day').startOf('day').add(8, 'hours').toDate().toString()
    const [scheduleFormData, setScheduleFormData] = useState({startDate: today, endDate: tomorrow, funeralhomename: [currentFH.name], director: 'none', note: ''})
    const handleScheduleChange = (e) => {
        if(e.target.id === 'Director') {
            if(e.target.value === 'none') {
                setScheduleFormData({...scheduleFormData, director: "none"})
            } else {
                setScheduleFormData({...scheduleFormData, director: JSON.parse(e.target.value)})
            }
        } else if(e.target.id === 'note') {
            setScheduleFormData({...scheduleFormData, note: e.target.value})
        }
    }
    const handleAddToSchedule = (e) => {
        e.preventDefault()
        setScheduleFormData({startDate: String(new Date()), endDate: String(tomorrow), funeralhomename: [currentFH.name], director: 'none', note: ''})
        setSchedule([...schedule, scheduleFormData])
    }
    const handleRemoveSchedule = (e, index) => {
        if(e.target.id === 'Remove') {
            setSchedule(schedule.filter((schedule, i) => {
                console.log(index, i)
                return i !== index
            }))
        } else if(e.target.id === 'Remove All') {
            setSchedule([])
        }
    }
    const handleScheduleSubmit = async (e) => {
        if(e.target.id === 'Submit All') {
            const array = []
            schedule.forEach(async (scheduledata, index, arr)=> {
                try {
                    await addMultipleOnCallSchedule({variables: {funeralhomename: scheduledata.funeralhomename, directorId: scheduledata.director._id, endDate: scheduledata.endDate, startDate: scheduledata.startDate, note: scheduledata.note}}).then(({data})=> {
                        data.scheduleMultipleOnCall.forEach((funeralhome, index) => {
                            funeralhome.on_calls.scheduledDirectors.forEach((newschedule, index) => {
                                if(newschedule.endDate === scheduledata.endDate && newschedule.startDate === scheduledata.startDate && newschedule.director._id === scheduledata.director._id) {
                                    array.push({director: newschedule.director.name, startDate: newschedule.startDate.split(' ')[0] + " " + dateFormat(newschedule.startDate), endDate: newschedule.endDate.split(' ')[0]+  " " + dateFormat(newschedule.endDate), funeralhome: funeralhome.name, note: newschedule.note})
                                }
                            })
                        })
                    })
                }
                catch (error) {
                    setAlert({status: true, info: [{error: "There has been an error: " + error}]})
                }
               if(arr.length === index + 1) {
                setAlert({status: true, info: array})
                setSchedule([])
            }
            })
        } else {
            const thisschedule = JSON.parse(e.target.id)
                try {
                    await addOnCallSchedule({variables: {funeralhomename: thisschedule.funeralhomename[0], directorId: thisschedule.director._id, endDate: thisschedule.endDate, startDate: thisschedule.startDate}}).then(({data})=> {
                        data.scheduleOnCall.on_calls.scheduledDirectors.forEach((newschedule, index) => {
                            if(newschedule.endDate === thisschedule.endDate && newschedule.startDate === thisschedule.startDate && newschedule.director._id === thisschedule.director._id){
                                setAlert({status: true, info: [...alert.info, {director: thisschedule.director.name, startDate: thisschedule.startDate.split(' ')[0] + " " + dateFormat(thisschedule.startDate), endDate: thisschedule.endDate.split(' ')[0]+  " " + dateFormat(thisschedule.endDate), funeralhome: thisschedule.funeralhomename[0]}]})
                                }
                        })
                    })
                }
                catch (error) {
                    setAlert({status: true, text: "There has been an error:" + error})
                }
            setSchedule([])
        }
    }
    const parentCompanyUseOnCallForAffiliates = currentFH.parentCompany?.funeralhomes.filter(({useOnCallForAffiliate}) => {
        if(useOnCallForAffiliate === null) {
            return false
        }
        return useOnCallForAffiliate._id === currentFH._id
    })
    return (
            <Card style={{textAlign: 'center', overflowY: 'scroll', maxHeight: '100vh'}}>
                    <Card.Header>
                    <h3>Schedule On Calls for {currentFH.name} {parentCompanyUseOnCallForAffiliates?.map((affiliate) => (
                        <div key={affiliate._id}>& {affiliate.name}</div>
                    ))}</h3>
                    </Card.Header>
                    <Card.Body>
                        <Accordion alwaysOpen>
                            <Accordion.Item eventKey='Form'>
                                <Accordion.Header>
                                    <h4>
                                    Scheduling Form
                                    </h4>
                                </Accordion.Header>
                                <Accordion.Body>
                                <Card style={{textAlign: 'center'}}>            
                                <Card.Header>
                                    Scheduling Director Form:
                                </Card.Header>
                                <Form.Group>
                                    <Form.Label>Director:</Form.Label>
                                    {currentFH.directors.length === 0 ?
                                    <p>No Directors for this funeral home</p>
                                    :
                                    <>
                                    <Form.Control value={JSON.stringify(scheduleFormData.director)} id="Director" onChange={handleScheduleChange} as='select'>
                                        <option value="none">Please Select A Director</option>
                                        {currentFH.directors.map((director) => (
                                            <option key={director._id} value={JSON.stringify(director)}>{director.name}</option>
                                        ))
                                        }
                                    </Form.Control>
                                    <Alert className='Alert' show={scheduleFormData.director === 'none'}>Please Select a Director</Alert>
                                    </>}
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Start Date & Time:</Form.Label>
                                    <TimePicker id="startDate" minDate={new Date(dayjs().format())} clearIcon={null} minDetail={'year'} value={scheduleFormData.startDate} disableCalendar={true} disableClock={true} onChange={
                                        (dateTime) => setScheduleFormData({...scheduleFormData, startDate: String(dateTime)})
                                    }></TimePicker>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>End Date & Time:</Form.Label>
                                    <TimePicker id="endDate" minDate={new Date(dayjs().add(1, 'minute').format())}  onChange={
                                        (dateTime) => setScheduleFormData({...scheduleFormData, endDate: String(dateTime)})
                                    } clearIcon={null} minDetail={'year'} value={scheduleFormData.endDate} disableCalendar={true} disableClock={true}></TimePicker>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Notes:</Form.Label> <br/>
                                Note Options:
                                <Form.Control value={scheduleFormData.note} id="note" onChange={handleScheduleChange} as='select'>
                                    <option value=''>Please Select a Note</option>
                                    <option>TFN</option>
                                    <option>On All Weekend</option>
                                    <option>Back Up</option>
                                </Form.Control>
                                Custom Notes: 
                                <Form.Control value={scheduleFormData.note} id="note" onChange={handleScheduleChange}>
                                </Form.Control>
                                </Form.Group>
                                <Card.Footer>
                                <Button onClick={handleAddToSchedule} type='submit' disabled={scheduleFormData.director === 'none'}>Add To Pending Schedule List</Button>
                                </Card.Footer>
                            </Card>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey='readytobescheduled'>
                                <Accordion.Header>
                                    <h4>Ready To Be Scheduled</h4>
                                </Accordion.Header>
                                <Accordion.Body>
                                {schedule.length > 0 ?
                                        <Card>
                                            <Card.Header>
                                            <h3>Ready To Be Scheduled</h3>
                                            </Card.Header>
                                        <Card.Body style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly'}}>
                                            {schedule.map((schedule, index) => (
                                            <Card key={schedule.startDate + schedule.endDate} style={{textAlign: 'center', width: '28vw'}}>
                                            <Card.Header>
                                            {schedule.director.name} is scheduled to be on call for: <br/> 
                                            {schedule.funeralhomename} {parentCompanyUseOnCallForAffiliates?.map((affiliate) => (<div key={affiliate._id}>& {affiliate.name}</div>))}
                                            </Card.Header>
                                            <Card.Body>
                                            Starting on: {schedule.startDate.split(' ')[0]} {dateFormat(schedule.startDate)}<br/>
                                            Ending on: {schedule.endDate.split(' ')[0]} {dateFormat(schedule.endDate)}<br/>
                                            Note: {schedule.note}
                                            </Card.Body>
                                            <Card.Footer>
                                            <Button id={JSON.stringify(schedule)} onClick={handleScheduleSubmit}>Schedule</Button>
                                            <Button id="Remove"  onClick={(e) => handleRemoveSchedule(e, index)}>Remove</Button>    
                                            </Card.Footer>
                                            </Card>
                                            ))
                                            }
                                        </Card.Body>
                                        <Card.Footer>
                                        <Button id="Submit All" onClick={handleScheduleSubmit}>Schedule All</Button>
                                        <Button id="Remove All" onClick={handleRemoveSchedule}>Remove All</Button>
                                        </Card.Footer>
                                        </Card>:
                                        <Card>
                                            <Card.Body>
                                                No one's ready to be scheduled
                                            </Card.Body>
                                        </Card>
                                        }
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    <Alert dismissible onClose={() => setAlert({status: false, info: ''})} show={alert.status}>Been Scheduled List Click to Dismiss! 
                        <div style={{display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'space-evenly'}}>
                        {alert.info && alert.info.map((info) => (
                            <Card style={{marginRight: '.5vw', width: '30vw', border: 'solid black 1px'}}>
                                {info.error === null ? 
                                <>
                                Error: {info.error}
                                </> :
                                <>
                                <Card.Header>
                                Director: {info.director} has been scheduled 
                                </Card.Header>
                                <Card.Body>
                                Start Date & Time: <br/> 
                                {info.startDate} <br/> 
                                End Date & Time: <br/>
                                {info.endDate} <br/>
                                For: {info.funeralhome} {parentCompanyUseOnCallForAffiliates?.map((affiliate) => (
                                    <> & {affiliate.name}</>
                                ))}<br/>
                                Note: {info.note}
                                </Card.Body>
                                </>
                            }
                            </Card>
                        ))}
                        </div>
                        </Alert>
                    </Card.Body>
            </Card>
    )
}
export default ScheduleOnCalls