import React, { useState } from "react";
import { QUERY_FEEDBACK } from "../../utils/queries";
import { useQuery, useMutation } from "@apollo/client";
import { REMOVE_FEEDBACK, SEEN_FEEDBACK } from "../../utils/mutations";
import { Card, Button } from "react-bootstrap";
const Feedbacklist = () => {
  const { data } = useQuery(QUERY_FEEDBACK);
  const [removeFeedback] = useMutation(REMOVE_FEEDBACK);
  const [seenFeedback] = useMutation(SEEN_FEEDBACK);
  const Feedback = data?.Feedback || [];
  const [deleteStatus, setDeleteStatus] = useState(false);
  const handleDeleteToggle = (e) => {
    if (deleteStatus.id === e.target.id) {
      setDeleteStatus(false);
    } else {
      setDeleteStatus({ id: e.target.id });
    }
  };
  const handleDelete = async () => {
    const Id = deleteStatus.id;
    try {
      await removeFeedback({ variables: { Id } });
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };
  const handleSeen = async (id) => {
    try {
      await seenFeedback({ variables: { seenFeedbackId: id } });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Card>
      <Card.Header>All Feedback</Card.Header>
      <Card.Body
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      >
        {Feedback &&
          Feedback.map((Feedback) => (
            <Card
              style={{ border: "1px solid black", margin: "2px" }}
              key={Feedback._id}
            >
              <Card.Header>
                <h3>Written By: {Feedback.author.username}</h3>
              </Card.Header>
              <Card.Body style={{ textAlign: "center" }}>
                <p>Message: {Feedback.message}</p>
                <p>Submitted At: {Feedback.submittedAt}</p>
                <p>{Feedback.seen ? "Seen" : "Unseen"}</p>
              </Card.Body>
              <Card.Footer style={{ textAlign: "center" }}>
                {deleteStatus.id === Feedback._id ? (
                  <>
                    <Button onClick={handleDelete}>Confirm Delete</Button>
                    <Button onClick={handleDeleteToggle}>Cancel Delete</Button>
                  </>
                ) : (
                  <>
                    <Button id={Feedback._id} onClick={handleDeleteToggle}>
                      Remove Feedback?
                    </Button>
                    {!Feedback.seen && (
                      <Button
                        onClick={() => {
                          handleSeen(Feedback._id);
                        }}
                      >
                        Make Feedback as Seen
                      </Button>
                    )}
                  </>
                )}
              </Card.Footer>
            </Card>
          ))}
      </Card.Body>
    </Card>
  );
};
export default Feedbacklist;
