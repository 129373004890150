import React from 'react'
import { Card } from 'react-bootstrap'
import ParentCompanyListCard from '../ParentCompanyListCard'
const ParentCompanyList = ({parentCompanies, handleRemovePC}) => {
    return (
        <Card style={{textAlign: 'center'}}>
            <Card.Header>
                Parent Company List
            </Card.Header>
            <Card.Body>
                {parentCompanies?.length > 0 ? 
                <>
                {parentCompanies.map((parentCompany) => (
                    <ParentCompanyListCard handleRemovePC={handleRemovePC} key={parentCompany._id} parentCompany={parentCompany}></ParentCompanyListCard>
                ))}
                </>:
                <>
                No Parent Companies
                </>
                }
            </Card.Body>
        </Card>
    )
}

export default ParentCompanyList