import React from "react";
import UserList from "../components/UserList";

const Users = ({currentUser}) => {
    return (
        <div>
            <UserList currentUser={currentUser}></UserList>
        </div>
    )
}
export default Users