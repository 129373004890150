import React, {useEffect, useState} from "react";
import MessageForm from "../components/MessageForm";
import MessagesList from "../components/MessagesList";
import MessageHistory from "../components/MessageHistory";
import {Tabs, Tab} from 'react-bootstrap'
import MessageAnswerList from "../components/MessageAnswerList";
import { useQuery} from "@apollo/client";
import { QUERY_MESSAGES } from "../utils/queries";
const Messages = ({currentUser}) => {
    const {data, loading} = useQuery(QUERY_MESSAGES)
    var Messages = data?.Messages
    const [currentMessages, setCurrentMessages] = useState([])
    useEffect(() => {
        const messageList = Messages || []
        setCurrentMessages(messageList)
    }, [Messages, setCurrentMessages])
    const [currentPage, setCurrentPage] = useState(localStorage.getItem('MessageCurrentPage') || "Answer List")
    var fhLS = null
    var messageFormFHLS = null
    if(JSON.parse(localStorage.getItem('messageInfo')) !== null) {
        fhLS = JSON.parse(localStorage.getItem('messageInfo')).funeralhome
        messageFormFHLS = JSON.parse(localStorage.getItem('messageInfo')).funeralhome
        if(fhLS !== "none") {
            fhLS = JSON.parse(fhLS).name
        }
    }
    const checkIfLocalStorage = () => {
        if(fhLS === null|| fhLS === 'none') {
            return false
        }
        return true
    }
    var localstorage2 = []
    if(JSON.parse(localStorage.getItem('pendingMessages')) !== null) {
        localstorage2 = JSON.parse(localStorage.getItem('pendingMessages'))
    }
    const [pendingMessages, setPendingMessages] = useState([...localstorage2])
    const handlePending = (pendingMessage) => {
        setPendingMessages(pendingMessage)
    }
    const [fhSelected, setFhSelected] = useState(checkIfLocalStorage)
    const [funeralhome, setFuneralhome] = useState(fhLS)
    const [messageFormFH, setMessageFormFH] = useState(messageFormFHLS || 'none')
    const [answerListClicked, setAnswerListClicked] = useState(false)
    const handleFHSelected = (e, funeralhome) => {
        if(funeralhome !== undefined) {
            setFhSelected(true)
            if(typeof funeralhome === "string") {
                setFhSelected(false)
                return
            }
            setFuneralhome(JSON.parse(funeralhome).name)
            return
        } if(e.target.value === 'none'){
            setFhSelected(false)
        } else {
            setFhSelected(true)
            setFuneralhome(JSON.parse(e.target.value).name)
        }
    }
    const handleFHNameClicked = (fh) => {
    var localstorage = null
    if(JSON.parse(localStorage.getItem('messageInfo')) !== null) {
        localstorage = JSON.parse(localStorage.getItem('messageInfo'))
        localstorage.funeralhome = fh
        localStorage.setItem('messageInfo', JSON.stringify(localstorage))
    }
    }
    const handleMessageAdded = (message) => {
        setCurrentMessages([...currentMessages, message])
    }
    return (
        <Tabs fill onSelect={(page) => {setCurrentPage(page); localStorage.setItem('MessageCurrentPage', page)}} activeKey={currentPage}>
            <Tab title='Answer List' eventKey={'Answer List'}>
                <MessageAnswerList
                selectFuneralHome={(e, funeralhome) => {
                handleFHNameClicked(funeralhome)
                setFhSelected(true)
                setFuneralhome(JSON.parse(funeralhome).name)
                setMessageFormFH(funeralhome)
                setAnswerListClicked(true)
                localStorage.setItem('MessageCurrentPage', 'Main Page')
                setCurrentPage('Main Page')
                }}>
                </MessageAnswerList>
            </Tab>
            <Tab eventKey={"Main Page"} title="Message Form">
                <MessageForm handleAddedMessage={(data) => handleMessageAdded(data)} fh={funeralhome} fhSelected={fhSelected} answerListClicked={answerListClicked} setMessageFormFH={() => {setMessageFormFH('none'); setAnswerListClicked(false)}} currentUser={currentUser} pendingMessages={pendingMessages} handlePending={handlePending} funeralhome={messageFormFH} handleFHSelected={handleFHSelected}></MessageForm>
            </Tab>
            <Tab eventKey={"Today"} title="Today's History">
                <MessagesList Messages={currentMessages} loading={loading} currentUser={currentUser} pendingMessages={pendingMessages} handlePending={handlePending}></MessagesList>
            </Tab>
            <Tab eventKey={"History"} title='History'>
                <MessageHistory currentUser={currentUser}></MessageHistory>
            </Tab>
        </Tabs>
    )
}
export default Messages