import React, {useState} from "react";
import { Card, Form, Button } from "react-bootstrap";
import { EDIT_TUTORIAL_QUESTIONS} from "../../utils/mutations";
import { useMutation } from "@apollo/client";
const TutorialQuestions = ({tutorial, setQuestionForm}) => {
    const [questions, setQuestions] = useState((tutorial.questions.length > 0 && tutorial.questions) ||[""])
    const [editTutorialQuestions] = useMutation(EDIT_TUTORIAL_QUESTIONS) 
    const handleQuestionChange = (e, index) => {
        setQuestions(questions.map((question, i) => {
            if(index===i) {
                return e.target.value
            }
            return question
        }))
    }
    const handleAddQuestion = () => {
            setQuestions([...questions, ""])
    }
    const handleChangeQuestionOrder = (index, direction) => {
        const orderedQuestions =  questions
        orderedQuestions.splice(direction, 0, questions.splice(index, 1)[0]);
        setQuestions([...orderedQuestions])
    }
    const handleQuestionRemoval = (index) => {
        setQuestions(questions.filter((question, i) => {
            return i !== index
        }))
    }
    const handleEditQuestions = () => {
        editTutorialQuestions({variables: {id: tutorial._id, questions}})
        setQuestionForm()
    }
    return (
        <Card>
            <Card.Header>
                Add Questions for {tutorial.name}
            </Card.Header>
            <Card.Body style={{display: "flex", flexWrap: 'wrap', justifyContent: 'center'}}>
                {questions.map((question, index) => (
                    <Form.Group key={index}>
                    <Form.Label>
                        Question {index + 1}:
                    </Form.Label>
                    <Form.Control as='textarea' onChange={(e) => handleQuestionChange(e, index)} style={{width: '50vw'}} value={question}>
                    </Form.Control>
                    {index !== 0 && 
                    <Button onClick={() => handleChangeQuestionOrder(index, index -1)}>&#x2191;</Button>
                    }
                    {index !== questions.length - 1 &&
                    <Button onClick={() => handleChangeQuestionOrder(index, index +1)}>&#8595;</Button>
                    }
                    {questions.length > 1 &&
                        <Button onClick={() => handleQuestionRemoval(index)}>Remove Question</Button>
                    }
                    </Form.Group>
                    
                ))}
            </Card.Body>
            <Card.Footer>
                <Button onClick={handleAddQuestion}>Add Another Question</Button>
                <Button onClick={(handleEditQuestions)}>
                Submit Question Changes
                </Button>
                <Button onClick={() => setQuestionForm()}>
                    Cancel Question Changes
                </Button>
            </Card.Footer>
        </Card>
    )
}
export default TutorialQuestions