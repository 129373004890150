import React, { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import { ADD_UPDATE } from "../../utils/mutations";
import dateFormat from "../../utils/dateFormat";
const UpdateForm = ({handleClose}) => {
    const [formData, setFormData] = useState({})
    const [textareaheight, setTextareaheight] = useState(1);
    const [addUpdate] = useMutation(ADD_UPDATE)
    const handleChange = (e) => {
        if(e.target.id === 'text'){
            if(e.target.value === "") {
                setTextareaheight(1)
            } else  {
        const height = e.target.scrollHeight;
        const rowHeight = 50; 
        const trows = Math.ceil(height / rowHeight) -1 ;
        if (trows && textareaheight) { 
            setTextareaheight(trows); 
          } 
        }
        }
    setFormData({...formData, [e.target.id]: e.target.value})
    }
    const handleSubmit = () => {
        const now = dateFormat(new Date()).split(' ')
        const date = now[0] + "/" + now[1] + "/" + now[2]
        const time = dateFormat(new Date()).split('at')[1]
        try {
            addUpdate({variables: {...formData, time: date + " at" + time}})
        } catch (error) {
            console.log(error)
        }
        window.location.reload()
    }
    return (
        <div>
        <div  className="modal show"
        id="ModalBox">
        <div className="box">
            <span className="close-icon" onClick={handleClose}>x</span>
                <Modal.Header style={{position: 'sticky', top: 0, zIndex: '1', background: 'lightgrey'}}>
                    <Modal.Title>
                    Updates
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>
                                Update Title:
                            </Form.Label>
                            <Form.Control id='title' onChange={handleChange} value={formData.title}>

                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                Update Text:
                            </Form.Label>
                            <Form.Control id='text' as='textarea' rows={textareaheight} onChange={handleChange} value={formData.text}>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                Update Number:
                            </Form.Label>
                            <Form.Control id='number' onChange={handleChange} value={formData.number}>
                            </Form.Control>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer style={{position: 'sticky', bottom: 0, zIndex: '1', background: 'lightgrey'}}>
                    <Button onClick={handleSubmit}>Submit</Button>
                </Modal.Footer>
            </div>
            </div>
        </div>
    )
}
export default UpdateForm