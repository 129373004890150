import React, {useState} from 'react'
import {Card, Form, Button, Alert} from 'react-bootstrap'
import { useMutation } from '@apollo/client'
import { ADD_LOCATION } from '../../utils/mutations'
const FuneralHomesLocationForm = ({funeralhome, changeFuneralhomes}) => {
    const [AddLocation] = useMutation(ADD_LOCATION)
    const [locationInfo, setLocationInfo] = useState({name: '', address: '', main: '',back: '', fax: '', email: '', directions: ''})
    const handleLocationChange = (e) => {
        setLocationInfo({...locationInfo, [e.target.id]: e.target.value })
    }
    const handleLocationSubmit = async (e) => {
        e.preventDefault()
        try{
            await AddLocation({
            variables: {...locationInfo, funeralHomeName: funeralhome.name}
            }).then(() => {
                setLocationInfo({name: '', address: '', main: '', back: '', fax: '', email: '', directions: ''})
                changeFuneralhomes()
            })
        }catch (e) {
            console.error(e);
        } 
    }
    return (
       <Card>
            <Card.Header >
            <h1>Add Location for Funeral Home: {funeralhome.name}</h1>
            </Card.Header>
            <Card.Body style={{overflowY: 'auto', height: '50vh'}}>
            <Form>
            <Form.Group>
                <Form.Label>Name:</Form.Label>
                <Form.Control className="formInput" autoComplete={'off'} value={locationInfo.name} onChange={handleLocationChange} id="name" placeholder="Location Name"></Form.Control>
            </Form.Group>
            <Alert show={locationInfo.name === undefined || locationInfo.name === ''} className="Alert">Name is Required</Alert>
            <Form.Group>
                <Form.Label>Address:</Form.Label>
                <Form.Control className="formInput" autoComplete={'off'} value={locationInfo.address} onChange={handleLocationChange} id="address" placeholder="Address"></Form.Control>
            </Form.Group>
            <Alert show={locationInfo.address === undefined || locationInfo.address === ''} className="Alert">Address is Required</Alert>
            <Form.Group>
                <Form.Label>Main Line:</Form.Label>
                <Form.Control className="formInput" autoComplete={'off'} onChange={handleLocationChange} value={locationInfo.main} id="main" placeholder="Main Line"></Form.Control>
            </Form.Group>
            <Alert show={locationInfo.main === undefined || locationInfo.main === ''} className="Alert">Main Line is Required</Alert>
            <Form.Group>
                <Form.Label>Back Line:</Form.Label>
                <Form.Control className="formInput" autoComplete={'off'}value={locationInfo.back} onChange={handleLocationChange} id="back" placeholder="Back Line"></Form.Control>
            </Form.Group>
            <Alert show={locationInfo.back === undefined || locationInfo.back === ''} className="Alert">Back is Required</Alert>
            <Form.Group>
                <Form.Label>Fax:</Form.Label>
                <Form.Control className="formInput" autoComplete={'off'} value={locationInfo.fax} onChange={handleLocationChange} id="fax" placeholder="Fax"></Form.Control>
            </Form.Group>
            <Alert show={locationInfo.fax === undefined || locationInfo.fax === ''} className="Alert">Fax is Required</Alert>
            <Form.Group>
                <Form.Label>Email:</Form.Label>
            <Form.Control className="formInput" autoComplete={'off'} onChange={handleLocationChange} value={locationInfo.email} id="email" placeholder="Email"></Form.Control>
            </Form.Group>
            <Form.Group>
                <Form.Label>Directions</Form.Label>
            <Form.Control className="formInput" autoComplete={'off'} onChange={handleLocationChange} value={locationInfo.directions} id="directions" placeholder="Directions"></Form.Control>
            </Form.Group>
        </Form>
        </Card.Body>
        <Card.Footer>
        <Button onClick={handleLocationSubmit}>Add Location</Button>
        </Card.Footer>
       </Card>
    )
}
export default FuneralHomesLocationForm