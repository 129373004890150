import React, {useState, useEffect} from 'react'
import { useQuery} from '@apollo/client'
import { Accordion, Tabs, Tab, Card, Button } from 'react-bootstrap'
import { QUERY_TUTORIALS } from '../utils/queries'
import TutorialForm from '../components/TutorialForm'
import TutorialCard from '../components/TutorialCard'
import TutorialHandler from '../components/TutorialHandler'
import WebsiteTutorials from '../components/WebsiteTutorials'
const Tutorial = ({currentUser}) => {
    const [key, setKey] = useState('List')
    const {data} = useQuery(QUERY_TUTORIALS)
    const Tutorials = data?.Tutorials
    const [currentTutorials, setCurrentTutorials] = useState([])
    const [canEdit] = useState(currentUser.adminStatus)
    const [takeTutorial, setTakeTutorial] = useState([])
    useEffect(() => {
        const tutorials = Tutorials || []
        setCurrentTutorials(tutorials)
        const take = []
        tutorials.forEach((tutorial) => {
            take.push({name: tutorial.name, status: !canEdit})
        })
        setTakeTutorial(take)
    }, [Tutorials, setCurrentTutorials, setTakeTutorial, canEdit])
    const handleTakeTutorial = ({name, status}, tutorial) => {
        if(name === tutorial.name){
            return status
        }
        else {
            return false
        }
    }
    return (
        <Tabs justify onSelect={(key) => setKey(key)} activeKey={key}>
            <Tab eventKey="List" title="General Answering Service Tutorials">
                <Tab.Content>
                <Accordion>
                    {currentTutorials.length === 0 && <Card><Card.Header style={{textAlign: 'center'}}>No Tutorials</Card.Header></Card>}
                {currentTutorials && currentTutorials.map((Tutorial) => (
                    <Accordion.Item key={Tutorial._id} eventKey={Tutorial.name}>
                        <Accordion.Header>
                            <h2>
                            {Tutorial.name}:
                            </h2>
                        </Accordion.Header>
                        <Accordion.Body>
                            {takeTutorial.some((data) =>  handleTakeTutorial(data, Tutorial)) ?
                                <TutorialHandler setTakeTutorial={() => {setTakeTutorial([...takeTutorial.filter(({name}) => {return name !== Tutorial.name}), {name: Tutorial.name, status: false}])}} Tutorial={Tutorial}></TutorialHandler>
                                    :   
                                    <>
                                    <Button onClick={() => {setTakeTutorial([...takeTutorial.filter(({name}) => {return name !== Tutorial.name}), {name: Tutorial.name, status: true}])}}>Start Tutorial</Button>
                                    <TutorialCard currentTutorials={currentTutorials} setCurrentTutorials={(data) => setCurrentTutorials} canEdit={canEdit} tutorial={Tutorial}></TutorialCard>
                                    </>
                            }
                        </Accordion.Body>
                    </Accordion.Item>
                ))
                }
            </Accordion>
            </Tab.Content>
            </Tab>
            {canEdit &&
            <Tab eventKey="Form" title="General Answering Service Tutorial Form">
                <TutorialForm setKey={() => setKey("List")} setCurrentTutorials={(data) => setCurrentTutorials(data)} currentTutorials={currentTutorials}></TutorialForm>
            </Tab>
            }
            <Tab eventKey={'Videos'} title='Website Video Tutorials'>
                  <WebsiteTutorials></WebsiteTutorials> 
            </Tab>
        </Tabs>
    )
}

export default Tutorial