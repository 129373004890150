import React from "react";
import {Card} from 'react-bootstrap'
import OnCallCard from "../OnCallCard";
const SingleFHOnCalls = ({funeralhome}) => {
    return (
        <Card>
            <Card.Header>
                On Calls List:
            </Card.Header>
            <Card.Body style={{display: 'grid', justifyContent: 'space-evenly', height: '40vh', overflow: 'auto'}}>
            {funeralhome.on_calls.currentOnCalls && funeralhome.on_calls.currentOnCalls.map((director) => (
            <OnCallCard key={director._id} director={director} funeralhome={funeralhome}></OnCallCard>
            ))
            }</Card.Body>
        </Card>
    )
}
export default SingleFHOnCalls