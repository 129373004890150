import React, {useState} from "react";
import { Card, Button, Form } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import {EDIT_TUTORIAL, DELETE_TUTORIAL } from '../../utils/mutations'
import TutorialQuestion from "../TutorialQuestion";
const TutorialCard = ({tutorial, canEdit, setCurrentTutorials, currentTutorials}) => {
    const [questionForm, setQuestionForm] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [editData, setEditData] = useState({})
    const [editTutorial] = useMutation(EDIT_TUTORIAL)
    const [deleteTutorial] = useMutation(DELETE_TUTORIAL)
    const handleEditChange = (e) => {
        setEditData({...editData, [e.target.id]: e.target.value})
    }
    const handleEditSubmit = (e) => {
        e.preventDefault()
        try {
            editTutorial({variables: {name: editData.name, type: editData.type, id: editData._id}}).then(({data}) => {
                setCurrentTutorials([...currentTutorials, {...data.editTutorial}])
                setEditMode(false)
            })
        } catch (error) {
            console.log(error)
        }
    }
    const handleTutorialRemoval = async (id) => {
        try {
            deleteTutorial({variables: {id}}).then(({data}) => {
                setCurrentTutorials(data.deleteTutorial)
            })
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <Card key={tutorial._id}>
            {questionForm ?
                <TutorialQuestion setQuestionForm={() => setQuestionForm(false)} tutorial={tutorial}></TutorialQuestion>
                :
                <>
                {editMode ?
            <>
                <Card.Header>
                    Edit Mode
                </Card.Header>
            <Card.Body>
                <Form>
                    <Form.Label>
                        Name:
                    </Form.Label>
                    <Form.Control onChange={handleEditChange} id="name" value={editData.name}>
                    </Form.Control>
                </Form>
            </Card.Body>
            <Card.Footer>
            <Button onClick={handleEditSubmit}>Submit Edit</Button>
            <Button onClick={() => setEditMode(null)}>Cancel Edit</Button>
            </Card.Footer>
            </>: <>
                <Card.Header>
                <h4>{tutorial.name}</h4>
                </Card.Header>
                <Card.Body>
                {tutorial.questions && tutorial.questions.map((question, index) => (
                        <Card key={question}>
                            <Card.Header>
                                Question {index + 1}:
                            </Card.Header>
                            <Card.Body>
                            {question}
                            </Card.Body>
                        </Card>
                    ))}
                </Card.Body>
                {canEdit && 
                    <Card.Footer>
                    <Button onClick={() => {setEditMode(tutorial.name); setEditData(tutorial)}}>Edit Tutorial</Button>
                    <Button onClick={() => handleTutorialRemoval(tutorial._id)}>Remove Tutorial</Button>
                    <Button onClick={() => setQuestionForm(true)}>Open Question Form/Editing</Button>
                </Card.Footer>
                }
                </>
                    }
                </>
            }
            </Card>
    )
}
export default TutorialCard