import React, {useState} from "react";
import { useMutation } from "@apollo/client";
import { HANDLE_NOTES, PUT_ON_CALL_FOR_MULTI_FH, REMOVE_ON_CALL_FOR_FH } from "../../utils/mutations";
import dateFormat from "../../utils/dateFormat";
import { Card, Form, ListGroup, Button, Alert, } from "react-bootstrap";
const BulkOnCalls = ({directors}) => {
    var Directors = directors
    var arrayForSort = [...Directors]
    arrayForSort = arrayForSort.sort(function(a, b){
        if(a.funeralhome === null || b.funeralhome === null) {
            return 2000
        } 
        var nameA = a.funeralhome.name.toLowerCase(), nameB = b.funeralhome.name.toLowerCase();
        if(nameA < nameB) //sort string ascending
            {return -1;}
        if(nameA > nameB)
            {return 1;}
        return 0;
    })
    arrayForSort = arrayForSort.filter(({funeralhome}) => {
        if(funeralhome === null) {
            return false
        }
        return funeralhome.status
    })
    arrayForSort = arrayForSort.filter(({funeralhome, _id}) => {
        return funeralhome.on_calls.directorPool.some((director) => {return director.id !== _id})
    })
    Directors = arrayForSort
    const [onCallList, setOnCallList] = useState([])
    const [takeOffCallList, setTakeOffCallList] = useState([])
    const [fhOnCallList, setFHOnCallList] = useState([])
    const [noteMode, setNoteMode] = useState({director: ''})
    const [noteFormData, setNoteFormData] = useState('')
    const [putOnCallForMultiFH] = useMutation(PUT_ON_CALL_FOR_MULTI_FH)
    const [handleNotes] = useMutation(HANDLE_NOTES)
    const [removeOnCallForFH] = useMutation(REMOVE_ON_CALL_FOR_FH)
    const handleOnCallListChange = (e, directorId) => {
        if(e.target.checked === true) {
            setOnCallList([...onCallList, {name: e.target.id, directorId}])
        }
        else if(e.target.checked === false) {
            setOnCallList((onCallsList) => onCallsList.filter(({name}) => name !== e.target.id))
            setFHOnCallList((fhOnCallList) => fhOnCallList.filter(({name}) => name !== e.target.id))
        }
    }
    const handleFHOnCallListChange = (e, director) => {
        if(e.target.checked) {
            setFHOnCallList([...fhOnCallList, {name: director.name, funeralhome: e.target.id, directorId: director._id}])
            if(!onCallList.some(({name}) => {return director.name === name})) {
                setOnCallList([...onCallList, {name: director.name, directorId: director._id}])
            }
        } else if(!e.target.checked) {
            setFHOnCallList(fhOnCallList.filter((({funeralhome, name}) => {
                if(fhOnCallList.filter(({funeralhome: fhname}) => {return fhname === funeralhome}).length <= 1 && takeOffCallList.length > 0) {
                    setTakeOffCallList(takeOffCallList.filter(({funeralhome: fhname}) => {return fhname !== funeralhome}))
                } 
                else if(fhOnCallList.filter(({name}) => name === director.name).length === 1) {
                    setOnCallList(onCallList.filter(({name}) => {return director.name !== name}))
                } 
                if(funeralhome === e.target.id && name === director.name) {   
                    return false
                } else {
                    return true
                }
            })))
        }
    }
    const handleBulkOnCallSubmit = async () => {
        const directors = onCallList
        const funeralhomes = fhOnCallList
        const replacedDirectors = takeOffCallList
        console.log(funeralhomes)
        const dotw = Date().split(' ')[0]
        var time = dateFormat(Date())
        time = dotw + " " + time
        const data = directors.map(({name, note, directorId}) => {
            const fhnames = []
            funeralhomes.forEach(({name: fhname, funeralhome: fh}) =>{
                if(name === fhname) {
                    fhnames.push(fh)
                }
            })
            return {name, fhnames, time, note, directorId}
        })
        data.forEach(async (info) => {
            try {
                await putOnCallForMultiFH({variables: info})
                await handleNotes({variables: {director: info.name, note: info.note}})
            } catch (e) {
                console.log(e)
            }
        })
        replacedDirectors.forEach(({funeralhome, oncalls}) => {
            oncalls.forEach(async (director) => {
                try {
                    await removeOnCallForFH({variables: {directorId: director._id, name: director.name, fhname: funeralhome}})
                } catch (error) {
                    console.log(error)
                }
            })
        })
        setTakeOffCallList([])
        setOnCallList([])
        setFHOnCallList([])
    }
    const checkIfOnCallForAffiliate = (funeralhome, director) => {
        var isOnCallForAffiliates = !funeralhome.on_calls.currentOnCalls.some(oncall => {
            return oncall.name === director.name
        })
        return isOnCallForAffiliates
    }
    const checkIfOnCallForFH = (director) => {
        var isOnCallForFH = director.funeralhome.on_calls.currentOnCalls.length === 0 || !director.funeralhome?.on_calls.currentOnCalls.some(oncall => {
            return oncall.name === director.name
        })
        return isOnCallForFH
    }
    const handleNoteChange = (e) => {
        setNoteFormData(e.target.value)
    }
    const handleNoteSubmit = (e, directorname) => {
        e.preventDefault()
        setOnCallList(onCallList.map((oncall) => {
            if(oncall.name === directorname) {
                return {...oncall, note: noteFormData}
            }
            return oncall
        }))
        setNoteMode({director: ''})
        setNoteFormData("")
    }
    const checkifFHChecked = (director, fh) => {
        return fhOnCallList.some(({name, funeralhome}) => {return director.name === name && fh.name === funeralhome})
    }
    const checkifDirectorChecked = (director) => {
        return onCallList.some(({name})=> {return name === director.name})
    }
    const handleReplaceOldOnCall = (e, funeralhome) => {
        if(e.target.checked) {
            setTakeOffCallList([...takeOffCallList, {funeralhome: funeralhome.name, oncalls: funeralhome.on_calls.currentOnCalls}])            
        } else {
            setTakeOffCallList((list) => list.filter(({funeralhome: fh}) => {return fh !== funeralhome.name}))
        }
    }
    const checkIfReplaceable = (funeralhome) => {
        if(funeralhome.on_calls.currentOnCalls.length === 0) {
            return false
        }
        return !takeOffCallList.some(({funeralhome: fh}) => {
            return fh === funeralhome.name
        })
    }
    return (
            <Card>
                <Card.Header style={{textAlign: 'center'}}>
                   <h1> Select all Directors to put On Call</h1>
                   <p>Only Directors within the Director who can be put on List will be shown</p>
                   <p>If they have more then 1 Funeral home they will be listed and you will need to select which funeral home to put them on for</p>
                   </Card.Header>
                   <Card.Body style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                {Directors && Directors.map((director,) => (
                        <Card key={director._id} style={{width: '30vw'}}>
                                <Card.Header style={{textAlign: 'center'}}>
                                    {checkIfOnCallForFH(director) || director.funeralhome?.parentCompany?.funeralhomes?.some((funeralhome) =>checkIfOnCallForAffiliate(funeralhome, director)) ?
                                        <Form.Check label={"Director: " + director.name} id={director.name} checked={checkifDirectorChecked(director)} type='checkbox' onChange={(e) => handleOnCallListChange(e, director._id)}></Form.Check>
                                :<>
                                Director:{director.name}<br/>
                                </>
                                }
                                Funeral Home: {director.funeralhome?.name}<br/>
                                {onCallList.map(({name, note}) => (
                                    <>
                                    {name === director.name && (note !== undefined || '') &&
                                    <>
                                    Note: {note}
                                    </>
                                    }
                                    </>
                                ))}
                            </Card.Header>   
                            <Card.Body> 
                                {director.funeralhome?.parentCompany?.funeralhomes?.some((funeralhome) =>checkIfOnCallForAffiliate(funeralhome, director))|| checkIfOnCallForFH(director) ?
                                    <>
                                    {onCallList.some(({name}) => {return name === director.name}) && !fhOnCallList.some(({name}) => {return name === director.name})
                                        && 
                                        <Alert>
                                        Please Select a Funeral Home for this director
                                        </Alert>
                                        }
                                        {noteMode.director === director.name
                                        ?
                                        <Form id={director.name} onSubmit={(e) => {handleNoteSubmit(e, director.name)}}>
                                        Note Options:
                                        <Form.Control value={noteFormData} onChange={handleNoteChange} as='select'>
                                            <option value=''>Please Select a Note</option>
                                            <option>TFN</option>
                                            <option>On All Weekend</option>
                                            <option>Back Up</option>
                                        </Form.Control><br/>
                                        Custom: 
                                        <Form.Control autoFocus value={noteFormData} onChange={handleNoteChange}>
                                        </Form.Control>
                                        <Button type="submit">Submit Note</Button>
                                        </Form> 
                                        :
                                        <ListGroup>
                                            
                                            <ListGroup.Item>
                                            {checkIfOnCallForFH(director) ? 
                                                <>
                                                {director.funeralhome?.name}
                                                <Form.Check style={{width: '100%'}} label={"Put On Call"} id={director.funeralhome?.name} checked={checkifFHChecked(director, director.funeralhome)} onChange={(e) => handleFHOnCallListChange(e, director)} type='checkbox'></Form.Check> 
                                                {checkifFHChecked(director, director.funeralhome) && checkIfReplaceable(director.funeralhome) &&
                                                <Form.Check onChange={(e) => handleReplaceOldOnCall(e, director.funeralhome)} label={"Replace Current On Call"}></Form.Check>
                                                }
                                                </>
                                                :
                                                <>
                                                On For {director.funeralhome?.name}<br/>
                                                </>
                                            }
                                            </ListGroup.Item>
                                            {director.funeralhome?.parentCompany?.funeralhomes?.length > 0 && 
                                                <>
                                            {director.funeralhome?.parentCompany?.funeralhomes?.map((funeralhome) => (
                                                <ListGroup.Item key={funeralhome._id}>
                                                {checkIfOnCallForAffiliate(funeralhome, director) ?
                                                <>
                                                {funeralhome.name}
                                                <Form.Check label={"Put On Call"} type='checkbox' id={funeralhome.name} checked={checkifFHChecked(director, funeralhome)} onChange={(e) => handleFHOnCallListChange(e, director)}></Form.Check>
                                                {checkifFHChecked(director, funeralhome) && checkIfReplaceable(funeralhome) && 
                                                <Form.Check onChange={(e) => handleReplaceOldOnCall(e, funeralhome)} label={"Replace Current On Call"}></Form.Check>
                                                }
                                                </>
                                                : 
                                                <>
                                                On Call For {funeralhome.name}<br/>
                                                </>
                                                }
                                                </ListGroup.Item>
                                            ))
                                            }
                                            </>
                                            }
                                            </ListGroup>  
                                        }
                                    </>
                                    :
                                    <Alert>
                                        Director is currently on for all Funeral homes
                                    </Alert>
                                    }    
                                </Card.Body>
                        {onCallList.some(({name}) => {
                            return name === director.name
                        }) &&
                        <Card.Footer style={{textAlign: 'center'}}>
                            <Button onClick={() => {
                                if(noteMode.director === director.name) {
                                    setNoteMode({director: ''})
                                } else {
                                    setNoteMode({director: director.name})
                                }
                            }}>
                            Toggle Add Note for Director
                            </Button>
                        </Card.Footer>
                            }
                        </Card>
                    ))}
                    {takeOffCallList.length > 0 &&
                    <Card style={{width: '30vw', textAlign: 'center'}}>
                        <Card.Header>
                            <h5>
                            Funeral Homes On Calls that will be replaced:
                            </h5>
                        </Card.Header>
                        <Card.Body>
                            {takeOffCallList.map(({funeralhome, oncalls}) => (
                                <Card>
                                    <Card.Header>
                                        {funeralhome}<br/>
                                        Directors to be Replaced:
                                    </Card.Header>
                                    <Card.Body>
                                        <ListGroup>
                                            {oncalls.map((director) => (
                                                <ListGroup.Item>
                                                    {director.name}
                                                </ListGroup.Item>
                                            ))}
                                        </ListGroup>
                                    </Card.Body>
                                    <Card.Footer>
                                            <Button onClick={() => setTakeOffCallList(takeOffCallList.filter((fh) => {
                                                return fh.funeralhome !== funeralhome
                                            }))}>Cancel Replacement</Button>
                                        </Card.Footer>
                                </Card>
                            ))}
                        </Card.Body>
                    </Card>
                    }
                    </Card.Body>
                    {onCallList.length > 0 &&
                    <Card.Footer style={{textAlign: 'center'}}>
                        <Button onClick={handleBulkOnCallSubmit}>Toggle All Selected Directors On Call {takeOffCallList.length > 0 && 'and Replace All Selected Directors'}</Button>
                    </Card.Footer>    
                    }
            </Card>
    )
}
export default BulkOnCalls