import React from "react";
import Feedbacklist from "../components/FeedbackList";
import FeedbackForm from "../components/FeedbackForm";
import FeedbackUserList from "../components/FeedbackUserList";
const Feedback = ({ currentUser }) => {
  return (
    <>
      <FeedbackForm currentUser={currentUser}></FeedbackForm>
      {!currentUser.adminStatus ? (
        <Feedbacklist></Feedbacklist>
      ) : (
        <FeedbackUserList currentUser={currentUser}></FeedbackUserList>
      )}
    </>
  );
};
export default Feedback;
