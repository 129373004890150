import React, {useState} from 'react'
import { Card, Button} from 'react-bootstrap'
import FuneralHomeOption from '../FuneralHomeOptions'
import { useMutation } from '@apollo/client'
import { HANDLE_DIRECTOR_WITH_NO_FH} from '../../utils/mutations'
import dateFormat from '../../utils/dateFormat'
const DirectorWithNoFHCard = ({director, currentUser}) => {
    const [editData, setEditData] = useState({funeralhome: 'none'})
    const [editDirector] = useMutation(HANDLE_DIRECTOR_WITH_NO_FH)
    const handleChange = (e) => {
        setEditData({...editData, [e.target.id]: e.target.value})
    }
    const handleSubmit = async () => {
        try {
            await editDirector({variables: {directorId: director._id, fhname: editData.funeralhome, editTime: dateFormat(new Date()), userId: currentUser._id}})
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <Card style={{margin: '5px', borderRadius: '1vw', width: '20vw', justifyContent: 'center', display:'flex', flexWrap: 'wrap'}}>
            <Card.Header>
                {director.name}
            </Card.Header>
            <Card.Body>
                <FuneralHomeOption onChange={handleChange} value={editData.funeralhome}>
                </FuneralHomeOption>
                <Button onClick={() => handleSubmit(director)}>Submit Funeral Home Change</Button>
            </Card.Body>
        </Card>
    )
}
const DirectorWithNoFHList = ({directors, style, currentUser}) => {
    return (
        <Card style={style}>
            <Card.Header>
                Directors with no funeral homes
            </Card.Header>
            <Card.Body style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly'}}>
                {directors.map((director) => (
                    <DirectorWithNoFHCard key={director._id} director={director} currentUser={currentUser}></DirectorWithNoFHCard>
                ))}
            </Card.Body>
        </Card>
    )
}
export default DirectorWithNoFHList