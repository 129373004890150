function generatePages(data, pageSize) {
    var pages = [];
    if(data.length > pageSize) {
        var size = pageSize
        for (var i=0; i<data.length; i+=size) {
        pages.push(data.slice(i,i+size));
        }
    } else {
        pages = [data]
    }
    return pages
}
module.exports = generatePages