import React, {useState} from 'react'
import { Form, Card, Button, Dropdown} from 'react-bootstrap'
import FuneralHomeOption from "../FuneralHomeOptions";
import { useMutation } from "@apollo/client";
import {PUT_ON_CALL_FOR_FH, REMOVE_ON_CALL_FOR_FH, PUT_ON_CALL_FOR_MULTI_FH, HANDLE_NOTES, REMOVE_ON_CALL_FOR_MULTI_FH, SET_DEFAULT_DIRECTOR, REMOVE_FROM_DIRECTOR_POOL} from "../../utils/mutations";
import dayjs from "dayjs";
const DirectorPoolCard = ({director, funeralhome, closeDirectorList}) => {
    const [affiliateOnCall, setAffiliateOnCall] = useState(funeralhome.name)
    const [onCallForMultipleMode, setOnCallForMultipleMode] = useState(false)
    const [multipleFHList, setMultipleFHList] = useState([])
    const [putOnCallForFH] = useMutation(PUT_ON_CALL_FOR_FH)
    const [removeOnCallForFH] = useMutation(REMOVE_ON_CALL_FOR_FH)
    const [putOnCallForMultiFH] = useMutation(PUT_ON_CALL_FOR_MULTI_FH)
    const [removeOnCallForMultiFH] = useMutation(REMOVE_ON_CALL_FOR_MULTI_FH)
    const [setDefaultDirector] = useMutation(SET_DEFAULT_DIRECTOR)
    const [removeFromDirectorPool] = useMutation(REMOVE_FROM_DIRECTOR_POOL)
    const [handleNotes] = useMutation(HANDLE_NOTES)
    const onCallAffiliates = (funeralhome.parentCompany !== null && funeralhome.parentCompany.funeralhomes.length > 1) ?  funeralhome.parentCompany.funeralhomes.filter((affiliate) => {
        if(affiliate.useOnCallForAffiliate !== null && affiliate.useOnCallForAffiliate._id === funeralhome._id) {
            return true
        } else {
            return false
        }
    }) : []
    const parentCompanyFuneralHomes = (funeralhome.parentCompany !== null && funeralhome.parentCompany.funeralhomes.length > 1) ? funeralhome.parentCompany.funeralhomes : []
    const setOnCall = async (e, funeralhome, defaultNote) => {
        const directorId = e.target.id
        const time = dayjs().format('ddd MM DD YYYY [at] hh:mm a')
        if(defaultNote !== '') {
            handleNotes({variables: {note: defaultNote, director: e.target.id}})
        } 
        if(funeralhome === "funeralhomeoption") {
            funeralhome = affiliateOnCall 
            //  Here to test clear all non current on calls
            // const dotw = 'Fri'
            // const time = "1 16 2023 at 6:03 pm"
            await putOnCallForFH({variables: {directorId, fhname: funeralhome, time}})
            } else if(!funeralhome.on_calls.currentOnCalls.some((director) => {return director._id  === directorId})) {
        //  Here to test clear all non current on calls
        // const time = "1 16 2023 at 6:03 pm"
            await putOnCallForFH({variables: {directorId, fhname: funeralhome.name, time}})
        } else {
            await removeOnCallForFH({variables: {directorId, fhname: funeralhome.name}})
        }
    closeDirectorList()
    }
    const handleAffiliateChange = (e) => {
        setAffiliateOnCall(e.target.value)
    }
    const checkOnCallForAllFH = () => {
        return funeralhome.on_calls.length === 0 || !funeralhome.on_calls.currentOnCalls.some((d) => {
            return d._id === director._id
        })
    }
    const handleMultiChange = (e) => {
        if(multipleFHList.includes(e.target.value)) {
            const changedList = multipleFHList.filter((fh) => fh !== e.target.value)
            setMultipleFHList(changedList)
        } else {
            setMultipleFHList([...multipleFHList, e.target.value])
        }
    }
    const handleMultiSubmit = async (e) => {
        e.preventDefault()
        const name = e.target.id
        const fhnames = multipleFHList
        const time = dayjs().format('ddd MM DD YYYY [at] hh:mm a')
        try {
            await putOnCallForMultiFH({variables: {directorId: director._id, fhnames, name, time}})
            closeDirectorList()
        } catch (error) {
            console.log(error)
        }
    }
    const checkIfOnCallForMulti = (director, funeralhome) => {
        if(funeralhome.on_calls?.currentOnCalls?.length === 0) {
            var oncall = true
        } else {
            oncall = !funeralhome.on_calls?.currentOnCalls?.some(oncall => {return oncall.name === director.name}) 
        }
        return oncall
    }
    const handleMultiRemoveOnCall = async () => {
        const directorId = director._id
        try {
            await removeOnCallForMultiFH({variables: {directorId, fhnames: funeralhome.parentCompany === null ? [funeralhome.name]: funeralhome.parentCompany.funeralhomes.map(({name}) => {return name})}})            
            closeDirectorList()
        } catch(error) {
            console.log(error)
        }
    }
    const handleMultiPutOncall = async (fhnames) => {
        const directorId = director._id
        const time = dayjs().format('ddd MM DD YYYY [at] hh:mm a')
        try {
            await putOnCallForMultiFH({variables: {directorId, fhnames, time}})
            closeDirectorList()
        } catch (error) {
            console.log(error)
        }
    }   
    const handleToggleDefaultDirector = async (funeralhome, {_id: directorId, defaultDirector: defaultStatus}) => {
        const time = dayjs().format('ddd MM DD YYYY [at] hh:mm a')
        try {
            await setDefaultDirector({variables: {directorId, time, defaultStatus: !defaultStatus}})
            if(onCallAffiliates.length > 0) {
                await putOnCallForMultiFH({variables: {directorId, fhnames: [...onCallAffiliates.map(({name})=> {return name}), funeralhome.name], time}})
            } else {
                await putOnCallForMultiFH({variables: {directorId, fhnames: [funeralhome.name], time}})
            }
        } catch (error){
            console.log(error)
        }
        closeDirectorList()
    }
    const generateLink = (primary_phone) => {
        if(primary_phone.split('-').length > 1) {
            return "https://voice.google.com/u/1/messages?itemId=t.%2B1" + primary_phone.split('-')[0] + primary_phone.split('-')[1] + primary_phone.split('-')[2] 
        }
        return 'https://voice.google.com/u/1/messages'
    }
    const handleRemoveFromDirectorPool = async () => {
        try {
            await removeFromDirectorPool({variables: {id: funeralhome._id, directorId: director._id}}).then(({data}) => {})
        } catch (error) {
            console.log(error)
        }
    }
    const onCallForFH = funeralhome.on_calls.length > 0 || funeralhome.on_calls.currentOnCalls.some((d) => {return director._id === d._id})
    return (
        <Card key={director._id}
        style={{margin: '5px', borderRadius: '1vw', width: '25vw', justifyContent: 'center', display:'flex', flexWrap: 'wrap'}}
        >
        {funeralhome.parentCompany?.funeralhomes?.length === 0 ?
        <Card.Header>{funeralhome.on_calls.defaultDirector?.some(({_id}) => {return _id === director._id}) && "Default "}Director</Card.Header> :
        <>
        {funeralhome?.name !== director.funeralhome?.name ? <Card.Header>Affiliate Director for {director.funeralhome?.name}</Card.Header>:
        <Card.Header>Director for {funeralhome.name}</Card.Header>  }
        </>
        }
        <Card.Body>
        Name: {director.name}
        <br/>
        Primary Contact Method: {director.primary_phone} <br/>
        {director.primary_phone &&
        <>
        <a target={'_blank'} rel='noreferrer' href={generateLink(director.primary_phone)}>Open Google Voice</a><br/>
        </>
        }
        <>Preffered Contact Type: {director.contact_method}<br/></>
        {(funeralhome !== null && funeralhome !== "Directors with No Funeral Home") &&
        <>
        {director.defaultNote !== ''&& <>Default Note: {director.defaultNote}<br/></>}
        {!director.defaultDirector &&
        <>
        {onCallAffiliates.length > 0 ?
                <>
                {checkOnCallForAllFH() ?
                    <Card>
                    <Card.Header>
                    Put on for {funeralhome.name} {onCallAffiliates.map(({name, _id}) => (<div key={_id}>& {name}</div>))}
                    </Card.Header>
                    <Card.Body>
                    <Button onClick={() => handleMultiPutOncall([...onCallAffiliates.map(({name}) => {return name}), funeralhome.name])}>
                    Put On Call for Listed Funeral Homes
                    </Button>
                    </Card.Body>
                    </Card>:
                    <Card>
                    <Card.Header>
                    Take off for {funeralhome.name} {onCallAffiliates.map(({name, _id}) => (<div key={_id}>& {name}</div>))}
                    </Card.Header>
                    <Card.Body>
                    <Button onClick={() => handleMultiRemoveOnCall()}>
                    Take Off Call for All Funeral Homes
                    </Button>
                    </Card.Body>
                    </Card>
                }
                </>
                :
                <>
                 {onCallForMultipleMode ? 
                    <Card.Body>
                    <Form id={director._id} onSubmit={handleMultiSubmit}>
                        <Form.Group style={{justifyContent: 'center'}}>
                        {parentCompanyFuneralHomes && parentCompanyFuneralHomes.map((affiliate) => (
                        <div key={affiliate._id}>
                        {checkIfOnCallForMulti(director.name, affiliate) &&
                        <Form.Check label={affiliate.name} type="checkbox" onChange={handleMultiChange} checked={multipleFHList.includes(affiliate.name)} value={affiliate.name}></Form.Check>
                        }
                        </div>
                    ))}
                </Form.Group>
                <Button type='submit'>Submit</Button>
                </Form>
                <Button onClick={() => setOnCallForMultipleMode(false)}>Cancel</Button>
                </Card.Body>:
                    <>
                    {parentCompanyFuneralHomes.length === 0 ?
                    <>
                    {onCallForFH ? 
                    <Card.Body>
                        On Call For All The Funeral Homes Available<br/>
                        <Button id={director._id} onClick={(e) => setOnCall(e, funeralhome, director.defaultNote)}>Take Off Call</Button>
                    </Card.Body>:
                    <Card.Body>
                        <Button id={director._id} onClick={(e) => setOnCall(e, funeralhome, director.defaultNote)}>Put On Call</Button> 
                    </Card.Body>
                        }
                    </>
                    :
                    <Card.Body>
                    Put on Call for which Funeral Home?
                    <FuneralHomeOption fhList={parentCompanyFuneralHomes} value={funeralhome.name} currentFHPage={funeralhome.name} affiliates={onCallAffiliates} onChange={handleAffiliateChange}></FuneralHomeOption>
                    <Dropdown>
                    <Button id={director._id} onClick={(e) => setOnCall(e, "funeralhomeoption", director.defaultNote)}>Put On Call</Button>
                    <Dropdown.Toggle>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setOnCallForMultipleMode(true)}>Put On For Multiple Funeral Homes?
                    </Dropdown.Item>
                    </Dropdown.Menu>
                    </Dropdown>
                    </Card.Body>
                    }
                    </>
                    }
                </>
                }
            </>
            }
        </>
        }
        </Card.Body>
        <Card.Footer>
        {director.defaultDirector ?
        <Button onClick={() => {closeDirectorList()}}>Remove as a Default Director</Button>
        :
        <Button onClick={() => handleToggleDefaultDirector(funeralhome, director)}>Add as a Default Director</Button>
        }
        <Button onClick={handleRemoveFromDirectorPool}>Remove From Director Pool</Button>
        </Card.Footer>
    </Card> 
    )
}
export default DirectorPoolCard