import React, {useState} from 'react'
import {useMutation } from '@apollo/client'
import { REMOVE_FULL_SCHEDULE_FOR_FH } from "../../utils/mutations";
import {Alert, Card, Button} from 'react-bootstrap'
import ScheduledDirectorsCard from '../ScheduledDirectorsCard';
const ScheduledDirectors = ({Funeralhome}) => {
    const [clearAlert, setClearAlert] = useState(false)
    const [clearSchedule] = useMutation(REMOVE_FULL_SCHEDULE_FOR_FH)
    const [removeAlert, setRemoveAlert] = useState({status: false})
    const handleScheduleClear = () => {
        try {
            clearSchedule({variables: {funeralhomename: Funeralhome.name}})
            setClearAlert(false)
            // window.location.reload()
        } catch (error) {
            console.log(error)
        }
    }
    if(Funeralhome.on_calls?.scheduledDirectors?.length === 0) {
        return (
            <Card>
                <Card.Body>
                    No Directors Have been scheduled
                </Card.Body>
            </Card>
        )
    }
    return (
            <Card>
            <Card.Header style={{textAlign: 'center'}}>
                <h2>
                    Schedule
                </h2>
                </Card.Header>
                <Card.Body>
            <div style={{display:'flex', flexWrap: 'wrap', width: '100%', textAlign: 'center', justifyContent: 'center'}}>                
                {Funeralhome.on_calls?.scheduledDirectors && Funeralhome.on_calls?.scheduledDirectors.map((schedule) => (
                    <ScheduledDirectorsCard Funeralhome={Funeralhome} key={schedule._id}  setRemoveAlert={(data) => setRemoveAlert(data)} schedule={schedule}></ScheduledDirectorsCard>
                ))
                }
            </div>
            <Alert show={removeAlert.status}>{removeAlert.text}</Alert>
            <Alert show={clearAlert} className='Alert'>Are you sure you would like to clear the whole schedule? All current schedules will be deleted. Page will be refreshed when cleared</Alert>
            </Card.Body>
            <Card.Footer style={{textAlign: 'center'}}>
            {Funeralhome?.on_calls?.scheduledDirectors?.length > 0 &&
                <>
            {clearAlert ?
            <>
            <Button onClick={handleScheduleClear}>CLEAR WHOLE SCHEDULE</Button>
            <Button onClick={() => setClearAlert(false)}>Cancel Clear Schedule</Button>
            </>
            :
            <Button onClick={() => setClearAlert(true)}>Clear Schedule?</Button>
            }</>
            }
            </Card.Footer>
            </Card>
    )
}
export default ScheduledDirectors