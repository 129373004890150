import React, { useState } from "react";
import { Card, Form, Button, Alert } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import {
  RECEIVED_FIRST_CALL,
  DELETE_FIRST_CALL,
  EDIT_NOTIFICATION_PROCESS,
  SEND_FIRST_CALL_EMAIL,
} from "../../utils/mutations";
import jsPDF from "jspdf";
import firstcallsheet from "../../utils/generatefcsheet";
const FirstCallCard = ({ firstcall, funeralhomes, currentUser }) => {
  const [receivedFirstCall] = useMutation(RECEIVED_FIRST_CALL);
  const [editNotificationProcess] = useMutation(EDIT_NOTIFICATION_PROCESS);
  const [deletefc] = useMutation(DELETE_FIRST_CALL);
  const [sendFirstCallEmail] = useMutation(SEND_FIRST_CALL_EMAIL);
  const [directorNotified, setDirectorNotified] = useState({ status: false });
  const [confirmDelete, setConfirmDelete] = useState({
    status: false,
    index: 200000,
  });
  const [notificationEdit, setNofiicationEdit] = useState({
    data: [...firstcall.notified],
    status: false,
  });
  const [formData, setFormData] = useState({
    director: "",
    time: "",
    process: "",
  });
  const handleDataNull = (data) => {
    if (data === null || data === "" || data === undefined) {
      return "N/A";
    } else {
      return data;
    }
  };
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };
  const generateFCData = (type) => {
    var fcData = { ...firstcall };
    if (type === "edit") {
      fcData.ppName = fcData.primaryPhysician?.name || "";
      fcData.ppNumber = fcData.primaryPhysician?.clinicPhone || "";
      fcData.ppFax = fcData.primaryPhysician?.fax || "";
      fcData.nokName = fcData.NOK?.name || "";
      fcData.nokRelationship = fcData.NOK?.relationship || "";
      fcData.nokNumber = fcData.NOK?.phone || "";
      fcData.nokAltNumber = fcData.NOK?.altPhone || "";
      fcData.nokBeenNotified = fcData.NOK?.beenNotified || "";
    } else {
      fcData.ppName = fcData.primaryPhysician?.name || "";
      fcData.ppNumber = fcData.primaryPhysician?.clinicPhone || "";
      fcData.ppFax = fcData.primaryPhysician?.fax || "";
      fcData.NOKName = fcData.NOK?.name || "";
      fcData.NOKRelationship = fcData.NOK?.relationship || "";
      fcData.NOKNumber = fcData.NOK?.phone || "";
      fcData.NOKAltNumber = fcData.NOK?.altPhone || "";
      fcData.NOKBeenNotified = fcData.NOK?.beenNotified || "";
    }
    fcData.callerName = fcData.callerInfo?.name || "";
    fcData.callerNumber = fcData.callerInfo?.number || "";
    fcData.callerType = fcData.callerInfo?.type || "";
    fcData.meInvolved = fcData.ME.involved;
    fcData.meName = fcData.ME.name;
    fcData.meRelease = fcData.ME.released;
    fcData.funeralhome = funeralhomes.filter((funeralhome) => {
      return funeralhome.name === fcData.funeralhome?.name;
    })[0];
    fcData.nod = fcData.NOD;
    fcData.tod = fcData.TOD;
    fcData.dob = fcData.DOB;
    fcData.cityorZip = fcData.cityorZIP;
    return fcData;
  };
  const resendEmail = () => {
    var fcData = generateFCData("edit");
    sendFirstCallEmail({
      variables: {
        ...fcData,
        funeralhome: fcData.funeralhome._id,
        firstcallId: firstcall._id,
      },
    });
  };
  const handleSubmit = () => {
    try {
      receivedFirstCall({ variables: formData }).then(() => {
        setDirectorNotified({ status: false });
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleEdit = () => {
    var fcData = generateFCData("edit");
    localStorage.setItem(
      "fcinfo",
      JSON.stringify({ ...fcData, firstcallID: firstcall._id })
    );
    localStorage.setItem("editmode", true);
    localStorage.setItem("fckey", "takeFirstCall");
    window.location.reload();
  };
  const deleteFirstCall = (id) => {
    try {
      deletefc({ variables: { firstcallID: id } });
      window.location.reload();
    } catch (error) {
      return error;
    }
  };
  const handlePrint = (updated) => {
    const fcData = generateFCData();
    const doc = new jsPDF();
    doc.text(firstcallsheet(fcData, updated), 10, 2, {
      lineHeightFactor: fcData.notes && fcData.notes.length > 60 ? 0.9 : 1,
    });
    doc.autoPrint();
    doc.output("dataurlnewwindow");
  };
  const handleNotifiedChange = (e, index) => {
    setNofiicationEdit({
      ...notificationEdit,
      data: notificationEdit.data.map((data, i) => {
        if (i !== index) {
          return data;
        } else {
          return { ...data, [e.target.id]: e.target.value };
        }
      }),
    });
  };
  const handleNotifiedRemoved = (index) => {
    setNofiicationEdit({
      ...notificationEdit,
      data: notificationEdit.data.filter((data, i) => {
        return i !== index;
      }),
    });
  };
  const handleEditNotification = () => {
    try {
      editNotificationProcess({
        variables: {
          firstcallId: firstcall._id,
          directors: notificationEdit.data.map(({ director }) => {
            return director;
          }),
          processes: notificationEdit.data.map(({ process }) => {
            return process;
          }),
          times: notificationEdit.data.map(({ time }) => {
            return time;
          }),
        },
      });
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Card style={{ width: "32vw", textAlign: "center" }}>
      <Card.Header>
        <h4>
          First Call For:{" "}
          {firstcall.funeralhome === null && "Funeral Home Removed"}{" "}
          {firstcall.funeralhome?.name} <br />
          Location: {firstcall.fhLocation}
        </h4>
      </Card.Header>
      <Card.Body>
        <b>Name Of Deceased:</b> {handleDataNull(firstcall.NOD)} <br />
        <b>Location of Deceased:</b> {handleDataNull(firstcall.location)}
        <br />
        <b>Rm/Apt/Fl:</b> {handleDataNull(firstcall.locationInfo)} <br />
        <b>Address of Location:</b>{" "}
        {handleDataNull(firstcall.addressOfLocation)} <b>City/Zip:</b>{" "}
        {handleDataNull(firstcall.cityorZIP)} <br />
        <b>Phone:</b> {handleDataNull(firstcall.locationNumber)}{" "}
        <b>Alt. Phone: </b>
        {handleDataNull(firstcall.locationAltNumber)} <br />
        <b>Next Of Kin:</b> {handleDataNull(firstcall.NOK.name)} <br />
        <b>Relationship:</b> {handleDataNull(firstcall.NOK.relationship)} <br />
        <b>Phone:</b> {handleDataNull(firstcall.NOK.phone)} <b>Alt. Phone:</b>{" "}
        {handleDataNull(firstcall.NOK.altPhone)} <br />
        <b>Has NOK Been Notified?</b>{" "}
        {handleDataNull(firstcall.NOK.beenNotified)} <br />
        <b>Is Family Present?</b> {handleDataNull(firstcall.familyPresent)}{" "}
        <b>Ready for Removal?</b> {handleDataNull(firstcall.readyForRemoval)}
        <br />
        <b>DOB:</b> {handleDataNull(firstcall.DOB)} <b>TOD:</b>{" "}
        {handleDataNull(firstcall.TOD)} <b>DOD:</b>{" "}
        {handleDataNull(firstcall.DOD)}
        <br />
        <b>Height:</b> {handleDataNull(firstcall.height)} <b>Weight:</b>{" "}
        {handleDataNull(firstcall.weight)} <br />
        <b>Notes:</b> {firstcall.notes === null ? "No Notes" : firstcall.notes}{" "}
        <br />
        {firstcall.ME.involved ? (
          <>
            <b>ME was involved,</b>{" "}
            {firstcall.ME.released ? (
              <>
                <b>ME has released, ME Name:</b> {firstcall.ME.name}
              </>
            ) : (
              <b>ME hasn't released</b>
            )}
          </>
        ) : (
          <b>ME wasn't involved</b>
        )}{" "}
        <br />
        <b>Taken at:</b> {firstcall.takenAt} Taken By: {firstcall.takenBy}{" "}
        <br />
        <b>Called in by:</b> {firstcall.callerInfo.name} (
        {firstcall.callerInfo.type}) {firstcall.callerInfo.number} <br />
        <b>Primary Physician:</b>{" "}
        {handleDataNull(firstcall.primaryPhysician.name)} <b>Clinic Phone:</b>{" "}
        {handleDataNull(firstcall.primaryPhysician.clinicPhone)} <b>Fax:</b>{" "}
        {handleDataNull(firstcall.primaryPhysician.fax)} <br />
        {firstcall.edited.map((edited, index) => (
          <div key={edited.time + index}>
            Update Time: {edited.time} <br />
            Updated By: {edited.by.username}
            <br />
          </div>
        ))}
        <br />
        {notificationEdit.status ? (
          <>
            {notificationEdit.data.map(({ director, time, process }, index) => (
              <div key={index}>
                <Form.Group>
                  Director:
                  <Form.Control
                    value={director}
                    onChange={(e) => handleNotifiedChange(e, index)}
                    id="director"
                    className="directorNotified"
                    style={{ width: "10vw", height: "3vh" }}
                  ></Form.Control>
                  Personally acknowledged this first call at:
                  <Form.Control
                    value={time}
                    onChange={(e) => handleNotifiedChange(e, index)}
                    id="time"
                    className="directorNotified"
                    style={{ width: "5vw", height: "3vh", fontSize: "1rem" }}
                  ></Form.Control>
                  How they were contacted:
                  <Form.Control
                    value={process}
                    onChange={(e) => handleNotifiedChange(e, index)}
                    id="process"
                    className="directorNotified"
                  ></Form.Control>
                </Form.Group>
                <Button onClick={() => handleNotifiedRemoved(index)}>
                  Remove
                </Button>
              </div>
            ))}
          </>
        ) : (
          <>
            {firstcall.notified.map((notified) => (
              <div key={notified.process + notified.director}>
                <>
                  Director: {notified.director} personally acknowledged
                  notification at {notified.time} <br />
                  Notification process: {notified.process}
                  <br />
                </>
              </div>
            ))}
          </>
        )}
        {firstcall.takenBy === currentUser.username &&
          firstcall.notified.length > 0 && (
            <>
              {notificationEdit.status &&
                notificationEdit.data !== firstcall.notified && (
                  <Button onClick={handleEditNotification}>
                    Submit Edited Notifcation Process
                  </Button>
                )}
              <Button
                onClick={() =>
                  setNofiicationEdit({
                    data: firstcall.notified,
                    status: !notificationEdit.status,
                  })
                }
              >
                Toggle Edit Notification Process
              </Button>
            </>
          )}
        {directorNotified.status && (
          <Form>
            <Form.Group>
              Director:
              <Form.Control
                value={formData.director}
                onChange={handleChange}
                id="director"
                className="directorNotified"
                style={{ width: "10vw", height: "3vh" }}
              ></Form.Control>
              Personally acknowledged this first call at:
              <Form.Control
                value={formData.time}
                onChange={handleChange}
                id="time"
                className="directorNotified"
                style={{ width: "5vw", height: "3vh", fontSize: "1rem" }}
              ></Form.Control>
              How they were contacted:
              <Form.Control
                value={formData.process}
                onChange={handleChange}
                id="process"
                className="directorNotified"
              ></Form.Control>
            </Form.Group>
          </Form>
        )}
      </Card.Body>
      <Card.Footer>
        {directorNotified.status ? (
          <>
            <Button
              onClick={() => handleSubmit(firstcall.notified.length !== 0)}
            >
              Submit Notification Process
            </Button>
            <Button onClick={() => setDirectorNotified({ status: false })}>
              Cancel
            </Button>
          </>
        ) : (
          <>
            {confirmDelete.status ? (
              <>
                <Button onClick={() => deleteFirstCall(firstcall._id)}>
                  Confirm Delete
                </Button>
                <Button onClick={() => setConfirmDelete({ status: false })}>
                  Cancel Delete
                </Button>
              </>
            ) : (
              <>
                <Button onClick={() => handleEdit()}>Edit First Call</Button>
                {firstcall.notified.length > 0 && (
                  <>
                    <Button
                      onClick={() =>
                        handlePrint(firstcall.notified.length !== 1)
                      }
                    >
                      Print
                    </Button>
                    <Button
                      onClick={() =>
                        resendEmail(firstcall.notified.length !== 1)
                      }
                    >
                      Resend First Call Email
                    </Button>
                  </>
                )}
                <Button onClick={() => setConfirmDelete({ status: true })}>
                  Delete First Call
                </Button>
              </>
            )}
          </>
        )}
        {(firstcall.notified.length === 0 ||
          firstcall.edited.length > firstcall.notified.length) &&
          !confirmDelete.status &&
          !directorNotified.status && (
            <Button
              onClick={() => {
                setDirectorNotified({ status: true });
                setFormData({
                  firstcallId: firstcall._id,
                  time: "",
                  process: "",
                  director: "",
                });
              }}
            >
              Been Received
            </Button>
          )}
        <Alert show={firstcall.notified.length === 0}>
          You can only print once the first call has been noted as recieved by a
          director, this information is vital for our records to hold directors
          accountable.
        </Alert>
        <Alert>{firstcall.emailedNote}</Alert>
      </Card.Footer>
    </Card>
  );
};
export default FirstCallCard;
