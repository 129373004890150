import React from "react";
import { Spinner } from "react-bootstrap";
const Loading = ({page}) => {
    return (
    <div style={{fontSize: '2vh',zIndex: "200"}}>
        Loading {page}
        <Spinner animation="border">
        </Spinner>
    </div>
    )

}
export default Loading