import React, { useState } from "react";
import { Alert, Form, Button, Card } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import {CREATE_SERVICE,} from "../../../utils/mutations";
import dateFormat from "../../../utils/dateFormat";
const ServicesForm = ({ Funeralhome}) => {
  const [createService] = useMutation(CREATE_SERVICE);
  const [serviceData, setServiceData] = useState({
    funeralhome: Funeralhome.name,
    fhLocation: Funeralhome.locations.length > 0 ?  "none": "Funeral Home has no locations",
    name: ''
  });
  const [alert, setAlert] = useState({ status: false, text: "" });
  const handleChange = (e) => {
    setServiceData({ ...serviceData, [e.target.id]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createService({
        variables: { ...serviceData, timeTaken: dateFormat(new Date())},
      })
      setAlert({
        status: true,
        text: "Service has been added for " + serviceData.name,
      });
      setServiceData({funeralhome: Funeralhome.name,
        fhLocation: Funeralhome.locations.length > 0 ?  "none": "Funeral Home has no locations",
        name: ''})
    } catch (error) {
      console.log(error);
      setAlert({ status: true, text: error.message });
      console.log(alert);
    }
  };
    return (
        <Card>
          <Card.Header>
            Add A Service
          </Card.Header>
          <Card.Body>
            <Form>
              <Form.Group>
                <Form.Label>Name:</Form.Label>
                <Form.Control placeholder="John Doe" value={serviceData.name} onChange={handleChange} id="name"></Form.Control>
                <Alert variant="danger" show={serviceData.name === ''}>Name is required</Alert>
              </Form.Group>
              <Form.Group>
                <Form.Label>Location:</Form.Label>
                {Funeralhome.locations.length > 0 ?
                <Form.Control
                id="fhLocation"
                value={serviceData.fhLocation}
                onChange={handleChange}
                as="select"
              >
                <option value={"none"}>Please Select a Location</option>
                <option>Unknown</option>
                {Funeralhome &&
                  Funeralhome.locations.map((location) => (
                    <option key={location.Name}>{location.Name}</option>
                  ))}
              </Form.Control>:
              <Form.Control as='select'><option>Funeral Home has no locations</option></Form.Control>
                }
                <Alert variant="danger" show={serviceData.fhLocation === 'none'}>Please select a Location</Alert>
              </Form.Group>
              <Form.Group>
                <Form.Label>Extra Info:</Form.Label>
                <Form.Control
                  placeholder="Private / No Service / Direct Cremation"
                  onChange={handleChange}
                  id="extraInfo"
                  value={serviceData.extraInfo}
                ></Form.Control>
              </Form.Group>
            </Form>
          </Card.Body>
          <Card.Footer>
            <Button onClick={handleSubmit}>Submit Service</Button>
            <Alert
              dismissible
              onClose={() => setAlert({ status: false })}
              show={alert.status}
            >
              {alert.text}
            </Alert>
          </Card.Footer>
        </Card>
    );
};
export default ServicesForm;
