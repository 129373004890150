import React, { useState } from "react";
import Auth from "../../utils/auth";
import { Navbar, Nav, NavDropdown, Badge } from "react-bootstrap";
import { QUERY_NOTIFICATION_NUMBERS } from "../../utils/queries";
import { useQuery } from "@apollo/client";
function Navigation({ currentUser, hidden }) {
  const loginStatus = Auth.loggedIn();
  const handleLogOut = () => {
    Auth.logout();
  };
  const { data, loading } = useQuery(QUERY_NOTIFICATION_NUMBERS, {
    variables: { userId: currentUser._id },
  });
  const notificationNumber = {
    firstcalls: data?.NotificationNumbers?.firstcalls|| 0,
    updates: data?.NotificationNumbers?.updates || 0,
  };
  const notificationValue =
    notificationNumber.firstcalls + notificationNumber.updates;

  const [pendingMessagesLength] = useState(
    JSON.parse(localStorage.getItem("pendingMessages"))?.length || 0
  );
  if (notificationValue !== 0) {
    window.document.title = `[${
      notificationValue + pendingMessagesLength
    }] Funeral Answering Network`;
  }
  const [activeKey] = useState(localStorage.getItem("navActiveKey") || "/");
  const handlePageChange = (key) => {
    localStorage.setItem("navActiveKey", key);
  };

  if (
    !loading &
    !(
      window.location.pathname.split("/").includes("FuneralHomes") ||
      window.location.pathname.split("/").includes("Messages") ||
      window.location.pathname.split("/").includes("Directors") ||
      window.location.pathname.split("/").includes("FirstCalls")
    )
  ) {
    const OtherNav = window.document.getElementById("OtherNav");
    if (!OtherNav.className.split(" ").includes("active")) {
      OtherNav.className = OtherNav.className + " active";
    }
    localStorage.setItem("navActiveKey", "/Other");
  }
  const handleNavClick = (e) => {
    if (e.target.id === "OtherNav") {
      const OtherNav = window.document.getElementById("OtherNav");
      OtherNav.className = OtherNav.className.split("active")[0];
    } else if (e.target.id === "FHNav") {
      const FHNav = window.document.getElementById("FHNav");
      FHNav.className = FHNav.className.split("active")[0];
    }
  };
  return (
    <Navbar hidden={!hidden} sticky="top" expand={true}>
      <Nav
        variant="tabs"
        onSelect={(key) => handlePageChange(key)}
        className=""
        activeKey={activeKey}
        defaultActiveKey={"FuneralHomes"}
        fill
        justify
        style={{ width: "100%" }}
      >
        <Nav.Item id="Messages">
          <Nav.Link href="/Messages">
            Messages{" "}
            {pendingMessagesLength === 0 ? (
              ""
            ) : (
              <Badge>{pendingMessagesLength}</Badge>
            )}
          </Nav.Link>
        </Nav.Item>
        <Nav.Item id="Directors">
          <Nav.Link href="/Directors">Directors/On Calls</Nav.Link>
        </Nav.Item>
        <Nav.Item id="Funeral Homes">
          <Nav.Link href="/FuneralHomes">Funeral Homes</Nav.Link>
        </Nav.Item>
        {/* {currentUser.adminStatus &&
            <NavDropdown onClick={handleNavClick} id="FHNav" title="FuneralHomes">   
            <div style={{overflow: "scroll", height: '30vh'}}>
            {funeralhomes.map(({name, _id}) => (
                <NavDropdown.Item key={_id} href={'/FuneralHomes/'+ name}>{name}</NavDropdown.Item>
            ))}
                </div>         
            </NavDropdown>
            }           */}
        <Nav.Item id="First Calls">
          <Nav.Link href="/FirstCalls">
            First Calls{" "}
            {notificationNumber.firstcalls === 0 ? (
              ""
            ) : (
              <Badge>{notificationNumber.firstcalls}</Badge>
            )}
          </Nav.Link>
        </Nav.Item>
        {/* {currentUser.adminStatus &&
            <Nav.Item id="StaffList"><Nav.Link href="/StaffList">Our Staff</Nav.Link></Nav.Item>            
            } */}
        <NavDropdown onClick={handleNavClick} id="OtherNav" title="Other">
          <NavDropdown.Item href="/">Home Page</NavDropdown.Item>
          <NavDropdown.Item href="/Info">General Information</NavDropdown.Item>
          <NavDropdown.Item id="Tutorial" href="/Tutorial">
            Tutorials
          </NavDropdown.Item>
          <NavDropdown.Item href="/Feedback">Feedback</NavDropdown.Item>
          <NavDropdown.Item href="/Updates">
            Updates{" "}
            {notificationNumber.updates === 0 ? (
              ""
            ) : (
              <Badge>{notificationNumber.updates}</Badge>
            )}
          </NavDropdown.Item>
          <NavDropdown.Item href="/ParentCompanies">
            Parent Companies
          </NavDropdown.Item>
          {currentUser.adminStatus && (
            <>
              <NavDropdown.Item id="Users" href="/Users">
                Users
              </NavDropdown.Item>
              {/* <NavDropdown.Item id="Profile" href="/Profile">Profile</NavDropdown.Item>*/}
              {/* <NavDropdown.Item id="Removals" href="/Removals">Removals</NavDropdown.Item>*/}
            </>
          )}
        </NavDropdown>
        {loginStatus && (
          <Nav.Item id="Logout">
            <Nav.Link onClick={handleLogOut} href="/Logout">
              Logout
            </Nav.Link>
          </Nav.Item>
        )}
      </Nav>
    </Navbar>
  );
}
export default Navigation;
