import React from 'react'
import ReactPlayer from 'react-player'
const TutorialVideoPlayer = ({url}) => {
    return (
        <div style={{justifyContent: 'center', display: 'flex'}}>
        <ReactPlayer controls={true} url={url}>
        </ReactPlayer>
        </div>
    )
}
export default TutorialVideoPlayer