import React, {useState, useEffect} from 'react'
import { Card, Button, Form, ListGroup, ListGroupItem} from 'react-bootstrap'
import { QUERY_FUNERALHOMES } from '../../utils/queries'
import { useQuery, useMutation } from '@apollo/client'
import { CREATE_PARENT_COMPANY } from '../../utils/mutations'
const ParentCompanyForm = ({handleAddPC}) => {
    const {data} = useQuery(QUERY_FUNERALHOMES)
    const [funeralhomelist, setFuneralhomelist] = useState([]) 
    const funeralhomes = data?.FuneralHomes
    useEffect(() => {
        const fh = funeralhomes || []
        setFuneralhomelist(fh)
    }, [setFuneralhomelist, funeralhomes])
    const [currentFH, setCurrentFH] = useState('none')
    const [formData, setFormData] = useState({name: '', funeralhomes: []})
    const [createParentCompany] = useMutation(CREATE_PARENT_COMPANY)
    const handleNameChange = (e) => {
        setFormData({...formData, name: e.target.value})
    }
    const handleFHChange = (e) => {
        setCurrentFH(JSON.parse(e.target.value))
    }
    const handleAddToFHList = () => {
        setFormData({...formData, funeralhomes: [...formData.funeralhomes, currentFH]})
        setFuneralhomelist(funeralhomelist.filter(({_id}) => {
            return currentFH.id !== _id 
        }))
    }
    const handleRemoveFromFHList = (e) => {
        setFormData({...formData, funeralhomes: formData.funeralhomes.filter(({id}) => {
            return id !== e.target.id
        })})
        const newFuneralhomelist = [...funeralhomelist, funeralhomes.filter(({_id}) => {
            return _id === e.target.id
        })[0]]
        var arrayForSort = newFuneralhomelist
        arrayForSort.sort(function(a, b){
            if(a.name == null || b.name == null) {
                return -200
            } 
            var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
            if (nameA < nameB) //sort string ascending
             {return -1;}
            if (nameA > nameB)
             {return 1;}
            return 0;
           });
        setFuneralhomelist(arrayForSort)
        setCurrentFH('none')
    }
    const handleAddParentCompany = async () => {
        try {
            await createParentCompany({variables: {name: formData.name, funeralhomes: formData.funeralhomes.map(({id}) => {
                return id
            })}}).then(({data}) => {
                setFormData({name: '', funeralhomes: []})
                setCurrentFH('none')
                setFuneralhomelist(funeralhomes)
                handleAddPC(data.createParentCompany)
            })
        } catch (error){
            console.log(error)
        }
    }
    return (
        <Card style={{textAlign: 'center'}}>
            <Card.Header>
                Parent Company Form
            </Card.Header>
            <Card.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>
                            Name:
                        </Form.Label>
                        <Form.Control placeholder='Company Name' value={formData.name} onChange={handleNameChange}>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Funeral Homes:
                        </Form.Label>
                        {funeralhomelist.length !== 0 ?
                        <>
                        <Form.Control as="select" value={JSON.stringify(currentFH)} onChange={handleFHChange}>
                            <option value={JSON.stringify({name: 'none'})}>
                                Please Select a Funeral Home
                            </option>
                            {funeralhomelist.map((funeralhome) => (
                                <option key={funeralhome._id} value={JSON.stringify({name: funeralhome.name, id: funeralhome._id})}>{funeralhome.name}</option>
                            ))}
                        </Form.Control>
                        <Button onClick={handleAddToFHList}>Add To Funeral Home List</Button>
                        </>
                        :<><br/>
                        Either All Funeral Homes have been selected / No Funeral Homes exist / Or There is an error
                        </>
                        }
                    </Form.Group>
                </Form>
                <Card>
                    <Card.Header>
                        List of Funeral Homes
                    </Card.Header>
                    <Card.Body>
                        {formData.funeralhomes.length === 0 ?
                        <>
                        No Funeral Homes Selected Yet</>
                        :
                        <ListGroup>
                        {formData.funeralhomes.map(({name, id}) => (
                            <ListGroupItem key={id}>
                                {name}<br/>
                                <Button id={id} onClick={handleRemoveFromFHList}>Remove</Button>
                            </ListGroupItem>                            
                        ))}
                        </ListGroup>
                        }
                    </Card.Body>
                </Card>
            </Card.Body>
            <Card.Footer>
                <Button onClick={handleAddParentCompany}>Submit</Button>
            </Card.Footer>
        </Card>
    )
}
export default ParentCompanyForm