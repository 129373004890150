import React, {useState} from 'react'
import {Button, Card, Alert} from 'react-bootstrap'
const FirstcallSavedList = ({savedFirstCall, handleClose, setFCData, setCallerType, setSavedFirstCalls}) => {
    const [alert, setAlert] = useState({status: false})
    const handleAlert = (type, index) => {
        setAlert({type, status: true, index})
    }
    const handleFCDataChange = (e) => {
        setFCData(savedFirstCall[e.target.id])
        setCallerType(savedFirstCall[e.target.id].callerType.split(':')[0])
        if(savedFirstCall.length === 1) {
        localStorage.setItem('fcinfo', JSON.stringify(savedFirstCall[0]))
        localStorage.setItem('savedFCs', JSON.stringify([]))
        } else {
        localStorage.setItem('fcinfo', JSON.stringify(savedFirstCall[e.target.id]))
        localStorage.setItem('savedFCs', JSON.stringify(savedFirstCall.splice(e.target.id, 1)))
        }
        if(savedFirstCall[e.target.id].editMode) {
            console.log("is Edit Mode")
            localStorage.setItem('editmode', true)
        }
        handleClose()
        window.location.reload()
    }
    const handleFCDataDelete = (e) => {
        const firstcall = e.target.id
        localStorage.setItem("savedFCs", JSON.stringify(savedFirstCall.filter((fc) => {
            return JSON.stringify(fc) !== firstcall
        })))
        setSavedFirstCalls(savedFirstCall.filter((fc) => {
            return JSON.stringify(fc) !== firstcall
        }))
        if(savedFirstCall.filter((fc) => {
            return JSON.stringify(fc) !== firstcall
        }).length === 0) {
            localStorage.removeItem('savedFCs')
            handleClose() 
        }
        setAlert({status: false})
    }
    return (
        <div  className="modal show" id="ModalBox">
        <div className="box">
            <span className="close-icon" onClick={handleClose}>x</span>
            <Card>
                <Card.Header style={{textAlign: 'center'}}>
                    <h1>Your Saved First Calls</h1>
                    <Alert>
                Only you are able to access First calls saved on this computer!
                </Alert>
                </Card.Header>
                <Card.Body style={{textAlign: 'center'}}>
                You have Saved {savedFirstCall.length} First Calls
                {savedFirstCall.map((firstcall, index) => (
                    <div key={index}>
                        {alert.status && alert.index === index ?
                        <>
                        {alert.type === 'Delete' &&
                        <>
                        Are you Sure you would like to delete First Call?
                        <Button id={JSON.stringify(firstcall)} onClick={handleFCDataDelete}>Delete First Call</Button> <Button onClick={() => setAlert({status: false})}>Cancel</Button>
                        </>
                        }
                        {alert.type === 'Override' &&
                        <>
                        Are you sure you would like to overwrite the current Fist Call Form? All data will be lost and first call will no longer be saved
                        <Button id={index} onClick={handleFCDataChange}>Overwrite First Call</Button> <Button onClick={() => setAlert({status: false})}>Cancel</Button>
                        </>
                        }
                        </>:
                        <>
                        {firstcall.editMode && <>Continue Editing </>}
                        Funeral Home: {firstcall.funeralhome.name || 'No Funeral Home'} Deceased: {firstcall.nod || 'No Deceased Name'} <Button onClick={() => handleAlert("Override", index)}>Continue With First Call</Button><Button onClick={() => handleAlert('Delete', index)}>Delete First Call</Button>
                        </>
                        }
                    </div>
                ))

                } 
                </Card.Body>
            </Card>
        </div>
        </div>
    )
}
export default FirstcallSavedList