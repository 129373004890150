import React, {useState} from 'react'
import FHDirectorList from "../FHDirectorList";
import dateFormat from "../../utils/dateFormat";
import FHDirectorPoolList from "../FHDirectorPoolList";
import { useMutation } from "@apollo/client";
import {REFRESH_ON_CALL} from "../../utils/mutations";
import SingleFHOnCalls from "../SingleFHOnCalls";
import { Card, Dropdown, ListGroup, ListGroupItem } from 'react-bootstrap'
const FuneralHomesOnCallsCard = ({funeralhome, currentUser, handleJumpToFH}) => {
    const [refreshOnCall] = useMutation(REFRESH_ON_CALL)
    const [currentDirectorList, setCurrentDirectorList] = useState('SingleFHOnCalls')
    const handleToggleOnCallDirectorList = () => {
        setCurrentDirectorList('DirectorPool')
    }
    const handleToggleFullDirectorList = () => {
        setCurrentDirectorList('DirectorList')
    }
    const handleMessageClick = () => {
        localStorage.setItem("messageInfo", JSON.stringify({funeralhome: JSON.stringify({...funeralhome}), messageText: '', callersNumber: '', callersName: ''}))
        localStorage.setItem('navActiveKey', "/Messages")
        localStorage.setItem('MessageCurrentPage', "Main Page")
        window.location.href = "./Messages"
    }
    const handleRefreshOnCallsForFH = (funeralhome) => {
        funeralhome.on_calls.currentOnCalls.forEach(async (director, index) => {
            const dotw = Date().split(' ')[0]
            const time = dateFormat(Date())
            try {
                await refreshOnCall({variables: {directorId: director._id, time: dotw + " " + time}})
            } catch(error) {
                console.log(error)
            }
            if(index === funeralhome.on_calls.length - 1) {
                window.location.reload()
            }
        })
    }
    const FHDirectorListArray = [...funeralhome.directors]
    funeralhome.parentCompany?.funeralhomes?.forEach((fh) => {
        if(fh.useOnCallForAffiliate !== null && fh.useOnCallForAffiliate._id === funeralhome._id) {
            fh.directors.forEach((director) => {
                FHDirectorListArray.push(director)
            })
        }
    })
    return (
        <Card className={"FuneralHome-Card"} key={funeralhome._id} id={funeralhome.name}>
        <Card.Header><h3><a href={'/Funeralhomes/' + funeralhome.name}>{funeralhome.name}</a></h3>                        
        {funeralhome.useOnCallForAffiliate !== null && <h5>Same on Call as {funeralhome.useOnCallForAffiliate.name}</h5>}
        <Dropdown>
            <Dropdown.Toggle>
                On Call Options
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleMessageClick()}>
                    Take Message
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleRefreshOnCallsForFH(funeralhome)}>
                    Refresh On Calls
                </Dropdown.Item>
                {funeralhome.useOnCallForAffiliate !== null && 
                <Dropdown.Item onClick={() => handleJumpToFH(funeralhome.useOnCallForAffiliate.name)}>
                    Jump To Affiliate On Call
                </Dropdown.Item>
                }
                {currentDirectorList !== 'SingleFHOnCalls' &&
                <Dropdown.Item onClick={() => setCurrentDirectorList('SingleFHOnCalls')}>
                Show On Call List
                </Dropdown.Item>
                }
                {!(currentDirectorList === 'DirectorPool')
                &&
                <Dropdown.Item onClick={() => handleToggleOnCallDirectorList()}>
                Show Director List
                </Dropdown.Item>
                }
                {!(currentDirectorList === 'DirectorList')
                &&
                <Dropdown.Item onClick={() => handleToggleFullDirectorList()}>
                Show Full Staff List
                </Dropdown.Item>
                }
            </Dropdown.Menu>
        </Dropdown>
        </Card.Header>
        <Card.Body style={{height: '47vh'}}>
            {currentDirectorList === 'DirectorPool' && <FHDirectorPoolList closeDirectorList={() => setCurrentDirectorList('SingleFHOnCalls')} currentUser={currentUser} funeralhome={funeralhome} directors={funeralhome.on_calls?.directorPool}/>                                                    }
            {currentDirectorList === 'DirectorList' && <FHDirectorList closeDirectorList={() => setCurrentDirectorList('SingleFHOnCalls')} currentUser={currentUser} funeralhome={funeralhome} directors={FHDirectorListArray}/>}
            {currentDirectorList === 'SingleFHOnCalls' && <SingleFHOnCalls funeralhome={funeralhome}/>}
        </Card.Body>
{funeralhome.on_calls.directorPool?.length > 0 &&
<Card.Footer>
<h6>List of Directors Names:</h6>
<div style={{overflow: 'auto', height: "5vh"}}>
<ListGroup>
{funeralhome.on_calls.directorPool?.length === 0 && funeralhome.useAffiliateOnCall
?
<>
{funeralhome.useAffiliateOnCall.on_calls.directorPool.map((director) => (
<ListGroupItem as='li' key={director._id}>
{director.name}
</ListGroupItem>
))}
</>
:
<>
{funeralhome.on_calls.directorPool.map((director) => (
<ListGroupItem as='li' key={director._id}>
    {director.name}
</ListGroupItem>
))}
</>
}
</ListGroup>
</div>
</Card.Footer>            
}
</Card>
    )
}
export default FuneralHomesOnCallsCard