import React from "react";
import {Button, OverlayTrigger} from 'react-bootstrap'
const PagesHandler = ({pages, page, setPage, previousBadge, nextBadge, pageMessage, jumpBadges, pageTitle}) => {
    return (
        <>  
            <h1>
                {pageTitle}
            </h1>
            {pages.length > 1 &&
            <>
             <h2>
                {pageMessage}
            </h2>
            <OverlayTrigger
                placement="bottom"
                overlay={previousBadge}>
                <Button disabled={page === 0} onClick={() => setPage(page - 1)}>Previous Page</Button>
            </OverlayTrigger>
            <OverlayTrigger
                placement="bottom"
                overlay={nextBadge}>
                <Button disabled={page === pages.length - 1} onClick={() => setPage(page + 1)}>Next Page</Button>
            </OverlayTrigger>
            {pages.length > 2 && 
            <>
            <h2>
            Jump Betwen Pages:
            </h2>
            <div style={{display:'flex', flexWrap: 'wrap', justifyContent: 'center', overflowY: 'scroll', height: '15vh'}}>
            {pages.map((pageInfo, index) => (
                <div key={index}>
                {page === index ?
                    <Button disabled style={{width: "11vw", margin: '.25vw'}}>
                    Current Page
                    </Button>
                    :
                    <OverlayTrigger  
                    delay={{show: 1, hide: 2}}
                    placement="bottom"
                    overlay={jumpBadges[index]}>
                    <Button style={{width: "11vw", margin: '.25vw'}} disabled={page === index} onClick={() => setPage(index)}>Jump to Page {index + 1}</Button>
                    </OverlayTrigger>
                }
                </div>
            ))}
            </div>
            </>
        }
        </>
        }
        </>
    )
}
export default PagesHandler