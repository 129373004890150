import React from "react";
import { Card, Accordion } from "react-bootstrap";
import FuneralHomesLocationCard from "../FuneralHomesLocationCard";
const LocationList = ({Funeralhome, currentUser, changeFuneralhomes}) => {
    return (
        <Card>
            <Card.Header>
                Location List
            </Card.Header>
            <Card.Body>
            {Funeralhome?.locations?.length > 0 ? 
            <Accordion style={{width: '100%'}}>
            {Funeralhome.locations && Funeralhome.locations.map((location) => (
                <div key={location._id}>
                        <Accordion.Item style={{width: "100%"}} eventKey={location.Name}>
                        <Accordion.Header>
                        <h4>
                        {location.Name} 
                        </h4>
                        </Accordion.Header>
                        <Accordion.Body>
                            <FuneralHomesLocationCard Funeralhome={Funeralhome} changeFuneralhomes={changeFuneralhomes} currentUser={currentUser} location={location}></FuneralHomesLocationCard>            
                        </Accordion.Body>
                        </Accordion.Item>
                </div>
            ))
            }
            </Accordion>
            :<>
            No Locations
            </>}
            </Card.Body>
        </Card>
    )
}
export default LocationList