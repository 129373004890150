import React, { useState, useEffect } from "react";
import dateFormat from "../../utils/dateFormat";
import { useQuery, useReactiveVar, makeVar } from "@apollo/client";
import MessageFHInfo from "../MessageFHInfo";
// import { ADD_MESSAGE, ARCHIVE_MESSAGE} from "../../utils/mutations";
import { Form, Button, Alert, Card } from "react-bootstrap";
import Loading from "../Loading";
import $ from "jquery";
import "jquery-mask-plugin/dist/jquery.mask.min";
import { QUERY_FUNERALHOMES } from "../../utils/queries";
import MessagePendingList from "../MessagePendingList";
import dayjs from "dayjs";
export const FuneralhomeVar = makeVar([]);
const MessageForm = ({
  currentUser,
  pendingMessages,
  handlePending,
  setMessageFormFH,
  answerListClicked,
  handleFHNameClicked,
  funeralhome,
  handleFHSelected,
  fh,
  fhSelected,
  handleAddedMessage,
}) => {
  const { data, loading } = useQuery(QUERY_FUNERALHOMES);
  useEffect(() => {
    if (!loading && data !== undefined) {
      FuneralhomeVar(data.FuneralHomes);
    }
  }, [data, loading]);
  const funeralHomes = useReactiveVar(FuneralhomeVar);
  var localstorage = { messageText: "", callersName: "", callersNumber: "" };
  if (JSON.parse(localStorage.getItem("messageInfo")) !== null) {
    localstorage = JSON.parse(localStorage.getItem("messageInfo"));
  }
  var time = dayjs().format("hh:MM a");
  var date = dayjs().format("MM/DD/YYYY");
  // date = "10/20/22"
  const [messageData, setMessageData] = useState({
    ...localstorage,
    messageTakenDate: date,
    messageTakenTime: time,
    messageStatus: "taken",
    author: currentUser.username,
    funeralhome: localstorage.funeralhome || funeralhome || "none",
    fhLocation: "",
    doTheyNeedACall: true,
  });
  const [textareaheight, setTextareaheight] = useState(
    localstorage.textareaheight || 1
  );
  const handleChange = (e) => {
    const id = e.target.id;
    const value = e.target.value;
    if (id === "messageText") {
      if (e.target.value === "") {
        setTextareaheight(1);
      } else {
        const height = e.target.scrollHeight;
        const rowHeight = 50;
        const trows = Math.ceil(height / rowHeight) - 1;
        if (trows && textareaheight) {
          setTextareaheight(trows);
        }
      }
    }
    if (id === "funeralhome") {
      localStorage.setItem(
        "messageInfo",
        JSON.stringify({ ...messageData, [id]: value, whoItsFor: "", fhLocation: "" })
      );
      setMessageData({ ...messageData, [id]: value, whoItsFor: "", fhLocation: ""});
    } else {
      setMessageData({ ...messageData, [id]: value });
      localStorage.setItem(
        "messageInfo",
        JSON.stringify({ ...messageData, [id]: value })
      );
    }
  };
  const addToPending = async (e) => {
    var tempMessageData = messageData;
    var time = dateFormat(Date()).split(" ");
    tempMessageData.messageTakenTime = time[4] + time[5];
    tempMessageData.requiredInfo = JSON.parse(
      messageData.funeralhome
    ).requiredMessageInfo;
    tempMessageData.funeralhome = JSON.parse(messageData.funeralhome).name;
    handleFHSelected(e, tempMessageData.funeralhome);
    tempMessageData.timeSubmitted = new Date();
    tempMessageData.recievedTime = new Date();
    handlePending([...pendingMessages, tempMessageData]);
    localStorage.setItem(
      "pendingMessages",
      JSON.stringify([...pendingMessages, tempMessageData])
    );
    setMessageData({
      ...messageData,
      messageText: "",
      funeralhome: "none",
      callersName: "",
      callersNumber: "",
      fhLocation: "",
      whoItsFor: "",
    });
    localStorage.setItem(
      "messageInfo",
      JSON.stringify({
        ...messageData,
        messageText: "",
        funeralhome: "none",
        callersName: "",
        callersNumber: "",
        fhLocation: "",
        whoItsFor: "",
      })
    );
  };
  const checkFHLocations = () => {
    if (
      messageData.funeralhome === "none" ||
      messageData.funeralhome === null ||
      messageData.funeralhome === undefined
    ) {
      return false;
    }
    if (messageData.funeralhome.split(" ").length > 0) {
      return JSON.parse(messageData.funeralhome).locations.length > 1;
    }
  };
  const checkFHDirectors = () => {
    if (
      messageData.funeralhome === "none" ||
      messageData.funeralhome === null ||
      messageData.funeralhome === undefined
    ) {
      return false;
    }
    if (messageData.funeralhome.split(" ").length > 0) {
      return (
        JSON.parse(messageData.funeralhome).directors.length > 1 ||
        JSON.parse(messageData.funeralhome).parentCompany?.funeralhomes.some(
          (affiliate) => affiliate.directors.length > 1
        )
      );
    }
  };

  const currentFHList = funeralHomes.filter((funeralhome) => {
    return funeralhome.status;
  });

  if (
    funeralhome !== "none" &&
    messageData.funeralhome !== funeralhome &&
    answerListClicked
  ) {
    setMessageFormFH();
    setMessageData({ ...messageData, funeralhome });
  }
  if (!loading) {
    $("#callersNumber").mask("(000)-000-0000", {
      placeholder: "(___)-___-___",
    });
  }
  return (
    <div>
      {loading && <Loading page="Message Form"></Loading>}
      <div className="col-md-12" style={{ display: "flex", flexWrap: "wrap" }}>
        <Card className="col-md-4">
          <Card.Header>
            <h3>Funeral Home:</h3>
            <Form.Control
              as="select"
              autoFocus
              value={messageData.funeralhome}
              id="funeralhome"
              onChange={(e) => {
                handleChange(e);
                handleFHSelected(e);
              }}
            >
              <option value="none">Select A Funeral Home</option>
              {currentFHList &&
                currentFHList.map((funeralhome) => (
                  <option
                    id="Test"
                    value={JSON.stringify(funeralhome)}
                    key={funeralhome._id}
                  >
                    {funeralhome.name}
                  </option>
                ))}
            </Form.Control>
            <Alert
              style={{ width: "100%" }}
              variant="danger"
              show={messageData.funeralhome === "none"}
            >
              Please Select a Funeral Home!
            </Alert>
            {checkFHLocations() && (
                <>
                  <Form.Label>
                    <h5>Location:</h5>
                  </Form.Label>
                  <Form.Control
                    className="formInput"
                    autoComplete="off"
                    value={messageData.fhLocation}
                    as="select"
                    onChange={handleChange}
                    placeholder="Location"
                    id="fhLocation"
                  >
                    {/* <option value="none">Please Select a Location</option> */}
                    <option value="">No Location Specified</option>
                    {JSON.parse(messageData.funeralhome).locations.map(
                      (location) => (
                        <option key={location.Name}>{location.Name}</option>
                      )
                    )}
                  </Form.Control>
                  {JSON.parse(messageData.funeralhome) &&
                    JSON.parse(
                      messageData.funeralhome
                    ).requiredMessageInfo.includes("Location") && (
                      <Alert
                        variant="danger"
                        style={{ width: "100%" }}
                        show={
                          messageData.fhLocation === undefined ||
                          messageData.fhLocation === ""
                        }
                      >
                        Location Is Required!
                      </Alert>
                    )}
                </>
              )}
          </Card.Header>
          <Card.Body>
            <Form>
              <>
                <Form.Label>
                  <h3>Who's Calling:</h3>
                </Form.Label>
                <Form.Control
                  className="formInput"
                  autoComplete="off"
                  required
                  value={messageData.callersName}
                  onChange={handleChange}
                  placeholder="Caller's Name"
                  id="callersName"
                ></Form.Control>
                <Alert
                  variant="danger"
                  style={{ width: "100%" }}
                  show={messageData.callersName === ""}
                >
                  Name is Required!
                </Alert>
              </>
              <>
                <Form.Label>
                  <h3>Caller's Number:</h3>
                </Form.Label>
                <Form.Control
                  className="mb-3"
                  autoComplete="off"
                  required
                  value={messageData.callersNumber}
                  onChange={handleChange}
                  placeholder="Caller's Number"
                  id="callersNumber"
                ></Form.Control>
                <Alert
                  variant="danger"
                  style={{ width: "100%" }}
                  show={messageData.callersNumber === ""}
                >
                  Phone Number is required!
                </Alert>
              </>
              {checkFHDirectors() && (
                <>
                  <Form.Label>
                    <h5>Who The Message is for:</h5>
                  </Form.Label>
                  <br />
                  <Form.Control
                    className="formInput"
                    autoComplete="off"
                    value={messageData.whoItsFor}
                    onChange={handleChange}
                    placeholder="Who it's For"
                    id="whoItsFor"
                  ></Form.Control>
                  {JSON.parse(
                    messageData.funeralhome
                  ).requiredMessageInfo.includes("Who It's For") && (
                    <Alert
                      variant="danger"
                      style={{ width: "100%" }}
                      show={
                        messageData.whoItsFor === undefined ||
                        messageData.whoItsFor === ""
                      }
                    >
                      Who It's For Is Required!
                    </Alert>
                  )}
                </>
              )}
              <>
                <Form.Label>
                  <h3>Message:</h3>
                </Form.Label>
                <Form.Control
                  className="mb-3"
                  as="textarea"
                  autoComplete="off"
                  rows={textareaheight}
                  required
                  value={messageData.messageText}
                  onChange={handleChange}
                  placeholder="Message"
                  id="messageText"
                ></Form.Control>
                <Alert
                  variant="danger"
                  style={{ width: "100%" }}
                  show={messageData.messageText === ""}
                >
                  Message Is Required!
                </Alert>
              </>
              <Form.Group>
                <h3>
                  <Form.Check
                    onChange={(e) => {
                      setMessageData({
                        ...messageData,
                        doTheyNeedACall: e.target.checked,
                      });
                    }}
                    label="Do They Need a Call"
                    checked={messageData.doTheyNeedACall}
                  ></Form.Check>
                </h3>
                <Alert variant="danger">
                  Only click if they specified that they didn't want a call
                  back. Getting a call back is the default!
                </Alert>
              </Form.Group>
            </Form>
          </Card.Body>
          <Card.Footer>
            {messageData.funeralhome === "none" ? (
              <Button
                onClick={addToPending}
                disabled={
                  messageData.messageText === "" ||
                  messageData.callersNumber === "" ||
                  messageData.callersName === "" ||
                  messageData.funeralHome === "none"
                }
              >
                Add to pending
              </Button>
            ) : (
              <>
                {JSON.parse(
                  messageData.funeralhome
                ).requiredMessageInfo.includes("Location") &&
                  !JSON.parse(
                    messageData.funeralhome
                  ).requiredMessageInfo.includes("Who It's For") && (
                    <Button
                      onClick={addToPending}
                      disabled={
                        messageData.messageText === "" ||
                        messageData.callersNumber === "" ||
                        messageData.callersName === "" ||
                        messageData.funeralHome === "none" ||
                        messageData.fhLocation === undefined ||
                        messageData.fhLocation === ""
                      }
                    >
                      Add to pending
                    </Button>
                  )}
                {JSON.parse(
                  messageData.funeralhome
                ).requiredMessageInfo.includes("Who It's For") &&
                  !JSON.parse(
                    messageData.funeralhome
                  ).requiredMessageInfo.includes("Location") && (
                    <Button
                      onClick={addToPending}
                      disabled={
                        messageData.messageText === "" ||
                        messageData.callersNumber === "" ||
                        messageData.callersName === "" ||
                        messageData.funeralHome === "none" ||
                        messageData.whoItsFor === undefined ||
                        messageData.whoItsFor === ""
                      }
                    >
                      Add to pending
                    </Button>
                  )}
                {JSON.parse(
                  messageData.funeralhome
                ).requiredMessageInfo.includes("Location") &&
                  JSON.parse(
                    messageData.funeralhome
                  ).requiredMessageInfo.includes("Who It's For") && (
                    <Button
                      onClick={addToPending}
                      disabled={
                        messageData.messageText === "" ||
                        messageData.callersNumber === "" ||
                        messageData.callersName === "" ||
                        messageData.funeralHome === "none" ||
                        messageData.whoItsFor === undefined ||
                        messageData.whoItsFor === "" ||
                        messageData.fhLocation === undefined ||
                        messageData.fhLocation === ""
                      }
                    >
                      Add to pending
                    </Button>
                  )}
                {!JSON.parse(
                  messageData.funeralhome
                ).requiredMessageInfo.includes("Location") &&
                  !JSON.parse(
                    messageData.funeralhome
                  ).requiredMessageInfo.includes("Who It's For") && (
                    <Button
                      onClick={addToPending}
                      disabled={
                        messageData.messageText === "" ||
                        messageData.callersNumber === "" ||
                        messageData.callersName === "" ||
                        messageData.funeralHome === "none" ||
                        messageData.whoItsFor === undefined
                      }
                    >
                      Add to pending
                    </Button>
                  )}
              </>
            )}
          </Card.Footer>
        </Card>
        <div className="col-md-8">
          {fhSelected ? (
            <MessageFHInfo
              setFuneralhome={(funeralhome) => handleFHNameClicked(funeralhome)}
              currentUser={currentUser}
              funeralhome={fh}
            ></MessageFHInfo>
          ) : (
            <MessagePendingList
              handleAddedMessage={(message) => handleAddedMessage(message)}
              handleMessageDataClear={() =>
                setMessageData({
                  ...messageData,
                  messageText: "",
                  funeralhome: "none",
                  callersName: "",
                  callersNumber: "",
                })
              }
              currentFHList={currentFHList}
              pendingMessages={pendingMessages}
              handlePending={handlePending}
            ></MessagePendingList>
          )}
        </div>
      </div>
    </div>
  );
};

export default MessageForm;
