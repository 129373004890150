import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Card, Button, Form, Alert } from "react-bootstrap";
import FuneralHomeOption from "../FuneralHomeOptions";
import { REMOVE_MESSAGE_BY_ID, EDIT_MESSAGE } from "../../utils/mutations";
const MessageCard = ({
  pendingMessages,
  Message,
  currentUser,
  handlePending,
  index,
  page,
}) => {
  const [deleteMessage, setDeleteMessage] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editData, setEditData] = useState({
    funeralhome: Message.funeralhome || "none",
    oldFH: Message.funeralhome || "none",
    fhLocation: Message.fhLocation,
    callersName: Message.callersName,
    callersNumber: Message.callersNumber,
    messageText: Message.messageText,
    whoItsFor: Message.whoItsFor,
    id: Message._id,
  });
  const [copiedMessage, setCopiedMessage] = useState(false);
  const [removeMessageById] = useMutation(REMOVE_MESSAGE_BY_ID);
  const [editMessage] = useMutation(EDIT_MESSAGE);
  const [textareaheight, setTextareaheight] = useState(1);
  const [currentFH, setCurrentFH] = useState(Message.funeralhome);
  const editChange = (e) => {
    if (e.target.id === "messageText") {
      if (e.target.value === "") {
        setTextareaheight(1);
      } else {
        const height = e.target.scrollHeight;
        const rowHeight = 50;
        const trows = Math.ceil(height / rowHeight) - 1;
        if (trows && textareaheight) {
          setTextareaheight(trows);
        }
      }
    }
    setEditData({ ...editData, [e.target.id]: e.target.value });
  };
  const editSubmit = (e) => {
    e.preventDefault();
    try {
      editMessage({
        variables: {
          ...editData,
          funeralhome: editData.funeralhome.name,
          oldFH: editData.oldFH.name,
        },
      });
      setEditMode(false)
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };
  const handleCopy = () => {
    const hasParentCompany = Message.funeralhome.parentCompany !== null;
    const fixes = [
      "Please Call ",
      Message.fhLocation + ": ",
      " They Asked for " + Message.whoItsFor + ",",
      Message.funeralhome.name + ": ",
    ];
    var generatedMessage =
      Message.callersName +
      " " +
      Message.callersNumber +
      " RE: " +
      Message.messageText;
    if (Message.doTheyNeedACall) {
      generatedMessage = fixes[0] + generatedMessage;
    }
    if (
      Message.funeralhome.requiredMessageInfo.includes("Location") ||
      Message.fhLocation !== ""
    ) {
      generatedMessage = fixes[1] + generatedMessage;
    }
    if (hasParentCompany) {
      generatedMessage = fixes[3] + generatedMessage;
    }
    if (Message.funeralhome.requiredMessageInfo.includes("Who It's For")) {
      generatedMessage = generatedMessage + fixes[2];
    }
    generatedMessage = generatedMessage + " Thanks, " + Message.author;
    navigator.clipboard.writeText(generatedMessage);
    setCopiedMessage(true);
  };
  const changeToggle = (e) => {
    setCopiedMessage(!copiedMessage);
  };
  const handleDeleteToggle = () => {
    setDeleteMessage(!deleteMessage);
  };
  const handleDelete = (e) => {
    removeMessageById({ variables: { id: e.target.id } });
    if (page !== "Message History") {
      const filteredList = pendingMessages.filter((message) => {
        return message._id !== Message._id;
      });
      handlePending(filteredList);
      localStorage.setItem("pendingMessages", JSON.stringify(filteredList));
    }
    window.location.reload();
  };
  return (
    <Card
      key={Message._id}
      style={{
        width: "32vw",
        borderRadius: "2vh",
        maxHeight: "55vh",
        minHeight: "45vh",
        textAlign: "center",
      }}
    >
      {editMode ? (
        <>
          <Card.Header>
            <h4>Edit Mode</h4>
            <Form.Group>
              <Form.Label>Funeral Home:</Form.Label>
              <FuneralHomeOption
                page="Message Card"
                onChange={(fh) => {
                  setCurrentFH(fh);
                  setEditData({ ...editData, funeralhome: fh });
                }}
                id="funeralhome"
                value={editData.funeralhome}
              ></FuneralHomeOption>
            </Form.Group>
            {currentFH?.locations?.length > 1 && (
              <Form.Group>
                <Form.Label>Location:</Form.Label>
                <Form.Control
                  id="fhLocation"
                  as="select"
                  onChange={editChange}
                  value={editData.fhLocation}
                >
                  <option>No Location Specified</option>
                  {currentFH?.locations.map((location) => (
                    <option key={location.Name}>{location.Name}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            )}
          </Card.Header>
          <Card.Body style={{ overflowY: "scroll" }}>
            <Form>
              <Form.Group>
                <Form.Label>Callers Name:</Form.Label>
                <Form.Control
                  id="callersName"
                  onChange={editChange}
                  value={editData.callersName}
                ></Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Callers Number:</Form.Label>
                <Form.Control
                  id="callersNumber"
                  onChange={editChange}
                  value={editData.callersNumber}
                ></Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Who The Message is for:</Form.Label>
                <Form.Control
                  id="whoItsFor"
                  onChange={editChange}
                  value={editData.whoItsFor}
                ></Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Message:</Form.Label>
                <Form.Control
                  rows={textareaheight}
                  as="textarea"
                  id="messageText"
                  onChange={editChange}
                  value={editData.messageText}
                ></Form.Control>
              </Form.Group>
            </Form>
          </Card.Body>
          <Card.Footer style={{ textAlign: "center" }}>
            <Button onClick={editSubmit}>Submit Changes</Button>
            <Button type="button" onClick={() => setEditMode(false)}>
              Cancel Edit
            </Button>
          </Card.Footer>
        </>
      ) : (
        <>
          <Card.Header>
            {Message.funeralhome === null ? (
              <h3> Funeral Home has been Deleted</h3>
            ) : (
              <h3>
                For: {Message.funeralhome.name} <br />
                {Message.fhLocation !== "" && (
                  <>Location: {Message.fhLocation}</>
                )}
              </h3>
            )}
          </Card.Header>
          <Card.Body style={{ overflowY: "scroll", textAlign: "center" }}>
            <p>
              Taken By:{Message.author} on: {Message.messageTaken.date} at:{" "}
              {Message.messageTaken.time}
            </p>
            <p>
              Called in by: {Message.callersName} # {Message.callersNumber}
            </p>
            <div>
              <p>
                Taken on {Message.messageTaken.date} at:{" "}
                {Message.messageTaken.time}
              </p>
            </div>
            <p>Recieved on {Message.respondTime}</p>
            {!Message.doTheyNeedACall && <p>They don't need a call back</p>}
            <p>Message: {Message.messageText}</p>
            {Message.whoItsFor !== "" && (
              <p>They Asked for {Message.whoItsFor}</p>
            )}
          </Card.Body>
          <Card.Footer style={{ textAlign: "center" }}>
            <Alert
              id={Message._id}
              show={deleteMessage}
              style={{ color: "red", fontWeight: "bold", fontSize: "larger" }}
            >
              Are you sure you want to delete this message?
            </Alert>
            <Alert show={copiedMessage}>
              Copied to clipboard <br />
              <Button onClick={changeToggle}>Dismiss</Button>
            </Alert>
            {!deleteMessage ? (
              <>
                {(currentUser.username === Message.author ||
                  currentUser.adminStatus) && (
                  <Button
                    onClick={() => {
                      setEditMode(true);
                    }}
                  >
                    Edit Message
                  </Button>
                )}
                <Button onClick={handleCopy}>Copy Message</Button>
              </>
            ) : (
              <Button id={Message._id} onClick={handleDelete}>
                Confirm Delete
              </Button>
            )}
            <Button
              id={Message.messageText}
              onClick={() => handleDeleteToggle(index)}
            >
              Toggle Delete Confirm
            </Button>
          </Card.Footer>
        </>
      )}
    </Card>
  );
};
export default MessageCard;
