import React from 'react'
import FuneralHomesOnCalls from '../components/FuneralHomesOnCalls'
import { useQuery } from '@apollo/client'
import { QUERY_FUNERALHOMES } from '../utils/queries'
const FHOCPage = ({currentUser, directorsWithNoFH}) => {
    const {data, loading} = useQuery(QUERY_FUNERALHOMES)
    const Funeralhomes = data?.FuneralHomes || []
    return (
        <FuneralHomesOnCalls currentUser={currentUser} loading={loading} directorsWithNoFH={directorsWithNoFH} FuneralHomes={Funeralhomes}></FuneralHomesOnCalls>
    )
}
export default FHOCPage