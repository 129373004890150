import { gql } from "@apollo/client";

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        username
        type
        access
      }
    }
  }
`;
export const ADD_USER = gql`
  mutation AddUser($username: String!, $email: String!, $password: String!) {
    addUser(username: $username, email: $email, password: $password) {
      token
      user {
        type
        username
      }
    }
  }
`;
export const REMOVE_USER = gql`
  mutation RemoveUser($username: String!) {
    removeUser(username: $username)
  }
`;
export const EDIT_USER = gql`
  mutation ChangeUser(
    $newUsername: String!
    $username: String!
    $email: String
    $messageEnder: String
    $adminStatus: Boolean
    $inTraining: Boolean
    $type: String
    $access: [String]
    $approved: Boolean
  ) {
    changeUser(
      newUsername: $newUsername
      username: $username
      email: $email
      messageEnder: $messageEnder
      adminStatus: $adminStatus
      inTraining: $inTraining
      type: $type
      access: $access
      approved: $approved
    ) {
      username
      messageEnder
      email
      type
      inTraining
      adminStatus
      access
      _id
      approved
    }
  }
`;
export const CHECK_APPROVAL = gql`
  mutation CheckApproval($username: String, $email: String) {
    checkApproval(username: $username, email: $email)
  }
`;
export const RESET_PASSWORD = gql`
  mutation ResetPassword($username: String, $password: String!) {
    resetPassword(username: $username, password: $password) {
      username
      password
    }
  }
`;
export const ADD_FUNERALHOME = gql`
  mutation AddFuneralHomes(
    $name: String!
    $doWeSend: Boolean
    $emailList: [String]
    $flowerRoomInfo: String
    $website: String
    $hours: String
    $emailInstructions: String
    $emailListChecked: [Boolean]
  ) {
    AddFuneralHomes(
      name: $name
      doWeSend: $doWeSend
      emailList: $emailList
      flowerRoomInfo: $flowerRoomInfo
      website: $website
      hours: $hours
      emailInstructions: $emailInstructions
      emailListChecked: $emailListChecked
    ) {
      hours
      flowerRoomInfo
      website
      name
      _id
      firstcallsEmail {
        doWeSend
        emailList {
          email
          status
        }
        emailInstructions
      }
    }
  }
`;
export const EDIT_FUNERALHOME = gql`
  mutation EditFuneralHome(
    $doWeTrackFC: Boolean
    $doWeTrackServices: Boolean
    $oldName: String!
    $name: String!
    $doWeSend: Boolean
    $emailList: [String]
    $website: String
    $flowerRoomInfo: String
    $hours: String
    $contactPreference: String
    $howToPronounce: String
    $howWeAnswer: [String]
    $phoneDisplayName: [String]
    $answeringWhoItsFor: [String]
    $userId: ID!
    $editTime: String!
    $specialInstructions: String
    $useOnCallForAffiliateId: String
    $requiredMessageInfo: [String]
    $emailInstructions: String
    $complicatedEmailInstructions: Boolean
    $emailListChecked: [Boolean]
  ) {
    EditFuneralHome(
      doWeTrackFC: $doWeTrackFC
      doWeTrackServices: $doWeTrackServices
      oldName: $oldName
      name: $name
      doWeSend: $doWeSend
      emailList: $emailList
      website: $website
      flowerRoomInfo: $flowerRoomInfo
      hours: $hours
      contactPreference: $contactPreference
      howToPronounce: $howToPronounce
      howWeAnswer: $howWeAnswer
      phoneDisplayName: $phoneDisplayName
      answeringWhoItsFor: $answeringWhoItsFor
      userId: $userId
      editTime: $editTime
      specialInstructions: $specialInstructions
      useOnCallForAffiliateID: $useOnCallForAffiliateId
      requiredMessageInfo: $requiredMessageInfo
      emailInstructions: $emailInstructions
      complicatedEmailInstructions: $complicatedEmailInstructions
      emailListChecked: $emailListChecked
    ) {
      _id
      name
      firstcallsEmail {
        emailList {
          email
          status
        }
        doWeSend
        emailInstructions
        complicatedInstructions
      }
      specialInstructions
      contactPreference
      website
      flowerRoomInfo
      hours
      flower_shops {
        location
        name
        number
      }
      locations {
        _id
        Name
        Address
        Main
        Back
        Fax
        Email
        Directions
        edited {
          time
          by {
            username
          }
        }
      }
      directors {
        defaultNote
        _id
        name
        primary_phone
        contact_method
        on_call {
          time
          note
        }
        funeralhome {
          _id
          name
        }
        edited {
          time
          by {
            username
          }
        }
        unavailable
      }
      status
      services {
        name
        fhLocation
        _id
        timeTaken
        serviceInfo {
          _id
          serviceType
          date
          time
          location
          edited {
            by {
              username
            }
            time
          }
        }
        extraInfo
        edited {
          time
          by {
            username
          }
        }
      }
      serviceOptions
      messages {
        _id
        author
        messageText
        messageTaken {
          date
          time
        }
        respondTime
        callersName
        callersNumber
        fhLocation
        messageStatus
        doTheyNeedACall
        whoItsFor
      }
      answeringThePhone {
        howWeAnswer
        whoItsFor
        phoneDisplayName
      }
      howToPronounce
      edited {
        by {
          username
        }
        time
      }
      useOnCallForAffiliate {
        _id
        directors {
          name
        }
        name
      }
      requiredMessageInfo
      doWeTrack {
        fc
        services
      }
      parentCompany {
        _id
        name
        funeralhomes {
          on_calls {
            currentOnCalls {
              _id
            }
          }
          name
          useOnCallForAffiliate {
            _id
            name
          }
          directors {
            defaultNote
            _id
            name
            primary_phone
            contact_method
            on_call {
              time
              note
            }
            funeralhome {
              name
            }
            unavailable
          }
        }
      }
      on_calls {
        scheduledDirectors {
          _id
          director {
            name
            _id
          }
          startDate
          endDate
          note
        }
        currentOnCalls {
          _id
          name
          funeralhome {
            name
            on_calls {
              currentOnCalls {
                name
                primary_phone
                contact_method
                on_call {
                  time
                  note
                }
                unavailable
                defaultNote
              }
            }
          }
          primary_phone
          contact_method
          on_call {
            time
            note
          }
          unavailable
          defaultNote
        }
        directorPool {
          _id
          name
          funeralhome {
            name
          }
          primary_phone
          contact_method
          on_call {
            time
            note
          }
          unavailable
          defaultNote
        }
      }
    }
  }
`;
export const ADD_FLOWER_SHOP = gql`
  mutation AddFlowerShop(
    $funeralhome: String!
    $location: String
    $name: String
    $number: String
  ) {
    AddFlowerShop(
      funeralhome: $funeralhome
      location: $location
      name: $name
      number: $number
    ) {
      _id
      flower_shops {
        location
        name
        number
      }
    }
  }
`;
export const REMOVE_FUNERALHOME = gql`
  mutation RemoveFuneralHome($name: String!) {
    RemoveFuneralHome(name: $name) {
      _id
      name
      firstcallsEmail {
        emailList {
          email
          status
        }
        doWeSend
        emailInstructions
        complicatedInstructions
      }
      specialInstructions
      contactPreference
      website
      flowerRoomInfo
      hours
      flower_shops {
        location
        name
        number
      }
      locations {
        _id
        Name
        Address
        Main
        Back
        Fax
        Email
        Directions
        edited {
          time
          by {
            username
          }
        }
      }
      directors {
        defaultNote
        _id
        name
        primary_phone
        contact_method
        on_call {
          time
          note
        }
        funeralhome {
          name
        }
        edited {
          time
          by {
            username
          }
        }
        unavailable
      }
      status
      services {
        name
        fhLocation
        _id
        timeTaken
        serviceInfo {
          _id
          serviceType
          date
          time
          location
          edited {
            by {
              username
            }
            time
          }
        }
        extraInfo
        edited {
          time
          by {
            username
          }
        }
      }
      serviceOptions
      messages {
        _id
        author
        messageText
        messageTaken {
          date
          time
        }
        respondTime
        callersName
        callersNumber
        fhLocation
        messageStatus
        doTheyNeedACall
        whoItsFor
      }
      answeringThePhone {
        howWeAnswer
        whoItsFor
        phoneDisplayName
      }
      howToPronounce
      edited {
        by {
          username
        }
        time
      }
      useOnCallForAffiliate {
        _id
        directors {
          name
        }
        name
      }
      requiredMessageInfo
      doWeTrack {
        fc
        services
      }
      parentCompany {
        _id
        name
        funeralhomes {
          on_calls {
            currentOnCalls {
              _id
            }
          }
          name
          useOnCallForAffiliate {
            _id
            name
          }
          directors {
            defaultNote
            _id
            name
            primary_phone
            contact_method
            on_call {
              time
              note
            }
            funeralhome {
              name
            }
            unavailable
          }
        }
      }
      on_calls {
        scheduledDirectors {
          _id
          director {
            name
            _id
          }
          startDate
          endDate
          note
        }
        currentOnCalls {
          _id
          name
          funeralhome {
            name
            on_calls {
              currentOnCalls {
                name
                primary_phone
                contact_method
                on_call {
                  time
                  note
                }
                unavailable
                defaultNote
              }
            }
          }
          primary_phone
          contact_method
          on_call {
            time
            note
          }
          unavailable
          defaultNote
        }
        directorPool {
          _id
          name
          funeralhome {
            name
          }
          primary_phone
          contact_method
          on_call {
            time
            note
          }
          unavailable
          defaultNote
        }
      }
    }
  }
`;
export const REMOVE_FLOWER_SHOP = gql`
  mutation RemoveFlowerShop($funeralhome: String!, $name: String) {
    RemoveFlowerShop(funeralhome: $funeralhome, name: $name) {
      _id
      flower_shops {
        location
        name
        number
      }
    }
  }
`;
export const ADD_LOCATION = gql`
  mutation AddLocations(
    $funeralHomeName: String!
    $name: String!
    $address: String!
    $main: String!
    $back: String!
    $fax: String!
    $directions: String
    $email: String
  ) {
    AddLocations(
      funeralHome_name: $funeralHomeName
      Name: $name
      Address: $address
      Main: $main
      Back: $back
      Fax: $fax
      Directions: $directions
      Email: $email
    ) {
      _id
      locations {
        _id
        Name
        Address
        Main
        Back
        Fax
        Email
        Directions
        edited {
          time
          by {
            username
          }
        }
      }
    }
  }
`;
export const EDIT_LOCATION = gql`
  mutation EditLocation(
    $funeralhomename: String!
    $name: String!
    $changedName: String!
    $address: String!
    $main: String!
    $back: String!
    $fax: String!
    $email: String
    $directions: String
    $editTime: String!
    $userId: ID!
  ) {
    EditLocation(
      funeralhomename: $funeralhomename
      Name: $name
      ChangedName: $changedName
      Address: $address
      Main: $main
      Back: $back
      Fax: $fax
      Email: $email
      Directions: $directions
      editTime: $editTime
      userId: $userId
    ) {
      _id
      locations {
        _id
        Name
        Address
        Main
        Back
        Fax
        Email
        Directions
        edited {
          time
          by {
            username
          }
        }
      }
    }
  }
`;
export const REMOVE_LOCATION = gql`
  mutation RemoveLocation($funeralHomeName: String!, $id: ID!) {
    RemoveLocation(funeralHome_name: $funeralHomeName, _id: $id) {
      _id
      locations {
        _id
        Name
        Address
        Main
        Back
        Fax
        Email
        Directions
        edited {
          time
          by {
            username
          }
        }
      }
    }
  }
`;
export const TAKE_FIRST_CALL = gql`
  mutation TakeFirstCall(
    $fhLocation: String!
    $takenAt: String!
    $takenBy: String!
    $callerName: String!
    $callerNumber: String!
    $callerType: String!
    $nod: String!
    $ppName: String
    $ppNumber: String
    $ppFax: String
    $nokName: String!
    $nokRelationship: String!
    $nokNumber: String!
    $nokAltNumber: String
    $nokBeenNotified: String!
    $locationInfo: String
    $addressOfLocation: String
    $cityorZip: String
    $locationNumber: String
    $locationAltNumber: String
    $tod: String
    $height: String
    $weight: String
    $readyForRemoval: String
    $meName: String
    $meRelease: Boolean
    $meInvolved: Boolean
    $familyPresent: String
    $funeralhome: ID
    $notes: String
    $location: String!
    $dob: String
    $dod: String
  ) {
    TakeFirstCall(
      fhLocation: $fhLocation
      takenAt: $takenAt
      takenBy: $takenBy
      callerName: $callerName
      callerNumber: $callerNumber
      callerType: $callerType
      NOD: $nod
      PPName: $ppName
      PPNumber: $ppNumber
      PPFax: $ppFax
      NOKName: $nokName
      NOKRelationship: $nokRelationship
      NOKNumber: $nokNumber
      NOKAltNumber: $nokAltNumber
      NOKBeenNotified: $nokBeenNotified
      locationInfo: $locationInfo
      addressOfLocation: $addressOfLocation
      cityorZIP: $cityorZip
      locationNumber: $locationNumber
      locationAltNumber: $locationAltNumber
      TOD: $tod
      height: $height
      weight: $weight
      readyForRemoval: $readyForRemoval
      meName: $meName
      meRelease: $meRelease
      meInvolved: $meInvolved
      familyPresent: $familyPresent
      funeralhome: $funeralhome
      notes: $notes
      location: $location
      DOB: $dob
      DOD: $dod
    ) {
      _id
      funeralhome {
        name
      }
      fhLocation
      takenAt
      takenBy
      NOD
      location
      locationInfo
      addressOfLocation
      cityorZIP
      locationNumber
      locationAltNumber
      DOB
      TOD
      height
      primaryPhysician {
        name
        clinicPhone
        fax
      }
      NOK {
        name
        relationship
        phone
        altPhone
        beenNotified
      }
      familyPresent
      readyForRemoval
      notes
      notified {
        director
        time
        process
      }
      callerInfo {
        name
        number
        type
      }
      ME {
        released
        name
        involved
      }
      weight
      DOD
      emailedNote
    }
  }
`;
export const DELETE_FIRST_CALL = gql`
  mutation DeleteFirstCall($firstcallID: ID!) {
    DeleteFirstCall(firstcallID: $firstcallID) {
      _id
      funeralhome {
        name
      }
      fhLocation
      takenAt
      takenBy
      NOD
      location
      locationInfo
      addressOfLocation
      cityorZIP
      locationNumber
      locationAltNumber
      DOB
      TOD
      height
      primaryPhysician {
        name
        clinicPhone
        fax
      }
      NOK {
        name
        relationship
        phone
        altPhone
        beenNotified
      }
      familyPresent
      readyForRemoval
      notes
      notified {
        director
        time
        process
      }
      callerInfo {
        name
        number
        type
      }
      ME {
        released
        name
        involved
      }
      weight
      DOD
      emailedNote
    }
  }
`;
export const RECEIVED_FIRST_CALL = gql`
  mutation ReceivedFirstCall(
    $firstcallId: ID!
    $director: String!
    $time: String!
    $process: String!
  ) {
    ReceivedFirstCall(
      firstcallID: $firstcallId
      director: $director
      time: $time
      process: $process
    ) {
      _id
      funeralhome {
        name
      }
      fhLocation
      takenAt
      takenBy
      callerInfo {
        name
        number
        type
      }
      NOD
      location
      locationInfo
      addressOfLocation
      cityorZIP
      locationNumber
      locationAltNumber
      DOB
      TOD
      height
      weight
      primaryPhysician {
        name
        clinicPhone
        fax
      }
      NOK {
        name
        relationship
        phone
        altPhone
        beenNotified
      }
      familyPresent
      ME {
        released
        name
        involved
      }
      readyForRemoval
      notes
      notified {
        director
        time
        process
      }
    }
  }
`;
export const EDIT_FIRST_CALL = gql`
  mutation EditFirstCall(
    $fhLocation: String!
    $takenAt: String!
    $takenBy: String!
    $callerName: String!
    $callerNumber: String!
    $callerType: String!
    $nod: String!
    $dob: String!
    $nokName: String!
    $nokRelationship: String!
    $nokNumber: String!
    $nokBeenNotified: String!
    $funeralhome: ID
    $location: String
    $locationInfo: String
    $addressOfLocation: String
    $cityorZip: String
    $locationNumber: String
    $locationAltNumber: String
    $tod: String
    $height: String
    $weight: String
    $ppName: String
    $ppNumber: String
    $ppFax: String
    $nokAltNumber: String
    $familyPresent: String
    $meInvolved: Boolean
    $meRelease: Boolean
    $meName: String
    $readyForRemoval: String
    $notes: String
    $firstcallId: ID!
    $dod: String
  ) {
    EditFirstCall(
      fhLocation: $fhLocation
      takenAt: $takenAt
      takenBy: $takenBy
      callerName: $callerName
      callerNumber: $callerNumber
      callerType: $callerType
      NOD: $nod
      DOB: $dob
      NOKName: $nokName
      NOKRelationship: $nokRelationship
      NOKNumber: $nokNumber
      NOKBeenNotified: $nokBeenNotified
      funeralhome: $funeralhome
      location: $location
      locationInfo: $locationInfo
      addressOfLocation: $addressOfLocation
      cityorZIP: $cityorZip
      locationNumber: $locationNumber
      locationAltNumber: $locationAltNumber
      TOD: $tod
      height: $height
      weight: $weight
      PPName: $ppName
      PPNumber: $ppNumber
      PPFax: $ppFax
      NOKAltNumber: $nokAltNumber
      familyPresent: $familyPresent
      meInvolved: $meInvolved
      meRelease: $meRelease
      meName: $meName
      readyForRemoval: $readyForRemoval
      notes: $notes
      firstcallID: $firstcallId
      DOD: $dod
    ) {
      _id
      funeralhome {
        name
      }
      fhLocation
      takenAt
      takenBy
      callerInfo {
        name
        number
        type
      }
      NOD
      location
      locationInfo
      addressOfLocation
      cityorZIP
      locationNumber
      locationAltNumber
      DOB
      TOD
      height
      weight
      primaryPhysician {
        name
        clinicPhone
        fax
      }
      NOK {
        name
        relationship
        phone
        altPhone
        beenNotified
      }
      familyPresent
      ME {
        released
        name
        involved
      }
      readyForRemoval
      notes
      notified {
        director
        time
        process
      }
      edited {
        by {
          username
        }
        time
      }
      DOD
    }
  }
`;
export const HANDLE_NOTES = gql`
  mutation handleNotes($director: String, $note: String) {
    handleNotes(director: $director, note: $note) {
      _id
      name
      primary_phone
      funeralhome {
        _id
        name
      }
      contact_method
      on_call {
        note
        time
      }
    }
  }
`;
export const SEND_FIRST_CALL_EMAIL = gql`
  mutation SendFirstCallEmail(
    $firstcallId: ID!
    $fhLocation: String!
    $takenAt: String!
    $takenBy: String!
    $callerName: String!
    $callerNumber: String!
    $callerType: String!
    $nod: String!
    $dob: String!
    $nokName: String!
    $nokRelationship: String!
    $nokNumber: String!
    $nokBeenNotified: String!
    $funeralhome: ID
    $location: String
    $locationInfo: String
    $addressOfLocation: String
    $cityorZip: String
    $locationNumber: String
    $locationAltNumber: String
    $dod: String
    $tod: String
    $height: String
    $weight: String
    $ppName: String
    $ppNumber: String
    $ppFax: String
    $nokAltNumber: String
    $familyPresent: String
    $meInvolved: Boolean
    $meName: String
    $meRelease: Boolean
    $notes: String
    $readyForRemoval: String
  ) {
    sendFirstCallEmail(
      firstcallID: $firstcallId
      fhLocation: $fhLocation
      takenAt: $takenAt
      takenBy: $takenBy
      callerName: $callerName
      callerNumber: $callerNumber
      callerType: $callerType
      NOD: $nod
      DOB: $dob
      NOKName: $nokName
      NOKRelationship: $nokRelationship
      NOKNumber: $nokNumber
      NOKBeenNotified: $nokBeenNotified
      funeralhome: $funeralhome
      location: $location
      locationInfo: $locationInfo
      addressOfLocation: $addressOfLocation
      cityorZIP: $cityorZip
      locationNumber: $locationNumber
      locationAltNumber: $locationAltNumber
      DOD: $dod
      TOD: $tod
      height: $height
      weight: $weight
      PPName: $ppName
      PPNumber: $ppNumber
      PPFax: $ppFax
      NOKAltNumber: $nokAltNumber
      familyPresent: $familyPresent
      meInvolved: $meInvolved
      meName: $meName
      meRelease: $meRelease
      notes: $notes
      readyForRemoval: $readyForRemoval
    )
  }
`;
export const ADD_TEMP_DIRECTOR = gql`
  mutation HandleTempDirector(
    $funeralhome: String!
    $name: String!
    $number: String!
    $contactMethod: String!
  ) {
    handleTempDirector(
      funeralhome: $funeralhome
      name: $name
      number: $number
      contact_method: $contactMethod
    ) {
      name
      on_calls {
        _id
      }
    }
  }
`;
export const REMOVE_TEMP_DIRECTOR = gql`
  mutation RemoveTempDirector($funeralhome: String) {
    removeTempDirector(funeralhome: $funeralhome) {
      on_calls {
        _id
        director {
          _id
          name
          primary_phone
          contact_method
        }
      }
    }
  }
`;
export const ADD_DIRECTOR = gql`
  mutation Mutation(
    $name: String!
    $primaryPhone: String!
    $contactMethod: String!
    $funeralhome: String
  ) {
    addDirector(
      name: $name
      primary_phone: $primaryPhone
      contact_method: $contactMethod
      funeralhome: $funeralhome
    ) {
      _id
      directors {
        _id
        name
        primary_phone
        contact_method
        funeralhome {
          name
        }
      }
    }
  }
`;
export const REMOVE_DIRECTOR = gql`
  mutation RemoveDirector($id: ID!, $funeralhome: ID!) {
    removeDirector(_id: $id, funeralhome: $funeralhome) {
      _id
      directors {
        _id
      }
    }
  }
`;
export const ADD_MESSAGE = gql`
  mutation AddMessage(
    $doTheyNeedACall: Boolean
    $whoItsFor: String
    $fhLocation: String
    $messageStatus: String
    $funeralhome: String
    $callersNumber: String
    $callersName: String
    $messageText: String
    $messageTakenTime: String
    $messageTakenDate: String
    $author: String
  ) {
    AddMessage(
      doTheyNeedACall: $doTheyNeedACall
      whoItsFor: $whoItsFor
      fhLocation: $fhLocation
      messageStatus: $messageStatus
      funeralhome: $funeralhome
      callersNumber: $callersNumber
      callersName: $callersName
      messageText: $messageText
      messageTakenTime: $messageTakenTime
      messageTakenDate: $messageTakenDate
      author: $author
    ) {
      whoItsFor
      respondTime
      messageText
      messageTaken {
        date
        time
      }
      messageStatus
      funeralhome {
        name
      }
      _id
      author
      callersName
      callersNumber
      fhLocation
      doTheyNeedACall
    }
  }
`;
export const ARCHIVE_MESSAGE = gql`
  mutation ArchiveMessage($id: ID!, $respondTime: String!) {
    ArchiveMessage(_id: $id, respondTime: $respondTime) {
      _id
      author
      messageText
      messageTaken {
        date
        time
      }
      respondTime
      callersName
      callersNumber
      funeralhome {
        name
      }
      messageStatus
      fhLocation
      whoItsFor
      doTheyNeedACall
    }
  }
`;
export const EDIT_MESSAGE = gql`
  mutation Mutation(
    $id: ID!
    $callersName: String
    $messageText: String
    $callersNumber: String
    $funeralhome: String
    $fhLocation: String
    $whoItsFor: String
    $oldFh: String
  ) {
    editMessage(
      _id: $id
      callersName: $callersName
      messageText: $messageText
      callersNumber: $callersNumber
      funeralhome: $funeralhome
      fhLocation: $fhLocation
      whoItsFor: $whoItsFor
      oldFH: $oldFh
    ) {
      _id
      author
      messageText
      messageTaken {
        date
        time
      }
      funeralhome {
        name
      }
      respondTime
      callersName
      callersNumber
      fhLocation
      messageStatus
      whoItsFor
    }
  }
`;
export const REMOVE_MESSAGE = gql`
  mutation RemoveMessage($messageText: String!) {
    RemoveMessage(messageText: $messageText)
  }
`;
export const REMOVE_MESSAGE_BY_ID = gql`
  mutation RemoveMessageById($id: ID!) {
    RemoveMessageById(_id: $id)
  }
`;
export const EDIT_DIRECTOR = gql`
  mutation EditDirector(
    $funeralhome: String!
    $oldfuneralhome: String!
    $name: String!
    $oldname: String!
    $primaryPhone: String!
    $contactMethod: String!
    $editTime: String!
    $userId: ID!
    $unavailable: Boolean
    $defaultNote: String
  ) {
    editDirector(
      funeralhome: $funeralhome
      oldfuneralhome: $oldfuneralhome
      name: $name
      oldname: $oldname
      primary_phone: $primaryPhone
      contact_method: $contactMethod
      editTime: $editTime
      userId: $userId
      unavailable: $unavailable
      defaultNote: $defaultNote
    ) {
      _id
      name
      directors {
        _id
        name
        primary_phone
        contact_method
        funeralhome {
          name
        }
      }
    }
  }
`;
export const ADD_STAFF_MEMBER = gql`
  mutation AddStaffMember(
    $type: String!
    $name: String!
    $contactMethod: String!
    $phoneNumber: String
  ) {
    addStaffMember(
      type: $type
      name: $name
      contactMethod: $contactMethod
      phoneNumber: $phoneNumber
    ) {
      type
      name
      phoneNumber
      contactMethod
    }
  }
`;
export const EDIT_STAFF_MEMBER = gql`
  mutation EditStaffMember(
    $type: String!
    $name: String!
    $contactMethod: String!
    $phoneNumber: String
    $id: ID!
  ) {
    editStaffMember(
      type: $type
      name: $name
      contactMethod: $contactMethod
      phoneNumber: $phoneNumber
      _id: $id
    ) {
      contactMethod
      name
      phoneNumber
      type
    }
  }
`;
export const REMOVE_STAFF_MEMBER = gql`
  mutation RemoveStaffMember($id: ID!) {
    removeStaffMember(_id: $id) {
      type
      name
      phoneNumber
      contactMethod
      _id
    }
  }
`;
export const ADD_REMOVAL_TEAM = gql`
  mutation AddRemovalTeam(
    $driverId: ID!
    $vehicle: String
    $type: String!
    $directorId: ID
  ) {
    addRemovalTeam(
      driverId: $driverId
      vehicle: $vehicle
      type: $type
      directorId: $directorId
    ) {
      driver {
        _id
        type
        name
        phoneNumber
        contactMethod
      }
      director {
        _id
        type
        name
        phoneNumber
        contactMethod
      }
      vehicle
      type
    }
  }
`;
export const EDIT_REMOVAL_TEAM = gql`
  mutation EditRemovalTeam(
    $id: ID!
    $type: String!
    $directorId: ID
    $driverId: ID
    $vehicle: String
    $googleMapsLink: String
  ) {
    editRemovalTeam(
      _id: $id
      type: $type
      directorId: $directorId
      driverId: $driverId
      vehicle: $vehicle
      googleMapsLink: $googleMapsLink
    ) {
      _id
      type
      driver {
        _id
        type
        name
        phoneNumber
        contactMethod
      }
      director {
        _id
        type
        name
        phoneNumber
        contactMethod
      }
      vehicle
      googleMapsLink
    }
  }
`;
export const DELETE_REMOVAL_TEAM = gql`
  mutation DeleteRemovalTeam($id: ID) {
    deleteRemovalTeam(_id: $id)
  }
`;
export const ADD_FEEDBACK = gql`
  mutation AddFeedback(
    $author: String
    $message: String
    $submittedAt: String
  ) {
    addFeedback(author: $author, message: $message, submittedAt: $submittedAt) {
      _id
      author {
        username
      }
      submittedAt
      message
      seen
    }
  }
`;
export const REMOVE_FEEDBACK = gql`
  mutation RemoveFeedback($Id: String!) {
    removeFeedback(id: $Id) {
      _id
      author {
        username
      }
      submittedAt
      message
      seen
    }
  }
`;
export const SEEN_FEEDBACK = gql`
  mutation SeenFeedback($seenFeedbackId: ID) {
    seenFeedback(id: $seenFeedbackId) {
      _id
      author {
        username
      }
      submittedAt
      message
      seen
    }
  }
`;
export const PUT_ON_CALL_FOR_FH = gql`
  mutation PutOnCallForFH(
    $note: String
    $fhname: String!
    $time: String
    $directorId: ID!
  ) {
    putOnCallForFH(
      note: $note
      fhname: $fhname
      time: $time
      directorID: $directorId
    ) {
      on_calls {
        scheduledDirectors {
          _id
          director {
            name
            _id
            on_call {
              time
              note
            }
          }
          startDate
          endDate
          note
        }
        currentOnCalls {
          _id
          name
          funeralhome {
            name
          }
          primary_phone
          contact_method
          on_call {
            time
            note
          }
          unavailable
          defaultNote
        }
      }
      name
    }
  }
`;
export const PUT_ON_CALL_FOR_MULTI_FH = gql`
  mutation putOnCallForMultiFH(
    $note: String
    $time: String
    $fhnames: [String]
    $directorId: ID!
  ) {
    putOnCallForMultiFH(
      note: $note
      time: $time
      fhnames: $fhnames
      directorID: $directorId
    ) {
      _id
      on_calls {
        currentOnCalls {
          _id
          name
          funeralhome {
            name
          }
          primary_phone
          contact_method
          on_call {
            time
            note
          }
          unavailable
          defaultNote
        }
      }
      name
    }
  }
`;
export const REMOVE_ON_CALL_FOR_FH = gql`
  mutation RemoveOnCallForFH($fhname: String!, $directorId: ID!) {
    removeOnCallForFH(fhname: $fhname, directorID: $directorId) {
      _id
      on_calls {
        currentOnCalls {
          name
          funeralhome {
            name
          }
          primary_phone
          contact_method
          on_call {
            time
            note
          }
          unavailable
          defaultNote
        }
      }
      name
    }
  }
`;
export const REMOVE_ON_CALL_FOR_MULTI_FH = gql`
  mutation RemoveOnCallForMultiFH($fhnames: [String], $directorId: ID!) {
    removeOnCallForMultiFH(fhnames: $fhnames, directorID: $directorId) {
      _id
      on_calls {
        currentOnCalls {
          _id
          name
          funeralhome {
            name
          }
          primary_phone
          contact_method
          on_call {
            time
            note
          }
          unavailable
          defaultNote
        }
      }
    }
  }
`;
export const REFRESH_ON_CALL = gql`
  mutation RefreshOnCall($directorId: ID, $time: String) {
    refreshOnCall(directorID: $directorId, time: $time) {
      _id
      name
      primary_phone
      contact_method
      on_call {
        note
        time
      }
    }
  }
`;
export const ADD_SERVICE = gql`
  mutation AddService(
    $name: String!
    $serviceType: String!
    $date: String!
    $time: String!
    $location: String!
  ) {
    addService(
      name: $name
      serviceType: $serviceType
      date: $date
      time: $time
      location: $location
    ) {
      _id
      services {
        name
        fhLocation
        _id
        timeTaken
        serviceInfo {
          _id
          serviceType
          date
          time
          location
          edited {
            by {
              username
            }
            time
          }
        }
        extraInfo
        edited {
          time
          by {
            username
          }
        }
      }
    }
  }
`;
export const CREATE_SERVICE = gql`
  mutation AddService(
    $funeralhome: String!
    $fhLocation: String!
    $name: String!
    $extraInfo: String
    $timeTaken: String
  ) {
    createService(
      funeralhome: $funeralhome
      fhLocation: $fhLocation
      name: $name
      extraInfo: $extraInfo
      timeTaken: $timeTaken
    ) {
      _id
      services {
        name
        fhLocation
        _id
        timeTaken
        serviceInfo {
          _id
          serviceType
          date
          time
          location
          edited {
            by {
              username
            }
            time
          }
        }
        extraInfo
        edited {
          time
          by {
            username
          }
        }
      }
    }
  }
`;
export const DELETE_SERVICE = gql`
  mutation DeleteService(
    $funeralhome: String!
    $fhLocation: String!
    $name: String!
  ) {
    deleteService(
      funeralhome: $funeralhome
      fhLocation: $fhLocation
      name: $name
    ) {
      _id
      services {
        name
        fhLocation
        _id
        timeTaken
        serviceInfo {
          _id
          serviceType
          date
          time
          location
          edited {
            by {
              username
            }
            time
          }
        }
        extraInfo
        edited {
          time
          by {
            username
          }
        }
      }
    }
  }
`;
export const DELETE_SERVICE_INFO = gql`
  mutation DeleteServiceInfo(
    $name: String!
    $serviceType: String!
    $date: String!
    $time: String!
    $location: String!
  ) {
    deleteServiceInfo(
      name: $name
      serviceType: $serviceType
      date: $date
      time: $time
      location: $location
    ) {
      _id
      services {
        name
        fhLocation
        _id
        timeTaken
        serviceInfo {
          _id
          serviceType
          date
          time
          location
          edited {
            by {
              username
            }
            time
          }
        }
        extraInfo
        edited {
          time
          by {
            username
          }
        }
      }
    }
  }
`;
export const EDIT_SERVICE_OPTIONS = gql`
  mutation AddServiceOption($funeralhome: String!, $options: [String!]) {
    editServiceOptions(funeralhome: $funeralhome, options: $options) {
      _id
      serviceOptions
    }
  }
`;
export const EDIT_SERVICE = gql`
  mutation EditService(
    $oldName: String!
    $userId: ID!
    $editTime: String!
    $extraInfo: String
    $name: String!
    $fhLocation: String!
    $funeralhome: String!
  ) {
    editService(
      oldName: $oldName
      userId: $userId
      editTime: $editTime
      extraInfo: $extraInfo
      name: $name
      fhLocation: $fhLocation
      funeralhome: $funeralhome
    ) {
      _id
      services {
        name
        fhLocation
        _id
        timeTaken
        serviceInfo {
          _id
          serviceType
          date
          time
          location
          edited {
            by {
              username
            }
            time
          }
        }
        extraInfo
        edited {
          time
          by {
            username
          }
        }
      }
    }
  }
`;
export const EDIT_SERVICE_INFO = gql`
  mutation EditServiceInfo(
    $time: String!
    $oldLocation: String!
    $location: String!
    $userId: ID!
    $date: String!
    $serviceType: String!
    $oldServiceType: String!
    $name: String!
    $editTime: String!
  ) {
    editServiceInfo(
      time: $time
      oldLocation: $oldLocation
      location: $location
      userId: $userId
      date: $date
      serviceType: $serviceType
      oldServiceType: $oldServiceType
      name: $name
      editTime: $editTime
    ) {
      _id
      services {
        name
        fhLocation
        _id
        timeTaken
        serviceInfo {
          _id
          serviceType
          date
          time
          location
          edited {
            by {
              username
            }
            time
          }
        }
        extraInfo
        edited {
          time
          by {
            username
          }
        }
      }
    }
  }
`;
export const CREATE_TUTORIAL = gql`
  mutation CreateTutorial($name: String) {
    createTutorial(name: $name) {
      _id
      name
      questions
    }
  }
`;
export const DELETE_TUTORIAL = gql`
  mutation DeleteTutorial($id: ID) {
    deleteTutorial(_id: $id) {
      _id
      name
      questions
      type
    }
  }
`;
export const EDIT_TUTORIAL_QUESTIONS = gql`
  mutation EditTutorialQuestions($questions: [String], $id: ID) {
    editTutorialQuestions(questions: $questions, _id: $id) {
      _id
      name
      questions
    }
  }
`;
export const EDIT_TUTORIAL = gql`
  mutation EditTutorial($id: ID, $name: String) {
    editTutorial(_id: $id, name: $name) {
      _id
      name
      questions
    }
  }
`;
export const ADD_ON_CALL_SCHEDULE = gql`
  mutation ScheduleOnCall(
    $note: String
    $startDate: String!
    $endDate: String!
    $directorId: ID!
    $funeralhomename: String!
  ) {
    scheduleOnCall(
      note: $note
      startDate: $startDate
      endDate: $endDate
      directorId: $directorId
      funeralhomename: $funeralhomename
    ) {
      _id
      name
      on_calls {
        scheduledDirectors {
          director {
            name
            _id
          }
          startDate
          endDate
          note
          _id
        }
      }
    }
  }
`;
export const EDIT_ON_CALL_SCHEDULE = gql`
  mutation EditScheduleOnCall(
    $scheduleId: ID!
    $startDate: String!
    $endDate: String!
    $directorId: ID
  ) {
    editScheduleOnCall(
      scheduleId: $scheduleId
      startDate: $startDate
      endDate: $endDate
      directorId: $directorId
    ) {
      _id
      on_calls {
        scheduledDirectors {
          director {
            name
            _id
          }
          startDate
          endDate
          note
          _id
        }
      }
    }
  }
`;
export const ADD_MULTIPLE_ON_CALL_SCHEDULE = gql`
  mutation ScheduleMultipleOnCall(
    $funeralhomename: [String]!
    $directorId: ID!
    $startDate: String!
    $endDate: String!
    $note: String
  ) {
    scheduleMultipleOnCall(
      funeralhomename: $funeralhomename
      directorId: $directorId
      startDate: $startDate
      endDate: $endDate
      note: $note
    ) {
      _id
      name
      on_calls {
        scheduledDirectors {
          director {
            name
            _id
          }
          startDate
          endDate
          note
          _id
        }
      }
    }
  }
`;
export const REMOVE_ON_CALL_SCHEDULE = gql`
  mutation RemoveScheduleOnCall($funeralhomename: String!, $scheduleId: ID!) {
    removeScheduleOnCall(
      funeralhomename: $funeralhomename
      scheduleID: $scheduleId
    ) {
      _id
      on_calls {
        scheduledDirectors {
          director {
            name
            _id
          }
          startDate
          endDate
          note
          _id
        }
      }
    }
  }
`;
export const REMOVE_FULL_SCHEDULE_FOR_FH = gql`
  mutation RemoveFullScheduleForFH($funeralhomename: String!) {
    removeFullScheduleForFH(funeralhomename: $funeralhomename) {
      _id
      on_calls {
        scheduledDirectors {
          director {
            name
            _id
          }
          startDate
          endDate
          note
          _id
        }
      }
    }
  }
`;
export const TOGGLE_FH_STATUS = gql`
  mutation ToggleFuneralhomeStatus($id: ID) {
    toggleFuneralhomeStatus(_id: $id) {
      status
      _id
    }
  }
`;
export const ADD_UPDATE = gql`
  mutation AddUpdate(
    $text: String!
    $time: String!
    $number: String!
    $title: String
  ) {
    addUpdate(text: $text, time: $time, number: $number, title: $title) {
      _id
      text
      readList {
        username
      }
      number
      time
      title
    }
  }
`;
export const MARK_AS_READ = gql`
  mutation MarkAsRead($id: ID!, $userId: ID!) {
    markAsRead(_id: $id, userId: $userId) {
      _id
      text
      readList {
        username
      }
      number
      time
      title
    }
  }
`;
export const EDIT_UPDATE = gql`
  mutation EditUpdate(
    $id: ID!
    $text: String!
    $time: String!
    $number: String!
    $title: String!
  ) {
    editUpdate(
      _id: $id
      text: $text
      time: $time
      number: $number
      title: $title
    ) {
      _id
      text
      number
      time
      title
    }
  }
`;
export const DELETE_UPDATE = gql`
  mutation RemoveUpdate($id: ID!) {
    removeUpdate(_id: $id)
  }
`;
export const SET_DEFAULT_DIRECTOR = gql`
  mutation SetDefaultDirector(
    $directorId: ID!
    $defaultStatus: Boolean!
    $time: String
  ) {
    setDefaultDirector(
      directorID: $directorId
      defaultStatus: $defaultStatus
      time: $time
    ) {
      name
      _id
      contact_method
      defaultDirector
      defaultNote
      on_call {
        note
        time
      }
      primary_phone
      unavailable
    }
  }
`;
export const EDIT_NOTIFICATION_PROCESS = gql`
  mutation EditNotificationProcess(
    $firstcallId: ID!
    $directors: [String]!
    $times: [String]!
    $processes: [String]!
  ) {
    EditNotificationProcess(
      firstcallID: $firstcallId
      directors: $directors
      times: $times
      processes: $processes
    ) {
      notified {
        director
        time
        process
      }
    }
  }
`;
export const ADD_TO_DIRECTOR_POOL = gql`
  mutation AddToDirectorPool($id: ID!, $directorId: ID!) {
    addToDirectorPool(_id: $id, directorID: $directorId) {
      _id
      on_calls {
        directorPool {
          _id
          contact_method
          defaultNote
          name
          on_call {
            time
            note
          }
          primary_phone
          unavailable
        }
      }
    }
  }
`;
export const REMOVE_FROM_DIRECTOR_POOL = gql`
  mutation RemoveFromDirectorPool($id: ID!, $directorId: ID!) {
    removeFromDirectorPool(_id: $id, directorID: $directorId) {
      _id
      on_calls {
        directorPool {
          _id
          contact_method
          defaultNote
          name
          primary_phone
          on_call {
            time
            note
          }
          unavailable
        }
      }
    }
  }
`;
export const HANDLE_DIRECTOR_WITH_NO_FH = gql`
  mutation HandleDirectorWithNoFH(
    $directorId: ID!
    $fhname: String!
    $userId: ID!
    $editTime: String
  ) {
    handleDirectorWithNoFH(
      directorID: $directorId
      fhname: $fhname
      userId: $userId
      editTime: $editTime
    ) {
      _id
      directors {
        _id
      }
    }
  }
`;
export const CREATE_PARENT_COMPANY = gql`
  mutation CreateParentCompany($funeralhomes: [ID!], $name: String!) {
    createParentCompany(funeralhomes: $funeralhomes, name: $name) {
      _id
      name
      funeralhomes {
        _id
        name
      }
    }
  }
`;
export const REMOVE_PARENT_COMPANY = gql`
  mutation RemoveParentCompany($id: ID!) {
    removeParentCompany(_id: $id) {
      _id
      name
      funeralhomes {
        name
        _id
      }
    }
  }
`;
export const EDIT_PARENT_COMPANY = gql`
  mutation EditParentCompany(
    $id: ID!
    $name: String!
    $funeralhomes: [ID!]
    $removedfuneralhomes: [ID!]
  ) {
    editParentCompany(
      _id: $id
      name: $name
      funeralhomes: $funeralhomes
      removedfuneralhomes: $removedfuneralhomes
    ) {
      _id
      name
      funeralhomes {
        name
      }
    }
  }
`;
