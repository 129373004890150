import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { REFRESH_ON_CALL } from "../../utils/mutations";
import { Button, Card, Dropdown, Offcanvas, Alert } from "react-bootstrap";
import dayjs from "dayjs";
import Filter from "../Filter";
import DirectorWithNoFHList from "../DIrectorWithNoFHList";
import FuneralHomesOnCallsCard from "../FuneralHomesOnCallsCard";
const FuneralHomesOnCalls = ({
  FuneralHomes,
  currentUser,
  directorsWithNoFH,
}) => {
  FuneralHomes.forEach((funeralhome) => {
    if (
      funeralhome.on_calls.scheduledDirectors.some((schedule) => {
        return schedule.director === null;
      })
    ) {
      window.location.reload();
    } else if (
      funeralhome.on_calls.currentOnCalls.some((oncall) => {
        return oncall.director === null;
      })
    ) {
      window.location.reload();
    }
  });
  const [FHList, setFHList] = useState([]);
  const [filtered, setFiltered] = useState(
    localStorage.getItem("OnCallsFilter") === null ? false : true
  );
  const [refreshOnCall] = useMutation(REFRESH_ON_CALL);
  useEffect(() => {
    const fhlist = FuneralHomes.map((fh) => {
      return fh;
    });
    if (localStorage.getItem("OnCallsFilter") === "Current On Calls") {
      const fhList = fhlist.filter((funeralhome) => {
        return (
          !funeralhome.on_calls.currentOnCalls.some((director) => {
            const Today = dayjs().format("ddd MM DD YYYY");
            return (
              director.defaultDirector ||
              director.on_call.time.split(" at ")[0] === Today
            );
          }) ||
          (funeralhome.useOnCallForAffiliate !== null
            ? funeralhome.on_calls.currentOnCalls.length === 0
            : funeralhome.useOnCallForAffiliate?.on_calls.currentOnCalls
                .length === 0)
        );
      });
      if (fhList.length !== 0) {
        setFHList(fhList);
        setFiltered(true);
        setAlert({ status: true, text: "Filtered By Non Current On Calls" });
      } else {
        setAlert({ status: true, text: "All Funeral Homes have on calls" });
        setFiltered(false);
        setFHList(FuneralHomes);
      }
    } else {
      setFHList(fhlist);
    }
  }, [setFHList, FuneralHomes, refreshOnCall]);
  const [extraInfoBox, setExtraInfoBox] = useState(false);
  const [alert, setAlert] = useState({ status: false, text: "" });
  const arrayForSort = [...FuneralHomes];
  arrayForSort.sort(function (a, b) {
    if (a.name == null || b.name == null) {
      return -200;
    }
    var nameA = a.name.toLowerCase(),
      nameB = b.name.toLowerCase();
    if (nameA < nameB) {
      //sort string ascending
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
  FuneralHomes = arrayForSort.filter((data) => {
    return data.status;
  });
  const checkOnForAll = () => {
    const filteredList = FHList.filter((funeralhome) => {
      return (
        !funeralhome.on_calls.currentOnCalls.some((director) => {
          const Today = dayjs().format("ddd MM DD YYYY");
          return (
            director.defaultDirector ||
            director.on_call.time.split(" at ")[0] === Today
          );
        }) ||
        (funeralhome.useOnCallForAffiliate !== null
          ? funeralhome.on_calls.currentOnCalls.length === 0
          : funeralhome.useOnCallForAffiliate?.on_calls.currentOnCalls
              .length === 0)
      );
    });
    if (filteredList.length !== 0) {
      setFHList(filteredList);
      setFiltered(true);
      localStorage.setItem("OnCallsFilter", "Current On Calls");
      setAlert({ status: true, text: "Filtered By Non Current On Calls" });
    } else {
      setAlert({ status: true, text: "All Funeral Homes have on calls" });
      setFiltered(false);
      localStorage.removeItem("OnCallsFilter");
      setFHList(FuneralHomes);
    }
  };
  const handleJumpToFH = (funeralhome) => {
    const funeralhomediv = document.getElementById(funeralhome);
    funeralhomediv.scrollIntoView({ block: "start" });
  };
  const closedStyle = {
    outerDiv: {
      display: "flex",
      flexWrap: "wrap",
      textAlign: "center",
      justifyContent: "space-evenly",
    },
    innerDiv: { width: "31vw", marginTop: "1vh" },
    card: { width: "98.5%" },
  };
  const openStyle = {
    outerDiv: {
      display: "flex",
      flexWrap: "wrap",
      textAlign: "center",
      justifyContent: "space-evenly",
    },
    innerDiv: { width: "25vw", marginTop: "1vh" },
    card: { width: "80%" },
  };
  const [style, setStyle] = useState(closedStyle);
  const handleUnfilter = () => {
    setFHList(FuneralHomes);
    setFiltered(false);
    setFilter("none");
    localStorage.removeItem("OnCallsFilter");
    setAlert({ status: false });
  };
  const [filter, setFilter] = useState("none");
  const handleSetFilter = (data) => {
    console.log(data);
    if (data === "none") {
      handleUnfilter();
    } else {
      setFilter(data);
      setFiltered(true);
      setFHList(
        FHList.filter(({ name }) => {
          return data === name;
        })
      );
    }
  };
  return (
    <Card style={style.card}>
      <Dropdown
        drop="start"
        style={{
          zIndex: 1,
          right: 0,
          top: 0,
          marginTop: "9vh",
          position: "fixed",
        }}
      >
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              setExtraInfoBox(true);
            }}
          >
            Open Funeral Home List
          </Dropdown.Item>
          {!filtered && (
            <Dropdown.Item onClick={checkOnForAll}>
              Check If We Have An On Call For Everyone
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
        <Dropdown.Toggle>
          <br />
          O<br />
          n<br />
          <br />
          C<br />
          a<br />
          l<br />
          l<br />
          <br />
          O<br />
          p<br />
          t<br />
          i<br />
          o<br />
          n<br />
          s<br />
        </Dropdown.Toggle>
      </Dropdown>
      <Card.Header style={{ textAlign: "center" }}>
        <h1>On Calls:</h1>
        {alert.status && (
          <Alert dismissible onClose={() => setAlert({ status: false })}>
            {alert.text}
          </Alert>
        )}
        {filtered ? (
          <h2>
            List is currently filtered{" "}
            <Button onClick={handleUnfilter}>Unfilter</Button>
          </h2>
        ) : (
          <Filter
            filter={filter}
            filterData={FHList.map((funeralhome) => {
              return funeralhome.name;
            })}
            setFilter={(data) => handleSetFilter(data)}
            filterType="Funeral Home"
          ></Filter>
        )}
        <Offcanvas
          onShow={() => setStyle(openStyle)}
          style={{ zIndex: 2, marginTop: "7vh", marginBottom: "4vh" }}
          onHide={() => {
            setExtraInfoBox(false);
            setStyle(closedStyle);
          }}
          backdrop={false}
          scroll={true}
          placement="end"
          show={extraInfoBox}
        >
          <Offcanvas.Header closeButton>
            <h2>Funeral Home List</h2>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Card>
              <Card.Header>
                Click on the name to jump to the specific Funeral Home
              </Card.Header>
              <Card.Body>
                {FHList.map((fh) => (
                  <Card onClick={() => handleJumpToFH(fh.name)} key={fh.name}>
                    <Card.Body>{fh.name}</Card.Body>
                  </Card>
                ))}
              </Card.Body>
            </Card>
          </Offcanvas.Body>
        </Offcanvas>
      </Card.Header>
      <Card.Body>
        <div style={{ ...style.outerDiv, overflowY: "scroll", height: "60vh" }}>
          {FHList &&
            FHList.map((funeralhome) => (
              <div
                key={funeralhome._id}
                style={style.innerDiv}
                className={"FuneralHome"}
              >
                {funeralhome?.status && (
                  <FuneralHomesOnCallsCard
                    handleJumpToFH={(fhname) => {
                      handleJumpToFH(fhname);
                    }}
                    currentUser={currentUser}
                    funeralhome={funeralhome}
                  ></FuneralHomesOnCallsCard>
                )}
              </div>
            ))}
          {directorsWithNoFH.length > 0 && currentUser.adminStatus && (
            <DirectorWithNoFHList
              style={style.innerDiv}
              directors={directorsWithNoFH}
              currentUser={currentUser}
            ></DirectorWithNoFHList>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};
export default FuneralHomesOnCalls;
