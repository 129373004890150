import React, { useState } from "react";
import { Form, Alert, Card, Button } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import { ADD_FEEDBACK } from "../../utils/mutations";
import dateFormat from "../../utils/dateFormat";
const FeedbackForm = (props) => {
  const currentUser = props.currentUser;
  const [addFeedback] = useMutation(ADD_FEEDBACK);
  const [feedbackData, setFeedbackData] = useState({ message: "" });
  const [showAlert, setShowAlert] = useState(false);
  const [showBadAlert, setShowBadAlert] = useState(false);
  const onChange = (e) => {
    setFeedbackData({ ...feedbackData, [e.target.id]: e.target.value });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const now = new Date();
    const submittedAt = dateFormat(now);
    try {
      addFeedback({
        variables: {
          ...feedbackData,
          submittedAt,
          author: currentUser.username,
        },
      });
      setShowAlert(true);
      window.location.reload();
    } catch (e) {
      setShowBadAlert(true);
      console.log(e);
    }
  };
  const onDismiss = () => {
    setShowAlert(false);
    setShowBadAlert(false);
  };
  return (
    <Card>
      <Card.Header>Give Feedback</Card.Header>
      <Card.Body>
        <Form onSubmit={onSubmit}>
          <Form.Control
            className="formInput"
            autoComplete="off"
            placeholder="Message"
            value={feedbackData.message}
            autoFocus
            onChange={onChange}
            id="message"
          ></Form.Control>
          <Button type="submit">Submit Feedback</Button>
          <Alert dismissible onClose={onDismiss} show={showAlert}>
            <Alert.Heading>Your Feedback has been submitted!</Alert.Heading>
            Click button to Dismiss!
          </Alert>
          <Alert dismissible onClose={onDismiss} show={showBadAlert}>
            <Alert.Heading>
              There was an Error Submitting your message!
            </Alert.Heading>
            Click button to Dismiss!
          </Alert>
        </Form>
      </Card.Body>
    </Card>
  );
};
export default FeedbackForm;
