import { gql } from "@apollo/client";

export const QUERY_FUNERALHOMES = gql`
  query FuneralHomes {
    FuneralHomes {
      _id
      name
      firstcallsEmail {
        emailList {
          email
          status
        }
        doWeSend
        emailInstructions
        complicatedInstructions
      }
      specialInstructions
      contactPreference
      website
      flowerRoomInfo
      hours
      flower_shops {
        location
        name
        number
      }
      locations {
        _id
        Name
        Address
        Main
        Back
        Fax
        Email
        Directions
        edited {
          time
          by {
            username
          }
        }
      }
      directors {
        defaultNote
        _id
        name
        primary_phone
        contact_method
        on_call {
          time
          note
        }
        funeralhome {
          _id
          name
        }
        edited {
          time
          by {
            username
          }
        }
        unavailable
      }
      status
      services {
        name
        fhLocation
        _id
        timeTaken
        serviceInfo {
          _id
          serviceType
          date
          time
          location
          edited {
            by {
              username
            }
            time
          }
        }
        extraInfo
        edited {
          time
          by {
            username
          }
        }
      }
      serviceOptions
      messages {
        _id
        author
        messageText
        messageTaken {
          date
          time
        }
        respondTime
        callersName
        callersNumber
        fhLocation
        messageStatus
        doTheyNeedACall
        whoItsFor
      }
      answeringThePhone {
        howWeAnswer
        whoItsFor
        phoneDisplayName
      }
      howToPronounce
      edited {
        by {
          username
        }
        time
      }
      useOnCallForAffiliate {
        _id
        name
        on_calls {
          directorPool {
            contact_method
            defaultDirector
            defaultNote
            name
            primary_phone
            unavailable
          }
          currentOnCalls {
            contact_method
            defaultDirector
            defaultNote
            name
            primary_phone
            unavailable
          }
        }
      }
      requiredMessageInfo
      doWeTrack {
        fc
        services
      }
      parentCompany {
        _id
        name
        funeralhomes {
          _id
          firstcallsEmail {
            emailList {
              email
              status
            }
            doWeSend
            emailInstructions
            complicatedInstructions
          }
          on_calls {
            directorPool {
              _id
            }
            currentOnCalls {
              _id
            }
          }
          name
          useOnCallForAffiliate {
            _id
            name
          }
          directors {
            defaultNote
            _id
            name
            primary_phone

            contact_method
            on_call {
              time
              note
            }
            funeralhome {
              _id
              name
            }
            unavailable
          }
        }
      }
      on_calls {
        scheduledDirectors {
          _id
          director {
            name
            _id
          }
          startDate
          endDate
          note
        }
        currentOnCalls {
          _id
          name
          defaultDirector
          funeralhome {
            name
            on_calls {
              currentOnCalls {
                name
                primary_phone

                contact_method
                on_call {
                  time
                  note
                }
                unavailable
                defaultNote
                defaultDirector
              }
            }
          }
          primary_phone
          contact_method
          on_call {
            time
            note
          }
          unavailable
          defaultNote
        }
        directorPool {
          _id
          name
          funeralhome {
            name
          }
          primary_phone
          contact_method
          on_call {
            time
            note
          }
          unavailable
          defaultNote
          defaultDirector
        }
      }
    }
  }
`;
export const QUERY_FUNERALHOMES_BY_NAME = gql`
  query FuneralhomebyName($name: String) {
    FuneralhomebyName(name: $name) {
      _id
      name
      firstcallsEmail {
        emailList {
          email
          status
        }
        doWeSend
        emailInstructions
        complicatedInstructions
      }
      specialInstructions
      contactPreference
      website
      flowerRoomInfo
      hours
      flower_shops {
        location
        name
        number
      }
      locations {
        _id
        Name
        Address
        Main
        Back
        Fax
        Email
        Directions
        edited {
          time
          by {
            username
          }
        }
      }
      directors {
        defaultNote
        _id
        name
        primary_phone
        contact_method
        on_call {
          time
          note
        }
        funeralhome {
          _id
          name
        }
        edited {
          time
          by {
            username
          }
        }
        unavailable
        defaultDirector
      }
      status
      services {
        name
        fhLocation
        _id
        timeTaken
        serviceInfo {
          _id
          serviceType
          date
          time
          location
          edited {
            by {
              username
            }
            time
          }
        }
        extraInfo
        edited {
          time
          by {
            username
          }
        }
      }
      serviceOptions
      messages {
        _id
        author
        messageText
        messageTaken {
          date
          time
        }
        respondTime
        callersName
        callersNumber
        fhLocation
        messageStatus
        doTheyNeedACall
        whoItsFor
      }
      answeringThePhone {
        howWeAnswer
        whoItsFor
        phoneDisplayName
      }
      howToPronounce
      edited {
        by {
          username
        }
        time
      }
      useOnCallForAffiliate {
        _id
        name
      }
      requiredMessageInfo
      doWeTrack {
        fc
        services
      }
      parentCompany {
        _id
        name
        funeralhomes {
          _id
          firstcallsEmail {
            emailList {
              email
              status
            }
            doWeSend
            emailInstructions
            complicatedInstructions
          }
          on_calls {
            directorPool {
              _id
            }
            currentOnCalls {
              _id
            }
            scheduledDirectors {
              _id
            }
          }
          name
          useOnCallForAffiliate {
            _id
            name
          }
          directors {
            defaultNote
            _id
            name
            primary_phone

            contact_method
            on_call {
              time
              note
            }
            funeralhome {
              _id
              name
            }
            unavailable
          }
        }
      }
      on_calls {
        scheduledDirectors {
          _id
          director {
            name
            _id
          }
          startDate
          endDate
          note
        }
        currentOnCalls {
          _id
          name
          funeralhome {
            name
          }
          primary_phone
          contact_method
          on_call {
            time
            note
          }
          unavailable
          defaultNote
        }
        directorPool {
          _id
          name
          funeralhome {
            name
          }
          primary_phone
          contact_method
          on_call {
            time
            note
          }
          unavailable
          defaultNote
        }
      }
    }
  }
`;
export const QUERY_DIRECTORS = gql`
  query Directors {
    Directors {
      defaultNote
      _id
      name
      primary_phone
      contact_method
      funeralhome {
        name
        _id
        status
        on_calls {
          directorPool {
            _id
          }
          currentOnCalls {
            _id
            name
            funeralhome {
              name
            }
            primary_phone
            contact_method
            on_call {
              time
              note
            }
            unavailable
            defaultNote
          }
        }
      }
      on_call {
        note
        time
      }
      edited {
        by {
          username
        }
        time
      }
      unavailable
    }
  }
`;
export const QUERY_MESSAGES = gql`
  query Messages {
    Messages {
      _id
      author
      messageText
      messageTaken {
        date
        time
      }
      funeralhome {
        name
        locations {
          Name
        }
        parentCompany{
          name
        }
        requiredMessageInfo
      }
      respondTime
      callersName
      callersNumber
      messageStatus
      doTheyNeedACall
      fhLocation
      whoItsFor
    }
  }
`;
export const QUERY_ALL_MESSAGES = gql`
  query GetAllMessages {
    getAllMessages {
      _id
      author
      messageText
      messageTaken {
        date
        time
      }
      respondTime
      callersName
      callersNumber
      funeralhome {
        name
        locations {
          Name
        }
        parentCompany {
          name
        }
        requiredMessageInfo
      }
      fhLocation
      messageStatus
      doTheyNeedACall
      whoItsFor
    }
  }
`;
export const QUERY_STAFF_LIST = gql`
  query OurStaffList {
    OurStaffList {
      _id
      name
      type
      phoneNumber
      contactMethod
    }
  }
`;
export const QUERY_REMOVAL_TEAM = gql`
  query RemovalTeamList {
    RemovalTeamList {
      _id
      driver {
        _id
        type
        name
        phoneNumber
        contactMethod
      }
      director {
        _id
        type
        name
        phoneNumber
        contactMethod
      }
      vehicle
      type
      googleMapsLink
    }
  }
`;
export const QUERY_USERS = gql`
  query GetAllUsers {
    getAllUsers {
      _id
      username
      email
      password
      access
      adminStatus
      inTraining
      messageEnder
      approved
      availability {
        available
        shift
        notAvailable
      }
      type
    }
  }
`;

export const QUERY_FEEDBACK = gql`
  query Feedback {
    Feedback {
      _id
      author {
        username
      }
      submittedAt
      message
      seen
    }
  }
`;
export const QUERY_USER_FEEDBACK = gql`
  query UserFeedback($userId: ID) {
    UserFeedback(userId: $userId) {
      _id
      author {
        username
      }
      submittedAt
      message
      seen
    }
  }
`;
export const QUERY_TUTORIALS = gql`
  query Tutorials {
    Tutorials {
      _id
      name
      questions
    }
  }
`;
export const QUERY_FIRSTCALLS = gql`
  query FirstCalls {
    FirstCalls {
      _id
      funeralhome {
        name
        locations {
          Name
        }
      }
      fhLocation
      takenAt
      takenBy
      callerInfo {
        name
        number
        type
      }
      NOD
      location
      locationInfo
      addressOfLocation
      cityorZIP
      locationNumber
      locationAltNumber
      DOB
      DOD
      TOD
      height
      weight
      primaryPhysician {
        name
        clinicPhone
        fax
      }
      NOK {
        name
        relationship
        phone
        altPhone
        beenNotified
      }
      familyPresent
      readyForRemoval
      notes
      _id
      edited {
        by {
          username
        }
        time
      }
      notified {
        director
        time
        process
      }
      ME {
        released
        name
        involved
      }
      emailedNote
    }
  }
`;
export const QUERY_UPDATES = gql`
  query Updates {
    Updates {
      _id
      text
      readList {
        _id
        username
      }
      number
      time
      title
    }
  }
`;
export const QUERY_PARENT_COMPANIES = gql`
  query ParentCompanies {
    ParentCompanies {
      _id
      name
      funeralhomes {
        _id
        name
      }
    }
  }
`;
export const QUERY_NOTIFICATION_NUMBERS = gql`
  query NotificationNumbers($userId: ID) {
    NotificationNumbers(userId: $userId) {
      firstcalls
      updates
    }
  }
`;
