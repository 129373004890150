function generatefcsheet(data, updated) {
  var notes = [];
  if (data.notes && data.notes.length > 60) {
    notes = data.notes.match(/.{1,60}/g);
  }
  return `
${updated ? "Updated" : ``}
Funeral Home: ${data.funeralhome.name} 

Chapel: ${data.fhLocation ? data.fhLocation : ""}

Date & Time: ${data.takenAt ? data.takenAt : ""} Taken by: ${
    data.takenBy ? data.takenBy : ""
  }

Name of Caller: ${data.callerName ? data.callerName : ""} Caller's #: ${
    data.callerNumber ? data.callerNumber : ""
  } 

Caller Type: ${data.callerType ? data.callerType : ""}

Name of Deceased: ${data.NOD ? data.NOD : ""}

Location of Deceased: ${data.location ? data.location : ""} 

Rm/Apt/Flr/Where In Home: ${data.locationInfo ? data.locationInfo : "Not Given"}

Address: ${
    data.addressOfLocation ? data.addressOfLocation : "Not Given"
  } City/ZIP: ${data.cityorZIP ? data.cityorZIP : "Not Given"}

Phone: ${data.locationNumber ? data.locationNumber : "Not Given"} Alt. Phone: ${
    data.locationAltNumber ? data.locationAltNumber : "Not Given"
  }

Next of Kin: ${data.NOKName ? data.NOKName : "Not Given"} Relationship: ${
    data.NOKRelationship ? data.NOKRelationship : "Not Given"
  }

Phone: ${data.NOKNumber ? data.NOKNumber : "Not Given"} Alt. Phone: ${
    data.NOKAltNumber ? data.NOKAltNumber : "Not Given"
  }

Date of Birth: ${data.DOB ? data.DOB : "Not Given"} Age: ${
    data.age ? data.age : "Not Given"
  }

Time of Death: ${data.TOD ? data.TOD : "Not Given"} Date of Death: ${
    data.DOD ? data.DOD : "Not Given"
  } 

Height: ${data.height ? data.height : "Not Given"}  Weight: ${
    data.weight ? data.weight : "Not Given"
  }

Primary Physician: ${data.ppName ? data.ppName : "Not Given"} 

Clinic Phone: ${data.ppNumber ? data.ppNumber : "Not Given"} Fax: ${
    data.ppFax ? data.ppFax : "Not Given"
  }

NOK Notification Status: ${
    data.NOKBeenNotified ? data.NOKBeenNotified : "Not Given"
  }

Is family present?: ${data.familyPresent ? data.familyPresent : "Not Given"}

${
  data.meInvolved
    ? `ME was involved ${
        data.meRelease ? "ME has released" : "ME has not released"
      }; Name of ME: ${data.meName ? data.meName : "Not Given"} `
    : "ME was not involved"
}

Are they ready for funeral home to come?: ${
    data.readyForRemoval ? data.readyForRemoval : "Not Given"
  }

${
  notes.length === 0
    ? `Notes: ${data.notes ? data.notes : "Info Not Given"}`
    : `Notes: ${notes
        .map((note) => {
          return `${note}
          `;
        })
        .join("")}`
}

Director: ${
    data.notified[data.notified.length - 1].director
  } Personally acknowledged this first call at: ${
    data.notified[data.notified.length - 1].time
  }

How They Were Contacted: ${data.notified[data.notified.length - 1].process}
`;
}

module.exports = generatefcsheet;
