import React, {useState} from "react";
import { useQuery} from "@apollo/client";
import { QUERY_ALL_MESSAGES } from "../../utils/queries";
import {Card, Badge} from "react-bootstrap";
import PagesHandler from "../PagesHandler";
import Loading from "../Loading";
import generatePages from "../../utils/generatePages";
import MessageCard from "../MessageCard";
const MessageHistory = ({currentUser}) => {
    const {data, loading} = useQuery(QUERY_ALL_MESSAGES)
    var Messages = data?.getAllMessages || []
    var arrayForSort = [...Messages]
    arrayForSort.reverse()
    Messages = arrayForSort
    const [page, setPage] = useState(0)
    const pages = generatePages(Messages, 18)
    return (
        <Card>
            <Card.Header style={{textAlign: 'center'}}>
            <PagesHandler
            pageTitle={'Message History'}
            page={page}
            pages={pages}
            pageMessage={pages.length > 1 &&<>Page #{page + 1} Start Date and Time: {pages[page] && pages[page][0].messageTaken.date + " " +  pages[page][0].messageTaken.time} End Date and Time: {pages[page] && pages[page][pages[page].length -1].messageTaken.date + " " +  pages[page][pages[page].length -1].messageTaken.time}  </>}
            previousBadge={pages.length > 1 &&<Badge style={{fontSize: '1.5vh'}}>Start Date and Time: {pages[page -1] && pages[page -1][0].messageTaken.date + " " +  pages[page -1][0].messageTaken.time}<br/>End Date and Time: {pages[page -1] && pages[page - 1][pages[page].length - 1].messageTaken.date + " " +  pages[page -1][pages[page].length - 1].messageTaken.time}</Badge>}
            setPage={(page) => setPage(page)}
            nextBadge={pages.length > 1 && <Badge style={{fontSize: '1.5vh'}}>Start Date and Time: {pages[page + 1] && pages[page + 1][0].messageTaken.date + " " +  pages[page + 1][0].messageTaken.time}<br/>End Date and Time: {pages[page + 1] && pages[page + 1][pages[page + 1].length - 1].messageTaken.date + " " +  pages[page + 1][pages[page + 1].length - 1].messageTaken.time}</Badge>}
            jumpBadges={pages.length > 1 && pages.map((pageInfo) => (
                <Badge style={{fontSize: '1.5vh'}}>Start Date and Time: {pageInfo[0].messageTaken.date} {pageInfo[0].messageTaken.time}<br/>End Date and Time: {pageInfo[pageInfo.length -1].messageTaken.date} {pageInfo[pageInfo.length - 1].messageTaken.time}
                </Badge>
            ))}
            >
            </PagesHandler>
            </Card.Header>
            <Card.Body>
        <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly',overflow: 'scroll'}}>
            {loading &&<Loading page="Message History"></Loading>}
            {pages[page] && pages[page].map((Message, index) => (
                <MessageCard key={Message._id} Message={Message} currentUser={currentUser} page={'Message History'}></MessageCard>
            ))}
        </div>            
        </Card.Body>
        </Card>
    )
}
export default MessageHistory