import React, {useState} from 'react'
import { useMutation } from '@apollo/client'
import { EDIT_FUNERALHOME, TOGGLE_FH_STATUS, REMOVE_FUNERALHOME } from '../../utils/mutations'
import dateFormat from '../../utils/dateFormat'
import auth from '../../utils/auth'
import { Form, Button, Card} from 'react-bootstrap'
const SingleFHEdit = ({currentFH, handleClose}) => {
    var currentUser = ''
    const loggedIn = auth.loggedIn()
    if(loggedIn) {
        currentUser = auth.getProfile().data
    }
    const canEdit = currentUser.adminStatus
    var localstorage = JSON.parse(localStorage.getItem('fhEditData'))
    if(localstorage === null || localstorage.oldName !== currentUser.name) {
        localstorage = null
    }
    const checkUseOnCallForAffiliate = () => {
        if(currentFH.useOnCallForAffiliate !== null) {
            return currentFH.useOnCallForAffiliate._id
        }
        return 'null'
    }
    const [EditFuneralHome] = useMutation(EDIT_FUNERALHOME)
    const [toggleFHStatus] = useMutation(TOGGLE_FH_STATUS)
    const [removeFuneralhome] = useMutation(REMOVE_FUNERALHOME)
    const [funeralHomeEditData, setFuneralHomeEditData] = useState(localstorage || {oldName: currentFH.name || '', name: currentFH.name || '', doWeSend: currentFH.firstcallsEmail.doWeSend ||  false, answeringThePhone: currentFH.answeringThePhone.length > 0  ? currentFH.answeringThePhone : [{whoItsFor: '', howWeAnswer: '', phoneDisplayName: ''}], emailList: currentFH.firstcallsEmail.emailList.map(({email}) => {return email}) || [], emailListChecked: currentFH.firstcallsEmail.emailList.map(({status}) => {return status}), website: currentFH.website || '', flowerRoomInfo: currentFH.flowerRoomInfo || '', hours: currentFH.hours || '', contactPreference: currentFH.contactPreference || '', specialInstructions: currentFH.specialInstructions || '', useOnCallForAffiliateId: checkUseOnCallForAffiliate(), requiredMessageInfo: currentFH.requiredMessageInfo, emailInstructions: currentFH.firstcallsEmail.emailInstructions || '', doWeTrackServices: currentFH.doWeTrack.services, doWeTrackFC: currentFH.doWeTrack.fc, howToPronounce: currentFH.howToPronounce || ''})
    const [deleteFHToggle, setDeleteFHToggle] = useState(false)
    const handleFHEdit = (e) => {
        e.preventDefault();
        try {
            EditFuneralHome({variables: {...currentFH, ...funeralHomeEditData, howWeAnswer: currentFH.answeringThePhone.map(({howWeAnswer}) => {return howWeAnswer}), phoneDisplayName: currentFH.answeringThePhone.map(({phoneDisplayName}) => {return phoneDisplayName}), answeringWhoItsFor: currentFH.answeringThePhone.length > 1 ? currentFH.answeringThePhone.map(({whoItsFor}) => {return whoItsFor}): [currentFH.name], userId: currentUser._id, editTime: dateFormat(new Date())}}).then(({data}) => {
                localStorage.setItem('fhPageKey', funeralHomeEditData.name)
                handleClose()
            })
        } catch (error) {
            console.log(error)
        }
    }
    const handleFHChange = (e) => {
        if(e.target.id === 'doWeSend' || e.target.id === 'doWeTrackFC' || e.target.id === 'doWeTrackServices') {
            setFuneralHomeEditData({...funeralHomeEditData, [e.target.id]: e.target.checked})
        }
        else {
            setFuneralHomeEditData({...funeralHomeEditData, [e.target.id]: e.target.value})
        }
    }
    const handleLocalStorage = () => {
        localStorage.setItem('fhEditData', JSON.stringify(funeralHomeEditData))
    }
    const handleRequiredMessageInfoChange = (e) => {
        if(funeralHomeEditData.requiredMessageInfo.includes(e.target.id)) {
            setFuneralHomeEditData({...funeralHomeEditData, requiredMessageInfo: funeralHomeEditData.requiredMessageInfo.filter((messageInfo) => {
                return messageInfo !== e.target.id
            })})
        } else {
            setFuneralHomeEditData({...funeralHomeEditData, requiredMessageInfo: [...funeralHomeEditData.requiredMessageInfo, e.target.id]})
        }
    }
    const handleToggleFH = async(e) => {
        try {
            await toggleFHStatus({variables: {id: e.target.id}}).then(() => {
            })
        } catch (error) {
            console.log(error)
            handleClose()
        }
    }
    const handleDelete = async () => {
        try {
            await removeFuneralhome({variables: {name: currentFH.name}})
            handleClose()
        } catch (error) {
            console.log(error)
            handleClose()
        }
    }
    const affiliates = currentFH.parentCompany !== null ? currentFH.parentCompany.funeralhomes.filter((affiliate) => {return affiliate.name !== currentFH.name}) : []
    return (
            <div className="modal show" id='ModalBox'>
            <Card style={{padding: '0px'}} className="box">
            <Card.Header style={{textAlign: 'center',top: 0, position: 'sticky', background: 'lightgray'}}>
            <span className="close-icon" onClick={() => {
                handleLocalStorage();
                handleClose();
            }}>x</span>
            <h3>
            Editing {currentFH.name}'s Extra Info
            </h3>
                </Card.Header>
            <Card.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>
                            Name:
                        </Form.Label><br></br>
                        <Form.Control className="formInput" autoComplete='false' style={{width: '100%'}} onChange={handleFHChange} id="name" value={funeralHomeEditData.name}>
                        </Form.Control>
                    </Form.Group>
                    {affiliates.length > 0 &&
                        <Form.Group>
                        <Form.Label>
                            Use Affiliates On Call:
                        </Form.Label>
                        <Form.Control id='useOnCallForAffiliateId' onChange={handleFHChange} value={funeralHomeEditData.useOnCallForAffiliateId} as='select'>
                            <option value={"null"}>Do Not Use Affiliates On Call</option>
                            {affiliates.map((affiliate) => (
                                    <option id={affiliate._id} value={String(affiliate._id)}key={affiliate._id}>{affiliate.name}</option>
                            
                            ))}
                        </Form.Control>
                    </Form.Group>
                    }
                    <Form.Group style={{display: 'block'}}>
                            Do We Track Services:
                            <Form.Check id="doWeTrackServices" checked={funeralHomeEditData.doWeTrackServices} onChange={handleFHChange} inline type={"switch"}>
                            </Form.Check>
                            Do We Take First Calls:
                            <Form.Check id='doWeTrackFC' checked={funeralHomeEditData.doWeTrackFC} onChange={handleFHChange} inline type={'switch'}>
                            </Form.Check>
                    </Form.Group>
                        <Form.Group style={{display: 'block'}}>
                                    Required Message Info:<br/>
                                    <h3>
                                        Will always be selected
                                    </h3>
                                Callers Name:
                                <Form.Check defaultChecked readOnly inline type="switch">
                                </Form.Check>
                                Callers Number:
                                <Form.Check defaultChecked readOnly inline type="switch">
                                </Form.Check>
                                Message:
                                <Form.Check defaultChecked readOnly inline type="switch">
                                </Form.Check>
                                <h3>
                                    Extra Options
                                </h3>
                                Location:
                                <Form.Check onChange={handleRequiredMessageInfoChange} id="Location" checked={funeralHomeEditData.requiredMessageInfo.includes("Location")} inline type='switch' >
                                
                                </Form.Check>
                                Who they're calling for:
                                <Form.Check onChange={handleRequiredMessageInfoChange} id="Who It's For" checked={funeralHomeEditData.requiredMessageInfo.includes("Who It's For")} inline type='switch'>

                                </Form.Check>
                        </Form.Group>
                </Form>
                {canEdit && currentFH.edited !== undefined && currentFH.edited.by !== null &&
                <>Last edited by {currentFH.edited.by.username} on {currentFH.edited.time}</>
                }
                </Card.Body>
                <Card.Footer style={{textAlign: 'center',position: 'sticky', bottom: 0, background: 'lightgray'}}>
                    {!deleteFHToggle ?
                    <>
                    <Button className='formInput' type="button" onClick={handleFHEdit}>Submit Changes</Button>
                    {canEdit &&
                        <>
                        {currentFH.status ? 
                        <Button onClick={handleToggleFH} id={currentFH._id}>Disable Funeralhome</Button>
                        :
                        <Button onClick={handleToggleFH} id={currentFH._id}>Enable Funeralhome</Button>
                        }
                        <Button onClick={() => setDeleteFHToggle(true)}>
                            Remove {currentFH.name}
                        </Button>
                        </>
                    }
                    </>
                    :
                    <>
                        <Button onClick={handleDelete}>
                        Confirm Delete
                        </Button>
                        <Button onClick={() => setDeleteFHToggle(false)}>
                        Cancel Delete
                        </Button>
                    </>
                    }                    
                </Card.Footer>
            </Card>
            </div>
    )
}
export default SingleFHEdit