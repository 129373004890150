import React from "react";
import { Card } from "react-bootstrap";
const AffiliateList = ({funeralhomes}) => {
    return (
        <Card>
            <Card.Header>
                Affiliates List
            </Card.Header>
            <Card.Body>
            {funeralhomes.map((funeralhome) => (
                <Card key={funeralhome._id}>
                    <Card.Body>
                        {funeralhome.name}
                    </Card.Body>
                </Card>
            ))}
            </Card.Body>
        </Card>
    )
}
export default AffiliateList