import React from 'react'
import { useQuery } from '@apollo/client'
import { QUERY_FUNERALHOMES_BY_NAME } from '../../utils/queries'
import { Card,} from 'react-bootstrap'
import IndividualFH from '../IndividualFH';
const MessageFHInfo = ({funeralhome, currentUser}) => {
    const name = funeralhome
    const {data, loading} = useQuery(QUERY_FUNERALHOMES_BY_NAME, {variables: {name}})
    const Funeralhome = data?.FuneralhomebyName || []
    return (
        <Card className="FuneralHome-Card" style={{fontSize: '2.4vh', overflowY: 'scroll', maxHeight:'125vh'}}>
            {Funeralhome.length !== 0 ?
            <IndividualFH loading={loading} currentUser={currentUser} Funeralhome={Funeralhome}>
            </IndividualFH>:
            <>There was an issue getting the funeral homes information</>
            }
            
        </Card>
    )
}
export default MessageFHInfo