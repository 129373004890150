import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  EDIT_FIRST_CALL,
  TAKE_FIRST_CALL,
  CREATE_SERVICE,
} from "../../utils/mutations";
import dateFormat from "../../utils/dateFormat";
import { QUERY_FUNERALHOMES } from "../../utils/queries";
import auth from "../../utils/auth";
import { Form, Button, Alert, Dropdown } from "react-bootstrap";
import "./firstcall.css";
import FirstcallSavedList from "../FirstcallSavedList";
import Loading from "../Loading";
import $ from "jquery";
import "jquery-mask-plugin/dist/jquery.mask.min";
// import AddressAutoComplete from "../AddressAutoComplete";
const FirstCalls = ({ setKey }) => {
  var currentlocalstorage = "";
  var currentSavedlocalStorage = "";
  if (localStorage.getItem("fcinfo") !== "") {
    currentlocalstorage = JSON.parse(localStorage.getItem("fcinfo"));
  }
  currentSavedlocalStorage = JSON.parse(localStorage.getItem("savedFCs"));
  const loggedIn = auth.loggedIn();
  var currentUser = [];
  if (loggedIn) {
    currentUser = auth.getProfile().data;
  }
  const date = new Date();
  const dotw = date.toString().split(" ")[0];
  const formattedDate = dateFormat(date).split(" ");
  const today = formattedDate[0] + "/" + formattedDate[1] + "/" + formattedDate[2];
  const [currentFCData, setFCData] = useState(
    {
      ...currentlocalstorage,
      location: currentlocalstorage?.location || "",
      callerType: currentlocalstorage?.callerType || "",
      callerName: currentlocalstorage?.callerName || "",
      callerNumber: currentlocalstorage?.callerNumber || "",
      nokNumber: currentlocalstorage?.nokNumber || "",
      nokBeenNotified: currentlocalstorage?.nokBeenNotified || "",
      dob: currentlocalstorage?.dob || "",
      nokName: currentlocalstorage?.nokName || "",
      fhLocation: currentlocalstorage?.fhLocation || "none",
      nokRelationship: currentlocalstorage?.nokRelationship || "",
      nod: currentlocalstorage?.nod || "",
      meInvolved: currentlocalstorage?.meInvolved || false,
      meRelease: currentlocalstorage?.meRelease || false,
      funeralhome: currentlocalstorage?.funeralhome || "none",
      takenBy: currentUser.username,
      dod: currentlocalstorage?.dod || "",
    } || ""
  );
  const [callerType, setCallerType] = useState(
    currentlocalstorage?.callerType.split(": ")[0] || "none"
  );
  const [currentSavedFCs, setCurrentSavedFCs] = useState(
    currentSavedlocalStorage || []
  );
  const [savedFirstCalls, setSavedFirstCalls] = useState();
  const [editMode, setEditMode] = useState(
    localStorage.getItem("editmode") || false
  );
  const [takefirstcall] = useMutation(TAKE_FIRST_CALL);
  const [editFirstCall] = useMutation(EDIT_FIRST_CALL);
  const [createService] = useMutation(CREATE_SERVICE);
  const { data, loading } = useQuery(QUERY_FUNERALHOMES);
  var funeralhomes = data?.FuneralHomes || [];
  var arrayForFilter = funeralhomes.filter((funeralhome) => {
    return funeralhome.doWeTrack.fc;
  });
  funeralhomes = arrayForFilter;
  const handleFormChange = (e) => {
    const id = e.target.id;
    const value = e.target.value;
    if (id === "meRelease" || id === "meInvolved") {
      console.log(value);
      setFCData({ ...currentFCData, [id]: value === "true" });
      localStorage.setItem(
        "fcinfo",
        JSON.stringify({ ...currentFCData, [id]: value === "true" })
      );
    } else {
      if (id === "funeralhome") {
        if (value === "none") {
          setFCData({ ...currentFCData, [id]: value });
          localStorage.setItem(
            "fcinfo",
            JSON.stringify({ ...currentFCData, [id]: value })
          );
        } else {
          if (JSON.parse(value).locations.length === 1) {
            setFCData({
              ...currentFCData,
              [id]: JSON.parse(value),
              fhLocation: JSON.parse(value).locations[0].Name,
            });
            localStorage.setItem(
              "fcinfo",
              JSON.stringify({
                ...currentFCData,
                [id]: JSON.parse(value),
                fhLocation: JSON.parse(value).locations[0].Name,
              })
            );
          } else if (JSON.parse(value).locations.length === 0) {
            setFCData({
              ...currentFCData,
              [id]: JSON.parse(value),
              fhLocation: "Has no locations",
            });
            localStorage.setItem(
              "fcinfo",
              JSON.stringify({
                ...currentFCData,
                [id]: JSON.parse(value),
                fhLocation: "Has no locations",
              })
            );
          } else {
            setFCData({
              ...currentFCData,
              [id]: JSON.parse(value),
              fhLocation: "none",
            });
            localStorage.setItem(
              "fcinfo",
              JSON.stringify({ ...currentFCData, [id]: JSON.parse(value) })
            );
          }
        }
      } else {
        if (id === "callerType") {
          setFCData({ ...currentFCData, [id]: callerType + ": " + value });
          localStorage.setItem(
            "fcinfo",
            JSON.stringify({
              ...currentFCData,
              [id]: callerType + ": " + value,
            })
          );
        } else {
          setFCData({ ...currentFCData, [id]: value });
          localStorage.setItem(
            "fcinfo",
            JSON.stringify({ ...currentFCData, [id]: value })
          );
        }
      }
    }
  }
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      await takefirstcall({
        variables: {
          ...currentFCData,
          funeralhome: currentFCData.funeralhome._id,
          takenAt: dateFormat(new Date()),
        },
      }).then(() => {
        if (currentFCData.funeralhome.doWeTrack.services) {
          createService({
            variables: {
              funeralhome: currentFCData.funeralhome.name,
              fhLocation: currentFCData.fhLocation,
              name: currentFCData.nod,
              timeTaken: dateFormat(new Date()),
            },
          });
        }
      });
      localStorage.removeItem("fcinfo");
      localStorage.setItem("fckey", "firstCallList");
      setFCData({
        location: "",
        callerType: "",
        callerName: "",
        callerNumber: "",
        nokNumber: "",
        nokBeenNotified: "",
        dob: "",
        nokName: "",
        fhLocation: "none",
        nokRelationship: "",
        nod: "",
        meInvolved: false,
        meRelease: false,
        funeralhome: "none",
        takenBy: currentUser.username,
        addressOfLocation: "",
        notes: "",
        locationNumber: "",
        locationAltNumber: "",
        nokAltNumber: "",
        meName: "",
        ppName: "",
        ppFax: "",
        ppNumber: "",
        height: "",
        weight: "",
        dod: "",
        tod: "",
        readyForRemoval: "",
        familyPresent: "",
        locationInfo: "",
      });
      setCallerType("none");
      setKey("firstCallList");
    } catch (e) {
      console.log(e);
    }
  };
  const handleClear = () => {
    localStorage.removeItem("fcinfo");
    document.location.reload();
  };
  const saveFirstCall = () => {
    if (editMode) {
      if (currentSavedFCs.length > 0) {
        setCurrentSavedFCs([
          ...currentSavedFCs,
          { ...currentFCData, editMode },
        ]);
        localStorage.setItem(
          "savedFCs",
          JSON.stringify([...currentSavedFCs, { ...currentFCData, editMode }])
        );
      } else {
        setCurrentSavedFCs([{ ...currentFCData, editMode }]);
        localStorage.setItem(
          "savedFCs",
          JSON.stringify([{ ...currentFCData, editMode }])
        );
      }
    } else {
      if (currentSavedFCs.length > 0) {
        setCurrentSavedFCs([...currentSavedFCs, currentFCData]);
        localStorage.setItem(
          "savedFCs",
          JSON.stringify([...currentSavedFCs, currentFCData])
        );
      } else {
        setCurrentSavedFCs([{ currentFCData }]);
        localStorage.setItem("savedFCs", JSON.stringify([currentFCData]));
      }
    }
    localStorage.removeItem("fcinfo");
    document.location.reload();
  };
  const handleEditMode = () => {
    setEditMode(false);
    localStorage.removeItem("fcinfo");
    localStorage.removeItem("editmode");
    window.location.reload();
  };
  const handleFormEditSubmit = () => {
    try {
      editFirstCall({
        variables: {
          ...currentFCData,
          firstcallId: currentFCData.firstcallID,
          funeralhome: currentFCData.funeralhome._id,
          takenAt: dateFormat(new Date()),
        },
      });
      localStorage.removeItem("fcinfo");
      localStorage.setItem("fckey", "firstCallList");
      localStorage.removeItem("editmode");
      setEditMode(false);
      setFCData({
        location: "",
        callerType: "",
        callerName: "",
        callerNumber: "",
        nokNumber: "",
        nokBeenNotified: "",
        dob: "",
        nokName: "",
        fhLocation: "none",
        nokRelationship: "",
        nod: "",
        meInvolved: false,
        meRelease: false,
        funeralhome: "none",
        takenBy: currentUser.username,
        addressOfLocation: "",
        notes: "",
        locationNumber: "",
        locationAltNumber: "",
        nokAltNumber: "",
        meName: "",
        ppName: "",
        ppFax: "",
        ppNumber: "",
        height: "",
        weight: "",
        dod: "",
        tod: "",
        readyForRemoval: "",
        familyPresent: "",
        locationInfo: "",
      });
      setCallerType("none");
      setKey("firstCallList");
    } catch (error) {
      console.log(error);
    }
  };
  if (!loading) {
    const currentYear = dateFormat(new Date()).split(" ")[2];
    $(".phonenumber").mask("(000)-000-0000", { placeholder: "(___)-___-___" });
    $("#tod").mask("t0:00 lm", {
      translation: {
        t: { pattern: /[012]/ },
        l: { pattern: /[pa ]/ },
        m: { pattern: /[m ]/ },
      },
      placeholder: "10:20 pm",
    });
    $("#dod").mask("10/00/0000", {
      translation: {
        1: { pattern: /[01]/ },
      },
      placeholder: "10/20/" + currentYear,
    });
    $("#dob").mask("10/00/0000", {
      placeholder: "10/10/1930",
      translation: {
        1: { pattern: /[0,1]/ },
      },
    });
  }
  const handleCallerIsNOK = () => {
    setFCData({
      ...currentFCData,
      nokName: currentFCData.callerName,
      nokRelationship: currentFCData.callerType.split(":")[1],
      nokNumber: currentFCData.callerNumber,
      nokBeenNotified: "Yes",
    });
    localStorage.setItem(
      "fcinfo",
      JSON.stringify({
        ...currentFCData,
        nokName: currentFCData.callerName,
        nokRelationship: currentFCData.callerType.split(":")[1],
        nokNumber: currentFCData.callerNumber,
      })
    );
  };
  return (
    <div>
      {loading && <Loading page={"First Call Form"}></Loading>}
      {editMode && (
        <Alert style={{ textAlign: "center" }}>
          <h1>Edit Mode</h1>
        </Alert>
      )}
      {savedFirstCalls && (
        <FirstcallSavedList
          savedFirstCall={currentSavedFCs}
          setSavedFirstCalls={(data) => setCurrentSavedFCs(data)}
          handleClose={() => setSavedFirstCalls(false)}
          setFCData={(data) => {
            setFCData(data);
            setEditMode(false);
          }}
          setCallerType={(data) => setCallerType(data)}
        ></FirstcallSavedList>
      )}
      <Dropdown
        drop="start"
        style={{ right: 0, top: 0, marginTop: "15vh", position: "fixed" }}
      >
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              handleClear();
              setEditMode(false);
            }}
          >
            Clear First Call Form
          </Dropdown.Item>
          {currentSavedFCs.length > 0 && (
            <Dropdown.Item onClick={() => setSavedFirstCalls(true)}>
              Saved First Calls
            </Dropdown.Item>
          )}
          <Dropdown.Item
            onClick={() => {
              saveFirstCall();
              setEditMode(false);
              localStorage.removeItem("editmode");
            }}
          >
            Save First Call For Later
          </Dropdown.Item>
          {editMode && (
            <Dropdown.Item onClick={handleEditMode}>Cancel Edit</Dropdown.Item>
          )}
        </Dropdown.Menu>
        <Dropdown.Toggle>
          F<br />
          i<br />
          r<br />
          s<br />
          t<br />
          C<br />
          a<br />
          l<br />
          l<br />
          O<br />
          p<br />
          t<br />
          i<br />
          o<br />
          n<br />s
        </Dropdown.Toggle>
      </Dropdown>
      <Form
        style={{
          width: "90vw",
          paddingLeft: "5vw",
          fontSize: "4vh",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
        }}
      >
        <h1 style={{ textAlign: "center", flexBasis: "100%" }}>
          Funeral Home Info
        </h1>
        <Form.Group>
          Funeral Home:
          <Form.Control
            onChange={handleFormChange}
            value={JSON.stringify(currentFCData.funeralhome) || "none"}
            id="funeralhome"
            as="select"
            style={{ width: "25vw" }}
          >
            <option value={"none"}>Please Select a Funeral Home</option>
            {funeralhomes.length > 0 &&
              funeralhomes.map((fh) => (
                <option value={JSON.stringify(fh)} key={fh._id}>
                  {fh.name}
                </option>
              ))}
          </Form.Control>
          <span style={{ paddingLeft: "2.5vw" }}>Location:</span>
          {currentFCData.funeralhome === "none" ? (
            <p>Please Select a Funeral Home</p>
          ) : (
            <>
              {currentFCData.funeralhome.locations.length > 1 ? (
                <>
                  <Form.Control
                    onChange={handleFormChange}
                    style={{ width: "20vw" }}
                    value={currentFCData.fhLocation}
                    as="select"
                    id="fhLocation"
                  >
                    <option value={"none"}>Please Select a Location</option>
                    <option>Unknown</option>
                    {currentFCData.funeralhome.locations.map((location) => (
                      <option key={location._id}>{location.Name}</option>
                    ))}
                  </Form.Control>
                </>
              ) : (
                <>{currentFCData.fhLocation}</>
              )}
            </>
          )}
        </Form.Group>
        <Alert
          show={
            currentFCData.funeralhome === "none" ||
            currentFCData.fhLocation === "none"
          }
          variant="danger"
        >
          Please Select a Funeral Home & Location
        </Alert>
        <Form.Group>
          <span style={{ paddingRight: "2vw" }}>
            Date: {dotw}{" "}
            {today}
          </span>
          <span style={{ paddingRight: "2vw" }}></span>
          Taken By: {currentUser.username}
        </Form.Group>
        <h1 style={{ textAlign: "center", flexBasis: "100%" }}>
          Caller Information:
        </h1>
        <Alert
          variant="danger"
          show={
            currentFCData.callerName === "" ||
            currentFCData.callerNumber === "" ||
            callerType === "none"
          }
        >
          All Caller Info Is Required
        </Alert>
        <Form.Group>
          Name Of Caller:
          <Form.Control
            autoComplete="off"
            placeholder="John Doe"
            id="callerName"
            value={currentFCData.callerName}
            onChange={handleFormChange}
            style={{ width: "30vw" }}
          ></Form.Control>
          <span style={{ paddingLeft: "4vw" }}>Caller's #:</span>
          <Form.Control
            className="phonenumber"
            onChange={handleFormChange}
            id="callerNumber"
            autoComplete="off"
            value={currentFCData.callerNumber}
            style={{ width: "13vw" }}
          ></Form.Control>
        </Form.Group>
        <Form.Group>
          Caller Type:
          <Form.Control
            onChange={(e) => {
              if (e.target.value === "none") {
                setFCData({ ...currentFCData, callerType: "none" });
                localStorage.setItem(
                  "fcinfo",
                  JSON.stringify({ ...currentFCData, callerType: "none: " })
                );
              } else {
                setFCData({
                  ...currentFCData,
                  callerType: e.target.value + ": ",
                });
                localStorage.setItem(
                  "fcinfo",
                  JSON.stringify({
                    ...currentFCData,
                    callerType: e.target.value + ": ",
                  })
                );
              }
              setCallerType(e.target.value);
            }}
            as="select"
            value={callerType}
            style={{ width: "22vw" }}
          >
            <option value="none">Please Select a Caller Type</option>
            <option>Family</option>
            <option>Nurse</option>
            <option>Medical Examiner</option>
            <option>Hospice</option>
            <option>Police</option>
            <option>Other</option>
          </Form.Control>
          <span style={{ paddingLeft: "3vw" }}>Sub Type:</span>
          {callerType !== "none" ? (
            <Form.Control
              autoComplete="off"
              placeholder="(Relationship,County,Dep)"
              onChange={handleFormChange}
              value={currentFCData.callerType.split(": ")[1]}
              id="callerType"
              style={{ width: "22vw" }}
            ></Form.Control>
          ) : (
            <>Please Select a Caller Type</>
          )}
        </Form.Group>
        <h1 style={{ textAlign: "center", flexBasis: "100%" }}>
          Deceased/Removal Information:
        </h1>
        <Alert variant="danger" show={currentFCData.nod === ""}>
          Name Of Deceased is Required
        </Alert>
        <Alert show={currentFCData.location === ""} variant="danger">
          Location of deceased is required
        </Alert>
        <Alert
          show={
            currentFCData.nokName === "" ||
            currentFCData.nokRelationship === "" ||
            currentFCData.nokBeenNotified === "" ||
            currentFCData.nokNumber === ""
          }
          variant="danger"
        >
          Next Of Kin Information and Notification Status is Required
        </Alert>
        <Form.Group>
          Name Of Deceased:
          <Form.Control
            autoComplete="off"
            id="nod"
            placeholder="Jim Doe"
            value={currentFCData.nod}
            onChange={handleFormChange}
            style={{ width: "51vw" }}
          ></Form.Control>
        </Form.Group>
        {/* <AddressAutoComplete
          setData={(data) => setFCData(data)}
          data={currentFCData}
          handleFormChange={handleFormChange}
          valueName={"Location of Deceased"}
          placeholder={"Residence / Facility Name"}
        >
                  </AddressAutoComplete> */}
          <Form.Group>
            Location of Deceased: 
            <Form.Control autoComplete="off" placeholder="Home/Residence, Facility Name" id='location' onChange={handleFormChange} value={currentFCData.location} style={{width: "27vw"}}>
            </Form.Control>
            Rm/Flr/Apt:
          <Form.Control
            autoComplete="off"
            id="locationInfo"
            value={currentFCData.locationInfo}
            onChange={handleFormChange}
            placeholder="Rm: 2/ Apt: 2"
            style={{ width: "11vw" }}
          ></Form.Control>
        </Form.Group>
        <Form.Group>
          Address of Location:
          <Form.Control
            autoComplete="off"
            id="addressOfLocation"
            value={currentFCData.addressOfLocation}
            onChange={handleFormChange}
            placeholder="123 Fake Street North West"
            style={{ width: "30vw" }}
          ></Form.Control>
          City/Zip:
          <Form.Control
            autoComplete="off"
            id="cityorZip"
            value={currentFCData.cityorZip}
            onChange={handleFormChange}
            placeholder="MPLS"
            style={{ width: "20vw" }}
          ></Form.Control>
        </Form.Group>
        <Form.Group>
          Phone:
          <Form.Control
            className="phonenumber"
            autoComplete="off"
            id="locationNumber"
            onChange={handleFormChange}
            value={currentFCData.locationNumber}
            style={{ width: "13vw" }}
          ></Form.Control>
          <span style={{ paddingLeft: "10vw" }}></span>
          Alt.Phone:
          <Form.Control
            autoComplete="off"
            id="locationAltNumber"
            onChange={handleFormChange}
            value={currentFCData.locationAltNumber}
            className="phonenumber"
            style={{ width: "13vw" }}
          ></Form.Control>
        </Form.Group>
        <Form.Group>
          Next of Kin:
          <Form.Control
            autoComplete="off"
            id="nokName"
            style={{ width: "35.5vw" }}
            placeholder="Jane Doe"
            onChange={handleFormChange}
            value={currentFCData.nokName}
          ></Form.Control>
          Relationship:
          <Form.Control
            autoComplete="off"
            id="nokRelationship"
            onChange={handleFormChange}
            value={currentFCData.nokRelationship}
            style={{ width: "11vw" }}
            placeholder="Spouse"
          ></Form.Control>
          {currentFCData.callerType.split(":")[0] === "Family" &&
            currentFCData.callerName !== currentFCData.nokName && (
              <Button
                style={{ marginLeft: ".5vw" }}
                onClick={handleCallerIsNOK}
              >
                Caller is NOK
              </Button>
            )}
        </Form.Group>
        <Form.Group>
          Phone:
          <Form.Control
            autoComplete="off"
            id="nokNumber"
            onChange={handleFormChange}
            value={currentFCData.nokNumber}
            className="phonenumber"
            style={{ width: "13vw" }}
          ></Form.Control>
          <span style={{ paddingLeft: "10vw" }}></span>
          Alt.Phone:
          <Form.Control
            autoComplete="off"
            id="nokAltNumber"
            onChange={handleFormChange}
            value={currentFCData.nokAltNumber}
            className="phonenumber"
            style={{ width: "13vw" }}
          ></Form.Control>
        </Form.Group>
        <Form.Group>
          Next of Kin notified?:
          <Form.Control
            style={{ width: "19vw" }}
            autoComplete="off"
            id="nokBeenNotified"
            onChange={handleFormChange}
            value={currentFCData.nokBeenNotified}
            placeholder="Yes/No/Left a message"
          ></Form.Control>
          <span style={{ paddingRight: "2vw" }}></span>
          Is family present?:
          <Form.Control
            autoComplete="off"
            style={{ width: "19vw" }}
            id="familyPresent"
            onChange={handleFormChange}
            value={currentFCData.familyPresent}
            placeholder="Yes No On the way"
          ></Form.Control>
        </Form.Group>
        <Form.Group>
          ME Involved?:
          <Form.Control
            onChange={handleFormChange}
            as="select"
            style={{ width: "10vw" }}
            id="meInvolved"
            value={currentFCData.meInvolved}
          >
            <option value={true}>Yes</option>
            <option value={false}>No</option>
          </Form.Control>
          {currentFCData.meInvolved === true && (
            <>
              <span style={{ width: "10vw" }}></span>
              ME released?:
              <Form.Control
                onChange={handleFormChange}
                as="select"
                style={{ width: "10vw" }}
                id="meRelease"
                value={currentFCData.meRelease}
              >
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </Form.Control>
            </>
          )}
        </Form.Group>
        {currentFCData.meInvolved === true && (
          <Form.Group>
            County/Name of the ME:
            <Form.Control
              onChange={handleFormChange}
              autoComplete="off"
              placeholder="County/Name"
              style={{ width: "15vw" }}
              id="meName"
              value={currentFCData.meName}
            ></Form.Control>
          </Form.Group>
        )}
        {callerType !== "Family" && (
          <Form.Group>
            Are They Ready for us to come?:
            <Form.Control
              id="readyForRemoval"
              onChange={handleFormChange}
              autoComplete="off"
              placeholder="Yes, No"
              style={{ width: "15vw" }}
              value={currentFCData.readyForRemoval}
            ></Form.Control>
          </Form.Group>
        )}
        <h1 style={{ textAlign: "center", flexBasis: "100%" }}>
          Death Certificate Information:
        </h1>
        <Form.Group>
          Date of Birth:
          <Form.Control
            autoComplete="off"
            id="dob"
            onChange={handleFormChange}
            value={currentFCData.dob}
            style={{ width: "12vw" }}
          ></Form.Control>
          {callerType !== "Family" && (
            <>
              Time Of Death:
              <Form.Control
                autoComplete="off"
                id="tod"
                onChange={handleFormChange}
                value={currentFCData.tod}
                style={{ width: "9vw" }}
              ></Form.Control>
              <span style={{ paddingLeft: "1vw" }}></span>
              Date of Death:
              <Form.Control
                id="dod"
                style={{ width: "11vw" }}
                onChange={handleFormChange}
                value={currentFCData.dod}
              ></Form.Control>
            </>
          )}
        </Form.Group>
        {callerType !== "Family" && (
          <>
            <Form.Group>
              Height:
              <Form.Control
                autoComplete="off"
                id="height"
                onChange={handleFormChange}
                value={currentFCData.height}
                placeholder="6ft"
                style={{ width: "7vw" }}
              ></Form.Control>
              <span style={{ paddingLeft: "2.5vw" }}>Weight:</span>
              <Form.Control
                autoComplete="off"
                id="weight"
                onChange={handleFormChange}
                value={currentFCData.weight}
                placeholder="200lbs"
                style={{ width: "7vw" }}
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              Primary Physician:
              <Form.Control
                autoComplete="off"
                id="ppName"
                style={{ width: "18vw" }}
                onChange={handleFormChange}
                placeholder="Doctor Dave Johnson"
                value={currentFCData.ppName}
              ></Form.Control>
              Clinic Phone:
              <Form.Control
                autoComplete="off"
                id="ppNumber"
                style={{ width: "13vw" }}
                className="phonenumber"
                onChange={handleFormChange}
                value={currentFCData.ppNumber}
              ></Form.Control>
              Fax:
              <Form.Control
                autoComplete="off"
                id="ppFax"
                style={{ width: "13vw" }}
                className="phonenumber"
                onChange={handleFormChange}
                value={currentFCData.ppFax}
              ></Form.Control>
            </Form.Group>
          </>
        )}
        <Form.Group>
          Notes:
          <Form.Control
            as="textarea"
            onChange={handleFormChange}
            autoComplete="off"
            placeholder="Notes"
            style={{ width: "75vw" }}
            id="notes"
            value={currentFCData.notes}
          ></Form.Control>
        </Form.Group>
        {!editMode ? (
          <Button
            disabled={
              currentFCData.nokName === "" ||
              currentFCData.nokRelationship === "" ||
              currentFCData.nokBeenNotified === "" ||
              currentFCData.nokNumber === "" ||
              currentFCData.location === "" ||
              currentFCData.funeralhome === "none" ||
              currentFCData.fhLocation === "none" ||
              callerType === "none" ||
              currentFCData.callerName === "" ||
              currentFCData.callerNumber === ""
            }
            onClick={handleFormSubmit}
          >
            Submit First Call
          </Button>
        ) : (
          <Button onClick={handleFormEditSubmit}>Submit Edit</Button>
        )}
      </Form>
    </div>
  );
};
export default FirstCalls;
