import React, {useState} from 'react'
import { Card, Button , Alert, Form} from 'react-bootstrap';
import { useMutation } from '@apollo/client';
import { ADD_USER } from '../../../utils/mutations';
import Auth from "../../../utils/auth";
import { CHECK_APPROVAL } from '../../../utils/mutations';
const SignupForm = () => {
    const [addUser] = useMutation(ADD_USER);
    const [checkApproval] = useMutation(CHECK_APPROVAL)
    const [currentUserInfo, setCurrentUserInfo] = useState({token: '', user: ''})
    const [notApprovedMessage, setNotApprovedMessage] = useState({status: false, text: 'You have not been approved yet, Please reach out to an admin to approve your account.'})
    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
      };
    const [formData, setFormData] = useState({
        username: "",
        newemail: "",
        newpassword: "",
      });
    const [showAlert, setShowAlert] = useState({ status: false });
    const [passwordShown, setPasswordShown] = useState(false);
    const [accountCreated, setAccountCreated] = useState(false)
    const handleSubmit = async (e) => {
        e.preventDefault();
        const { newemail, newpassword, username } = formData;
          try {
            const response = await addUser({
              variables: { email: newemail, password: newpassword, username },
            });
            const { token, user } = await response.data.addUser;
            if(user) {
                setAccountCreated(true)
                setCurrentUserInfo({token, user})
            }
          } catch (e) {
            console.log(e);
            setShowAlert({ status: true, text: e.message });
        }
    }
    const handleLogin = async () => {
        try {
            await checkApproval({variables: {username: currentUserInfo.user.username}}).then(({data}) => {
                if(data.checkApproval) {
                    const {token, user} = currentUserInfo
                    Auth.login(token, user)
                } else {
                    setNotApprovedMessage({...notApprovedMessage, status: true})
                }
            })
        } catch (error) {
            console.log(error)
        }
    }
    return (
          <Card style={{ display: "flex" }}>
            <Card.Header>
              <h2>
                Already Signed Up? Go to the login tab <br/>
                PLEASE MAKE YOUR USERNAME YOUR FIRST AND LAST INITIAL Example:
                (Cameron N.)
              </h2>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <Alert
                  dismissible
                  onClose={() => {
                    setShowAlert({ ...showAlert, status: false });
                  }}
                  show={showAlert.status}
                  variant="Danger"
                >
                  <Alert.Heading>
                    Something went wrong with your signup! <br />
                    {showAlert.text}
                  </Alert.Heading>
                  Click to Dismiss!
                </Alert>
                <Form.Group>
                  <Form.Label htmlFor="Username">Username:</Form.Label>
                  <Form.Control
                    className="formInput"
                    autoComplete="off"
                    type="text"
                    placeholder="Username"
                    name="username"
                    value={formData.username}
                    id="username"
                    onChange={handleChange}
                    required
                  />
                  <Alert show={formData.username === ""}>
                    Username is required!
                  </Alert>
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="email">Email:</Form.Label>
                  <Form.Control
                    className="formInput"
                    type="email"
                    placeholder="Your email address"
                    name="email"
                    onChange={handleChange}
                    value={formData.newemail}
                    required
                    id="newemail"
                    autoComplete="off"
                  />
                  <Form.Text>
                    The email currently isn't sent anything from the website so if
                    you don't feel comfortable using your personal email you can
                    create a fake one for now
                  </Form.Text>
                  <Alert show={formData.newemail === ""}>Email is required!</Alert>
                </Form.Group>
                <Form.Group>
                  <Form.Label htmlFor="password">Password:</Form.Label>
                  <Form.Control
                    className="formInput"
                    type={passwordShown ? "text" : "password"}
                    placeholder="Your password"
                    name="password"
                    onChange={handleChange}
                    value={formData.newpassword}
                    required
                    id="newpassword"
                  />
                  <Button
                    onClick={() => {
                      setPasswordShown(!passwordShown);
                    }}
                  >
                    {passwordShown ? "Hide" : "Show"}
                  </Button>
                  <Alert
                    show={
                      formData.newpassword === "" ||
                      formData.newpassword.split("").length < 5
                    }
                    type="invalid"
                  >
                    Password is required! And Must be at least 5 Characters!!!
                  </Alert>
                </Form.Group>
                <Alert show={accountCreated}>
                    Your account was created succesfully please wait for the account to be approved. You can login once you are approved <Button onClick={handleLogin}>Login</Button><br/>
                </Alert>
                <Alert variant='danger' show={notApprovedMessage.status} dismissible onClose={()=> setNotApprovedMessage({...notApprovedMessage, status: false})}>
                {notApprovedMessage.text}
                </Alert>
              </Form>
            </Card.Body>
            <Card.Footer>
              <Button
                disabled={
                  !(formData.username && formData.newemail && formData.newpassword)
                }
                type="submit"
                variant="success"
                onClick={handleSubmit}
              >
                Sign Up
              </Button>
            </Card.Footer>
          </Card>
    )
}
export default SignupForm