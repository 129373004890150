import React from "react";

const Footer = ({currentUser}) => {
    return (
        <footer className="footer" style={{marginTop: "10px", position: "fixed", bottom: "0",left: "0", width: "100%", zIndex: '4'}}>
            <div style={{width: '100%', background: 'black', color:' white', fontSize: '3vh', display: 'flex', justifyContent: 'space-between'}}>
                <div>
                Funeral Answering Network V: 0.4.0 [Work in progress build Will be prone to bugs]
                </div>
                {currentUser &&
                <div>
                User: {currentUser.username}
                </div>
                }
            </div>
        </footer>
    )
}
export default Footer