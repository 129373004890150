import React, { useState } from "react";
import { Button, Card, Accordion, Tabs, Tab } from "react-bootstrap";
import FuneralHomesLocationForm from "../FuneralHomesLocationForm";
import DirectorsForm from "../DirectorsForm";
import ServicesList from "../ServicesList";
import ServicesForm from "../ServicesForms/ServicesForm";
import ServiceInfoForm from "../ServicesForms/ServiceInfoForm";
import ScheduledDirectors from "../ScheduledDirectorList";
import ScheduleOnCalls from "../ScheduleOnCalls";
import SingleFHEdit from "../SingleFHEdit";
import FHDirectorList from "../FHDirectorList";
import LocationList from "../FuneralHomesLocationList";
import ServiceOptions from "../ServiceOptions";
import Loading from "../Loading";
import SingleFHOnCalls from "../SingleFHOnCalls";
import Flowershops from "../FlowershopList";
import GeneralFHInfoTable from "../GeneralFHInfoTable";
import FlowershopForm from "../FlowershopForm";
import AffiliateList from "../AffiliatesList";
import FHDirectorPoolList from "../FHDirectorPoolList";
// import FHFirstCallList from "../FHFirstCallList";
const IndividualFH = ({ currentUser, Funeralhome, loading, singleFHPage }) => {
  // if (Funeralhome?.length > 0) {
  //   if (
  //     Funeralhome?.scheduledDirectors.some((schedule) => {
  //       return schedule.director === null;
  //     })
  //   ) {
  //     window.location.reload();
  //   } else if (
  //     Funeralhome?.on_calls.some((oncall) => {
  //       return oncall.director === null
  //     })
  //   ) {
  //     window.location.reload();
  //   }
  // }\
  const [canEdit] = useState(currentUser.adminStatus);
  const [locationKey, setLoactionKey] = useState("List");
  const [editSingleFH, setEditSingleFH] = useState(false);
  const changeFuneralhomes = (funeralhome) => {
    setLoactionKey("List");
  };
  return (
    <Card style={{ fontSize: "4vh" }}>
      {!singleFHPage && (
        <Card.Header
          style={{
            position: "sticky",
            width: "100%",
            top: "0",
            background: "lightgrey",
            textAlign: "center",
            zIndex: "200",
          }}
        >
          <h3>
            <a
              style={{ color: "black" }}
              href={"/Funeralhomes/" + Funeralhome?.name}
            >
              {Funeralhome?.name}
            </a>
          </h3>
        </Card.Header>
      )}
      <Card.Body>
        {loading && (
          <Loading page={"Single Funeralhomes's information page"}></Loading>
        )}
        {editSingleFH && (
          <SingleFHEdit
            currentFH={Funeralhome}
            handleClose={() => setEditSingleFH(false)}
          ></SingleFHEdit>
        )}
        {!Funeralhome?.status && (
          <h3 className="Alert">We aren't answering for them currently</h3>
        )}
        <Accordion>
          <Accordion.Item eventKey="General Info Table">
            <Accordion.Header>
              <h3>General Funeral Home Info Table:</h3>
            </Accordion.Header>
            <Accordion.Body style={{ overflowY: "scroll", height: "60vh" }}>
              <GeneralFHInfoTable
                currentUser={currentUser}
                canEdit={canEdit}
                Funeralhome={Funeralhome}
              ></GeneralFHInfoTable>
            </Accordion.Body>
          </Accordion.Item>
          {/* <Accordion.Item eventKey='Messages'>
                    <Accordion.Header>
                    <h3>
                    Messages                        
                    </h3>
                    </Accordion.Header>{Funeralhome?.messages && Funeralhome?.messages.length === 0 ? 
                    <Accordion.Body>
                    <p>No Messages</p>
                    </Accordion.Body>
                    :
                    <Accordion.Body style={{display: 'flex', flexWrap: 'wrap'}}>
                {Funeralhome?.messages && Funeralhome?.messages.map((Message) => (
                     <Card key={Message._id} style={{border: 'solid 2px black', margin: '1px', width: '35vw', borderRadius: '2vh'}}>
                       <Card.Header>
                       {Message.fhLocation !== "" && Message.fhLocation !== null ?
                       <>For: {Message.fhLocation}</>:
                       <>
                       They didn't specify the Location they would like to use
                       </>}
                       </Card.Header>
                       <Card.Body>
                     <h4>Taken By: {Message.author} on: <br/>{Message.messageTaken.date} at: {Message.messageTaken.time}</h4>
                     <p>Called in by: {Message.callersName} # {Message.callersNumber}</p>
                     <div>
                     <p>Taken at {Message.messageTaken.date} at: {Message.messageTaken.time}</p>
                     </div>
                     {Message.messageStatus === "taken" ? 
                     <Button id={Message._id} onClick={handleRecieved}>Message Revieved</Button>
                     :
                     <p>Recieved at {Message.respondTime}</p>
                     }
                     <p>Message: {Message.messageText}</p>
                     </Card.Body>
                     <Card.Footer>
                     {Message.whoItsFor !== "" && Message.whoItsFor !== null ?
                         <p>They Asked for {Message.whoItsFor}</p> :
                         <p>They didn't specify who the message is for</p>
                     }
                     </Card.Footer>
                    </Card>
                ))
                }
                </Accordion.Body>
                }
                </Accordion.Item> */}
          <Accordion.Item eventKey={"Locations"}>
            <Accordion.Header>
              <h3>Locations:</h3>
            </Accordion.Header>
            <Accordion.Body>
              {canEdit ? (
                <Tabs
                  justify
                  onSelect={(key) => setLoactionKey(key)}
                  activeKey={locationKey}
                >
                  <Tab eventKey={"List"} title="Location List">
                    <LocationList
                      changeFuneralhomes={changeFuneralhomes}
                      currentUser={currentUser}
                      Funeralhome={Funeralhome}
                    ></LocationList>
                  </Tab>
                  <Tab eventKey={"Form"} title="Location Form">
                    <FuneralHomesLocationForm
                      changeFuneralhomes={changeFuneralhomes}
                      funeralhome={Funeralhome}
                    ></FuneralHomesLocationForm>
                  </Tab>
                </Tabs>
              ) : (
                <LocationList
                  currentUser={currentUser}
                  Funeralhome={Funeralhome}
                ></LocationList>
              )}
            </Accordion.Body>
          </Accordion.Item>
          {Funeralhome?.doWeTrack?.services && (
            <Accordion.Item eventKey="Services">
              <Accordion.Header>
                <h3>Services:</h3>
              </Accordion.Header>
              <Accordion.Body>
                <Accordion>
                  <Accordion.Item eventKey="Form">
                    <Accordion.Header>
                      <h4>Services Forms:</h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      <Tabs justify>
                        <Tab title="Service Form" eventKey="Service">
                          <ServicesForm
                            loading={loading}
                            Funeralhome={Funeralhome}
                          ></ServicesForm>
                        </Tab>
                        {Funeralhome?.services.length > 0 && (
                          <Tab title="Service Info Form" eventKey="Info">
                            <ServiceInfoForm
                              loading={loading}
                              Funeralhome={Funeralhome}
                            ></ServiceInfoForm>
                          </Tab>
                        )}
                      </Tabs>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="List">
                    <Accordion.Header>
                      <h4>Service List:</h4>
                    </Accordion.Header>
                    <Accordion.Body>
                      <ServicesList
                        currentUser={currentUser}
                        Funeralhome={Funeralhome}
                      ></ServicesList>
                    </Accordion.Body>
                  </Accordion.Item>
                  {canEdit && (
                    <Accordion.Item eventKey="Options">
                      <Accordion.Header>
                        <h4>Service Options:</h4>
                      </Accordion.Header>
                      <Accordion.Body>
                        <ServiceOptions
                          Funeralhome={Funeralhome}
                        ></ServiceOptions>
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                </Accordion>
                <div></div>
              </Accordion.Body>
            </Accordion.Item>
          )}
          <Accordion.Item eventKey="Directors">
            <Accordion.Header>
              <h3>Directors:</h3>
            </Accordion.Header>
            <Accordion.Body>
              {canEdit ? (
                <Tabs justify>
                  <Tab
                    title="Director List"
                    eventKey="List"
                    className="SingleFHDirectorList"
                  >
                    <FHDirectorList
                      currentPage={"singleFHPage"}
                      currentUser={currentUser}
                      directors={Funeralhome?.directors}
                      funeralhome={Funeralhome}
                      closeDirectorList={() => {}}
                    ></FHDirectorList>
                  </Tab>
                  <Tab title="Director Form" eventKey="Form">
                    <DirectorsForm
                      value={Funeralhome?.name}
                      page={"SingleFH"}
                    ></DirectorsForm>
                  </Tab>
                </Tabs>
              ) : (
                <div className="SingleFHDirectorList">
                  <FHDirectorList
                    currentPage={"singleFHPage"}
                    currentUser={currentUser}
                    directors={Funeralhome?.directors}
                    funeralhome={Funeralhome}
                    closeDirectorList={() => {}}
                  ></FHDirectorList>
                </div>
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="On Calls">
            <Accordion.Header>
              <h3>On Calls:</h3>
            </Accordion.Header>
            <Accordion.Body>
              <Accordion>
                <Accordion.Item eventKey="Scheduled">
                  <Accordion.Header>
                    <h4>Scheduled On Calls:</h4>
                  </Accordion.Header>
                  <Accordion.Body>
                    {!loading && (
                      <ScheduledDirectors
                        Funeralhome={Funeralhome}
                      ></ScheduledDirectors>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="Scheduling">
                  <Accordion.Header>
                    <h4>Scheduling On Calls:</h4>
                  </Accordion.Header>
                  <Accordion.Body>
                    {!loading && (
                      <ScheduleOnCalls
                        currentFH={Funeralhome}
                      ></ScheduleOnCalls>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="OnCallList">
                  <Accordion.Header>
                    <h4>Directors who can be on call</h4>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Card>
                      <Card.Header>On Call Director Pool</Card.Header>
                      <Card.Body
                        className="SingleFHDirectorList"
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <FHDirectorPoolList
                          closeDirectorList={() => {}}
                          currentUser={currentUser}
                          funeralhome={Funeralhome}
                          directors={Funeralhome?.on_calls?.directorPool}
                        ></FHDirectorPoolList>
                      </Card.Body>
                    </Card>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="List">
                  <Accordion.Header>
                    <h4>Current On Calls</h4>
                  </Accordion.Header>
                  <Accordion.Body>
                    <SingleFHOnCalls
                      loading={loading}
                      funeralhome={Funeralhome}
                    ></SingleFHOnCalls>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="Flower Shopes">
            <Accordion.Header>
              <h3>Flower Shops:</h3>
            </Accordion.Header>
            <Accordion.Body>
              {canEdit ? (
                <Tabs justify>
                  <Tab eventKey="List" title="Flower Shop List">
                    <Flowershops
                      flowershops={Funeralhome?.flower_shops}
                      funeralhome={Funeralhome?.name}
                      canEdit={canEdit}
                    ></Flowershops>
                  </Tab>
                  {canEdit && (
                    <Tab eventKey="Form" title="Flower Shop Form">
                      <FlowershopForm
                        funeralhome={Funeralhome?.name}
                      ></FlowershopForm>
                    </Tab>
                  )}
                </Tabs>
              ) : (
                <Flowershops
                  flowershops={Funeralhome?.flower_shops}
                  funeralhome={Funeralhome?.name}
                  canEdit={canEdit}
                ></Flowershops>
              )}
            </Accordion.Body>
          </Accordion.Item>
          {/*<Accordion.Item eventKey='First Calls'>
                    <Accordion.Header>
                        <h3>
                        First Calls
                        </h3>
                    </Accordion.Header>
                    <Accordion.Body>
                        <FHFirstCallList Funeralhome={Funeralhome}></FHFirstCallList>
                    </Accordion.Body>
            </Accordion.Item> */}
          {Funeralhome?.parentCompany !== null &&
            Funeralhome?.parentCompany?.funeralhomes.length > 1 && (
              <Accordion.Item eventKey="Affiliates">
                <Accordion.Header>
                  <h3>Affiliates:</h3>
                </Accordion.Header>
                <Accordion.Body>
                  Parent Company: {Funeralhome?.parentCompany.name}
                  <AffiliateList
                    funeralhomes={Funeralhome?.parentCompany?.funeralhomes.filter(
                      ({ name }) => {
                        return Funeralhome?.name !== name;
                      }
                    )}
                  ></AffiliateList>
                </Accordion.Body>
              </Accordion.Item>
            )}
        </Accordion>
      </Card.Body>
      {canEdit && (
        <Card.Footer
          style={{
            width: "100%",
            bottom: "0",
            background: "lightgrey",
            textAlign: "center",
          }}
        >
          <Button onClick={() => setEditSingleFH(true)}>
            Open Editing Important Info for {Funeralhome?.name}
          </Button>
        </Card.Footer>
      )}
    </Card>
  );
};
export default IndividualFH;
