import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { ADD_FUNERALHOME } from "../../utils/mutations";
import { Form, Button, Card } from "react-bootstrap";
const FuneralHomesForm = ({ currentUser }) => {
  const [AddFuneralHome] = useMutation(ADD_FUNERALHOME);
  const [formData, setFormData] = useState({
    emailList: [],
    name: "",
    emailInstructions: "",
    website: "",
  });
  const [formEmail, setFormEmail] = useState({ status: true, email: "" });
  const handleFHSubmit = async (e) => {
    e.preventDefault();
    try {
      await AddFuneralHome({
        variables: {
          ...formData,
          emailList: formData.emailList.map(({email}) => {
            return email;
          }),
          emailListChecked: formData.emailList.map(({ status }) => {
            return status;
          })
        }
      });
      setFormData({
        emailList: [],
        name: "",
        emailInstructions: "",
        website: "",
      });
    } catch (e) {
      console.error(e);
    }
  };
  const handleFormChange = (e) => {
    if (e.target.id === "emailList") {
      setFormData({ ...formData, [e.target.id]: [e.target.value] });
    } else if (e.target.id === "doWeSend") {
      setFormData({ ...formData, [e.target.id]: e.target.checked });
    } else {
      setFormData({ ...formData, [e.target.id]: e.target.value });
    }
  };
  const handleEmailListChange = (source, emailItem) => {
    if (source === "list") {
      if (formData.emailList.lenght === 1) {
        setFormData({ ...formData, emailList: [] });
      } else {
        setFormData({
          ...formData,
          emailList: formData.emailList.filter(({ email }) => {
            return email !== emailItem;
          }),
        });
      }
    } else {
      setFormData({
        ...formData,
        emailList: [...formData.emailList, formEmail],
      });
      setFormEmail({ status: true, email: "" });
    }
  };
  return (
    <Card style={{ textAlign: "center" }}>
      <Card.Header
        style={{
          position: "sticky",
          top: 0,
          zIndex: "1",
          background: "lightgrey",
        }}
      >
        <h1>Add Funeral Home</h1>
      </Card.Header>
      <Card.Body>
        <Form>
          <Form.Group>
            Funeral Home Name:
            <Form.Control
              id="name"
              onChange={handleFormChange}
              autoComplete={"off"}
              value={formData.name}
              autoFocus
              placeholder="Add Funeral Home"
            ></Form.Control>
            <br />
          </Form.Group>
          <Form.Group>
            <Form.Check
              onChange={handleFormChange}
              checked={formData.doWeSend}
              id="doWeSend"
              type="switch"
              label={"Do We Send First Call Forms"}
            ></Form.Check>
          </Form.Group>
          {formData?.doWeSend && (
            <>
              <Form.Group>
                <Form.Label>Emails For First Call Sheets:</Form.Label>
                <br />
                <Form.Check
                  checked={formEmail.status}
                  label="Active:"
                ></Form.Check>
                <Form.Control
                  style={{ width: "50vw" }}
                  value={formEmail.email}
                  onChange={(e) =>
                    setFormEmail({ ...formEmail, email: e.target.value })
                  }
                  autoComplete={"off"}
                  placeholder="Where to Send FC's"
                ></Form.Control>
                <br />
                <Button onClick={handleEmailListChange}>
                  Add Email to List
                </Button>
              </Form.Group>
              <>
                List of Emails to Be Added: <br />
                {formData.emailList.map(({ email, status }) => (
                  <div key={email}>
                    {!status ? "Inactive" : "Active"}: {email}
                    <Button
                      onClick={() => handleEmailListChange("list", email)}
                    >
                      Remove
                    </Button>
                    <br />
                  </div>
                ))}{" "}
                <br />
              </>
              <Form.Group>
                Email Instructions:
                <Form.Control
                  autoComplete="off"
                  placeholder="Email Instructions"
                  onChange={handleFormChange}
                  id="emailInstructions"
                  value={formData.emailInstructions}
                ></Form.Control>
              </Form.Group>
            </>
          )}

          <Form.Group>
            Website:
            <Form.Control
              id="website"
              value={formData.website}
              onChange={handleFormChange}
              autoComplete={"off"}
              placeholder="Link to Website"
            ></Form.Control>
            <br />
          </Form.Group>
        </Form>
      </Card.Body>
      <Card.Footer
        style={{
          position: "sticky",
          bottom: 0,
          zIndex: "1",
          background: "lightgrey",
        }}
      >
        <Button type="submit" onClick={handleFHSubmit}>
          Add Funeral Home
        </Button>
      </Card.Footer>
    </Card>
  );
};
export default FuneralHomesForm;
