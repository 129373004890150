import React from "react";
import { Card } from "react-bootstrap";
import MessagePendingCard from "../MessagePendingCard";
const MessagePendingList = ({
  pendingMessages,
  currentFHList,
  handlePending,
  handleMessageDataClear,
  handleAddedMessage,
}) => {
  return (
    <Card>
      <Card.Header>Pending:</Card.Header>
      <Card.Body
        className="scrollable-div"
        style={{
          display: "flex",
          flexWrap: "wrap",
          height: "60vh",
          overflowY: "scroll",
          justifyContent: "space-evenly",
        }}
      >
        {pendingMessages &&
          pendingMessages.map((message, index) => (
            <MessagePendingCard
              key={index}
              message={message}
              currentFHList={currentFHList}
              pendingMessages={pendingMessages}
              handlePending={handlePending}
              handleMessageDataClear={handleMessageDataClear}
              handleAddedMessage={handleAddedMessage}
            ></MessagePendingCard>
          ))}
        {!pendingMessages.length > 0 && <>No Current Pending</>}
      </Card.Body>
    </Card>
  );
};
export default MessagePendingList;
